import editSvg from'../../icons/edit.svg'
import userSvg from'../../icons/user.svg'
import bookSvg from'../../icons/book.svg'
import fitnessSvg from'../../icons/fitness.svg'

const matronLinks=[
    {
        title:"View Complaints",
        to:"sergeant/complaints",
        icon:bookSvg,
    },
    
]

export {matronLinks}