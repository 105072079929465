import axios from 'axios'
import React, { useState, useContext, useEffect } from 'react'
import { Autocomplete, Checkbox, FormControlLabel, Switch, TextField, Input } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { UserContext } from '../../../Contexts/UserContext';
import { baseUrl } from '../../../baseUrl';
import AlertDialog from '../../../components/AlertDialog';
import ConfirmDialog from '../../../components/ConfirmDialog';
import { Table, Button, Upload, message } from 'antd';
import dayjs from 'dayjs';


function AdmitStudentClerk() {
    const [admissionNo, setAdmissionNo] = useState("")
    const [category, setCategory] = useState("promote")
    const [yearState, setYearState] = useState("1")


    const [verifyDistance, setVerifyDistance] = useState(false)
    const [verifyAdmiCategory, setVerifyAdmiCategory] = useState(false)
    const [verifyScholarshipCategory, setVerifyScholarshipCategory] = useState(false)
    const [verifyIncome, setVerifyIncome] = useState(false)
    const [checkDistance, setCheckDistance] = useState(false)
    const [mTechStatus, setMTechStatus] = useState(false)
    const [parentName, setParentName] = useState("");
    const [parentNumber, setParentNumber] = useState("");
    const [messInDate, setMessInDate] = useState(dayjs().format('YYYY-MM-DD'));

    const [income, setIncome] = useState()
    const [admissionCategory, setAdmissionCategory] = useState()
    const [scholarshipCategory, setScholarshipCategory] = useState()
    const [distance, setDistance] = useState()



    const [verified, setVerified] = useState({
        admissionCategory: false,
        scholarshipCategory: false,
        annualFamilyIncome: false,
        distance: false,
    });


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setApplicant(prevData => ({
            ...prevData,
            [name]: value
        }));
    };



    const [submitted, setSubmitted] = useState(false);

    const renderField = (label, name, isEditable = false) => (
        <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-1">
                {label}:
            </label>
            <div className="flex items-center">
                <input
                    type="text"
                    name={name}
                    value={applicant[name]}
                    onChange={isEditable ? handleInputChange : undefined}
                    readOnly={!isEditable || submitted}
                    className={`flex-grow px-3 py-2 border ${isEditable && !submitted ? 'border-gray-400' : 'border-gray-300'} rounded-md ${isEditable && !submitted ? 'bg-white' : 'bg-gray-50'} focus:outline-none focus:ring-2 focus:ring-gray-500 transition duration-150 ease-in-out`}
                />
                {isEditable && !submitted && (
                    <div className="ml-2 flex items-center">
                        <input
                            type="checkbox"
                            name={name}
                            checked={verified[name]}
                            onChange={handleCheckboxChange}
                            className="form-checkbox h-5 w-5 text-gray-600 transition duration-150 ease-in-out"
                        />
                        <span className="ml-2 text-sm text-gray-600">Verified</span>
                    </div>
                )}
            </div>
        </div>
    );

    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        setVerified(prevVerified => ({
            ...prevVerified,
            [name]: checked
        }));
    };

    console.log('yearState', yearState)
    const years = [
        "First Year",
        "Second Year",
        "Third Year",
        "Fourth Year",
        ""
    ]
    const { setLoading, user } = useContext(UserContext);
    console.log('user', user)

    const [open1, setOpen1] = useState("");
    const [open2, setOpen2] = useState("");
    const [modalText, setModalText] = useState("");
    const [modalHeading, setModalHeading] = useState("");

    const [applications, setApplications] = useState([]);

    useEffect(() => {
        axios.get(`${baseUrl}/warden/get-all-applicants`).then((res) => {
            setApplications(res.data);

            console.log('res.datvhjva', res.data)
        });
    }, [])



    const handleVerifyAllotment = async () => {
        // setLoading(true)

        if (applicant.distance > distance || applicant.afi < income) {
            await axios.get(`${baseUrl}/warden/verify-allotment`, { params: { admission_no: admissionNo, distance: distance, afi: income, admissionCategory: admissionCategory, scholarshipCategory: admissionCategory } }).then(res => {
                setLoading(false)
                setModalHeading("Request Completed ");
                setModalText(`New rank computed is within the allotted list and hence the applicant can be admitted now itself                `);
                setAdmitStatus(res.data.admit_status)
                console.log('res.data', res.data)
                setOpen1(true);

            }).catch(err => {
                setLoading(false)
                setModalHeading("Score Recomputed");
                setModalText(err.response.data.msg);
                setOpen1(true);
                setAdmitStatus(false)


            })
        }
        else if (applicant.admission_category != admissionCategory) {
            await axios.get(`${baseUrl}/warden/verify-allotment`, { params: { admission_no: admissionNo, distance: distance, afi: income, admissionCategory: admissionCategory, scholarshipCategory: admissionCategory } }).then(res => {
                setLoading(false)
                // setModalHeading("Request Completed ");
                // setModalText(`New rank computed is within the allotted list and hence the applicant can be admitted now itself                `);
                setAdmitStatus(res.data.admit_status)
                // console.log('res.data', res.data)
                // setOpen1(true);

            }).catch(err => {
                setLoading(false)
                setModalHeading("Score Recomputed ");
                setModalText(err.response.data.msg);
                setOpen1(true);
                setAdmitStatus(false)


            })
        }
        else {
            setAdmitStatus(true)
        }
    }
    const handleSubmit = async () => {
        // setLoading(true)
        await axios.get(`${baseUrl}/warden/admit-student`, { params: { admission_no: admissionNo, distance: distance, afi: income, parentName: parentName, parentNumber: parentNumber, messInDate: messInDate } }).then(res => {
            setLoading(false)
            setModalHeading("Request Completed ");
            setModalText(`Applicant is Successfully admitted with hostel Admission No : ${res.data.HostelAdmissionNo} and is added in to the inmate list.`);
            setOpen1(true);

        }).catch(err => {
            setLoading(false)
            setSubmitted(true)
            setModalHeading("Error ");
            setModalText(err.response.data.msg);
            setOpen1(true);

        })
    }
    const [applicant, setApplicant] = useState([])


    const [admitStatus, setAdmitStatus] = useState(false)
    useEffect(() => {
        axios.get(`${baseUrl}/warden/get-individual-fee`, { params: { admissionNo: admissionNo, mTechStatus: mTechStatus } }).then(res => {
            setFees(res.data)
            console.log('res.data', res.data)
            setFeeStatus(res.data.map(fee => {
                return { ...fee, status: false }
            }
            ))
        })
    }, [mTechStatus, admissionNo, admitStatus])

    // useEffect(() => {
    //     const getApplicantDetails = setTimeout(() => {
    //         setApplicant([]);
    //         axios.get(`${baseUrl}/warden/get-hostel-applicant`, { params: { admissionNo: admissionNo } })
    //             .then((res) => {
    //                 setApplicant(res.data);
    //                 console.log('res.datass', res.data)
    //             })
    //     }, 3000)

    //     return () => clearTimeout(getApplicantDetails)
    // }, [admissionNo])



    const [fees, setFees] = useState([])
    // useEffect(() => {
    //     axios.get(`${baseUrl}/warden/get-all-fee`).then(res => {
    //         setFees(res.data)
    //         console.log('res.data', res.data)
    //         setFeeStatus(res.data.map(fee => {
    //             return { ...fee, status: false }
    //         }
    //         ))
    //     })
    // }, [])
    const [feeStatus, setFeeStatus] = useState([])

    const handleFeePayment = async (feeId, status) => {
        try {
            console.log('feeId', feeId)
            setFeeStatus(feeStatus.map(fee => fee.fee_id === feeId ? { ...fee, status: status } : fee));
            console.log('feeStatus', feeStatus)
        } catch (error) {
            console.error('Error updating fee payment status:', error);
        }
    };

    const [disableStatus, setDisableStatus] = useState()
    const [studentCategory, setStudentCategory] = useState('General');
    const [totalFee, setTotalFee] = useState(0);
    const [allChecked, setAllChecked] = useState(false);
    const [showAlert, setShowAlert] = useState(false);

    useEffect(() => {

        setDisableStatus(feeStatus.every(fee => fee.status))
        calculateTotalFees()

    }, [feeStatus, mTechStatus])


    const [editMode, setEditMode] = useState({
        10: false, // Advance Rent
        11: false, // Caution Deposit (General)
        12: false, // Caution Deposit (SC/ST/OEC)
        1: false,  // Mess Advance
    });

    const handleEditMode = (feeId) => {
        setEditMode(prev => ({ ...prev, [feeId]: !prev[feeId] }));
    };

    const handleValueChange = (feeId, newValue) => {
        setFeeStatus(feeStatus.map(fee =>
            fee.fee_id === feeId ? { ...fee, value: parseFloat(newValue) || 0 } : fee
        ));
    };
    const calculateTotalFees = () => {
        let total = feeStatus.reduce((sum, fee) => {
            if (fee.status) {
                if (fee.fee_id == 11 || fee.fee_id == 12) {
                    if (['SC', 'ST', 'OEC'].includes(applicant.admission_category) &&
                        (applicant.programme === 'UG' || (applicant.programme === 'PG' && !mTechStatus))) {
                        // message.warning(`file uploaded successfully`);

                        return sum + parseFloat(fee.value);
                    } else {
                        return sum + parseFloat(fee.value);
                    }
                    // } else if (fee.applies_to === 'ALL' || fee.applies_to.includes(applicant?.admission_category)) {
                }
                return sum + parseFloat(fee.value);
            }
            return sum;
        }, 0);

        setTotalFee(total);
    };
    return (
        <div className="w-11/12">
            <h2 className="font-bold ">Enter Details
            </h2>
            {/* <div className="bg-gradient-to-br from-slate-100 to-gray-200 min-h-screen p-8">
                <div className="max-w-2xl mx-auto bg-white rounded-lg shadow-lg overflow-hidden">
                    <div className="bg-gray-800 text-white py-4 px-6">
                        <h1 className="text-2xl font-bold">Hostel Admission Form</h1>
                    </div>
                    <div className="p-6">
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
                            {renderField('Admission No', 'admission_no')}
                            {renderField('Name', 'name')}
                            {renderField('Department', 'department')}
                            {renderField('Programme', 'programme')}
                            {renderField('Year of Admission', 'year_of_admission')}
                            {renderField('Type of Admission', 'type_of_admission')}
                            {renderField('Postoffice', 'post_office')}
                            {renderField('District', 'district')}
                        </div>
                        <div className="bg-gray-100 p-4 rounded-md mb-6">
                            <h2 className="text-lg font-semibold text-gray-800 mb-3">Verification Required</h2>
                            <div className="space-y-4">
                                {renderField('Admission Category', 'admission_category', true)}
                                {renderField('Scholarship Category', 'scholarship_category', true)}
                                {renderField('Annual Family Income', 'afi', true)}
                                {renderField('Distance', 'distance', true)}
                            </div>
                        </div>
                        {!submitted && (
                            <div className="text-center">
                                <button
                                    onClick={handleSubmit}
                                    className="bg-gray-800 text-white px-6 py-2 rounded-md hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-105"
                                >
                                    Submit Application
                                </button>
                            </div>
                        )}
                        {submitted && (
                            <div className="mt-8">
                                <h2 className="text-xl font-semibold text-gray-800 mb-4">Hostel Fees</h2>
                                <div className="space-y-2">
                                    {feeStatus.map((fee, index) => (
                                        <div key={index} className="flex justify-between items-center">
                                            <span>{fee.fee_name}:</span>
                                            <span className="font-medium">Rs. {fee.value}/-</span>
                                        </div>
                                    ))}
                                    <div className="border-t pt-2 mt-2">
                                        <div className="flex justify-between items-center font-semibold text-lg">
                                            <span>Total Fee:</span>
                                            <span>Rs. {totalFee}/-</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-6 text-center">
                                    <button className="bg-gray-800 text-white px-6 py-2 rounded-md hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-105">
                                        Proceed to Payment
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div> */}
            <div className="grid grid-cols-2 w-100 gap-3 mb-3 w-full">
                <label htmlFor="">Enter College Admission No : </label>{" "}

                <Autocomplete
                    id="auto-complete"
                    options={applications}
                    getOptionLabel={(option) => option.admission_no}
                    sx={{ width: 300, outline: "null" }}
                    onChange={(event, newValue) => {

                        axios.get(`${baseUrl}/warden/get-hostel-applicant`, { params: { admissionNo: newValue.admission_no } })
                            .then((res) => {
                                setApplicant(res.data);
                                setDistance(res.data.distance)
                                if (res.data.distance >= 20) {
                                    setCheckDistance(true)
                                }
                                setIncome(res.data.afi)
                                setAdmissionCategory(res.data.admission_category)
                                setScholarshipCategory(res.data.scholarship_category)
                                setAdmitStatus(false)
                                console.log('res.datass', res.data)
                            }).catch(err => {
                                console.log('err', err)
                                setLoading(false)
                                setModalHeading("Request Completed ");
                                setModalText(err.response.data.msg);
                                setOpen1(true);
                                setAdmitStatus(false)

                            })
                        setAdmissionNo(newValue.admission_no)
                    }}
                    renderInput={(params) => <TextField {...params} label="Admission no" variant="standard" />}

                />
                {applicant.length !== 0 && <>
                    <label htmlFor="">Name :</label>{" "}
                    <p>{applicant?.name}</p>
                    <label htmlFor="">Department :</label>{" "}
                    <p>{applicant?.department}</p>
                    <label htmlFor="">Programme :</label>{" "}
                    <p>{applicant?.programme}</p>
                    <label htmlFor="">Year of Admission :</label>{" "}
                    <p>{applicant?.year_of_admission}</p>
                    <label htmlFor="">Type of Admission :</label>{" "}
                    <p>{applicant?.type_of_admission}</p>
                    <label htmlFor="">Postoffice :</label>{" "}
                    <p>{applicant?.post_office}</p>
                    <label htmlFor="">District:</label>{" "}
                    <p>{applicant?.district}</p>
                    <label htmlFor=""> Admission Category :</label>{" "}
                    <div className='flex justify-between items-center '>
                        {verifyAdmiCategory ? <p className='p-2'>{admissionCategory}</p> :
                            <select
                                className="p-3 ring-slate-200 ring-2 rounded-xl outline-none"
                                name="admission_category"
                                value={admissionCategory}
                                onChange={e => {
                                    if (e.target.value == "SC")
                                        setScholarshipCategory('SC')
                                    else if (e.target.value == "ST")
                                        setScholarshipCategory('ST')
                                    else if (e.target.value == "GOIN")
                                        setScholarshipCategory('Other')

                                    setAdmissionCategory(e.target.value)


                                }}
                            >
                                <option value={"SC"}>SC</option>
                                <option value={"ST"}>ST</option>
                                <option value={"Ph"}>Ph</option>
                                <option value={"BPL"}>BPL</option>
                                <option value={"GOIN"}>GOIN</option>
                                <option value={"Other"}>Other</option>
                            </select>

                        }
                        <FormControlLabel
                            control={<Checkbox
                                checked={verifyAdmiCategory}
                                onChange={(event) => {
                                    if (!event.target.checked) setAdmitStatus(false)
                                    setVerifyAdmiCategory(event.target.checked);
                                }} />}

                            label={verifyAdmiCategory ? "Verified" : "Verify/Edit"}
                        />
                    </div>
                    <label htmlFor=""> Scholarship Category:</label>{" "}
                    <div className='flex justify-between w-1/8 items-center '>
                        {
                            verifyScholarshipCategory ? <p className='p-2'>{scholarshipCategory}</p> :
                                <select
                                    className="p-3 ring-slate-200 ring-2 rounded-xl outline-none"
                                    name="scholarship_category"
                                    value={scholarshipCategory}
                                    onClick={e => {
                                        message.warning("Proceed to edit Scholarship Category")

                                    }}
                                    onChange={e => {
                                        if (admissionCategory == "SC")
                                            setScholarshipCategory('SC')
                                        else if (admissionCategory == "ST")
                                            setScholarshipCategory('ST')
                                        else if (admissionCategory == "GOIN")
                                            setScholarshipCategory('Other')
                                        else setScholarshipCategory(e.target.value)


                                    }
                                    }
                                >
                                    <option value={"SC"}>SC</option>
                                    <option value={"ST"}>ST</option>
                                    <option value={"OBC(H)"}>OBC(H)</option>
                                    <option value={"OEC"}>OEC</option>
                                    <option value={"Fisherman"}>Fisherman</option>
                                    <option value={"Ph"}>Ph</option>
                                    <option value={"Other"}>Other</option>
                                </select>

                        }
                        <FormControlLabel
                            control={<Checkbox
                                checked={verifyScholarshipCategory}
                                onChange={(event) => {
                                    if (!event.target.checked) setAdmitStatus(false)
                                    setVerifyScholarshipCategory(event.target.checked);
                                }} />}

                            label={verifyScholarshipCategory ? "Verified" : "Verify/Edit"}
                        />
                    </div>
                    <label htmlFor=""> Annual Family Income :</label>{" "}
                    <div className='flex justify-between w-1/8 items-center '>
                        {verifyIncome ? <p className='p-2'>{income}</p> : <input onChange={(e) => { setIncome(e.target.value) }} value={income} type='number' min={10000} max={1000000000} className='ring-slate-200 p-2 w-1/2 ring-2 rounded-xl outline-none' />

                        }
                        <FormControlLabel
                            control={<Checkbox
                                checked={verifyIncome}
                                onChange={(event) => {
                                    if (!event.target.checked) setAdmitStatus(false)
                                    setVerifyIncome(event.target.checked);
                                }} />}

                            label={verifyIncome ? "Verified" : "Verify/Edit"}
                        />
                    </div>
                    <label htmlFor=""> Distance:</label>{" "}
                    <div className='flex justify-between w-1/8 items-center '>
                        {verifyDistance ? <p className='p-2'>{distance}</p> : <input onChange={(e) => {
                            if (e.target.value >= 20) {
                                setCheckDistance(true)
                            }
                            else {
                                setCheckDistance(false)

                            }
                            setDistance(e.target.value)
                        }
                        } value={distance} min={0} max={600} type='number' className='ring-slate-200 p-2 w-1/2 ring-2 rounded-xl outline-none' />

                        }
                        <FormControlLabel
                            control={<Checkbox
                                checked={verifyDistance}
                                onChange={(event) => {
                                    if (!event.target.checked) setAdmitStatus(false)
                                    setVerifyDistance(event.target.checked);
                                }} />}

                            label={verifyDistance ? "Verified" : "Verify/Edit"}

                        />
                    </div>
                    {applicant.programme == 'PG' && (applicant.scholarship_category == 'SC' || applicant.scholarship_category == 'ST' || applicant.scholarship_category == 'OEC') &&
                        <div className='flex justify-end w-4/8 items-center '>
                            <label className='mx-3'>Check Only if the applicant programme is M-Tech </label>
                            <FormControlLabel
                                control={<Checkbox
                                    checked={mTechStatus}
                                    onChange={(event) => {
                                        setMTechStatus(event.target.checked);
                                    }} />}


                            />
                        </div>

                    }
                    {distance < 20 && <div className='p-3 gap-2 w-full col-span-2'>
                        {/* <h2 className="text-xl font-bold">Note</h2> */}

                        <p className='text-black'><InfoIcon className='text-black' /> Obtained Principal's permission which is issued before the last date to apply for Hostel admission

                            <FormControlLabel
                                className='p-2'
                                control={<Checkbox
                                    checked={checkDistance}
                                    onChange={(event) => {
                                        if (!event.target.checked) setAdmitStatus(false)
                                        setCheckDistance(event.target.checked);
                                    }} />}

                            />
                        </p>
                    </div>}


                </>
                }

            </div>
            {/* <div className="bg-gray-100 p-4 rounded-md mb-6">
                <h2 className="text-lg font-semibold text-gray-800 mb-3">Verification Required</h2>
                <div className="space-y-4">
                    {renderField('Admission Category', 'admission_category', true)}
                    {renderField('Scholarship Category', 'scholarship_category', true)}
                    {renderField('Annual Family Income', 'afi', true)}
                    {renderField('Distance', 'distance', true)}
                </div>
            </div> */}



            {
                admitStatus == true &&
                <div>
                    <h2 className="font-bold ">Parent Details</h2>
                    <div className="grid grid-cols-2 w-100 gap-3 mb-3 w-full">
                        <label className='p-2'>Parent Name :</label>  <input onChange={(e) => { setParentName(e.target.value) }} type='text' className='ring-slate-200 p-2 ring-2 rounded-xl outline-none' />
                        <label className='p-2'>Parent Number :</label> <input onChange={(e) => { setParentNumber(e.target.value) }} type='number' className='ring-slate-200 p-2  ring-2 rounded-xl outline-none' />
                        <label className='p-2'>Mess in Date :</label>  <input
                            type="date"
                            value={messInDate}
                            min={dayjs().format('YYYY-MM-DD')}
                            max={dayjs().add(2, 'day').format('YYYY-MM-DD')}
                            onChange={(e) => {
                                setMessInDate(e.target.value);
                            }}
                            className="w-12/12 py-2 px-3 rounded-xl ring-2 ring-slate-300 focus:outline-none"
                            required
                        />
                    </div>
                    <h2 className="font-bold ">Hostel Fees</h2>
                    {feeStatus.map((fee) => (
                        <div key={fee.fee_id} className="flex justify-between items-center border-b pb-2">
                            <div className="flex items-center space-x-2">
                                <Checkbox
                                    id={`fee-${fee.fee_id}`}
                                    checked={fee.status}
                                    onChange={(event) => handleFeePayment(fee.fee_id, event.target.checked)}
                                />
                                <label htmlFor={`fee-${fee.fee_id}`}>{fee.fee_name}</label>
                            </div>
                            <div className="flex items-center space-x-2">
                                {fee.editable ? (
                                    <>
                                        {editMode[fee.fee_id] ? (
                                            <Input
                                                type="number"
                                                value={fee.value}
                                                onChange={(e) => handleValueChange(fee.fee_id, e.target.value)}
                                                className="w-24"
                                            />
                                        ) : (
                                            <span>Rs. {fee.value}/-</span>
                                        )}
                                        <button
                                            onClick={() => handleEditMode(fee.fee_id)}
                                            className="px-2 py-1 bg-blue-500 text-white rounded hover:bg-blue-600"
                                        >
                                            {editMode[fee.fee_id] ? 'Save' : 'Edit'}
                                        </button>
                                    </>
                                ) : (
                                    <span>Rs. {fee.value}/-</span>
                                )}
                            </div>
                        </div>
                    ))}
                    {/* {feeStatus.map((fee) => {
                        return (
                            <div className='flex justify-between items-center'>
                                <p className='p-2'>{"   " + fee.fee_name} :</p>
                                <div className='flex items-center '>
                                    <span className="ml-2">Rs. {fee.value}/-</span>
                                    <Checkbox checked={fee.status} onChange={(event) => handleFeePayment(fee.fee_id, event.target.checked)} />
                                    <label>Paid</label>
                                </div>


                            </div>

                        )
                    })} */}
                    <div className="mt-4">
                        <strong>Total Fee: Rs. {totalFee}/-</strong>
                    </div>
                </div>

            }
            < div className="w-full flex items-end justify-end mt-5">

                {admitStatus ?
                    <button disabled={!disableStatus || parentName == "" || parentNumber == ""} onClick={(e) => {
                        e.preventDefault()
                        setModalHeading("Confirm")
                        // handleSubmit()
                        setModalText("Do you wish to continue.")
                        setOpen2(true)
                    }} className={disableStatus && parentName != "" && parentNumber != "" ? " bg-stone-800 text-white px-4 py-3 rounded-lg text-sm" : "bg-[#e0e0e0] text-[#a6a6a6] px-4 py-3 rounded-lg text-sm"}>Admit</button>
                    : <button disabled={verifyDistance == false || verifyIncome == false || verifyAdmiCategory == false || verifyScholarshipCategory == false || checkDistance == false} className={(verifyDistance == false || verifyIncome == false || verifyAdmiCategory == false || verifyScholarshipCategory == false || checkDistance == false) ? "bg-[#e0e0e0] text-[#a6a6a6] px-4 py-3 rounded-lg text-sm" : " bg-stone-800 text-white px-4 py-3 rounded-lg text-sm"} onClick={handleVerifyAllotment}>Submit</button>
                }

            </div>
            <AlertDialog
                open={open1}
                setOpen={setOpen1}
                modalHeading={modalHeading}
                modalText={modalText}
            />
            <ConfirmDialog
                open={open2}
                setOpen={setOpen2}
                modalHeading={modalHeading}
                modalText={modalText}
                confirmFunction={handleSubmit} />

        </div >
    )
}

export default AdmitStudentClerk