import React, { useEffect, useState } from 'react'
import { motion } from "framer-motion"
import axios from "axios";
import dayjs from 'dayjs'
import AlertDialog from '../../../components/AlertDialog';
import { baseUrl } from '../../../baseUrl';


function InviteApplicationCleark({ setInviteStatus }) {
    const [details, setDetails] = useState({})
    const [hostel, setHostel] = useState("MH")
    const [currentYear, setCurrentYear] = useState(1)
    const [programme, setProgramme] = useState("UG")
    const [type, setType] = useState([])
    const [years, setYears] = useState([])
    const [currpage, setCurrPage] = useState(1)
    const [openDate, setOpenDate] = useState()
    const [closeDate, setCloseDate] = useState()
    const [concern, setConcern] = useState()
    const [rankListDate, setRankListDate] = useState()
    const today = dayjs().format("YYYY-MM-DD")
    console.log('today', today)

    const [open1, setOpen1] = useState("");
    const [open2, setOpen2] = useState("");
    const [modalText, setModalText] = useState("");
    const [modalHeading, setModalHeading] = useState("");




    const options = [
        { value: 'Regular', label: 'Regular' },
        { value: 'College Transfer', label: 'College Transfer' },
        { value: 'LET', label: 'LET' }
    ]
    const colourStyles = {
        control: (styles) => ({ ...styles, backgroundColor: '#fffff', borderRadius: 10, borderColor: "#e3e8f0", padding: "4px" }),
    }

    // const today = new Date()
    const submitHandler = (e) => {
        console.log('hostel', currentYear)
        e.preventDefault()
        axios.post(`${baseUrl}/warden/invite-application`, {
            hostel: hostel,
            currentYear: currentYear,
            programme: programme,
            openDate: openDate,
            closeDate: closeDate,
            concern: concern,
            rankListDate: rankListDate,
        }).then(res => {
            console.log(res)
            setModalHeading("Request Completed ");
            setModalText("Application Invited Successfully .");
            setOpen1(true);
            setInviteStatus(true)
        }).catch(err => {
            console.log(err)
            setModalHeading("Request Completed ");
            setModalText(err.response.data.msg);
            setOpen1(true);
        })
    }

    useEffect(() => {
        axios.get(`${baseUrl}/warden/admission-years`).then(res => {
            setYears(res.data)
        })
    }, [])

    const [admiConfigs,setAdminConfigs] = useState()
    useEffect(() => {
        axios.get(`${baseUrl}/warden/get-hostel-admin-configs`).then(res => {
            console.log('res.data', res.data)
            setAdminConfigs(res.data)
        })
    }, [])


    return (
        <div className="w-11/12 ">
            <h2 className="font-bold">Enter Details</h2>
            <form action="" onSubmit={submitHandler}>
                <div className="grid grid-cols-2 gap-y-3 w-6/12">
                    <label htmlFor="" className="mt-2">Hostel :</label>
                    <select value={hostel} onChange={e => setHostel(e.target.value)} className="p-3 ring-slate-200 ring-2 rounded-xl outline-none">
                        <option value="MH">Mens Hostel</option>
                        <option value="LH">Ladies Hostel</option>
                    </select>
                    <label htmlFor="" className="mt-2">Year:</label>
                    <select value={currentYear} onChange={e => setCurrentYear(e.target.value)} className="p-3 ring-slate-200 ring-2 rounded-xl outline-none">
                        <option value="1" >First Year</option>
                        <option value="2">Second Year</option>
                        <option value="3">Third Year</option>
                        <option value="4">Fourth Year</option>

                    </select>
                    <label htmlFor="" className="mt-2">Programme :</label>
                    <select onChange={e => setProgramme(e.target.value)} className="p-3 ring-slate-200 ring-2 rounded-xl outline-none ">
                        <option value="UG">UG</option>
                        <option value="PG">PG</option>
                        <option value="Phd">Phd</option>
                    </select>

                    <label htmlFor="" className="mt-2">Open In :</label>
                    <input
                        type="date"
                        min={today}
                        value={openDate}
                        onChange={(e) => {
                            setOpenDate(e.target.value);
                        }}
                        className="w-12/12 py-2 px-3 rounded-xl ring-2 ring-slate-300 focus:outline-none"
                        required
                    />
                    <label htmlFor="" className="mt-2">Close In :</label>
                    <input
                        type="date"
                        min={dayjs(openDate).add(admiConfigs?.gap_invite_close, 'day').format("YYYY-MM-DD")}
                        value={closeDate}
                        onChange={(e) => {
                            setCloseDate(e.target.value);
                        }}
                        className="w-12/12 py-2 px-3 rounded-xl ring-2 ring-slate-300 focus:outline-none"
                        required
                    />
                    {closeDate && (<label htmlFor="" className="mt-2">Provisional Rank List:</label>)}
                    {closeDate && (<p className='p-3'>{dayjs(closeDate).add(admiConfigs?.gap_close_provisional, 'day').format("DD-MM-YYYY")}</p>)}

                    <label htmlFor="" className="mt-2">Submiting Consern Till :</label>
                    <input
                        type="date"
                        min={dayjs(dayjs(closeDate).add(admiConfigs?.gap_close_provisional,'day')).add(admiConfigs?.gap_provisional_concern, 'day').format("YYYY-MM-DD")}
                        value={concern}
                        onChange={(e) => {
                            setConcern(e.target.value);
                            setRankListDate(dayjs(e.target.value).add(admiConfigs?.gap_concern_final, 'day').format("YYYY-MM-DD"));
                        }}
                        className="w-12/12 py-2 px-3 rounded-xl ring-2 ring-slate-300 focus:outline-none"
                        required
                    />
                    {concern && (<label htmlFor="" className="mt-2">Final Rank List and Allotment:</label>)}
                    {concern && (<p className='p-3'>{dayjs(concern).add(admiConfigs?.gap_concern_final, 'day').format("DD-MM-YYYY")}</p>)}

                </div>
                <div className="w-full flex items-center justify-end mt-5">
                    <motion.button whileHover={{ x: 10, backgroundColor: 'green' }} className="p-3 bg-stone-800 text-white rounded-xl" type="submit">Invite Application</motion.button>

                </div>
            </form>
            <AlertDialog
                open={open1}
                setOpen={setOpen1}
                modalHeading={modalHeading}
                modalText={modalText}
            />
        </div>
    )
}

export default InviteApplicationCleark