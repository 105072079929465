import {useState,useEffect} from 'react'
import AddMessSuspensionForm from './AddMessSuspensionForm';
import AddedMessSuspensionList from './AddedMessSuspensionList';


function UploadMessSuspentionListPage() {

const [res,setRes]=useState([])

  return (
   <div className='w-11/12'>
        <AddMessSuspensionForm setRes={setRes}/>
        <hr/>
        <AddedMessSuspensionList res={res}/>
    </div>
 
  )
}

export default UploadMessSuspentionListPage