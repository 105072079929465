import editSvg from'../../icons/edit.svg'
import userSvg from'../../icons/user.svg'
import bookSvg from'../../icons/book.svg'
import fitnessSvg from'../../icons/fitness.svg'

const ClerkA3Links=[
    {
        title:"Mess",
        to:"CLRKA3/messmanagement",
        icon: editSvg,
    },
    {
        title:"Mess Bill",
        to:"CLRKA3/manage-mess-bills",
        icon: editSvg,
    },
    {
        title:"Hostel Admission",
        to:"CLRKA3/hostel-admission",
        icon: editSvg,
    },
    {
        title:"Egrantz",
        to:"CLRKA3/egrants",
        icon: editSvg,
    },
    {
        title:"Mess Suspension",
        to:"CLRKA3/mess-suspension",
        icon: editSvg,
    },
    {
        title:"Manage User",
        to:"CLRKA3/manage-user",
        icon: editSvg,
    },

    
]

export {ClerkA3Links}