import React, { useEffect, useState } from 'react';
import { Input, Button, Form, message, Modal, AutoComplete } from 'antd';
import { EditOutlined, SaveOutlined, UndoOutlined, LockOutlined } from '@ant-design/icons';
import axios from 'axios';
import { baseUrl } from '../../../baseUrl';
import AlertDialog from '../../../components/AlertDialog';

const InmateProfile = () => {
    const [form] = Form.useForm();
    const [admissionNo, setAdmissionNo] = useState('');
    const [profile, setProfile] = useState(null);
    const [editableFields, setEditableFields] = useState({});
    const [loading, setLoading] = useState(false);
    const [open1, setOpen1] = useState(false);
    const [modalText, setModalText] = useState("");
    const [modalHeading, setModalHeading] = useState("");
    const [options, setOptions] = useState([]);


    useEffect(() => {
        const searchAdmissionNumbers = async () => {
            if (admissionNo.length > 2) {
                try {
                    const response = await axios.get(`${baseUrl}/warden/search-userid`, {
                        params: { query: admissionNo }
                    });
                    setOptions(response.data.map(no => ({ value: no })));
                } catch (error) {
                    console.error('Error searching admission numbers:', error);
                }
            } else {
                setOptions([]);
            }
        };

        searchAdmissionNumbers();
    }, [admissionNo])

    const fetchProfile = async () => {
        try {
            setLoading(true);
            const res = await axios.get(`${baseUrl}/warden/user-details`, { params: { admissionNo } });
            setProfile(res.data);
            form.setFieldsValue(res.data);
        } catch (error) {
            message.error('Failed to fetch profile');
        } finally {
            setLoading(false);
        }
    };

    const resetPassword = async () => {
        try {
            const res = await axios.get(`${baseUrl}/utils/reset-password-users`, { params: { user_id: admissionNo } });
            message.success(`Password has been reset`);
        } catch (error) {
            message.error('Failed to reset password');
        }
    };

    const handleEdit = (field) => {
        if (field === 'admission_no') {
            Modal.confirm({
                title: 'Warning',
                content: `You are attempting to edit a unique field (${field}) in the database system. Do you want to continue?`,
                onOk: () => setEditableFields({ ...editableFields, [field]: true }),
            });
        }
        if (field === 'hostel_admission_no') {
            Modal.confirm({
                title: 'Warning',
                content: `You are attempting to edit a unique field (${field}) in the database system. Do you want to continue?`,
                onOk: () => setEditableFields({ ...editableFields, [field]: true }),
            });
        }
        else {
            setEditableFields({ ...editableFields, [field]: true });
        }
    };

    const handleSave = async () => {
        try {
            const values = await form.validateFields();
            const updatedFields = Object.keys(editableFields).reduce((acc, field) => {
                if (editableFields[field]) {
                    acc[field] = values[field];
                }
                return acc;
            }, {});

            console.log('updatedFields', updatedFields)
            console.log('profile.admission_no', profile.admission_no)
            if (
                updatedFields.admission_no !== profile.admission_no ||
                updatedFields.hostel_admission_no !== profile.hostel_admission_no
            ) {
                const { data: { isDuplicate } } = await axios.post(`${baseUrl}/warden/check-duplicates`, {
                    admission_no: updatedFields.admission_no,
                    hostel_admission_no: updatedFields.hostel_admission_no,
                });

                if (isDuplicate) {
                    message.error('Cannot save changes. A duplicate entry exists.');
                    return;
                }
            }


            const res = await axios.put(`${baseUrl}/warden/update-user-details`, { admissionNo: admissionNo, UpdateValues: updatedFields });
            console.log('res', res)
            setProfile({ ...profile, ...updatedFields });
            setEditableFields({});
            message.success('Profile updated successfully');


        } catch (error) {
            message.error('Failed to update profile');
        }
    };

    const handleCancel = (field) => {
        form.setFieldsValue({ [field]: profile[field] });
        setEditableFields({ ...editableFields, [field]: false });
    };

    return (
        <div className="w-11/12 mx-auto p-6 bg-white rounded-lg shadow-md">
            <h1 className="text-xl font-bold mb-6 text-gray-800">View Details</h1>
            {!profile ? (
                <div className="mb-8 w-1/8 flex items-center justify-center gap-4">
                    <AutoComplete
                        value={admissionNo}
                        options={options}
                        onSelect={setAdmissionNo}
                        onSearch={setAdmissionNo}
                        placeholder="Enter User Id"
                        className="mb-2 w-4/12"
                    />
                    <button
                        onClick={fetchProfile}
                        disabled={loading}
                        className="flex items-center w-1/6 px-4 py-2 bg-gray-800 text-white rounded-md hover:bg-gray-700 transition-colors"
                    >
                        {loading ? 'Loading...' : 'View Profile'}
                    </button>
                </div>
            ) : (
                <div className='flex flex-col justify-between items-end'>
                    <Button
                        onClick={resetPassword}
                        className="mb-6 bg-red-500 hover:bg-red-600 text-white font-semibold py-2 px-4 rounded flex items-center"
                        icon={<LockOutlined />}
                    >
                        Reset Password
                    </Button>
                    <Form form={form} layout="horizontal" className='w-full'>
                        {Object.entries(profile).map(([key, value]) => (
                            <Form.Item key={key} label={key} className="mb-4">
                                <div className="flex items-center space-x-4">
                                    <div className="flex-grow font-medium text-gray-700">
                                        {editableFields[key] ? (
                                            <Form.Item
                                                name={key}
                                                className="mb-0"
                                            >
                                                <Input className="border-2 border-gray-300 focus:border-blue-500" />
                                            </Form.Item>
                                        ) : (
                                            value
                                        )}
                                    </div>
                                    <div className="flex items-center space-x-2">
                                        {editableFields[key] ? (
                                            <>
                                                <Button
                                                    onClick={() => handleSave()}
                                                    icon={<SaveOutlined />}
                                                    className="text-green-500 hover:text-green-600"
                                                />
                                                <Button
                                                    onClick={() => handleCancel(key)}
                                                    icon={<UndoOutlined />}
                                                    className="text-gray-500 hover:text-gray-600"
                                                />
                                            </>
                                        ) : (
                                            <Button
                                                onClick={() => handleEdit(key)}
                                                icon={<EditOutlined />}
                                                className="text-blue-500 hover:text-blue-600"
                                            />
                                        )}
                                    </div>
                                </div>
                            </Form.Item>
                        ))}
                    </Form>
                </div>
            )}
            <AlertDialog open={open1} setOpen={setOpen1} modalHeading={modalHeading} modalText={modalText} />
        </div>
    );
};

export default InmateProfile;