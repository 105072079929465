import React, { useState, useEffect } from 'react';
import { baseUrl } from '../../../baseUrl';
import axios from 'axios';
import { Table, Input, Button, message, Form, Modal, Popconfirm } from 'antd';


const HostelAdmissionConfig = () => {
  const [settings, setSettings] = useState({
    gap_invite_close: 0,
    gap_close_provisional: 0,
    gap_provisional_concern: 0,
    gap_concern_final: 0,
  });
  const names = {gap_invite_close:'Gap between open in and close in date',gap_close_provisional:'Gap between close in and provisional ranklist date',gap_provisional_concern:'Gap between  provisional ranklist and concern date',gap_concern_final:'Gap between concern in and final ranklist date'}
  const [editing, setEditing] = useState(null);
  const [tempValue, setTempValue] = useState('');
  const [selectedDate, setSelectedDate] = useState('2024-06-08');
  const [requests, setRequests] = useState(0);

  useEffect(() => {
    fetchSettings();
  }, [selectedDate])
  const fetchSettings = async () => {
    try {
      await axios.get(`${baseUrl}/warden/get-hostel-admin-configs`).then(res => {

        setSettings(res.data);
      })
    } catch (error) {
      console.error('Error fetching settings:', error);
    }
  };

  

  const handleEdit = (key, value) => {
    setEditing(key);
    setTempValue(value.toString());
  };

  const handleSave = async () => {
    if (editing) {
      try {
        axios.put(`${baseUrl}/warden/update-hostel-admin-configs`, { key: editing, value: parseInt(tempValue, 10) })
        setSettings(prev => ({ ...prev, [editing]: parseInt(tempValue, 10) }));
        message.success(`${editing.split('_').join(' ')} updated to ${parseInt(tempValue, 10)}`)
        setEditing(null);
      } catch (error) {
        message.error(`Failed to update`)
        console.error('Error updating setting:', error);
      }
    }
  };

  return (
    <div className="p-4 max-w-md mx-auto bg-white rounded-lg shadow-md">
      {Object.entries(settings).map(([key, value]) => (
        <div key={key} className="mb-4 flex items-center justify-between">
          <span className="text-gray-700">{names[key]}:</span>
          {editing === key ? (
            <div className="flex items-center">
              <input
                type="number"
                value={tempValue}
                onChange={(e) => setTempValue(e.target.value)}
                className="border rounded px-2 py-1 w-16 mr-2"
              />
              <button
                onClick={handleSave}
                className="bg-blue-500 text-white px-2 py-1 rounded"
              >
                Save
              </button>
            </div>
          ) : (
            <div className="flex items-center">
              <span className="mr-2">{value}</span>
              <button
                onClick={() => handleEdit(key, value)}
                className="bg-gray-200 px-2 py-1 rounded"
              >
                Edit
              </button>
            </div>
          )}
        </div>
      ))}

    </div>
  );
};

export default HostelAdmissionConfig;