import axios from 'axios'
import React, { useState, useContext, useEffect } from 'react'
import { Autocomplete, Checkbox, FormControlLabel, Switch, TextField } from '@mui/material';
import { UserContext } from '../../../Contexts/UserContext';
import { baseUrl } from '../../../baseUrl';
import AlertDialog from '../../../components/AlertDialog';
import ConfirmDialog from '../../../components/ConfirmDialog';


function PayHDF() {

    const { setLoading, user } = useContext(UserContext);
    console.log('user', user)

    const [admissionNo, setAdmissionNo] = useState("")


    const [open1, setOpen1] = useState("");
    const [open2, setOpen2] = useState("");
    const [modalText, setModalText] = useState("");
    const [modalHeading, setModalHeading] = useState("");

    const [applications, setApplications] = useState([]);
    const [applicant, setApplicant] = useState([])

    const [hdfAmount, setHdfAmount] = useState()


    const handleOptionChange = (event, newValue) => {

        axios.get(`${baseUrl}/warden/hdf-details`, { params: { admissionNo: newValue.admission_no, hdfAmount: hdfAmount } }).then((res) => {
            setApplicant(res.data);

            setHdfAmount(res.data.hdfAmount)

            console.log('res.datass', res.data)
        }).catch(err => {
            console.log('err', err)
            setLoading(false)
            setModalHeading("Request Completed ");
            setModalText(err.response.data.msg);
            setOpen1(true);

        })
        setAdmissionNo(newValue.admission_no)
    }


    const [fees, setFees] = useState([])

    useEffect(() => {
        axios.get(`${baseUrl}/warden/get-all-fee`).then(res => {
            setFees(res.data)
            console.log('res.datass', res.data)
            // console.log('rhi',res.data.find((fee) => fee.fee_id == 3))
        })
    }, [])
    const handleSubmit = () => {
        axios.post(`${baseUrl}/warden/update-hdf`, { admissionNo: admissionNo, hdfAmount: hdfAmount }).then((res) => {

            console.log('res.datass', res.data)
        }).then((res) => {
            setModalHeading("Request Completed ");
            setModalText("Amout Paid.");
            setLoading(false)
            setOpen1(true);

        }).catch(err => {
            console.log('err', err)
            setLoading(false)
            setModalHeading("Request Completed ");
            setModalText(err.response.data.msg);
            setOpen1(true);

        })



    }


    useEffect(() => {
        axios.get(`${baseUrl}/warden/get-all-applicants`).then((res) => {
            setApplications(res.data);

            console.log('res.datvhjva', res.data)
        });
    }, [])


    return (
        <div className='"w-11/12 ">'>
            <h2 className="font-bold ">Enter Details
            </h2>
            <form className="grid grid-cols-2 w-100 gap-3 mb-3 w-full" onSubmit={(e) => {
                e.preventDefault()
                setModalHeading("Confirm")
                setModalText(`HDF amount paid at the time of admission is ${applicant.hdfAmount} extended deadline is ${applicant?.deadLine} and remaining amount for which deadline is extended is ${applicant?.hdfAmount - hdfAmount}`)
                setOpen2(true)
            }}>
                <label htmlFor="">Enter College Admission No : </label>{" "}

                <Autocomplete
                    id="auto-complete"
                    options={applications}
                    getOptionLabel={(option) => option.admission_no}
                    sx={{ width: 300, outline: "null" }}
                    onChange={handleOptionChange}
                    renderInput={(params) => <TextField {...params} label="Admission no" variant="standard" />}

                />
                {applicant.length !== 0 && <>
                    <label htmlFor="">Name :</label>{" "}
                    <p>{applicant?.name}</p>
                    <label htmlFor="">Hostel Admission No :</label>{" "}
                    <p>{applicant?.admission_no}</p>
                    <label htmlFor="">Amount to be paid :</label>{" "}
                    <input onChange={(e) => {setHdfAmount(e.target.value) }} value={hdfAmount} min={0} max={applicant.hdfAmount} type='number' className='py-2 px-3 rounded-xl ring-2 ring-slate-300 focus:outline-none' required />
                    <button className={applicant?.allotment_published ? "bg-[#e0e0e0] text-[#a6a6a6] p-3 rounded-lg text-sm w-1/2" : " bg-stone-800 text-white p-3 rounded-lg text-sm w-1/2"} type='submit'>Submit</button>

                </>
                }
            </form>
            <AlertDialog open={open1} setOpen={setOpen1} modalHeading={modalHeading} modalText={modalText} />
            <ConfirmDialog open={open2} setOpen={setOpen2} modalHeading={modalHeading} modalText={modalText} confirmFunction={handleSubmit} />
        </div>
    )
}

export default PayHDF