import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Layout, Menu, Button, Drawer } from 'antd';
import {
    UserOutlined,
    KeyOutlined,
    LogoutOutlined,
    MenuOutlined,
} from '@ant-design/icons';
import { UserContext } from '../Contexts/UserContext';
import { baseUrl } from '../baseUrl';

const { Sider } = Layout;
const { SubMenu } = Menu;

function SideBar({ myLinks, roles, setRole, myActiveIndex, roleIndex, currentRole }) {
    const { user } = useContext(UserContext);
    const [activeIndex, setActiveIndex] = useState(-1);
    const [links, setLinks] = useState(myLinks);
    const [isMobile, setIsMobile] = useState(false);
    const [drawerVisible, setDrawerVisible] = useState(false);
    const navigate = useNavigate();

    const rolesMap = {
        'AA': "Assistant Administrator",
        'admin': 'admin',
        'SA': 'Staff Advisor',
        'HOD': 'HOD',
        'WD': 'Warden',
        'HO': 'Hostel Office',
        'MTRN': 'Matron',
        'SG': 'Seargant',
        'CLRKA1': 'Clerk A1',
        'CLRKA2': 'Clerk A2',
        'CLRKA3': 'Clerk A3',
        'CLRKB1': 'Clerk B1'
    };

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 850);
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        const myActiveIndex = localStorage.getItem('activeIndex') == null ? 0 : parseInt(localStorage.getItem('activeIndex'));
        const newLinks = myLinks.map(item => ({ ...item }));

        newLinks.forEach((link, linkIndex) => {
            if (linkIndex !== myActiveIndex && link.subLinks && link.subLinkOpen) {
                newLinks[linkIndex].subLinkOpen = false;
            }
        });

        setLinks(newLinks);

        if ((localStorage.getItem('subIndex') == null || localStorage.getItem('subIndex') === "-1") && myLinks.length > 0 && myLinks[0].subLinks) {
            localStorage.setItem('subIndex', JSON.stringify(0));
        }
    }, [myLinks]);

    const logout = () => {
        axios.get(`${baseUrl}/logout`, { withCredentials: true })
            .then(() => {
                navigate("/");
                window.location.reload();
            })
            .catch((error) => {
                console.log("FAILED!!! ", error);
            });
    };

    const onMenuClick = ({ key }) => {
        const [parentKey, childKey] = key.split('-');
        console.log('parentKey', parentKey)
        const parentIndex = parseInt(parentKey);
        const childIndex = childKey ? parseInt(childKey) : -1;

        if (childIndex === -1) {
            localStorage.setItem('subIndex', JSON.stringify(-1));
            localStorage.setItem('activeIndex', JSON.stringify(parentIndex));
            setActiveIndex(parentIndex);
            setDrawerVisible(false)

        } else {
            localStorage.setItem('subIndex', JSON.stringify(childIndex));
            localStorage.setItem('activeIndex', JSON.stringify(parentIndex));
            setActiveIndex(parentIndex);
            setDrawerVisible(false)
        }
    };

    const menuItems = links.map((link, index) => {
        if (link.subLinks) {
            return (
                <SubMenu key={index} icon={<img src={link.icon} alt={link.title} />} title={link.title}>
                    {link.subLinks.map((subLink, subIndex) => (
                        <Menu.Item key={`${index}-${subIndex}`}>
                            <Link to={subLink.to}>{subLink.title}</Link>
                        </Menu.Item>
                    ))}
                </SubMenu>
            );
        }
        return (
            <Menu.Item key={index} icon={<img src={link.icon} alt={link.title} />}>
                <Link to={link.to}>{link.title}</Link>
            </Menu.Item>
        );
    });

    if (roles) {
        menuItems.push(
            <SubMenu key="roles" icon={<UserOutlined />} title="Roles">
                {roles.map((role, index) => (
                    <Menu.Item key={`role-${index}`} onClick={() => setRole(role)}>
                        <Link to={role.split(' ').join('')}>{rolesMap[role]}</Link>
                    </Menu.Item>
                ))}
            </SubMenu>
        );
    }

    const sidebarContent = (
        <>
            <div className="logo" style={{ padding: '16px', textAlign: 'center' }}>
                <div style={{
                    width: '64px',
                    height: '64px',
                    borderRadius: '50%',
                    backgroundColor: '#f0f2f5',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    margin: '0 auto'
                }}>
                    <UserOutlined style={{ fontSize: '32px', color: '' }} />
                </div>
                <div style={{ marginTop: '8px', fontWeight: 'bold' }}>{user.name}</div>
            </div>
            <Menu
                theme="light"
                mode="inline"
                defaultSelectedKeys={[localStorage.getItem('activeIndex') || '0']}
                onClick={onMenuClick}
            >
                {menuItems}
            </Menu>
            <Menu theme="light" mode="inline" selectable={false}>
                <Menu.Item key="change-password" icon={<KeyOutlined />}>
                    <Link to="/change-password">Change Password</Link>
                </Menu.Item>
                <Menu.Item key="logout" icon={<LogoutOutlined />} onClick={logout}>
                    Logout
                </Menu.Item>
            </Menu>
        </>
    );

    return (
        <>
            {isMobile && (
                <Button
                    type="primary"
                    icon={<MenuOutlined />}
                    onClick={() => setDrawerVisible(true)}
                    style={{ position: 'fixed', top: 16, left: 16, zIndex: 1 }}
                    className='bg-slate-500 opacity-95'
                />
            )}
            {isMobile ? (
                <Drawer
                    placement="left"
                    closable={false}
                    onClose={() => setDrawerVisible(false)}
                    visible={drawerVisible}
                    bodyStyle={{ padding: 0 }}
                >
                    {sidebarContent}
                </Drawer>
            ) : (
                <Sider width={250} theme="light">
                    {sidebarContent}
                </Sider>
            )}
        </>
    );
}

export default SideBar;