import React from 'react'
import { useEffect, useState } from "react"
import axios from "axios";
import CheckIcon from '@mui/icons-material/Check';
import EditIcon from '@mui/icons-material/Edit';
import dayjs from 'dayjs'
import notFound from '../../../images/not-found.jpg'
import * as XLSX from "xlsx";
import { baseUrl } from '../../../baseUrl';
import { Table, Input, DatePicker, Button, message, Steps, theme } from 'antd';
import { SearchOutlined, EditOutlined, SaveOutlined, CloseOutlined } from '@ant-design/icons';
import ConfirmDialog from '../../../components/ConfirmDialog';
import AlertDialog from '../../../components/AlertDialog';


function ViewAllotmentClerk() {
  const [hostel, setHostel] = useState("MH")
  const [current_year, setCurrent_year] = useState("All")
  const [programme, setProgramme] = useState("All")
  const [invites, setInvites] = useState([])
  const [ranklist, setRanklist] = useState()

  const [allotment, setAllotment] = useState([])


  const [open1, setOpen1] = useState("");
  const [open2, setOpen2] = useState("");
  const [actualDeadline, setActualDeadline] = useState("");
  const [modalText, setModalText] = useState("");
  const [modalHeading, setModalHeading] = useState("");

  useEffect(() => {
    axios.get(`${baseUrl}/warden/get-current-invites`).then((res) => {
      setInvites(res.data)
      console.log('res.data', res.data)
    })
  }, [])

  useEffect(() => {
    axios.get(`${baseUrl}/warden/get-allotment`, { params: { table_name: ranklist } }).then(res => {
      setAllotment(res.data.allotment)
      setActualDeadline(res.data.deadline)

      console.log('res.data ranbis', res.data)
    })
  }, [ranklist])

  const [editMode, setEditMode] = useState({});




  const downloadExcel = () => {
    const ws = XLSX.utils.json_to_sheet(allotment);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");
    const excelFileName = `Applicant_List.xlsx`;
    XLSX.writeFile(wb, excelFileName);
  };

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [editingKey, setEditingKey] = useState('');

  const dateFormat = 'YYYY-MM-DD';


  const toggleEditMode = (id) => {
    setEditMode((prevEditMode) => ({
      ...prevEditMode,
      [id]: !prevEditMode[id],
    }));
  };


  const isEditing = (record) => record.user_id === editingKey;

  const edit = (record) => {
    setEditingKey(record.user_id);
  };

  const cancel = () => {
    setEditingKey('');
  };
  const save = async (key) => {
    try {
      const row = allotment.find((item) => key === item.user_id);
      if (row) {
        console.log('row', row)
        // Here you would typically make an API call to update the data
        // For now, we'll just update the local state
        const res = await axios.get(`${baseUrl}/warden/update-deadline`, { params: { user_id: row.user_id, deadline: row.deadline, ranklist_table_name: ranklist } });
        const newData = [...allotment];
        const index = newData.findIndex((item) => key === item.user_id);
        if (index > -1) {
          newData[index] = row;
          setAllotment(newData);
          setEditingKey('');
          message.success('Dates updated successfully');
        }
      }
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <button
          type="button"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </button>
        <button onClick={() => handleReset(clearFilters)} style={{ width: 90 }}>
          Reset
        </button>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <span style={{ fontWeight: 'bold' }}>{text}</span>
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const columns = [
    {
      title: 'Index',
      key: 'index',
      render: (text, record, index) => index + 1,
    },
    {
      title: 'Admission No',
      dataIndex: 'user_id',
      key: 'user_id',
      ...getColumnSearchProps('user_id'),
    },
    {
      title: 'Student Name',
      dataIndex: 'name',
      key: 'name',
      ...getColumnSearchProps('name'),
    },
    {
      title: 'Deadline (Admission)',
      dataIndex: 'deadline',
      key: 'deadline',
      width: 200,
      render: (text, record) => {
        const editable = isEditing(record);
        return editable ? (
          <DatePicker
            format={dateFormat}
            defaultValue={dayjs(text, dateFormat)}
            onChange={(date, dateString) => {
              console.log('date', date)
              record.deadline = date.format("YYYY-MM-DD");;
            }}
          />
        ) : (
          text ? dayjs(text).format('YYYY-MM-DD') : 'N/A'
        );
      },
    },
    {
      title: 'Admission Taken',
      dataIndex: 'admission_taken',
      key: 'admission_taken',
      ...getColumnSearchProps('admission_taken'),
    },
    {
      title: 'Admission Category',
      dataIndex: 'admission_category',
      key: 'admission_category',
      ...getColumnSearchProps('admission_category'),
    },
    {
      title: 'Alloted Category',
      dataIndex: 'alloted_category',
      key: 'alloted_category',
      ...getColumnSearchProps('alloted_category'),
    },
    {
      title: 'Rank',
      dataIndex: 'rank',
      key: 'rank',
      ...getColumnSearchProps('rank'),
    },

  ];

  const handleDateChange = (id, field, value) => {
    console.log('value', value)
    setAllotment((prevData) =>
      prevData.map((item) => {
        if (item.user_id === id) {
          console.log(`id ${id} fie ${field} ${item.deadline}`)
          axios.get(`${baseUrl}/warden/update-deadline`, {
            params: { user_id: item.user_id, deadline: value, ranklist_table_name: ranklist }
          }).then(res => {
            console.log(res)
            message.success(`Deadline extended to ${item.deadline}`)
          })

          return { ...item, [field]: value }
        } else {
          return item
        }
      }
      )
    );

  };
  const years = {
    1: "First Year",
    2: "Second Year",
    3: "Third Year",
    4: "Forth Year",
    5: "Fifth Year",

  }

  const clearAllotment = () => {
    axios.get(`${baseUrl}/warden/clear-allotment`, { params: { ranklist_table_name: ranklist } })
      .then((res) => {
        console.log(res.data)
        setModalHeading("Request Completed ");
        setModalText("Allotment has been Cleared .");
        setOpen1(true);
      }).catch(err => {
        console.log('err', err)
        setModalHeading("Request Completed ");
        setModalText(err.response.data.msg);
        setOpen1(true);
      });
  }
  const handleClear = () => {
    setModalHeading("Confirm");
    setModalText("Allotment list will be cleared.");
    setOpen2(true);
  }
  return (
    <div className='w-11/12'>
      <div className='grid grid-cols-2 gap-4 items-center w-6/12'>
        <label>Select Rank List : </label>

        <select onChange={e => setRanklist(e.target.value)} className='p-3 ring-slate-200 ring-2 rounded-xl outline-none'>
          <option value={"select"} >Select</option>

          {invites?.map(invite => {
            return <option value={invite.rank_list_table_name}>{`${invite.programme} ${invite.hostel} ${years[invite.current_year]}`}</option>
          })}
        </select>

      </div>

      {ranklist &&

        (
          <div className="w-full overflow-x-scroll">

            <div className="flex items-center justify-end mb-5">
              <button className="bg-red-600 text-white p-3 rounded-lg text-sm mr-5" onClick={handleClear}>Clear Allotment</button>

              <button onClick={() => {
                console.log("GU")
                downloadExcel()
              }
              } className="bg-stone-800 text-white p-3 rounded-lg text-sm mr-5">Download as Excel</button>
            </div>
            <div className="container mx-auto p-4">

            </div>
            {allotment && allotment.length ?
              <Table columns={columns} dataSource={allotment} pagination={{ pageSize: 100 }} rowKey={(record) => record.user_id} /> :
              <div className="m-3 flex flex-col justify-center items-center " >
                <h2 className="font-bold col-span-2 text-2xl text-red-600">No Allotment List Found !!!</h2>
                <img src={notFound} className="w-11/12" />
              </div>
            }
          </div>
        )

      }
      <AlertDialog
        open={open1}
        setOpen={setOpen1}
        modalHeading={modalHeading}
        modalText={modalText}
      />
      <ConfirmDialog
        open={open2}
        setOpen={setOpen2}
        modalHeading={modalHeading}
        modalText={modalText}
        confirmFunction={clearAllotment}
      />

    </div >
  )
}

export default ViewAllotmentClerk