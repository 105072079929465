
import { useState, useContext } from "react"
import ConfirmDialog from "../../../components/ConfirmDialog";
import { UserContext } from "../../../Contexts/UserContext";



function AddedMessSuspensionList({res }) {

    const { user, setLoading } = useContext(UserContext)

    const [open1, setOpen1] = useState(false);
    const [modalHeading, setModalHeading] = useState("")
    const [modalText, setModalText] = useState("")

 

    return (
        <>
            <div className='w-12/12 overflow-x-scroll'>
                <h1 className="font-semibold text-black text-lg mb-3 mt-3"></h1>
                <h1 className="font-bold text-black text-xl mb-3 mt-3">Status List </h1>
                <table className='w-full relative table-auto'>
                    <tr className='rounded-xl p-3 bg-primary text-center'>
                        <th className='p-3'>Sl.No</th>
                        <th className='p-3'>Hostel Ad.No</th>
                        <th className='p-3'>Name</th>
                        <th className='p-3'>Status</th>
                       
                    </tr>
                    {res.map((response, index) => {

                        return (
                            <tr
                                key={index}
                                className={'border-b text-center border-slate-200 border-solid hover:bg-gray-300'}
                            >
                                <td className='p-3'>{index + 1}</td>
                                <td className='p-3'>{response.row["Hostel Admission Number"]}</td>
                                <td className='p-3'>{response.row["Name"]}</td>
                                
                                <td className={`p-3 ${response['status']?"text-green-400":"text-red-400"}`}>{response['status']?"Success":"Error"}</td>
                            </tr>
                        )
                    })}
                </table>
            </div>
            <ConfirmDialog open={open1} setOpen={setOpen1} modalHeading={modalHeading} modalText={modalText} confirmFunction={() => { }} />
        </>
    )
}


export default AddedMessSuspensionList