import InfoIcon from "@mui/icons-material/Info";
import axios from "axios";
import { useState, useContext, useEffect } from "react";
import { UserContext } from "../../../Contexts/UserContext";
import AlertDialog from "../../../components/AlertDialog";
import ConfirmDialog from "../../../components/ConfirmDialog";
import { baseUrl } from "../../../baseUrl";


function MessOutForm({
  noofkdaybefore,
  noofDays,
  noofMaxmessoutDays,
  noOfMaxMessOutsinMonth,
  messOutHistory,
  setMessOutHistory,
}) {
  const [fromDate, setFromDate] = useState("");
  const [allowableDays, setAllowableDays] = useState(31);
  const [cumulativeMessCount, setCumulativeMessCount] = useState(0);
  const [toDate, setToDate] = useState('');
  const [modalText, setModalText] = useState("");
  const [modalHeading, setModalHeading] = useState("");
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);
  const [isMessout, setisMessOut] = useState(false);
  const [hasUpcomingMessout, setHasUpcomingMessout] = useState(false);
  const [messoutEntry, setMessoutEntry] = useState(null);
  const [messoutThisMonth, setMessoutsThisMonth] = useState(0);
  const [exceededLimit, setExceededLimit] = useState(false);
  const { user, setLoading } = useContext(UserContext);

  const dateConverter = (inputdate) => {
    const date = new Date(inputdate);
    let month = (date.getMonth() + 1).toString();
    let day = date.getDate().toString();
    let year = date.getFullYear();
    if (month.length < 2) {
      month = "0" + month;
    }
    if (day.length < 2) {
      day = "0" + day;
    }
    return [year, month, day].join("-");
  };
  const parseDateDDMMYYYY = (inputdate) => {
    const date = new Date(inputdate);
    let month = (date.getMonth() + 1).toString();
    let day = date.getDate().toString();
    let year = date.getFullYear();
    if (month.length < 2) {
      month = "0" + month;
    }
    if (day.length < 2) {
      day = "0" + day;
    }
    return day + "-" + month + "-" + year;
  };
  useEffect(() => {
    setLoading(true);
    axios
      .post(`${baseUrl}/inmate/check/messout`, {
        user_id: user.user_id,
        hostel: user.hostel,
      })
      .then((res) => {
        if (res.data.status == "ok") {
          if (res.data.isMessout) {
            setisMessOut(true);
            setFromDate(dateConverter(res.data.currentMessoutDetails.fromdate))
            if (res.data.currentMessoutDetails.showtodate) {
              setToDate(dateConverter(res.data.currentMessoutDetails.todate));
            }
            setMessoutEntry(res.data.currentMessoutDetails);
          } else if (res.data.hasUpcomingMessout) {
            setHasUpcomingMessout(true);
            setFromDate(dateConverter(res.data.upcomingMessoutDetails.fromdate))
            if (res.data.upcomingMessoutDetails.showtodate) {
              setToDate(dateConverter(res.data.upcomingMessoutDetails.todate));
            }
            setMessoutEntry(res.data.upcomingMessoutDetails);

          }
          setAllowableDays(res.data.max_mess_in_stretch);
          setMessoutsThisMonth(res.data.monthlyMessoutCount);

        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [messOutHistory]);

  const getToday = () => {
    const date = new Date();
    date.setDate(date.getDate() + noofkdaybefore);
    let month = (date.getMonth() + 1).toString();
    let day = (date.getDate()).toString();
    let year = date.getFullYear();
    if (month.length < 2) {
      month = "0" + month;
    }
    if (day.length < 2) {
      day = "0" + day;
    }
    return year + "-" + month + "-" + day;
  };


  const renderStateWithMessOutEntry = () => {

    const submitMessoutHandler = (e) => {
      e.preventDefault()
      try {
        const date = new Date(toDate);
        if (date < new Date(getToday())) {
          setModalHeading("Invalid Date");
          setModalText("Please Check Your date and Apply Again .");
          setOpen1(true);
          return;
        }
        setModalText(
          "You have filled Mess Out  from " +
          parseDateDDMMYYYY(fromDate) +
          " And Mess In from " +
          parseDateDDMMYYYY(toDate) +
          "\nDo you want to Confirm?"
        );
        setOpen2(true)

      } catch (err) {
        setModalHeading("Something went wrong");
        setModalText("Couldn't complete your request . Please proceed after some time");
        setOpen1(true);

      }
    }
    const submitMessoutRequest = async () => {
      setLoading(true)
      axios
        .post(`${baseUrl}/inmate/edit-messout`, {
          messout_id: messoutEntry.messout_id,
          user_id: user.user_id,
          fromDate: dateConverter(fromDate),
          toDate: dateConverter(toDate),
          hostel: user.hostel
        })
        .then((res) => {
          if (res.data.status == "ok") {
            setMessOutHistory([...messOutHistory, res.data.data[0]]);
            setModalHeading("Messout Edited successfully")
            setModalText("Your Messout Edit Request Is Completed !");
            setOpen1(true)
          } else {
            setModalHeading("Request Failed ");
            setModalText("You Cannot Apply Messout For The Given Date As This Will Exceed The Monthly Limit ! ");
            setOpen1(true);
          }

        }).catch((err) => {
          setModalHeading("Exceeded Limit");
          setModalText("The Request Failed as it will exceed the limit for the dates . Please reduce it and try again !");
          setOpen1(true);
        }).finally(() => {
          setLoading(false);
        })
    }

    const handleMessoutDeleteRequest = () => {
      const f = new Date(fromDate);
      const d = new Date();
      if (f.getTime() <= d.getTime() && !isMessout) {
        setModalHeading("Invalid Operation !")
        setModalText("This messout has already started . You cannot delete this messout .")
        setOpen1(true);
      }

      setModalHeading("Are You Sure?")
      setModalText("You are about to delete the messout entry from " + parseDateDDMMYYYY(messoutEntry.fromdate) + " To date " + parseDateDDMMYYYY(messoutEntry.todate) + " . Do you want to continue ?")
      setOpen3(true);
    }

    const submitDeleteMessoutRequest = () => {
      setLoading(true)
      axios
        .post(`${baseUrl}/inmate/cancel-messout`, {
          messout_id: messoutEntry.messout_id,
          user_id: user.user_id
        })
        .then((res) => {
          if (res.data.status == "ok") {
            const filteredMessouts = messOutHistory.filter(obj => obj.messout_id != messoutEntry.messout_id);
            setMessOutHistory(filteredMessouts);
            setHasUpcomingMessout(false);
            setisMessOut(false)
            setFromDate('')
            setToDate('')
            setMessoutEntry(null)
            setModalHeading("Request Completed")
            setModalText("Your request is deleted succesfully ");
            setOpen1(true)
          } else {
            setModalHeading("Request Failed ! ");
            setModalText("Invalid Operation Performed !");
            setOpen1(true);
          }

        }).catch((err) => {
          setModalHeading("Request Failed ! ");
          setModalText("Invalid Operation Performed !");
          setOpen1(true);
        }).finally(() => {
          setLoading(false);
        })
    }

    const getMessInStartDate = () => {
      const date = new Date(fromDate);
      date.setDate(date.getDate() + noofDays);
      let month = (date.getMonth() + 1).toString();
      let day = (date.getDate()).toString();
      let year = date.getFullYear();
      if (month.length < 2) {
        month = "0" + month;
      }
      if (day.length < 2) {
        day = "0" + day;
      }
      return year + "-" + month + "-" + day;
    }

    const getMessInEndDate = () => {
      const date = new Date(fromDate);
      date.setDate(date.getDate() + noofMaxmessoutDays);
      let month = (date.getMonth() + 1).toString();
      let day = (date.getDate()).toString();
      let year = date.getFullYear();
      if (month.length < 2) {
        month = "0" + month;
      }
      if (day.length < 2) {
        day = "0" + day;
      }
      return year + "-" + month + "-" + day;
    }

    const isMessInInputDisabled = () => {
      if (!fromDate || fromDate.length < 4)
        return true;
      else
        return false;
    };

    const isEditable = () => {
      const t = new Date();
      const o = new Date(fromDate);
      if (o.getTime() > t.getTime())
        return true;
      else
        return false;
    }

    return (
      <>
        <form onSubmit={submitMessoutHandler}>
          <div className="grid grid-cols-2 w-100 gap-4 mb-3">
            <label htmlFor="">Mes out from date:</label>{" "}
            <input
              required
              type="date"
              value={fromDate}
              disabled={!isEditable()}
              min={
                getToday()}
              onChange={(e) => {
                setFromDate(e.target.value)
                setToDate('')
              }}
              className="w-12/12 py-2 px-3 rounded-xl ring-2 ring-slate-300 focus:outline-none"
            />
          </div>
          <div className="grid grid-cols-2 w-100 gap-4 mb-3">
            <label htmlFor="">Mess In date:</label>{" "}
            <input
              required
              disabled={isMessInInputDisabled()}
              type="date"
              min={getMessInStartDate()}
              max={getMessInEndDate()}
              value={toDate}
              onChange={(e) => {
                setToDate(e.target.value)
              }}
              className="w-12/12 py-2 px-3 rounded-xl ring-2 ring-slate-300 focus:outline-none"
            />
          </div>
          {noofDays > 0 ? <p className="flex items-center">
            <InfoIcon className="text-sm mr-1" /> Minimum {noofDays} days of leave
            is required for Mess Out
          </p> : ""}
          {noofMaxmessoutDays > 0 ? <p className="flex items-center">
            <InfoIcon className="text-sm mr-1" /> Maximum {noofMaxmessoutDays}{" "}
            days of leave possible per Mess Out
          </p> : ""}
          {noOfMaxMessOutsinMonth && <p className="flex items-center">
            <InfoIcon className="text-sm mr-1" />
            You can request {noOfMaxMessOutsinMonth}  mess out in this month
          </p>}
          {
            isEditable() && <div className="w-full flex items-end justify-end mt-5">
              <button
                type="button"
                onClick={() => {
                  handleMessoutDeleteRequest()
                }}
                className="ml-auto p-3 bg-stone-800 text-white rounded-xl"
              >
                Delete Messout
              </button>
            </div>
          }

          <div className="w-full flex items-end justify-end mt-5">
            <button
              type="submit"
              className="ml-auto p-3 bg-stone-800 text-white rounded-xl"
            >
              Submit
            </button>
          </div>
        </form>
        <AlertDialog
          open={open1}
          setOpen={setOpen1}
          modalHeading={modalHeading}
          modalText={modalText}
        />
        <ConfirmDialog
          open={open2}
          setOpen={setOpen2}
          modalHeading={modalHeading}
          modalText={modalText}
          confirmFunction={submitMessoutRequest}
        />
        <ConfirmDialog
          open={open3}
          setOpen={setOpen3}
          modalHeading={modalHeading}
          modalText={modalText}
          confirmFunction={submitDeleteMessoutRequest}
        />
      </>
    )
  }






  const renderStateNewMessout = () => {
    const submitMessoutHandler = (e) => {
      e.preventDefault()
      try {
        const date = new Date(fromDate);
        if (date < new Date(getToday())) {
          setModalHeading("Invalid Date");
          setModalText("Please Check Your date and Apply Again .");
          setOpen1(true);
          return;
        }
        setModalText(
          "You have filled Mess Out  from " +
          parseDateDDMMYYYY(fromDate) +
          " And Mess In from " +
          parseDateDDMMYYYY(toDate) +
          "\nYou can change the dates later . Do you want to Confirm?"
        );
        setOpen2(true)

      } catch (err) {
        setModalHeading("Something went wrong");
        setModalText("Couldn't complete your request . Please proceed after some time");
        setOpen1(true);

      }
    }
    const submitMessoutRequest = async () => {
      setLoading(true)
      axios
        .post(`${baseUrl}/inmate/applymessout`, {
          user_id: user.user_id,
          fromDate: dateConverter(fromDate),
          toDate: dateConverter(toDate),
          hostel: user.hostel
        })
        .then((res) => {
          if (res.data.status == "ok") {
            setMessOutHistory([...messOutHistory, res.data.data[0]]);
            setModalHeading("Messout applied successfully")
            setModalText("Your messout request is completed ");
            setOpen1(true)
          } else {
            setModalHeading("Exceeded Limit");
            setModalText("The Request Failed as it will exceed the limit for the dates . Please reduce it and try again !");
            setOpen1(true);
          }

        }).catch((err) => {
          setModalHeading("Exceeded Limit");
          setModalText("The Request Failed as it will exceed the limit for the dates . Please reduce it and try again !");
          setOpen1(true);
        }).finally(() => {
          setLoading(false);
        })
    }


    const getMessInStartDate = () => {
      const date = new Date(fromDate);
      date.setDate(date.getDate() + noofDays);
      let month = (date.getMonth() + 1).toString();
      let day = (date.getDate()).toString();
      let year = date.getFullYear();
      if (month.length < 2) {
        month = "0" + month;
      }
      if (day.length < 2) {
        day = "0" + day;
      }
      return year + "-" + month + "-" + day;
    }

    const getMessInEndDate = () => {
      const date = new Date(fromDate);
      date.setDate(date.getDate() + noofMaxmessoutDays);
      let month = (date.getMonth() + 1).toString();
      let day = (date.getDate()).toString();
      let year = date.getFullYear();
      if (month.length < 2) {
        month = "0" + month;
      }
      if (day.length < 2) {
        day = "0" + day;
      }
      return year + "-" + month + "-" + day;
    }

    const isMessInInputDisabled = () => {
      if (!fromDate || fromDate.length < 4)
        return true;
      else
        return false;
    };

    return (
      <>
        <form onSubmit={submitMessoutHandler}>
          <div className="grid grid-cols-2 w-100 gap-4 mb-3">
            <label htmlFor="">Mess out from date:</label>{" "}
            <input
              required
              type="date"
              min={
                getToday()}
              onChange={(e) => {
                setFromDate(e.target.value)
                setToDate('')
              }}
              className="w-12/12 py-2 px-3 rounded-xl ring-2 ring-slate-300 focus:outline-none"
            />
          </div>
          <div className="grid grid-cols-2 w-100 gap-4 mb-3">
            <label htmlFor="">Mess In date:</label>{" "}
            <input
              required
              disabled={isMessInInputDisabled()}
              type="date"
              min={getMessInStartDate()}
              max={getMessInEndDate()}
              value={toDate}
              onChange={(e) => {
                setToDate(e.target.value)
              }}
              className="w-12/12 py-2 px-3 rounded-xl ring-2 ring-slate-300 focus:outline-none"
            />
          </div>
          {noofDays > 0 ? <p className="flex items-center">
            <InfoIcon className="text-sm mr-1" /> Minimum {noofDays} days of leave
            is required for Mess Out
          </p> : ""}
          {noofMaxmessoutDays > 0 ? <p className="flex items-center">
            <InfoIcon className="text-sm mr-1" /> Maximum {noofMaxmessoutDays}{" "}
            days of leave possible per Mess Out
          </p> : ""}
          {noOfMaxMessOutsinMonth && <p className="flex items-center">
            <InfoIcon className="text-sm mr-1" />
            You can request {noOfMaxMessOutsinMonth}  mess out in this month
          </p>}
          <div className="w-full flex items-end justify-end mt-5">
            <button
              type="submit"
              className="ml-auto p-3 bg-stone-800 text-white rounded-xl"
            >
              Submit
            </button>
          </div>
        </form>
        <AlertDialog
          open={open1}
          setOpen={setOpen1}
          modalHeading={modalHeading}
          modalText={modalText}
        />
        <ConfirmDialog
          open={open2}
          setOpen={setOpen2}
          modalHeading={modalHeading}
          modalText={modalText}
          confirmFunction={submitMessoutRequest}
        />




      </>)
  }

  return (
    <div className="mb-3">
      <h2 className="font-semibold text-lg mb-2">
        {!hasUpcomingMessout
          ? exceededLimit
            ? "Exceeded Monthly Limit for this month"
            : isMessout
              ? "Apply for Mess in"
              : "Apply for Mess Out"
          : "You Have upcoming Mess out "}
      </h2>
      {!hasUpcomingMessout && !isMessout && renderStateNewMessout()}
      {(hasUpcomingMessout || isMessout) && messoutEntry && renderStateWithMessOutEntry()}
    </div>
  );

}
export default MessOutForm;
