import React, { useState, useEffect, useContext } from 'react'
import { motion } from 'framer-motion'
import { UserContext } from '../../Contexts/UserContext'
import MessSuspendedListPageClerk from './Components/MessSuspendedListPageClerk'
import UploadMessSuspentionListPage from '../Warden/Components/UploadMessSuspendionListPage'
import SuspendInmateDues from '../Warden/Components/SuspendInmateDues'
import CloseMessComponent from '../Warden/Components/CloseMessComponent'
import MessSuspendedListPage from '../Warden/Components/MessSuspendedListPage'

function SuspendInmateMessPageClerk() {
    const { user } = useContext(UserContext)
    const [tabSelected, setTabSelected] = useState(1)

    return (
        <div className='flex flex-col w-full items-center min-h-screen h-full overflow-y-scroll'>
            <div className='flex flex-row justify-between w-11/12 pt-4 items-center'>
                <div className='text-xl font-bold'>Mess Management</div>
                <div className='flex flex-row space-x-4 items-center'>
                    <div className='bg-white border rounded-full w-10 aspect-square' />
                    <div>{user.name}</div>
                </div>
            </div>

            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.3 }} className=' flex flex-col items-center px-2 py-8 space-y-4 w-11/12 md mt-8  bg-white rounded-xl'>
                {/* white box nav bar */}
                <div className='flex flex-row justify-between w-11/12 items-center'>
                    <div className='flex flex-row tex-black text-sm font-bold relative mb-3'>
                        <div
                            className='ml-5  cursor-pointer '
                            onClick={() => {
                                setTabSelected(1)
                            }}
                        >
                            <div>Mess Suspended List</div>
                            <div className={tabSelected === 1 ? 'mt-2 h-1 self-center w-10/12 bg-stone-800 rounded-full' : ''} />
                        </div>


                    </div>
                </div>
                {tabSelected === 1 && <MessSuspendedListPage/>}
                {/* {tabSelected === 2 && <UploadMessSuspentionListPage/>} */}
                {/* {tabSelected === 3 && <SuspendInmateDues/>} */}
                {/* {tabSelected === 4 && <CloseMessComponent/>} */}
     

            </motion.div>
        </div>
    )
}

export default SuspendInmateMessPageClerk