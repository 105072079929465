import React from 'react'
import { useEffect, useState } from "react"
import axios from "axios";
import dayjs from 'dayjs'
import notFound from '../../../images/not-found.jpg'
import * as XLSX from "xlsx";
import { baseUrl } from '../../../baseUrl';
import { Table, Input, DatePicker, Button, message, Popconfirm, Form, theme, Modal } from 'antd';
import { SearchOutlined, EditOutlined, SaveOutlined, CloseOutlined, DeleteOutlined } from '@ant-design/icons';
import ConfirmDialog from '../../../components/ConfirmDialog';
import AlertDialog from '../../../components/AlertDialog';


function ViewMessExemptInmates({ date, hostel }) {
    const [current_year, setCurrent_year] = useState("All")
    const [titles, setTitles] = useState([])
    const [title, setTitle] = useState("")
    const [invites, setInvites] = useState([])
    const [messExemptInmates, setMessExemptInmates] = useState([])
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [form] = Form.useForm();




    const [open1, setOpen1] = useState("");
    const [open2, setOpen2] = useState("");
    const [actualDeadline, setActualDeadline] = useState("");
    const [modalText, setModalText] = useState("");
    const [modalHeading, setModalHeading] = useState("");

    const fetchMessExemptInmates = () => {
        axios.get(`${baseUrl}/warden/get-mess-exemption`, { params: { date: date, hostel: hostel, title: title } }).then((res) => {
            setMessExemptInmates(res.data)
        }).catch((err) => {
            console.log(err)
            setModalHeading("Something went wrong")
            setModalText("Something went wrong while generating the mess bill. Please try again after sometime . If the issue persisit try contacting the developer ")
            // setOpen1(true)

        })
    }
    useEffect(() => {
        fetchMessExemptInmates()
    }, [hostel, date, title])

    const handleDelete = (hostel_admission_no) => {
        axios.delete(`${baseUrl}/warden/delete-mess-exemption`, { params: { date: date, hostel_admission_no: hostel_admission_no }, }).then(() => {
            message.success('Exemption deleted successfully');
            fetchMessExemptInmates();
        }).catch(error => {
            message.error('Failed to delete Exemption');
        });
    };

    const handleAdd = (values) => {
        axios.post(`${baseUrl}/warden/add-mess-exemption`, { ...values, date: date }).then(() => {
            setIsModalVisible(false);
            form.resetFields();
            message.success('Exemption added successfully');
            fetchMessExemptInmates()
        }).catch(error => {
            message.error(error.response.data.msg);
        });
    };

    const downloadExcel = () => {
        const ws = XLSX.utils.json_to_sheet(messExemptInmates);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");
        const excelFileName = `Applicant_List.xlsx`;
        XLSX.writeFile(wb, excelFileName);
    };

    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [editingKey, setEditingKey] = useState('');

    const dateFormat = 'YYYY-MM-DD';



    const isEditing = (record) => record.hostel_admission_no === editingKey;

    const edit = (record) => {
        setEditingKey(record.hostel_admission_no);
    };

    const cancel = () => {
        setEditingKey('');
    };
    const save = async (key) => {
        try {
            const row = messExemptInmates.find((item) => key === item.hostel_admission_no);
            if (row) {
                console.log('row', row)
                // Here you would typically make an API call to update the data
                // For now, we'll just update the local state
                // const res = await axios.get(`${baseUrl}/warden/update-deadline`, { params: { hostel_admission_no: row.hostel_admission_no, deadline: row.deadline, ranklist_table_name: ranklist } });
                const newData = [...messExemptInmates];
                const index = newData.findIndex((item) => key === item.hostel_admission_no);
                if (index > -1) {
                    newData[index] = row;
                    setMessExemptInmates(newData);
                    setEditingKey('');
                    message.success('Amount updated successfully');
                }
            }
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    };
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <button
                    type="button"
                    onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 90, marginRight: 8 }}
                >
                    Search
                </button>
                <button onClick={() => handleReset(clearFilters)} style={{ width: 90 }}>
                    Reset
                </button>
            </div>
        ),
        filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
                setTimeout(() => this.searchInput.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <span style={{ fontWeight: 'bold' }}>{text}</span>
            ) : (
                text
            ),
    });

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const columns = [
        {
            title: 'Index',
            key: 'index',
            render: (text, record, index) => index + 1,
        },
        {
            title: 'Hostel Admission No',
            dataIndex: 'hostel_admission_no',
            key: 'hostel_admission_no',
            ...getColumnSearchProps('hostel_admission_no'),
        },
        {
            title: 'Inmate Name',
            dataIndex: 'name',
            key: 'name',
            ...getColumnSearchProps('name'),
        },
        {
            title: 'Reason',
            dataIndex: 'reason',
            key: 'reason',

        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => {
                return <Popconfirm
                    title="Are you sure you want to delete this Exemption?"
                    onConfirm={() => handleDelete(record.hostel_admission_no)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button icon={<DeleteOutlined />} type="danger">
                        Delete
                    </Button>
                </Popconfirm>
            },
        },

    ];




    const clearMessExemption = () => {
        axios.delete(`${baseUrl}/warden/delete-mess-exemption-month`, { params: { date: date, title: title, hostel: hostel }, })
            .then((res) => {
                console.log(res.data)
                setModalHeading("Request Completed ");
                setModalText(`Mess Expemption has been Cleared .`);
                fetchMessExemptInmates()
                setOpen1(true);
            }).catch(err => {
                console.log('err', err)
                setModalHeading("Request Completed ");
                setModalText(err.response.data.msg);
                setOpen1(true);
            });

    }
    const handleClear = () => {
        setModalHeading("Confirm");
        setModalText(`Mess Expemption for ${date} list will be cleared.`);
        setOpen2(true);
    }

    return (
        <div className='w-11/12'>
            {/* <div className='grid grid-cols-2 gap-4 items-center w-6/12'>
                <label>Select Expense : </label>

                <select onChange={e => setTitle(e.target.value)} className='p-3 ring-slate-200 ring-2 rounded-xl outline-none'>
                    {titles?.map(data => {
                        return <option value={data.title}>{`${data.title}`}</option>
                    })}
                </select>

            </div> */}


            <div className="w-full overflow-x-scroll">
                <div className="flex items-center justify-end m-3">
                    <button className="bg-red-600 text-white p-3 rounded-lg text-sm mr-5" onClick={handleClear} type='button'>Clear List</button>
                    <button onClick={() => {
                        console.log("GU")
                        downloadExcel()
                    }
                    } className="bg-stone-800 text-white p-3 rounded-lg text-sm mr-5">Download as Excel</button>
                    <button onClick={() => setIsModalVisible(true)} type='button' className="bg-stone-800 text-white p-3 rounded-lg text-sm mr-5">
                        Add Expemption
                    </button>
                </div>
                <Form form={form} component={false}>
                    {messExemptInmates && messExemptInmates.length ?
                        <Table columns={columns} dataSource={messExemptInmates} pagination={{ pageSize: 100 }} rowKey={(record) => record.hostel_admission_no} /> :
                        <div className="m-3 flex flex-col justify-center items-center " >
                            <h2 className="font-bold col-span-2 text-2xl text-black">No Additional Mess Expense List Found !!!</h2>
                        </div>
                    }
                </Form>
                {/* <Table columns={columns} dataSource={messExemptInmates} pagination={{ pageSize: 100 }} rowKey={(record) => record.hostel_admission_no} /> */}
                <Modal
                    title="Add New Expense"
                    visible={isModalVisible}
                    onOk={() => {
                        form
                            .validateFields()
                            .then((values) => {
                                handleAdd(values);
                            })
                            .catch((info) => {
                                console.log('Validate Failed:', info);
                            });
                    }}
                    onCancel={() => {
                        setIsModalVisible(false);
                        form.resetFields();
                    }}
                >
                    <Form
                        form={form}
                        layout="vertical"
                        name="form_in_modal"
                    >
                        {/* <Form.Item
                                    name="expense"
                                    label="Expense Name"
                                    rules={[{ required: true, message: 'Please input the Expense!' }]}
                                >
                                    <Input />
                                </Form.Item> */}
                        <Form.Item
                            name="hostelAdmissionNo"
                            label="Hostel Admission No"
                            rules={[{ required: true, message: 'Please input the Hostel Admission No!' }]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name="reason"
                            label="Reason"
                            rules={[{ required: true, message: 'Please input the Reason!' }]}
                        >
                            <Input />
                        </Form.Item>
                        {/* <Form.Item
                                    name="issuedDate"
                                    label="Issued Date"
                                    rules={[{ required: true, message: 'Please input the Issued Date!' }]}
                                >
                                    <Input type="date" />
                                </Form.Item> */}
                    </Form>
                </Modal>
            </div>




            <AlertDialog
                open={open1}
                setOpen={setOpen1}
                modalHeading={modalHeading}
                modalText={modalText}
            />
            <ConfirmDialog
                open={open2}
                setOpen={setOpen2}
                modalHeading={modalHeading}
                modalText={modalText}
                confirmFunction={clearMessExemption}
            />

        </div >
    )
}

export default ViewMessExemptInmates