
import { useEffect, useContext, useState } from 'react'
import { UserContext } from '../../Contexts/UserContext'
import AlertDialog from '../../components/AlertDialog'
import ConfirmDialog from '../../components/ConfirmDialog'
import { baseUrl } from "../../baseUrl";
import Dateconverter from "../../Utils/DateConverter"
import axios from 'axios'
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';


const ManageEgrants = (props) => {
  const { user, setLoading } = useContext(UserContext)


  const [open1, setOpen1] = useState(false)
  const [open2, setOpen2] = useState(false)
  const [modalHeading, setModalHeaading] = useState("")
  const [modalText, setModalText] = useState("")
  const [menuStates, setMenuStates] = useState([]);
  const [status, setStatus] = useState(4)
  const [selectedHostel, setSelectedHostel] = useState("MH");
  const [egrantsList, setEgrantsList] = useState([])
  const [filteredList,setFilteredList]=useState([])
  const [selectedStudent, setSelectedStudent] = useState(null)
  const [updateStatus, setUpdateStatus] = useState(null)

  const [reload, setReload] = useState(false)

  useEffect(() => {
    setLoading(true)
    axios.get(`${baseUrl}/warden/get-egrants-list?hostel=${selectedHostel}&&status=${status}`).then((res) => {
      setEgrantsList(res.data.data)
      setFilteredList(res.data.data)
      const updatedStates = Array(res.data.data.length).fill(false)
      setMenuStates(updatedStates);
    }).catch((err) => {
      console.log(err)
      alert(err.msg)
    }).finally(() => {
      setReload(false)
      setLoading(false)
    })
  }, [status, selectedHostel, reload])

  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset-UTF-8';
  const mp = {
    "2": "Approved",
    "-1": "Rejected",
    "1": "Processing",
    "0": "Applied",
    "4":"Not Eligible",
    "3":"Differently Abled"
  }
  const downloadExcel = async () => {
      const columns = ["Sl.No","hostel_admission_no", "name","status"];

      const header = ["Sl.No","Hostel Admission No", "Name","Status"];

      const rows = egrantsList.map((row, index) => {
          return columns.map((column, i) => {
              if (column == "Sl.No") {
                  return index+1
              }

              if (column == "hostel_admission_no")
                  return row["hostel_admission_no"]
              if (column == "name")
                  return row["name"]
              return mp[row[column]]
          })
      });
      const datas=[header,...rows]
      const ws = XLSX.utils.json_to_sheet(datas);
      const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], { type: fileType });
      const date =new Date()
      FileSaver.saveAs(data, `Egrants-List-${selectedHostel}-${Dateconverter.parseDateDDMMYYYY(date)}.xlsx`)

  }

  const toggleMenu = (index, studentData) => {
    const updatedStates = Array(10).fill(false)
    updatedStates[index] = !updatedStates[index];
    setMenuStates(updatedStates);
  };


  const updateStatusHandler = () => {
    setLoading(true)
    const data = {
      hostel_admission_no: selectedStudent.hostel_admission_no,
      status: updateStatus
    }
    axios.post(`${baseUrl}/warden/update-egrants-status`, data).then((res) => {
      const updatedStates = Array(egrantsList.length).fill(false)
      setMenuStates(updatedStates);
      setModalHeaading("Status Updated ")
      setModalText("The status for the inmate " + selectedStudent.hostel_admission_no + "  Has been changed succesfully !")
      setOpen1(true)


    }).catch((err) => {
      setModalHeaading("OOps Something went wrong ")
      setModalText("Something went wrong while trying . Please try again later ")
      setOpen1(true)
    }).finally(() => {
      setReload(true)
      setLoading(false)
    })
  }

  const openConfirmModal = (update_status, studentData) => {
    if (update_status == null || update_status > 3 || studentData == null) {
      setModalHeaading("Invalid Operation")
      setModalText("Please ensure you have selected all the field properly and try again")
      setOpen1(true)
      return;
    }

    setSelectedStudent(studentData)
    setUpdateStatus(update_status)
    setModalHeaading("Are you sure ?")
    setModalText("You are about to update the egrants status of the inmate with hostel admission no " + studentData.hostel_admission_no + " as " + mp[update_status] + " . Do you want to continue ?")
    setOpen2(true)
  }



  const StatusMenu = ({ isOpen, toggleMenu }) => {
    return (
      <div className={`rounded-md absolute top-0 z-50 right-1 ${isOpen ? "" : "hidden"}`}>
        <ul className="bg-white border rounded shadow-md py-1 w-36">
          <li onClick={() => {
            openConfirmModal(2, selectedStudent)
          }} className="m-1 cursor-pointer px-4 py-2 bg-green-400 rounded-md hover:bg-green-500 ">Approve</li>
          <li onClick={() => {
            openConfirmModal(-1, selectedStudent)
          }} className="m-1 cursor-pointer px-4 py-2 bg-red-400  rounded-md  hover:bg-red-500">Reject</li>
          <li
            onClick={() => {
              openConfirmModal(1, selectedStudent)
            }} className="m-1 cursor-pointer px-4 py-2 rounded-md bg-blue-400 hover:bg-blue-500">Processing</li>
          <li onClick={() => {
            openConfirmModal(0, selectedStudent)
          }} className="m-1 cursor-pointer px-4 py-2 rounded-md bg-yellow-200 hover:bg-yellow-500">Applied</li>
          <li onClick={() => {
            openConfirmModal(3, selectedStudent)
          }} className="m-1 cursor-pointer px-4 py-2 rounded-md bg-purple-200 hover:bg-purple-500">Differently Abled</li>
        </ul>
      </div>
    );
  };

  const search= (keyword) => {
    if(keyword.length==0 || keyword==='')
    return setFilteredList(egrantsList)
    const searchTermLower = keyword.toLowerCase();
    setFilteredList(egrantsList.filter((inmate) =>
      inmate.hostel_admission_no.toLowerCase().includes(searchTermLower)
    ));
  };

  return (
    <>
      <div className='flex flex-col w-full items-center overflow-y-scroll'>
        <div className='flex flex-row justify-between w-11/12 pt-4 items-center'>
          <div className='text-xl font-bold'>Manage Inmate Egrants</div>
          <div className='flex flex-row space-x-4 items-center'>
            <div className='bg-white border rounded-full w-10 aspect-square' />
            <div>{user.name}</div>
          </div>
        </div>

        <div className='flex flex-col items-center py-8 space-y-4 w-11/12 md mt-8  bg-white rounded-xl'>
          <div className="flex items-center justify-between w-5/6 p">
            <div className="grid grid-cols-2 w-100 gap-4 mb-3">
              <label className='font-bold' htmlFor="">Select Status :</label>{" "}
              {<select
                defaultValue={status}
                onChange={(e) => {
                  setStatus(e.target.value)
                }}
                className="px-5 p-3  ring-slate-200 ring-2 rounded-xl outline-none"
              >
                <option value={4}>All</option>
                <option value={2}>Approved</option>
                <option value={-1}>Rejected</option>
                <option value={1}>Processing</option>
                <option value={0}>Applied</option>
                <option value={3}>Differently Abled</option>
              </select>}
            </div>
          </div>

          <div className="flex items-center justify-between w-5/6 p">
            <div className="grid grid-cols-2 w-100 gap-4 mb-3">
              <label className='font-bold' htmlFor="">Select Hostel :</label>{" "}
              {<select
                defaultValue={selectedHostel}
                onChange={(e) => {
                  setSelectedHostel(e.target.value);
                }}
                className=" p-3  ring-slate-200 ring-2 rounded-xl outline-none"
              >
                <option value="MH">Mens Hostel</option>
                <option value="LH">Ladies Hostel</option>

              </select>}
            </div>
          </div>

          <div className="flex  items-center justify-between w-5/6 ">
           <input onChange={(e)=>{search(e.target.value)}} placeholder='Search Hostel Admission No ... ' className=' p-3 w-5/6  ring-slate-200 ring-2 rounded-xl outline-none'/>
          </div>

          <div className="w-full flex items-end justify-end mr-10">
          <button
            type="button"
            onClick={()=>{
              downloadExcel()
            }}
            className="ml-auto p-3 bg-green-700 text-white rounded-xl"
          >
           Download Excel
          </button>
        </div>
          <div className="flex items-center justify-between w-11/12 py-4">
            <p className="font-semibold">No Of Inmates : {filteredList.length}</p>
          </div>

          <div className='w-11/12 overflow-x-scroll'>
            <table className='w-11/12 relative table-auto'>
              <tr className='rounded-xl p-3 bg-primary text-center'>
                <th className='p-4'>Sl.No</th>
                <th className='p-3'>Hostel Admission No.</th>
                <th className='p-3'>Name</th>
                <th className='p-3'>Status</th>
                <th className='p-3'>Edit.</th>
              </tr>
              {filteredList.length > 0 && filteredList.map((data, index) => (
                <tr
                  key={index}
                  className={'border-b text-center border-slate-200 border-solid hover:bg-gray-300'}
                >
                  <td className='p-3'>{index + 1}</td>
                  <td className='p-3'>{data.hostel_admission_no}</td>
                  <td className='p-3'>{data.name}</td>
                  <td className='p-3'>{mp[data.status]}</td>
                  <td className="border px-4 py-2 relative">
                    <button
                      onClick={() => {
                        setSelectedStudent(data)
                        toggleMenu(index, data)
                      }}
                      className="z-10 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                    >
                      Change Status
                    </button>
                    <StatusMenu isOpen={menuStates[index]} toggleMenu={() => { toggleMenu(index) }} />
                  </td>
                </tr>
              ))}
            </table>
          </div>
        </div>
        <AlertDialog
          open={open1}
          setOpen={setOpen1}
          modalHeading={modalHeading}
          modalText={modalText}
        />
        <ConfirmDialog
          open={open2}
          setOpen={setOpen2}
          modalHeading={modalHeading}
          modalText={modalText}
          confirmFunction={updateStatusHandler}
        />
      </div>
    </>

  )
}
export default ManageEgrants