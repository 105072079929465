import axios from "axios";
import { useEffect, useContext, useState } from "react";
import { UserContext } from "../Contexts/UserContext";
import { baseUrl } from "../baseUrl";

const Mess_Special_Events_Purchase_History = () => {
  const { user, setLoading } = useContext(UserContext);
  const [active,set_active]=useState(1);
  const [user_purchase_history,set_user_purchase_history]=useState([])
  const parseDateDDMMYYYY = (inputdate) => {
    const date = new Date(inputdate);
    let month = (date.getMonth() + 1).toString();
    let day = date.getDate().toString();
    let year = date.getFullYear();
    if (month.length < 2) {
      month = "0" + month;
    }
    if (day.length < 2) {
      day = "0" + day;
    }
    return day + "-" + month + "-" + year;
  };

  useEffect(()=>{
    setLoading(true)
    axios.get(`${baseUrl}/inmate/get-inmate-purchase-history?user_id=${user.user_id}&&is_active=${active}`).then((res)=>{
        set_user_purchase_history(res.data.data);

    }).catch((err)=>{

    }).finally(()=>{
        setLoading(false)
    })

  },[active])

  

  return (
    <div className="w-11/12  ">
      <div className="flex items-center justify-between w-full">
        <select
          onChange={(e) => {
           set_active(e.target.value)
          }}
          className="w-1/2 p-3 ring-slate-200 ring-2 rounded-xl outline-none"
        >
          <option value={1}>Active</option>
          <option value={0}>All</option>
        </select>
      </div>
      <h2 className="font-semibold text-lg m-2">
        Mess Special Events Purchase History
      </h2>
      <div className="overflow-x-scroll">
      <table className="w-full relative table-auto mr-2 ">
        <tr className="rounded-xl p-3 bg-primary text-center">
          <th className="p-3">Sl.No</th>
          <th className="p-3">Item</th>
          <th className="p-3">Event Date</th>
          <th className="p-3">Event Price</th>
          <th className="p-3">Booked Date</th>
          {/* <th className="p-3">Status</th> */}
        </tr>
        {user_purchase_history.map((purchase, index) => {
          return (
            <tr
              className="border-b text-center border-slate-200 border-solid hover:bg-gray-300"
              key={index}
            >
              <td className="p-3">{index + 1}</td>
              <td className="p-3">{ purchase.item}</td>
              <td className="p-3">{parseDateDDMMYYYY(purchase.event_date)}</td>
              <td className="p-3">{purchase.event_price!=null?purchase.event_price:"to be updated"}</td>
              <td className="p-3">{parseDateDDMMYYYY(purchase.booking_date)}</td>
              {/* <td className="p-3">{index + 1}</td> */}
            </tr>
          );
        })}
      </table>
      </div>
    </div>
  );
};

export default Mess_Special_Events_Purchase_History;
