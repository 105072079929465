import React, { useState, useEffect, useContext } from 'react'
import UploadMessBill from '../../components/UploadMessBill'
import MessOutList from '../../components/MessOutList'
import { motion } from 'framer-motion'
import MessDuesView from '../../components/MessDuesView'
import CurrentMessInmates from '../../components/CurrentMessInmates'
import MessOutReqs from '../../components/MessOutReqs'
import axios from 'axios'
import { baseUrl } from '../../baseUrl'
import { UserContext } from '../../Contexts/UserContext'
import MessAttendance from '../../components/MessAttendance'
import MessOutReqsforToday from '../../components/MessoutReqForToday'
import AddMessOutAdminForm from '../../components/AddMessOutAdmin'
import ExtendMessOutAdminForm from '../../components/ExtendMessout'
import GetMessOut_Month_History_Of_inmate from '../../components/GetMessoutHistoryOf_Inmate_Component'
import Add_Mess_Special_Events from '../../components/Add_Mess_Special_Events'
import Get_Mess_Special_Events from '../../components/Get_Mess_Special_Events'
import IntersectingMessout from '../Inmate/Components/IntersectingMessout'
import { Layout, Menu, Avatar, Typography, Select } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import MessInInmatesCLRKA3 from '../Clerk_A3/Components/MessInInmatesCLRKA3'
import MessOutInmatesCLRKA3 from '../Clerk_A3/Components/MessOutInmatesCLRKA3'
import MessoutConfig from '../Clerk_A3/Components/MessoutConfig'

const { Header } = Layout;
const { Title } = Typography;
const { Option } = Select;

function MessManagementWD() {
    const { user } = useContext(UserContext)
    const [selectedHostel, setSelectedHostel] = useState('MH');
    const [tabSelected, setTabSelected] = useState(1)
    const [messoutpredaysk, setMessoutpredaysk] = useState(0)
    const [noofDays, setNoofDays] = useState(0)
    const [maxNoofDays, setMaxNoofDays] = useState(null)
    const [maxNoofDaysMonth, setNoofDaysMonth] = useState(0);
    const [inmates, setInmates] = useState([])
    const [allInmates, setAllInmates] = useState([])
    const { setLoading } = useContext(UserContext)

    useEffect(() => {
        if (user.stage == 'inmate') {
            const url = user.hostel === "MH" ? `${baseUrl}/inmate/mess-requirements` : `${baseUrl}/inmate/mess-requirements-LH`;
            axios.get(url)
                .then((res) => {
                    setMessoutpredaysk(res.data.daysK[0].value)
                    setNoofDays(res.data.min[0].value)
                    setMaxNoofDays(res.data.max[0].value)
                    setNoofDaysMonth(res.data.maxinmonth[0].value)
                })

        }
        else if (user.is_admin) {

            const url = selectedHostel === "MH" ? `${baseUrl}/inmate/mess-requirements` : `${baseUrl}/inmate/mess-requirements-LH`;
            axios.get(url)
                .then((res) => {
                    setMessoutpredaysk(res.data.daysK[0].value)
                    setNoofDays(res.data.min[0].value)
                    setMaxNoofDays(res.data.max[0].value)
                    setNoofDaysMonth(res.data.maxinmonth[0].value)
                })
        }
        else {
            const url = user.hostel === "MH" ? `${baseUrl}/inmate/mess-requirements` : `${baseUrl}/inmate/mess-requirements-LH`;
            axios.get(url)
                .then((res) => {
                    setMessoutpredaysk(res.data.daysK[0].value)
                    setNoofDays(res.data.min[0].value)
                    setMaxNoofDays(res.data.max[0].value)
                    setNoofDaysMonth(res.data.maxinmonth[0].value)
                })

        }

    }, [selectedHostel])
    const menuItems = [
        { key: '1', label: 'Mess In Inmates' },
        { key: '2', label: 'Mess Out Inmates' },
        { key: '3', label: 'Mess Attendance' },
        { key: '4', label: 'Add Mess Out' },
        { key: '6', label: 'View Mess Out' },
        { key: '8', label: 'View Special Mess Events' },
        { key: '9', label: 'Intersecting Messouts' },
        { key: '10', label: 'Mess configs' },
    ];

    if (!user.is_admin && user.designation === 'student') {
        menuItems.splice(7, 0, { key: '7', label: 'Add Special Mess Events' });
    }

    return (
        <div className='flex flex-col w-full items-center min-h-screen h-full overflow-y-scroll'>
            <div className='flex flex-row justify-between w-11/12 pt-4 items-center'>
                <div className='text-xl font-bold'>Mess Management</div>
                <div className='flex flex-row space-x-4 items-center'>
                    <div className='bg-white border rounded-full w-10 aspect-square' />
                    <div>{user.name}</div>
                </div>
            </div>

            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.3 }} className=' flex flex-col items-center py-8 space-y-4 w-11/12 md mt-8  bg-white rounded-xl'>
                {/* white box nav bar */}
                <div className="w-full">
                    <Menu
                        className='font-bold w-full justify-start'
                        mode="horizontal"
                        selectedKeys={[tabSelected.toString()]}
                        onSelect={({ key }) => setTabSelected(parseInt(key))}
                        items={menuItems}
                    />
                </div>
                {tabSelected === 1 && <MessInInmatesCLRKA3 inmates={inmates} setInmates={setInmates} />}
                {tabSelected === 2 && <MessOutInmatesCLRKA3 selectedHostel={selectedHostel} setSelectedHostel={setSelectedHostel} messoutpredaysk={messoutpredaysk} setMessoutpredaysk={setMessoutpredaysk} maxNoofDays={maxNoofDays} setMaxNoofDays={setMaxNoofDays} noofDays={noofDays} setNoofDays={setNoofDays} maxNoofDaysMonth={maxNoofDaysMonth} setNoofDaysMonth={setNoofDaysMonth} />}
                {tabSelected === 3 && <MessAttendance allInmates={allInmates} setAllInmates={setAllInmates} />}
                {tabSelected === 4 && <AddMessOutAdminForm />}
                {tabSelected === 5 && <ExtendMessOutAdminForm />}
                {tabSelected === 6 && <GetMessOut_Month_History_Of_inmate />}
                {tabSelected === 7 && <Add_Mess_Special_Events />}
                {tabSelected === 8 && <Get_Mess_Special_Events />}
                {tabSelected === 9 && <IntersectingMessout />}
                {tabSelected === 10 && <MessoutConfig messoutpredaysk={messoutpredaysk} setMessoutpredaysk={setMessoutpredaysk} maxNoofDays={maxNoofDays} setMaxNoofDays={setMaxNoofDays} noofDays={noofDays} setNoofDays={setNoofDays} maxNoofDaysMonth={maxNoofDaysMonth} setNoofDaysMonth={setNoofDaysMonth} />}

                {/* {tabSelected===4&&<AddPerDayMessExpense suppliers={suppliers} setSuppliers={setSuppliers}/>}
        {tabSelected===5&&<MessExpenseList suppliers={suppliers} setSuppliers={setSuppliers}/>} */}

            </motion.div>
        </div>
    )
}

export default MessManagementWD