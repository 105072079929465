import React, { useContext, useEffect, useState } from 'react'
import { baseUrl } from '../../../baseUrl'
import axios from 'axios'
import { UserContext } from '../../../Contexts/UserContext'
import { Table, Button, Upload, message } from 'antd';

function HostelFeeStudent() {
    const [fees, setFees] = useState([])
    const { user } = useContext(UserContext)

    useEffect(() => {
        axios.get(`${baseUrl}/warden/get-all-fee`, { params: { admissionNo: user.user_id, mTechStatus: false } }).then(res => {
            setFees(res.data)
        })
    }, [])


    const columns = [
        {
            title: 'Index',
            key: 'index',
            render: (text, record, index) => index + 1,
        },
        {
            title: 'Fee Name',
            dataIndex: 'fee_name',
            key: 'fee_name',
        },
        {
            title: 'Amount',
            dataIndex: 'value',
            key: 'value',
        },

    ]
    return (
        <div className='w-11/12'>
            <div className="mt-6">
                <h2 className="text-xl font-bold mb-4">Hostel Fees</h2>
                <Table
                    columns={columns}
                    dataSource={fees}
                    pagination={false} 

                />
            </div>
        </div>
    )
}

export default HostelFeeStudent