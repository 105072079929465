import axios from 'axios'
import React, { useState, useContext, useEffect } from 'react'
import dayjs from 'dayjs'

import { Autocomplete, TextField } from '@mui/material';
import { baseUrl } from '../../../baseUrl';
import ConfirmDialog from '../../../components/ConfirmDialog';
import AlertDialog from '../../../components/AlertDialog';
import { UserContext } from '../../../Contexts/UserContext';


function RelieveInmatesClerk() {

    const { setLoading, user } = useContext(UserContext);
    console.log('user', user)

    const [hostelAdmissionNo, setHostelAdmissionNo] = useState("")


    const [open1, setOpen1] = useState("");
    const [open2, setOpen2] = useState("");
    const [modalText, setModalText] = useState("");
    const [modalHeading, setModalHeading] = useState("");

    const [inmates, setInmates] = useState([]);
    const [inmate, setInmate] = useState({})
    console.log('inmate', inmate)


    const [joiningDate, setJoiningDate] = useState()
    const [vacatingDate, setVacatingDate] = useState()




    const handleOptionChange = (event, newValue) => {

        axios.get(`${baseUrl}/warden/inmate-details-relieve`, { params: { HostelAdmissionNo: newValue.hostel_admission_no } }).then((res) => {
            setInmate(res.data);

            console.log('res.datass', res.data)
        }).catch(err => {
            console.log('err', err)
            setLoading(false)
            setModalHeading("Request Completed ");
            setModalText(err.response.data.msg);
            setOpen1(true);

        })
        setHostelAdmissionNo(newValue.hostel_admission_no)
    }

    const handleSubmit = () => {
        axios.delete(`${baseUrl}/warden/relieve-inmate`, { params: { hostelAdmissionNo: hostelAdmissionNo, joiningDate: joiningDate, vacatingDate: vacatingDate, programme: inmate?.programme, department: inmate?.department } }).then((res) => {
            console.log('res.datass', res.data)
        }).then((res) => {
            setModalHeading("Request Completed ");
            setModalText(`Inmate having ${hostelAdmissionNo} has been relieved from the hostel.`);
            setLoading(false)
            setOpen1(true);

        }).catch(err => {
            console.log('err', err)
            setLoading(false)
            setModalHeading("Request Completed ");
            setModalText(err.response.data.msg);
            setOpen1(true);

        })



    }


    useEffect(() => {
        axios.get(`${baseUrl}/warden/get-all-inmates`).then((res) => {
            setInmates(res.data);

            console.log('res.datvhjva', res.data)
        });
    }, [])


    return (
        <div className='"w-11/12 ">'>
            <h2 className="font-bold ">Enter Details
            </h2>
            <form className="grid grid-cols-2 w-100 gap-3 mb-3 w-full" onSubmit={(e) => {
                e.preventDefault()
                setModalHeading("Confirm")
                setModalText(`You are attempting to relieve Name: ${inmate?.name}, Hostel Admission No:${hostelAdmissionNo}, College Admission No: ${inmate?.admission_no}. This process will delete the inmate's data from the system. Do you want to confirm.`)
                setOpen2(true)
            }}>
                <label htmlFor="">Enter Hostel Admission No : </label>{" "}

                <Autocomplete
                    id="auto-complete"
                    options={inmates}
                    getOptionLabel={(option) => option.hostel_admission_no}
                    sx={{ width: 300, outline: "null" }}
                    onChange={handleOptionChange}
                    renderInput={(params) => <TextField {...params} label="Admission no" variant="standard" />}

                />
                {Object.keys(inmate).length !== 0 && <>
                    <label htmlFor="">Name :</label>{" "}
                    <p>{inmate?.name}</p>
                    <label htmlFor="">College Admission No :</label>{" "}
                    <p>{inmate?.admission_no}</p>
                    <label htmlFor="">Joining date :</label>{" "}
                    <input
                        type="date"
                        value={joiningDate}
                        onChange={(e) => {
                            setJoiningDate(e.target.value);
                        }}
                        className="w-12/12 py-2 px-3 rounded-xl ring-2 ring-slate-300 focus:outline-none"
                        required
                    /><label htmlFor="" className="mt-2">Date of vacating hostel  :</label>
                    <input
                        type="date"
                        min={dayjs().format("YYYY-MM-DD")}
                        value={vacatingDate}
                        onChange={(e) => {
                            setVacatingDate(e.target.value);
                        }}
                        className="w-12/12 py-2 px-3 rounded-xl ring-2 ring-slate-300 focus:outline-none"
                        required
                    />
                    <button className={inmate?.allotment_published ? "bg-[#e0e0e0] text-[#a6a6a6] p-3 rounded-lg text-sm w-1/2" : " bg-stone-800 text-white p-3 rounded-lg text-sm w-1/2"} type='submit'>Submit</button>


                </>
                }
            </form>
            <AlertDialog open={open1} setOpen={setOpen1} modalHeading={modalHeading} modalText={modalText} />
            <ConfirmDialog open={open2} setOpen={setOpen2} modalHeading={modalHeading} modalText={modalText} confirmFunction={handleSubmit} />
        </div>
    )
}

export default RelieveInmatesClerk