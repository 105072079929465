import React from 'react'
import { useState, useEffect, useContext } from "react";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import axios from "axios";
import 'jspdf-autotable';
import { baseUrl } from "../../../baseUrl";
import { UserContext } from "../../../Contexts/UserContext";
function MessoutConfig({ messoutpredaysk, setMessoutpredaysk, noofDays, setNoofDays, maxNoofDays, setMaxNoofDays, maxNoofDaysMonth, setNoofDaysMonth }) {


    const { user } = useContext(UserContext)
    var date = new Date();
    var dateFormat = date.getFullYear() + "-" + ((date.getMonth() + 1) < 10 ? "0" + (date.getMonth() + 1) : (date.getMonth() + 1)) + "-" + (date.getDate().length != 2 ? "0" + date.getDate() : date.getDate());
    const [selectedDate, setSelectedDate] = useState(dateFormat);
    const [messreqs, setMessreqs] = useState([]);
    const [tabSelected, setTabSelected] = useState(1);
    const [isEditKpred, setisEditKpred] = useState(false)
    const [isEdit, setIsEdit] = useState(false);
    const [isMaxEdit, setIsMaxEdit] = useState(false);
    const [isMaxmonthEdit, setIsMaxmonthedit] = useState(false);
    const [selectedHostel, setSelectedHostel] = useState("MH");
    const { setLoading } = useContext(UserContext);
    const submitHandlerMessoutk = (e) => {
        e.preventDefault();
        setisEditKpred(!isEditKpred);
        axios
            .put(`${baseUrl}/inmate/messoutpredaysk?hostel=${selectedHostel}`, {
                noofDays: messoutpredaysk,
            })
            .then((res) => {
                console.log(res);
            });
    };

    const submitHandler = (e) => {
        e.preventDefault();
        setIsEdit(!isEdit);
        axios
            .put(`${baseUrl}/inmate/messoutdays?hostel=${selectedHostel}`, {
                noofDays: noofDays,
            })
            .then((res) => {
                console.log(res);
            });
    };

    const submitMaximumNoofDays = (e) => {
        e.preventDefault();
        setIsMaxEdit(!isMaxEdit);
        axios
            .put(`${baseUrl}/inmate/messoutmaximumdays?hostel=${selectedHostel}`, {
                noofDays: maxNoofDays,
            })
            .then((res) => {
                console.log(res);
            });
    };
    const submitMaximumNoofDaysInMonth = (e) => {
        e.preventDefault();
        setIsMaxmonthedit(!isMaxmonthEdit);
        axios
            .put(`${baseUrl}/inmate/messoutmaximumdays-month?hostel=${selectedHostel}`, {
                noofDays: maxNoofDaysMonth,
            })
            .then((res) => {
                console.log(res);
            });
    };
    return (
        <div className="w-11/12">
            <div className="flex items-center justify-between w-4/12">
                <select
                    onChange={(e) => {
                        setSelectedHostel(e.target.value);
                    }}
                    className="p-3 ring-slate-200 ring-2 rounded-xl outline-none"
                >
                    <option value="MH">Mens Hostel</option>
                    <option value="LH">Ladies Hostel</option>
                </select>
            </div>
            <div>
                <div className="flex items-center mt-5 mb-5">
                    <p className="font-semibold">
                        Minimum Number of Days Before next mess out:
                        {isEditKpred ? (
                            <input
                                type="number"
                                min="0"
                                max="100"
                                className="border-solid border-2 rounded-lg ml-3 p-1 w-20"
                                value={messoutpredaysk}
                                onChange={(e) => {
                                    setMessoutpredaysk(e.target.value);
                                }}
                            />
                        ) : (
                            <span className="ml-3">{messoutpredaysk}</span>
                        )}
                    </p>
                    {!isEditKpred ? (
                        <button
                            className="submit-button-black ml-5"
                            onClick={() => {
                                setisEditKpred(!isEditKpred)
                            }}
                        >
                            <EditIcon /> Edit
                        </button>
                    ) : (
                        <button
                            className="submit-button-black text-sm ml-5"
                            onClick={submitHandlerMessoutk}
                        >
                            <CheckIcon className="text-sm" /> Confirm
                        </button>
                    )}
                </div>

                <div className="flex items-center mt-5 mb-5">
                    <p className="font-semibold">
                        Minimum Number of Days for Mess Out:
                        {isEdit ? (
                            <input
                                type="number"
                                min="1"
                                max="100"
                                className="border-solid border-2 rounded-lg ml-3 p-1 w-20"
                                value={noofDays}
                                onChange={(e) => {
                                    setNoofDays(e.target.value);
                                }}
                            />
                        ) : (
                            <span className="ml-3">{noofDays}</span>
                        )}
                    </p>
                    {!isEdit ? (
                        <button
                            className="submit-button-black ml-5"
                            onClick={() => {
                                setIsEdit(!isEdit);
                            }}
                        >
                            <EditIcon /> Edit
                        </button>
                    ) : (
                        <button
                            className="submit-button-black text-sm ml-5"
                            onClick={submitHandler}
                        >
                            <CheckIcon className="text-sm" /> Confirm
                        </button>
                    )}
                </div>
                <div className="flex items-center mt-5 mb-5">
                    <p className="font-semibold">
                        Maximum Number of Days for Mess Out:
                        {isMaxEdit ? (
                            <input
                                type="number"
                                min="1"
                                max="100"
                                className="border-solid border-2 rounded-lg ml-3 p-1 w-20"
                                value={maxNoofDays}
                                onChange={(e) => {
                                    setMaxNoofDays(e.target.value);
                                }}
                            />
                        ) : (
                            <span className="ml-3">{maxNoofDays}</span>
                        )}
                    </p>
                    {!isMaxEdit ? (
                        <button
                            className="submit-button-black ml-5"
                            onClick={() => {
                                setIsMaxEdit(!isMaxEdit);
                            }}
                        >
                            <EditIcon /> Edit
                        </button>
                    ) : (
                        <button
                            className="submit-button-black text-sm ml-5"
                            onClick={submitMaximumNoofDays}
                        >
                            <CheckIcon className="text-sm" /> Confirm
                        </button>
                    )}
                </div>
                <div className="flex items-center mt-5 mb-5">
                    <p className="font-semibold">
                        Maximum No of Days for Mess Out in a Month:
                        {isMaxmonthEdit ? (
                            <input
                                type="number"
                                min="1"
                                max="100"
                                className="border-solid border-2 rounded-lg ml-3 p-1 w-20"
                                value={maxNoofDaysMonth}
                                onChange={(e) => {
                                    setNoofDaysMonth(e.target.value);
                                }}
                            />
                        ) : (
                            <span className="ml-3">{maxNoofDaysMonth}</span>
                        )}
                    </p>
                    {!isMaxmonthEdit ? (
                        <button
                            className="submit-button-black ml-5"
                            onClick={() => {
                                setIsMaxmonthedit(!isMaxmonthEdit);
                            }}
                        >
                            <EditIcon /> Edit
                        </button>
                    ) : (
                        <button
                            className="submit-button-black text-sm ml-5"
                            onClick={submitMaximumNoofDaysInMonth}
                        >
                            <CheckIcon className="text-sm" /> Confirm
                        </button>
                    )}
                </div>
            </div>
        </div>
    )
}

export default MessoutConfig