import React, { useState } from "react";
import { motion } from "framer-motion";
import CreateStudentUserSA from "./components/CreateStudentUserSA";


function ManageUserSA() {
  const [tabSelected, setTabSelected] = useState(1);
  const [userData, setUserData] = useState(null);

  const handleData = (data) => {
    if (data != null) {
      setUserData(data);
    }
  };
  return (
    <div className="flex flex-col w-full items-center min-h-screen h-full overflow-y-scroll ">
      <div className="flex flex-row justify-between w-11/12 pt-4 items-center">
        <div className="text-xl font-bold">Manage User</div>
        <div className="flex flex-row space-x-4 items-center">
          <div className="bg-white border rounded-full w-10 aspect-square" />
          <div>user Name</div>
        </div>
      </div>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.3 }}
        className="flex flex-col items-center py-8 space-y-4 w-11/12 md mt-8 bg-white rounded-xl"
      >
        {/* white box nav bar */}
        <div className="flex flex-row justify-between w-11/12 items-center">
          <div className="flex flex-row tex-black text-sm font-bold relative mb-3">
            <div
              className="cursor-pointer "
              onClick={() => {
                setTabSelected(1);
              }}
            >
              <div>Add Student User</div>
              <div
                className={
                  tabSelected === 1
                    ? "mt-2 h-1 self-center w-10/12 bg-stone-800 rounded-full"
                    : ""
                }
              />
            </div>

          </div>
        </div>
        {tabSelected === 1 && (
          <CreateStudentUserSA setTabSelected={setTabSelected} data={handleData} />
        )}
      </motion.div>
    </div>
  );
}

export default ManageUserSA;
