import { useState, useEffect } from 'react'
import GenerateMessBillForm from '../../components/GenerateMessBillForm'
import GeneratedMessBillList from '../../components/GeneratedMessBillList';
function GenerateMessBillPage() {


  const [calculatedMessBillList, setCalculatedMessBillList] = useState([]);

  return (
    <div className='w-11/12'>
      <GenerateMessBillForm setCalculatedMessBillList={setCalculatedMessBillList} />
      <hr />
      {calculatedMessBillList.length > 0 && <GeneratedMessBillList calculatedMessBillList={calculatedMessBillList} />}
    </div>

  )
}

export default GenerateMessBillPage