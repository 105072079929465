import { useState, useEffect, useContext } from "react";
import { motion } from "framer-motion";
import { UserContext } from "../Contexts/UserContext";
import axios from "axios";
import { baseUrl } from "../baseUrl";
import dateUtils from '../Utils/DateConverter'
import nothing_to_show from "../images/nothing_to_show.jpg"
import { Link } from "react-router-dom";
function InmateTransactionsListComponent() {

  const { setLoading, user } = useContext(UserContext);
  const [userTransactionList,setUserTransactionList]=useState([])
   const [status, setStatus] = useState(0)

   const [toggleModel,setToggleModel]=useState([])

  useEffect(() => {
    setLoading(true);
  
    axios.get(`${baseUrl}/inmate/get-transactions?user_id=${user.user_id}&&status=${status}`).then((res) => {
      if(status==-1)
      {
        setToggleModel(Array.from({ length: res.data.data.length }, () => false))
      }
      setUserTransactionList(res.data.data)
    }).catch((err) => {
      console.log(err)

    }).finally(() => {
      setLoading(false)
    })
  }, [status])

  const mp={
    "0":"Processing",
    "1":"Approved Receipt Not generated",
    "-1":"Rejected",

  }


  return (
    <>
      <div className="w-11/12  mb-20 ">
        <div className="flex items-center justify-between w-full">
          <select
            onChange={(e) => {
              setStatus(e.target.value)
            }}
            className="w-1/2 p-3 ring-slate-200 ring-2 rounded-xl outline-none"
          >
            <option value={0}>Transactions Processing</option>
            <option value={1}>Approved Transactions</option>
            <option value={-1}>Rejected Transactions</option>

          </select>
        </div>
        {userTransactionList.length == 0 ? <div className=" h-full flex flex-col justify-center items-center ">
          <div className="flex m-5 items-center justify-center">
            <img className="h-64" src={nothing_to_show}></img>
          </div>
          <div className="">
            <p className="font-bold "> Nothing to show here</p>
          </div>



        </div> :
          userTransactionList.map((bill, index) => {
            console.log(bill)
            return (
              <motion.div
                key={index}
                className="md:flex sm:p-2 items-center justify-between w-11/12 md:h-24 bg-gray-100 py-3 cursor-pointer mb-3 rounded-md p-2 mt-5 m-2"
              >
                {/* <div className="block">
                <p className="flex">
                  <p className="">Published Date</p> :{index}
                </p>
                <p className="flex">
                  <p className="">Bill Month</p> :{index}
                </p>
                </div> */}
                  <p className="flex">
                  <p className="font-semibold">Transactions Date </p> : {dateUtils.parseDateDDMMYYYY(bill.paid_date)}
                </p>
                <p className="flex">
                  <p className="font-semibold">Transactions ID </p> : {bill.transaction_id}
                </p>
                <p className="flex">
                  <p className="font-semibold">Bill Month </p> : {dateUtils.getMonthName(bill.month) + " - " + bill.year}
                </p>
                <p className="flex m-2">
                  <p className="font-bold">Amount </p> :{bill.amount}
                </p>
                <p 
                 className={`${bill.payment_status==-1?"bg-red-400":bill.payment_status==1?bill.payment_mode==1?"bg-green-400":"bg-blue-400":bill.payment_status==2?"bg-green-400":"bg-white"}  flex flex shadow-md rounded-md px-2`}>
                 {bill.payment_status==2 && <p className=" rounded-md p-2">{bill.payment_status==2 &&  "Receipt : " +bill.receipt_number}</p> } 
                 {bill.payment_status==1 && bill.payment_mode==0 && <p className=" rounded-md p-2">{bill.payment_status==1 && bill.payment_mode==0 && "Receipt Not Generated"}</p> } 
                 {bill.payment_status==1 && bill.payment_mode==1 && <p className=" rounded-md p-2">{bill.payment_status==1 && bill.payment_mode==1 && "Receipt :" + bill.transaction_id}</p> }
                 {bill.payment_status==0 && <p className=" rounded-md p-2">{bill.payment_status==0 &&  mp[bill.payment_status]}</p> } 
                 {bill.payment_status==-1 && <p className=" rounded-md p-2">{bill.payment_status==-1 &&  "Rejected : " + bill.reason}</p> } 
                
                </p>
                
              </motion.div>
            );
          })}
     
      </div>
    </>
  );
}

export default InmateTransactionsListComponent;
