import editSvg from'../../icons/edit.svg'
import userSvg from'../../icons/user.svg'
import bookSvg from'../../icons/book.svg'
import fitnessSvg from'../../icons/fitness.svg'

const hostelOfficeLinks=[
    {
        title:"Mess",
        to:"HO/messmanagement",
        icon: editSvg,
    },
    {
        title:"Mess Bill",
        to:"HO/manage-mess-bills",
        icon: editSvg,
    },
    // {
    //     title:"Hostel Admission",
    //     to:"HO/hostel-admission",
    //     icon: editSvg,
    // },
    // {
    //     title:"Egrantz",
    //     to:"HO/egrants",
    //     icon: editSvg,
    // },
    {
        title:"Hostel Registry",
        to:"WD/hostelregistry",
        icon: editSvg,
    },
    {
        title:"Mess Suspension",
        to:"HO/mess-suspension",
        icon: editSvg,
    },
    // {
    //     title:"Manage User",
    //     to:"HO/manage-user",
    //     icon: editSvg,
    // },

    
]

export {hostelOfficeLinks}