import InfoIcon from "@mui/icons-material/Info";
import axios from "axios";
import { useState, useContext, useEffect } from "react";
import { UserContext } from "../Contexts/UserContext";
import AlertDialog from "./AlertDialog";
import ConfirmDialog from "./ConfirmDialog";
import { baseUrl } from "../baseUrl";
function Add_Mess_Special_Events() {
  const { setLoading,user } = useContext(UserContext);
  const [event_date,set_event_date]=useState();
  const [book_before_date,set_book_before_date]=useState("")
  const [item,set_item]=useState("");
  const [item_price,set_item_price]=useState(0);
  const [served_time,set_served_time]=useState(-1)
  const [defaultselected,setDefaultSelected]=useState(false);
  const [open1, setOpen1] = useState("");
  const [open2, setOpen2] = useState("");
  const [modalText, setModalText] = useState("");
  const [modalHeading, setModalHeading] = useState("");

  const mp = {
    1: "BreakFast",
    2: "Lunch",
    3: "Tea",
    4:"Dinner"
  };

  const dateConverter = (inputdate) => {
    const date = new Date(inputdate);
    let month = (date.getMonth() + 1).toString();
    let day = date.getDate().toString();
    let year = date.getFullYear();
    if (month.length < 2) {
      month = "0" + month;
    }
    if (day.length < 2) {
      day = "0" + day;
    }
    return [year, month, day].join("-");
  };
  const parseDateDDMMYYYY = (inputdate) => {
    const date = new Date(inputdate);
    let month = (date.getMonth() + 1).toString();
    let day = date.getDate().toString();
    let year = date.getFullYear();
    if (month.length < 2) {
      month = "0" + month;
    }
    if (day.length < 2) {
      day = "0" + day;
    }
    return day + "-" + month + "-" + year;
  };
  
  const getMinDateForEvent = () => {
    const date = new Date();
    date.setDate(date.getDate() + 3);
    let month = (date.getMonth() + 1).toString();
    let day = (date.getDate()).toString();
    let year = date.getFullYear();
    if (month.length < 2) {
      month = "0" + month;
    }
    if (day.length < 2) {
      day = "0" + day;
    }
    return year + "-" + month + "-" + day;
  };
  const getMinDateForEventBooking = () => {
    const date = new Date();
    let month = (date.getMonth() + 1).toString();
    let day = (date.getDate()).toString();
    let year = date.getFullYear();
    if (month.length < 2) {
      month = "0" + month;
    }
    if (day.length < 2) {
      day = "0" + day;
    }
    return year + "-" + month + "-" + day;
  };

  const getMaxDateForEventBooking = () => {
    const date = new Date(event_date);
    let month = (date.getMonth() + 1).toString();
    let day = (date.getDate()).toString();
    let year = date.getFullYear();
    if (month.length < 2) {
      month = "0" + month;
    }
    if (day.length < 2) {
      day = "0" + day;
    }
    return year + "-" + month + "-" + day;
  };

  const submit_Request_Confirmation=(e)=>{
    e.preventDefault();
    if(served_time==-1)
    {
        setModalHeading("Please choose all Field")
        setModalText("Please check if you have filled all the neccessary fields before submission \nPlease correct and try again")
        setOpen1(true)
        return;
    }
    const event_date_1=new Date(event_date);
    const book_before_date_1=new Date(book_before_date)
    if(event_date_1<=book_before_date_1)
    {
      setModalHeading("Invalid date")
        setModalText("Please choose the relevent dates appropriately . The date to book should be prior to the event of date")
        setOpen1(true)
        return;

    }
    if(item_price<=0){
      setModalHeading("Invalid price")
      setModalText("The item price should be greater than zero  . Please change it and proceed .")
      setOpen1(true)
      return;
    }
    setModalHeading("Confirm Adding Event")
    setModalText("You are about to add a Special Mess item "+item+ " for the date "+parseDateDDMMYYYY(event_date)+" at time " +mp[served_time] + ". Inmates will be able to book for the item before "+parseDateDDMMYYYY(book_before_date) +"\nDo you want to confirm ? ")
    setOpen2(true);
  }

  const add_mess_special_event=()=>{
    setLoading(true);
    const hostel=user.hostel
    axios.post(`${baseUrl}/inmate/add-mess-special-events`,{
        hostel,
        event_date,
        book_before_date,
        item,
        item_price,
        served_time,
        default_selected:defaultselected
    }).then((res)=>{
        setModalHeading("Event Added ")
        setModalText("The Event has been added successfully . Inmates will be able to book for the event before "+parseDateDDMMYYYY(book_before_date)+"\n You can see the booking report in your tab")
        setOpen1(true)
        set_book_before_date("")
        set_event_date("")
        set_item("")
        set_served_time("")
    }).catch((err)=>{
        setModalHeading("Something went wrong")
        setModalText("Your request could not be added . Please Try Again after sometime \n Thanks for your patience");
        setOpen1(true)
    }).finally(()=>[
        setLoading(false)
    ])
  }


  return (
    <div className="mb-3">
      <h2 className="font-semibold text-lg m-2">Add Mess Special Events</h2>
      <div className="m-2 p-2">
        <form onSubmit={submit_Request_Confirmation} >
          <div className="grid grid-cols-2 w-100 gap-4 mb-3">
            <label htmlFor="">Event Date :</label>{" "}
            <input
              required
              type="date"
              min={getMinDateForEvent()}
              value={event_date}
              onChange={(e) => {
                set_event_date(e.target.value)
              }}
              className="w-12/12 py-2 px-3 rounded-xl ring-2 ring-slate-300 focus:outline-none"
            />
          </div>
          <div className="grid grid-cols-2 w-100 gap-4 mb-3">
            <label htmlFor="">Book Before  :</label>{" "}
            <input
              required
              type="date"
              onChange={(e)=>{
                set_book_before_date(e.target.value)
              }}
              min={getMinDateForEventBooking()}
              max={getMaxDateForEventBooking()}
              value={book_before_date}
              className="w-12/12 py-2 px-3 rounded-xl ring-2 ring-slate-300 focus:outline-none"
            />
          </div>
          <div className="grid grid-cols-2 w-100 gap-4 mb-3">
            <label htmlFor="">Served Time:</label>{" "}
            <select
            required
              onChange={(e) => {
                set_served_time(e.target.value);
              }}
              name="reason-dropdown"
              id="reason-dropdown"
              className="py-2 px-3 rounded-xl ring-2 ring-slate-300 focus:outline-none"
            >
            <option value={-1}>--Select Served Time---</option>
              <option value={1}>BreakFast</option>
              <option value={2}>Lunch</option>
              <option value={3}>Tea</option>
              <option value={4}>Dinner</option>
            </select>
          </div>
          <div className="grid grid-cols-2 w-100 gap-4 mb-3 mt-5">
            <label htmlFor="">Item:</label>{" "}
            <input
              type="text"
              value={item}
              onChange={(e) => {
                set_item(e.target.value);
              }}
              className="w-12/12 py-2 px-3 rounded-xl ring-2 ring-slate-300 focus:outline-none"
              required
            />
          </div>
          <div className="grid grid-cols-2 w-100 gap-4 mb-3 mt-5">
            <label htmlFor="">Item Price (R.S):</label>{" "}
            <input
              type="number"
              value={item_price}
              min={1}
              onChange={(e) => {
                set_item_price(e.target.value);
              }}
              className="w-12/12 py-2 px-3 rounded-xl ring-2 ring-slate-300 focus:outline-none"
              required
            />
          </div>
          {/* <div className="grid grid-cols-2 w-100 gap-4 mb-3 mt-5">
            <label htmlFor="">Default Selected:</label>{" "}
            <input
              type="checkbox"
              value={defaultselected}
              onChange={(e) => {
                setDefaultSelected(!defaultselected);
              }}
              className=" py-2 px-3 rounded-xl "
            />
          </div> */}
        
    
          <div className="w-full flex items-end justify-end mt-5">
            <button
              type="submit"
              className="ml-auto p-3 bg-stone-800 text-white rounded-xl"
            >
              Submit
            </button>
          </div>
        </form>
        <AlertDialog
          open={open1}
          setOpen={setOpen1}
          modalHeading={modalHeading}
          modalText={modalText}
        />
        <ConfirmDialog
          open={open2}
          setOpen={setOpen2}
          modalHeading={modalHeading}
          modalText={modalText}
          confirmFunction={add_mess_special_event}
        />
      </div>
    </div>
  );
}
export default Add_Mess_Special_Events;
