import React from 'react'
import axios from 'axios'
import { useContext, useEffect, useState } from 'react'
import dayjs  from 'dayjs'
import { baseUrl } from '../../../baseUrl'
import { UserContext } from '../../../Contexts/UserContext'


function ViewHdfExtended() {
    const { user } = useContext(UserContext)

    const [students, setStudents] = useState([])
    useEffect(() => {
        axios.get(`${baseUrl}/warden/get-hdf-extended-list`).then(res => {
            console.log('res.datahdf list', res.data)
            setStudents(res.data)
        })
    },[])

    return (
        <div className="w-11/12 overflow-x-scroll">
            <table className='w-full relative table-auto'>
                <tr className='rounded-xl p-3 bg-primary text-center'>
                    <th className='p-3'>Sl.No</th>
                    <th className='p-3'>Name of Student</th>
                    <th className='p-3'>Admission No.</th>
                    <th className='p-3'>Amout Paid at the <br/> time of Admission</th>
                    <th className='p-3'>DeadLine</th>

                </tr>
                {students?.map((student, index) => (
                    <tr
                        className={'border-b text-center border-slate-200 border-solid hover:bg-gray-300 ' }
                    >
                        <td className='p-3'>{index + 1}</td>
                        <td className='p-3'>{student.name}</td>
                        <td className='p-3'>{student.admission_no}</td>
                        <td className='p-3'>{student.amount_paid}</td>
                        <td className='p-3'>{dayjs(student.deadline).format("DD-MM-YYYY")}</td>
                    </tr>
                ))}
            </table>
        </div>
    )
}

export default ViewHdfExtended