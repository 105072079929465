
import axios from "axios";
import { useState, useContext, useEffect } from "react";
import ConfirmDialog from "../../../components/ConfirmDialog";
import AlertDialog from "../../../components/AlertDialog";
import { baseUrl } from "../../../baseUrl";
import { UserContext } from "../../../Contexts/UserContext";
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import DateConverter from "../../../Utils/DateConverter";
function AddMessSuspensionForm({ setRes }) {

  const { setLoading } = useContext(UserContext)
  const [file, setFile] = useState(null)
  const [fromdate,setFromDate]=useState()
  const [todate,setTodate]=useState()

  const [modalText, setModalText] = useState("");
  const [modalHeading, setModalHeading] = useState("");
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);

  const downloadExcel=async()=>{
      const wb = XLSX.utils.book_new();
      const columns= ["Sl.No","Hostel Admission Number", "Name"]
      const ws = XLSX.utils.aoa_to_sheet([columns]);
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      const wbBlob = new Blob([XLSX.write(wb, { type: 'array', bookType: 'xlsx' })], { type: 'application/octet-stream' });
      
      FileSaver.saveAs(wbBlob,`Mess Suspension List Template.xlsx`)

  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!file) {
      setModalHeading("No file found")
      setModalText("Please select a file and try again .")
      setOpen1(true)
      return;
    }
    if(fromdate>todate){
      setModalHeading("Invalid Date")
      setModalText("Please select a valid Date Range and Try Again .")
      setOpen1(true)
      return;
    }
    setModalHeading("Are you sure ");
    setModalText("Please Ensure that you have selected the correct file for uploading . The selected Inmates Will be Suspended from Mess From "+DateConverter.parseDateDDMMYYYY(fromdate) + " To " +DateConverter.parseDateDDMMYYYY(todate) + " . Once Done this changes cannot be reverted . Do you want to continue ?")
    setOpen2(true);
  }

  const uploadAdditionalExpense = async () => {
    setLoading(true);
    if (!file) {
      setModalHeading("No file found")
      setModalText("Please select a file and try again .")
      setOpen1(true)
      return;
    }

    const formData = new FormData();
    formData.append('fromdate',fromdate)
    formData.append('todate',todate)
    formData.append('excelFile', file);

    axios.post(`${baseUrl}/warden/suspend-inmate-mess`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
      .then(response => {
        setRes(response.data.data)
        setModalHeading("File Uploaded")
        setModalText("Mess Suspension List Added succesfully")
        setOpen1(true)
      })
      .catch(error => {
        setModalHeading("Something went wrong")
        setModalText("please try again later")
        setOpen1(true)
      }).finally(() => {
        setLoading(false)
      });
  }

  return (
    <div className="mb-3">
       <div className="w-full flex items-end justify-end mt-5">
          <button
            type="button"
            onClick={()=>{
              downloadExcel()
            }}
            className="ml-auto p-3 bg-green-700 text-white rounded-xl"
          >
           Download Excel Template
          </button>
        </div>
      <h2 className="font-semibold text-lg mb-2">
        Upload Mess Suspension List
      </h2>
     
      <form onSubmit={handleSubmit}>
      <div className=" mt-4  grid grid-cols-2 w-100 gap-4 mb-3">
          <label className="font-bold" htmlFor="">From Date:</label>{" "}
          <input
            required
            type="date"
            // min={DateConverter.getToday()}
            onChange={(e) => {
              setFromDate(e.target.value)
            }}
            className="w-11/12 py-2 px-3 rounded-xl ring-2 ring-slate-300 focus:outline-none"
          />
      
        </div>
        <div className=" mt-4  grid grid-cols-2 w-100 gap-4 mb-3">
          <label className="font-bold" htmlFor="">To Date:</label>{" "}
          <input
            required
            type="date"
            min={DateConverter.getToday()}
            onChange={(e) => {
              setTodate(e.target.value)
            }}
            className="w-11/12 py-2 px-3 rounded-xl ring-2 ring-slate-300 focus:outline-none"
          />
      
        </div>
        <div className=" mt-4  grid grid-cols-2 w-100 gap-4 mb-3">
          <label className="font-bold" htmlFor="">Select The File :</label>{" "}
          <input
            required
            type="file"
            onChange={(e) => {
              setFile(e.target.files[0])
            }}
            placeholder="Select File"
            className="w-11/12 py-2 px-3 rounded-xl ring-2 ring-slate-300 focus:outline-none"
          />
      
        </div>
        <div className="w-full flex items-end justify-end mt-5">
          <button
            type="submit"
            className="ml-auto p-3 bg-stone-800 text-white rounded-xl"
          >
            Upload Mess Suspension List
          </button>
        </div>
      </form>
      <div className="bg-yellow-200 text-yellow-800 p-4 mt-4 rounded-md">
        <p className="font-semibold mb-2">Before proceeding:</p>
        <ul className="list-disc ml-6">
          <li>Make sure the file selected is correct </li>
          <li>No changes will be possible once done</li>
          <li>Double-check the accuracy of file uploaded.</li>
          <li>Check the status of the entries once the process is completed.</li>
        </ul>
      </div>
      <AlertDialog
        open={open1}
        setOpen={setOpen1}
        modalHeading={modalHeading}
        modalText={modalText}
      />
      <ConfirmDialog
        open={open2}
        setOpen={setOpen2}
        modalHeading={modalHeading}
        modalText={modalText}
        confirmFunction={uploadAdditionalExpense}
      />



    </div>
  );

}
export default AddMessSuspensionForm;
