import React, { useContext, useState } from 'react';
import axios from 'axios';
// import { Table, Alert } from '@/components/ui/';
import { baseUrl } from '../../../baseUrl'
import Alert from '@mui/material/Alert';
import { UserContext } from '../../../Contexts/UserContext';
import { Table, Button, Upload, message } from 'antd';
import { UploadOutlined, EyeOutlined, SendOutlined, InboxOutlined } from '@ant-design/icons';
const { Dragger } = Upload;





const BulkRelieInmatesClerk = () => {
  const [csvData, setCsvData] = useState([]);
  const [processedStatus, setProcessedStatus] = useState({});
  const [error, setError] = useState(null);
  const [file, setFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { setLoading } = useContext(UserContext)
  const [deletionProgress, setDeletionProgress] = useState(0);
  const [summary, setSummary] = useState(null);

  // Adjust as needed


  const handleDownloadTemplate = async () => {
    try {
      setSummary(null)
      const response = await axios.get(`${baseUrl}/warden/download-template-relieve`, {
        responseType: 'blob',
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'user_deletion_template.csv');
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      setError('Error downloading template: ' + error.message);
    }
  };

  const handleFile = (e) => {
    setFile(e.target.files[0]);
  };

  const handleFileUpload = (info) => {
    if (info.file.status !== 'uploading') {
      console.log(info.file, info.fileList);
    }
    if (info.file.status === 'done') {
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  const handleFileSubmit = async (e) => {
    e.preventDefault();
    if (!file) {
      setError("Please select a file to upload.");
      return;
    }

    const formData = new FormData();
    formData.append('file', file);

    setIsLoading(true);
    try {
      const response = await axios.post(`${baseUrl}/warden/upload-relieve`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        }
        , onUploadProgress: (progressEvent) => {
          const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
          setDeletionProgress(progress);
        },
      });
      setCsvData(response.data.map(d => {
        return { ...d, STATUS: 'Not processed' }
      }))
      // setCsvData(response.data);
      setError(null);
    } catch (err) {
      setError(`Error uploading file: ${err.response?.data?.message || err.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const processEntries = async () => {
    setLoading(true)
    setIsLoading(true);
    try {
      const response = await axios.post(`${baseUrl}/warden/process-relieve`, { entries: csvData });
      // setProcessedStatus(response.data.status);
      setCsvData(csvData.map(d => {
        return { ...d, STATUS: response.data.status[d['ADMI NO.']] || 'Not processed' }
      }))
      setSummary(response.data.summary);

    } catch (err) {


      setError(`Error processing entries: ${err.response?.data?.message || err.message}`);
    } finally {
      setIsLoading(false);
      setLoading(false)

    }
  };


  const [pageSize, setPageSize] = useState(10)

  const columns = csvData.length > 0 ? Object.keys(csvData[0]).map(key => ({
    title: key,
    dataIndex: key,
    key: key,
  })) : [];

  const props = {
    name: 'file',
    action: `${baseUrl}/warden/upload-relieve`,
    // method: 'post',
    onChange(info) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
        setFile(info.file.originFileObj)
        setCsvData(info.file.response.map(row => {
          return { ...row, STATUS: 'Not processed' }
        }))
      }
      if (info.file.status === 'done') {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };
  return (
    <div className="flex flex-col justify-start w-11/12 overflow-hidden">
      <div className="flex flex-col w-full p-4">
        <div className="mb-8">
          <h4 className="text-lg font-semibold mb-2">Instructions</h4>
          <ul className="list-disc list-inside text-sm text-gray-600 space-y-2">
            <li>Prepare a CSV or Excel file with the following columns: Inmate ID, Name, Room Number, Checkout Date</li>
            <li>Ensure all data is accurate and up-to-date</li>
            <li>The file size should not exceed 10MB</li>
            <li>Once uploaded, review the data before final submission</li>
          </ul>
          <button onClick={handleDownloadTemplate} className="flex items-center px-4 py-2 m-2 bg-gray-800 text-white rounded-md hover:bg-gray-700 transition-colors">
            {/* <Download className="w-5 h-5 mr-2" /> */}
            Download Template
          </button>
        </div>
        <div className='m-3'>

          <Dragger {...props} maxCount={1} accept='.csv,.xlx,.xlsx'>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">Click or drag file to this area to upload</p>
            <p className="ant-upload-hint">
              Support for a single or bulk upload. Strictly prohibited from uploading company data or other
              banned files.
            </p>
          </Dragger>

        </div>

        {file && (
          <div className="bg-blue-50 border border-blue-200 rounded-md p-4 my-6">
            <div className="flex items-center m-3">
              {/* <Info className="w-5 h-5 text-blue-500 mr-2" /> */}
              <span className="text-sm text-blue-700">File "{file.name}" ready for processing</span>
            </div>
            <button onClick={processEntries} className="px-4 py-2 bg-gray-800 text-white rounded-md  hover:bg-gray-700  transition-colors">
              Process Bulk Release
            </button>
          </div>
        )}
        {summary && (
          <div className="my-4 p-4 bg-green-100 text-green-700 rounded-lg">
            <h3 className="font-bold">Operation Summary</h3>
            <p>Total records processed: {summary.totalRecords}</p>
            <p>Users deleted: {summary.deletedUsers}</p>
            <p>New entries created: {summary.newEntries}</p>
          </div>
        )}

        {csvData.length > 0 && (
          <div style={{ marginTop: '24px' }}>
            <h2 style={{ fontSize: '20px', fontWeight: 'bold', marginBottom: '8px' }}>File Contents</h2>
            <Table
              columns={columns}
              dataSource={csvData}
              scroll={{ x: true }}
              pagination={{
                pageSize: pageSize , onChange: (page, pageSize) => {
                  setPageSize(pageSize)
                },
              }}
            />
            {/* <Button
              onClick={processEntries}
              style={{ marginTop: '16px' }}
              icon={<SendOutlined />}
            >
              Submit Data
            </Button> */}
          </div>
        )}
      </div>
      {/* 
      {deletionProgress > 0 && (
        <div className="my-4">
          <div className="w-full bg-gray-200 rounded-full h-2.5">
            <div className="bg-blue-600 h-2.5 rounded-full" style={{ width: `${deletionProgress}%` }}></div>
          </div>
          <p className="text-sm text-gray-600 mt-1">Progress: {deletionProgress}%</p>
        </div>
      )} */}

      {error && <Alert variant="filled" severity="warning">
        {error}
      </Alert>}
    </div>
  );
};

export default BulkRelieInmatesClerk;