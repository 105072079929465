import axios from 'axios'
import { useContext, useEffect, useState } from 'react'
import { UserContext } from '../../../Contexts/UserContext'
import { baseUrl } from '../../../baseUrl'
const RankListStudent = (props) => {

    const [ranks, setRanks] = useState([])
    const { user } = useContext(UserContext)

    useEffect(() => {
        axios.get(`${baseUrl}/student/ranklist`,{params:{admission_no:user.user_id}}).then(res => {
            setRanks(res.data)
            console.log('res.data', res.data)
        })
    },[])
    return (
        <div className="w-full overflow-x-scroll">
        <table className='w-full relative table-auto'>
            <tr className='rounded-xl p-3 bg-primary text-center'>
                <th className='p-3'>Sl.No</th>
                <th className='p-3'>Name of Student</th>
                <th className='p-3'>Admission No.</th>
                <th className='p-3'>District</th>
                <th className='p-3'>Annual Income</th>
                <th className='p-3'>Income  W</th>
                <th className='p-3'>Distance</th>
                <th className='p-3'>Distance W</th>
                <th className='p-3'>Total W</th>
                <th className='p-3'>Rank</th>
            </tr>
            {ranks.map((user, index) => (
                <tr
                    className={'border-b text-center border-slate-200 border-solid hover:bg-gray-300 ' + (user.AdmissionNo === props.admno ? ' bg-teal-200 hover:bg-teal-300' : '')}
                >
                    <td className='p-3'>{index + 1}</td>
                    <td className='p-3'>{user.name}</td>
                    <td className='p-3'>{user.admission_no}</td>
                    <td className='p-3'>{user.district}</td>
                    <td className='p-3'>{user.afi}</td>
                    <td className='p-3'>{user.i_w}</td>
                    <td className='p-3'>{user.distance}</td>
                    <td className='p-3'>{user.d_w}</td>
                    <td className='p-3'>{user.w}</td>
                    <td className='p-3'>{user.rank}</td>
                </tr>
            ))}
        </table>
        </div>

    )
}
export default RankListStudent