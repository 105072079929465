import {useState,useEffect} from 'react'
import CloseMessFormComponent from './CloseMessFormComponent';


function CloseMessComponent() {


  return (
   <div className='w-11/12'>
        <CloseMessFormComponent/>
        <hr/>
    </div>
 
  )
}

export default CloseMessComponent