import { useState, useEffect, useContext } from "react";
import axios from "axios";
import * as FileSaver from 'file-saver';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

import DialogBoxWithInputField from "../../../components/DialogBoxWithInputField";
import ConfirmDialog from "../../../components/ConfirmDialog";
import AlertDialog from "../../../components/AlertDialog";
import DateConverter from "../../../Utils/DateConverter";
import { UserContext } from "../../../Contexts/UserContext";
import { baseUrl } from "../../../baseUrl";


function UpdateBillReceiptNumber() {
  const { user, setLoading } = useContext(UserContext)
 
  const [open1,setOpen1]=useState(false);
  const [open2,setOpen2]=useState(false);
  const [open4,setOpen4]=useState(false)
  const [modalHeading,setModalHeading]=useState("");
  const [modalText,setModalText]=useState("");
  const [update,setUpdate]=useState(false)
  
  const [hostel,setHostel]=useState("LH")
  const [status, setStatus] = useState(1);
  const [paymentDetails, setPaymentDetails] = useState([]);
  const [selectedPaymentDetails,setSelectedPaymentDetails]=useState(null)


  /////////////variables for other option in rejection status //
  const [open3,setOpen3]=useState(false)
  const [value,setValue]=useState("")
  const [label,setLabel]=useState("")



  const paymentModeMap={
    0:"Bank",
    1:"Hostel Office",
    2:"Receipt Generated"
  }

  useEffect(() => {
    setLoading(true);
    axios.get(`${baseUrl}/clerk-a1/get-transactions?status=${status}&&hostel=${hostel}`).then((res) => {
      setPaymentDetails(res.data.data)
    }).catch((err) => {
      setPaymentDetails([])
      alert("something went wrong")
    }).finally(() => {
      setUpdate(false)
      setLoading(false)
    })
  }, [status,update,hostel])



  const handleSubmit=(details)=>{
    
    if(details==null){
      setModalHeading("Request Failed ")
      setModalText("Please choose a valid Transaction to Generate bill  and Try Again .")
      setOpen1(true)
      return
    }
    setSelectedPaymentDetails(details)
    setLabel("Receipt Number")
    setModalHeading("Receipt Number")
    setModalText("Enter the Receipt Number for the transaction ")
    setOpen3(true)
    
   
  }

  const generateReceipt=()=>{
    setLoading(true)
    const data={
      id:user.user_id,
      receipt_number:value,
      trans_id:selectedPaymentDetails.id

    }
    axios.post(`${baseUrl}/clerk-a1/generate-receipt-number`,data).then((res)=>{
      setUpdate(true)
      setModalHeading("Request Success !")
      setModalText("Generated Receipt for the transaction . !")
      setOpen1(true)


    }).catch((err)=>{
      setModalHeading("Something went wrong !")
      setModalText("Could not generate Receipt for the transaction . Please try again later")
      setOpen1(true)
    }).finally(()=>{
      setLoading(false)
    })

  }



  return (
    <>
      <div className="w-full m-5 p-4">
        {
          user.stage == 'inmate' ? <div className="flex items-center justify-between w-4/12">
            <select
              className="p-3 ring-slate-200 ring-2 rounded-xl outline-none"
            >
              <option >{user.hostel == 'MH' ? 'Mens Hostel' : 'Ladies Hostel'}</option>
            </select>
  
          </div> : ""}
     
        <div className="flex items-center justify-between w-4/12">
          <select
            onChange={(e) => {
              setStatus(e.target.value)
            }}
            className="m-2 p-4 ring-slate-200 ring-2 rounded-md outline-none"
          >

            <option value={1}>Approved</option>
            <option value={2}>Receipt Generated</option>
            <option value={3}>Bill Paid from hostel</option>
          </select>
        </div>
        <div className="flex items-center justify-between w-4/12">
          <select
            onChange={(e) => {
              setHostel(e.target.value)
            }}
            className="m-2 p-4 ring-slate-200 ring-2 rounded-md outline-none"
          >
            <option value={"LH"}>Ladies Hostel</option>
            <option value={"MH"}>Mens Hostel</option>
          </select>
        </div>

        <h2 className="text-black font-semibold text-lg mt-5 mb-3">
          Payment Transactions
        </h2>
        <div className="flex items-center justify-between w-4/12 py-4">
          <p className="font-semibold">No Of Payments :</p>
          <p className="font-semibold">{paymentDetails.length} </p>
        </div>
        <div className='w-12/12 overflow-x-scroll'>
          <table className="w-full relative table-auto">
            <tr className="rounded-xl p-3 bg-primary text-center">
              <th className="p-2">Sl.No</th>
              <th className="p-2">Hstl Admssn No.</th>
              <th className="p-2">Name</th>
              <th className="p-2">Month</th>
              <th className="p-2">Rent</th>
              <th className="p-2">Fine</th>
              <th className="p-2">Mess Bill</th>
              <th className="p-2">Amount Paid</th>
              <th className="p-2">Paid Date</th>
              <th className="p-2">Remaining Amount</th>
              <th className="p-2">Payment Mode</th>
              <th className="p-2">{status==1?"Actions":"Receipt Number"}</th>
            </tr>
            {paymentDetails.length>0 && paymentDetails.map((payment, index) => {
              return (
                <tr
                  key={index}
                  className={
                    "border-b text-center border-slate-200 border-solid hover:bg-gray-300"
                  }
                >
                  <td className="p-3 text-sm">{index+1}</td>
                  <td className="p-3 text-sm">{payment.hostel_admission_no}</td>
                  <td className="p-3 text-sm">{payment.name}</td>
                  <td className="p-3 text-sm">{payment.month + " - " + payment.year}</td>
                  <td className="p-3 text-sm">{payment.room_rent_paid}</td>
                  <td className="p-3 text-sm">{payment.fine_paid}</td>
                  <td className="p-3 text-sm ">{payment.amount-(parseFloat(payment.room_rent_paid)+parseFloat(payment.fine_paid))}</td>
                  <td className="p-3 text-sm">{payment.amount}</td>
                  <td className="p-3 text-sm">{DateConverter.parseDateDDMMYYYY(payment.paid_date)}</td>  
                  <td className="p-3 text-sm">{payment.remaining_amount}</td>   
                  <td className="p-3 text-sm">{paymentModeMap[payment.payment_mode]}</td>
                  
                  <td className="p-3  text-left whitespace-nowrap">
                    <button
                      disabled={payment.payment_status!=1}
                      className={` bg-green-400 hover:bg-green-700 text-white font-bold py-2 px-2 rounded`}
                      onClick={()=>{
                        handleSubmit(payment)
                      }}
                    >
                      {payment.payment_status==1 && payment.payment_mode==0&&"Generate Receipt"}
                      {payment.payment_status==1 && payment.payment_mode==1&&payment.transaction_id}
                      {payment.payment_status==2 && payment.payment_mode==0 && payment.receipt_number}
                    </button>
                   
                  </td>
               
                </tr>
              );
            })}
          </table>
      
        </div>
        <AlertDialog
        open={open1}
        setOpen={setOpen1}
        modalHeading={modalHeading}
        modalText={modalText}
      />
      <ConfirmDialog
        open={open2}
        setOpen={setOpen2}
        modalHeading={modalHeading}
        modalText={modalText}
        confirmFunction={generateReceipt}
      />
       <DialogBoxWithInputField
       open={open3}
       setOpen={setOpen3}
       value={value}
       setValue={setValue}
       modalText={modalText}
       modalHeading={modalHeading}
       confirmFunction={()=>{
        setOpen3(false)
        setModalHeading("Are You sure ?")
        setModalText("You are about to generate the Bill Receipt "+value +" for the transaction with transaction ID "+ DateConverter.trimTransactionID(selectedPaymentDetails.transaction_id) +" For the Inmate "+selectedPaymentDetails.hostel_admission_no + " , Name : " + selectedPaymentDetails.name + " . Do You want to continue ?")
        setOpen2(true)
       }}
       label={label}
       />
      </div>
    </>
  );
}

export default UpdateBillReceiptNumber;
