import React, { useContext, useEffect, useState } from 'react'
import ComplaintBox from '../../components/ComplaintBox';
import RoomChange from './RoomChange';
import HostelOut from '../../components/HostelOut'
import {motion} from 'framer-motion'
import { UserContext } from '../../Contexts/UserContext';
import axios from 'axios';
import { baseUrl } from '../../baseUrl';
import CalculateMessBill from '../../components/CalculateMessBill';
function CalculateMessBillPage() {
    const {user,setLoading}=useContext(UserContext)

  return (
    <div className='flex flex-col w-full items-center min-h-screen h-full overflow-y-scroll'>
      <div className='flex flex-row justify-between w-11/12 pt-4 items-center'>
        <div className='text-xl font-bold'>Hostel</div>
        <div className='flex flex-row space-x-4 items-center'>
            <div className='bg-white border rounded-full w-10 aspect-square'/>
            <div>{user.name}</div>
        </div>
      </div>
      <section className='flex flex-col items-center py-8 space-y-4 w-11/12 mt-8 bg-white rounded-xl admin-dashbord-heigh'>
       <CalculateMessBill/>
      </section>
    </div>
  )
}

export default CalculateMessBillPage