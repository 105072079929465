import { React, useState, useContext, useEffect } from 'react'
import { useForm } from "react-hook-form";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import ErrorIcon from '@mui/icons-material/Error';
import { Checkbox, FormControlLabel, TextField } from "@mui/material"
import { grey } from "@mui/material/colors"
import { motion } from "framer-motion"
import axios from 'axios'
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import { useRef } from 'react';
import jsPDF from 'jspdf';
import ReportTemplate from './ReportTemplate';
import { UserContext } from '../../../Contexts/UserContext';
import { baseUrl } from '../../../baseUrl';
import AlertDialog from '../../../components/AlertDialog';
import html2pdf from 'html2pdf.js';
import { Button, message, Steps, theme, Select } from 'antd';
import { Calendar, Clock, AlertCircle } from 'lucide-react';
import { format } from 'date-fns';


function HostelApplicationForm({ appliedStatus }) {
    const [studentData, setStudentData] = useState({});
    const [currpage, setCurrPage] = useState(1);
    const { user } = useContext(UserContext);
    const { setLoading } = useContext(UserContext);
    const navigate = useNavigate();
    const [prevData, setPrevData] = useState({});
    const {
        register,
        handleSubmit,
        reset,
        getValues,
        setValue,
        watch,
        formState: { errors },
    } = useForm();

    const [open, setOpen] = useState(false);
    const [open1, setOpen1] = useState(false);
    const [modalText, setModalText] = useState("");
    const [modalHeading, setModalHeading] = useState("");
    const reportTemplateRef = useRef(null);


    const handleGeneratePdf = () => {
        const doc = new jsPDF({
            format: 'a4',
            unit: 'px',
        });

        // Adding the fonts.
        doc.setFont('Inter-Regular', 'Normal');
        doc.setFontSize(3);

        doc.html(reportTemplateRef.current, {
            async callback(doc) {
                await doc.save('document');
            },
        });
    };

    const generatePDF = () => {
        const element = reportTemplateRef.current;
        const opt = {
            margin: 10,
            filename: `hostel_application.pdf`,
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2, useCORS: true },
            jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
        };

        html2pdf().set(opt).from(element).save();
    };

    useEffect(() => {
        axios.get(`${baseUrl}/student/getinfo`, { params: { user_id: user.user_id } })
            .then(res => {
                setPrevData(res.data)
                reset(res.data);
                console.log('res.data', res.data)
            })
    }, [])

    const districts = ["Thiruvananthapuram", "Kollam", "Pathanamthitta", "Alappuzha", "Kottayam", "Idukki", "Ernakulam", "Thrissur", "Palakkad", "Malappuram", "Kozhikode", "Wayanad", "Kannur", "Kasaragod", "Other"]

    const watchProgramme = watch("programme", "");
    const watchPincode = watch("pincode", "");

    const [postoffices, setpostoffices] = useState([])
    const [pincodeError, setPostOfficeError] = useState(false)



    const handleCheckPincode = () => {
        console.log('pincodeError')
        axios.get(`https://api.postalpincode.in/pincode/${watchPincode}`).then(res => {
            if (res.data[0].Status == "Error") {
                setPostOfficeError(true)
            }
            else {
                setpostoffices(res.data[0].PostOffice)
                setPostOfficeError(false)
                setValue('post_office', res.data[0].PostOffice[0].Name)

            }
        }).catch(err => {
            console.log('err', err)
            setPostOfficeError(true)

        })

    }

    // const { onChange, onBlur, name, ref } = register('pincode'); 



    const backdropClickHandler = (event) => {
        if (event.target === event.currentTarget) {
            setDistacePermissionState(false)
            setDownloadCopyState(false)
            setProduceAnnualIncomeState(false)
            setProduceAddressProof(false)
            setCasteCertificateState(false)
            setFishermanState(false)
            setPhDisablityCertificateState(false)
            setBplGOINCertificateState(false)
            setOpen(false);
        }

    }

    const [distacePermissionState, setDistacePermissionState] = useState(false)
    const [downloadCopyState, setDownloadCopyState] = useState(false)
    const [produceAnnualIncomeState, setProduceAnnualIncomeState] = useState(false)
    const [produceAddressProof, setProduceAddressProof] = useState(false)
    const [casteCertificateState, setCasteCertificateState] = useState(false)
    const [fishermanState, setFishermanState] = useState(false)
    const [bplGOINCertifcateState, setBplGOINCertificateState] = useState(false)
    const [phDisablityCertificateState, setPhDisablityCertificateState] = useState(false)


    const handleOpen = () => setOpen(true);
    const handleClose = () => {

        setDistacePermissionState(false)
        setDownloadCopyState(false)
        setProduceAnnualIncomeState(false)
        setProduceAddressProof(false)
        setCasteCertificateState(false)
        setFishermanState(false)
        setPhDisablityCertificateState(false)
        setBplGOINCertificateState(false)
        setOpen(false);
    }


    const saveData = (data) => {
        setCurrPage(currpage + 1)
    };

    const handleModal = (data) => {
        console.log('datjhhjas', data)
        setStudentData(data);
        // RenderModal(data)
        setOpen(true)

    }

    const handleSubmitData = (data) => {
        console.log('studentData', data)
        axios
            .post(`${baseUrl}/student/hostel-application`, {
                admission_category: data.admission_category,
                admission_no: data.admission_no,
                afi: data.afi,
                type_of_admission: data.type_of_admission,
                department: data.department,
                distance: data.distance,
                email: data.email,
                mobile_no: data.mobile_no,
                post_office: data.post_office,
                programme: data.programme,
                scholarship_category: data.scholarship_category,
                district: data.district,
                pincode: data.pincode,
                batch: data.batch,
                year: data.year_of_admission,
                name: data.name,
                discipline_specialisation_area: data.discipline_specialisation_area,
                gender: data.gender,
                current_year: data.current_year,
                batch: data.batch,
                designation: "student",
            })
            .then((res) => {
                setModalHeading("Request Completed ");
                setModalText("Application Submited.");
                setLoading(false)
                setOpen(false)
                // setOpen1(true);
                message.success("Application submitted successfully.")
                setCurrent(current + 1);

                // setCurrPage(currpage + 1)

                // setSubmited(!submited)
            })
            .catch((err) => {
                console.log("reg", err.response.data.msg);
                setModalHeading("Error");
                setModalText(err.response.data.msg);
                setOpen(false)
                setOpen1(true);


            })
            .finally(() => {
                setLoading(false);
            });
    }
    const [allConditionsMet, setAllConditionsMet] = useState(false);

    useEffect(() => {
        // Check if all conditions are true and update allConditionsMet
        const conditionsMet = (
            ((studentData.distance < 20) ? distacePermissionState : true) &&
            (downloadCopyState) &&
            (produceAnnualIncomeState) &&
            (produceAddressProof) &&
            (((studentData.admission_category == 'SC' || studentData.admission_category == 'ST') || ((studentData.admission_category == 'BPL' || studentData.admission_category == "Ph" || studentData.admission_category == "Other") && (studentData.scholarship_category == 'SC' || studentData.scholarship_category == "ST" || studentData.scholarship_category == "OEC"))) ? casteCertificateState : true) &&
            ((studentData.scholarship_category == 'OBC(H)') ? casteCertificateState : true) &&
            ((studentData.scholarship_category == 'Fisherman') ? fishermanState : true) &&
            ((studentData.admission_category == 'Ph') ? phDisablityCertificateState : true) &&
            ((studentData.admission_category == 'BPL') ? bplGOINCertifcateState : true) &&
            ((studentData.admission_category == 'GOIN') ? bplGOINCertifcateState : true)

            // Add other conditions here
        );
        setAllConditionsMet(conditionsMet);
        console.log('((studentData.admission_category == \'GOIN\') ? bplGOINCertifcateState : true)', ((studentData.admission_category == 'GOIN') ? bplGOINCertifcateState : true))
        console.log('distacePermissionState, downloadCopyState, produceAnnualIncomeState, produceAddressProof,casteCertificateState,fishermanState,phDisablityCertificateState,bplGOINCertifcateState', distacePermissionState, downloadCopyState, produceAnnualIncomeState, produceAddressProof, casteCertificateState, fishermanState, phDisablityCertificateState, bplGOINCertifcateState)
        console.log('conditionsMet', conditionsMet)
    }, [studentData, distacePermissionState, downloadCopyState, produceAnnualIncomeState, produceAddressProof, casteCertificateState, fishermanState, phDisablityCertificateState, bplGOINCertifcateState]);

    const renderField = (label, value) => (
        <div className="grid grid-cols-2 gap-2 py-2 border-b border-gray-200 last:border-b-0">
            <span className="font-medium text-sm text-gray-600">{label}</span>
            <span className="text-gray-800 text-sm">{value}</span>
        </div>
    );

    const renderDisclaimer = (text) => (
        <p className="text-sm text-gray-600 mb-2">{text}</p>
    );

    const handlePrint = () => {
        window.print();
    };


    const { token } = theme.useToken();
    const [current, setCurrent] = useState(0);

    const next = () => {
        setCurrent(current + 1);
    };

    const prev = () => {

        setDistacePermissionState(false)
        setDownloadCopyState(false)
        setProduceAnnualIncomeState(false)
        setProduceAddressProof(false)
        setCasteCertificateState(false)
        setFishermanState(false)
        setPhDisablityCertificateState(false)
        setBplGOINCertificateState(false)
        setCurrent(current - 1);
    };


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const [formData, setFormData] = useState({
        admission_no: '',
        hostel_admission_no: '',
        name: '',
        department: '',
        programme: '',
        year_of_admission: '',
        batch: '',
        gender: '',
        current_year: 1,
        type_of_admission: '',
        discipline_specialisation_area: '',
        mobile_no: '',
        email: '',
        admission_category: '',
        scholarship_category: '',
        aft: '',
        district: '',
        post_office: '',
        distance: '',
        afi: '',
        pincode: '',
        parent_name: '',
        parent_number: ''
    });

    // const [errors, setErrors] = useState({}); 



    const formatDate = (dateString) => {
        return format(new Date(dateString), 'MMMM d, yyyy');
    };

    const DateItem = ({ icon: Icon, label, date }) => (
        <div className="flex items-center space-x-4 py-2">
            <div className="bg-blue-100 p-2 rounded-full">
                <Icon className="h-6 w-6  text-slate-800 " />
            </div>
            <div>
                <p className="text-sm text-gray-500">{label}</p>
                <p className="font-semibold">{formatDate(date)}</p>
            </div>
        </div>
    );

    const steps = [
        {
            title: 'View Rules',
            content: () => (<div className="relative py-3 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl sm:mx-auto">
                <div className="absolute inset-0 bg-gradient-to-r from-slate-400 to-gray-600 shadow-lg transform -skew-y-6 sm:skew-y-0 sm:-rotate-6 sm:rounded-3xl"></div>
                <div className="relative px-4 py-10 bg-white shadow-lg sm:rounded-3xl sm:p-20">
                    <h2 className="text-3xl font-bold text-center text-slate-800 mb-8">Hostel Application</h2>

                    <h2 className="font-bold text-xl mb-4">Rules</h2>
                    <ol className="list-decimal pl-5 pt-2 space-y-2">
                        <li>Admission shall be confined to the regular full time UG/PG students who are in the current nominal roll of the college prepared from time to time by the College of Engineering, Trivandrum.</li>
                        <li>The warden shall have powers to issue instructions and standing orders to regulate the internal matters of the hostel and the warden's decision shall be final in all matters connected with the hostel.</li>
                    </ol>
                    {/* {appliedStatus.open_in && (
                        <div className="w-full flex items-end justify-end mt-5">
                            <motion.button
                                whileHover={{ x: 10, backgroundColor: 'green' }}
                                className="p-3 bg-stone-800 text-white rounded-xl"
                                onClick={() => setCurrPage(currpage + 1)}
                            >
                                Proceed to Apply
                            </motion.button>
                        </div>
                    )} */}
                    {/* <div className="mt-8">
                        <button
                            type="submit"
                            className="w-full py-3 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-slate-600 hover:bg-slate-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-slate-500 transition duration-150 ease-in-out"
                        >
                            Submit Direct Allotment
                        </button>
                    </div> */}
                    <div className="bg-white rounded-lg shadow-lg overflow-hidden w-full max-w-md mx-auto mt-3">
                        <div className="p-6">
                            <div className="bg-slate-500 text-white py-4 px-6">
                                <h2 className="text-xl font-bold text-center">Important Dates</h2>
                            </div>
                            <div className="space-y-4">
                                <DateItem icon={Calendar} label="Application Opens" date={appliedStatus.open_in_date} />
                                <DateItem icon={Calendar} label="Application Closes" date={appliedStatus.close_in_date} />
                                <DateItem icon={Calendar} label="Provisionl Ranklist (Expected Date)" date={appliedStatus.provisional_date} />
                                <DateItem icon={Clock} label="Concerns Addressed Until (Expected Date)" date={appliedStatus.concern_till_date} />
                                <DateItem icon={Clock} label="Final Ranklist (Expected Date)" date={appliedStatus.final_ranklist_date} />
                            </div>
                        </div>
                    </div>
                </div>

            </div>),
        },
        {
            title: 'Verify Details',
            content: () => (
                <div >
                    <div className="space-y-4">
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            <label htmlFor="admission_no" className="font-medium">College Admission No:</label>
                            <input
                                type="text"
                                id="admission_no"
                                disabled
                                {...register("admission_no", { required: "This field is required." })}
                                className="w-full py-2 px-3 rounded-xl ring-2 ring-slate-300 focus:outline-none"
                            />
                            {errors.admission_no && (
                                <p className="text-red-600 font-semibold col-span-full text-sm">
                                    {errors.admission_no.message}
                                </p>
                            )}
                        </div>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            <label htmlFor="">Name :</label>{" "}
                            <input
                                type="text"
                                name="name"
                                // defaultValue={user.name}
                                {...register("name", {
                                    required: "This field is required.",
                                })}
                                className="w-12/12 py-2 px-3 rounded-xl ring-2 ring-slate-300 border-0 focus:outline-none"
                            />
                            {errors.name && (
                                <p className="text-red-600 font-semibold grid md:col-span-2 text-sm col-span-1 ">
                                    {errors.name.message}
                                </p>
                            )}
                        </div>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            <label htmlFor="">Gender :</label>{" "}
                            <select
                                disabled
                                name="gender"
                                {...register("gender", {
                                    required: "This field is required.",
                                })}
                                className="p-3 ring-slate-200 ring-2 rounded-xl outline-none"
                            >
                                <option value={"Male"}>Male</option>
                                <option value={"Female"}>Female</option>
                                <option value={"Other"}>Other</option>
                            </select>
                            {errors.gender && (
                                <p className="text-red-600 font-semibold grid md:col-span-2 text-sm col-span-1 ">
                                    {errors.gender.message}
                                </p>
                            )}
                        </div>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            <label htmlFor="">Mobile number  :</label>{" "}
                            <input
                                type="text"
                                name="mobile_no"
                                {...register("mobile_no", {
                                    required: "This field is required.",
                                })}
                                className="w-12/12 py-2 px-3 rounded-xl ring-2 ring-slate-300 focus:outline-none"
                            />
                            {errors.mobile_no && (
                                <p className="text-red-600 font-semibold grid md:col-span-2 text-sm col-span-1 ">
                                    {errors.mobile_no.message}
                                </p>
                            )}
                        </div>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            <label htmlFor="">Email:</label>{" "}
                            <input
                                type="text"
                                name="email"
                                {...register("email", {
                                    required: "Email is required.",
                                    pattern: {
                                        value: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
                                        message: "Email is not valid.",
                                    },
                                })}
                                className="w-12/12 py-2 px-3 rounded-xl ring-2 ring-slate-300 focus:outline-none"
                            />
                            {errors.email && (
                                <p className="text-red-600 font-semibold grid md:col-span-2 text-sm col-span-1 ">
                                    {errors.email.message}
                                </p>
                            )}
                        </div>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            <label htmlFor="">Year of Admission:</label>{" "}
                            <input
                                type="number"
                                disabled
                                name="year_of_admission"
                                // defaultValue={prevData.year_of_admission}
                                {...register("year_of_admission", {
                                    required: "Year of admissioin is required.",
                                })}
                                className="w-12/12 py-2 px-3 rounded-xl ring-2 ring-slate-300 focus:outline-none"
                            />
                            {errors.year_of_admission && (
                                <p className="text-red-600 font-semibold grid md:col-span-2 text-sm col-span-1 ">
                                    {errors.year_of_admission.message}
                                </p>
                            )}
                        </div>

                    </div>
                </div>)
        },
        {
            title: 'Verify Details',
            content: () => (
                <div>
                    <div className="space-y-4">
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            <label htmlFor="">Department :</label>{" "}
                            <div>
                                <select
                                    className="p-3 ring-slate-200 ring-2 rounded-xl outline-none"
                                    name="department"
                                    {...register("department", {
                                        required: "This field is required.",
                                    })}
                                    autoWidth
                                    label="Age"
                                >
                                    <option value={"CE"}>CE</option>
                                    <option value={"ME"}>ME </option>
                                    <option value={"ECE"}>
                                        ECE{" "}
                                    </option>
                                    <option value={"EEE"}>
                                        EEE{" "}
                                    </option>
                                    <option value={"CSE"}>CSE</option>
                                    <option value={"Architecture"}> Architecture </option>
                                    <option value={"Computer Application"}> Computer Application </option>
                                    <option value={"CETSOM"}> CET SOM </option>
                                </select>
                            </div>
                            {errors.department && (
                                <p className="text-red-600 font-semibold grid md:col-span-2 text-sm col-span-1 ">
                                    {errors.department.message}
                                </p>
                            )}
                        </div>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            <label htmlFor="">Batch :</label>{" "}
                            <div>
                                <select
                                    className="p-3 ring-slate-200 ring-2 rounded-xl outline-none"
                                    name="batch"
                                    {...register("batch", {
                                        required: "This field is required.",
                                    })}
                                    autoWidth
                                    label="Age"
                                >
                                    <option value={"B1"}>B1</option>
                                    <option value={"B2"}>B2</option>
                                    <option value={"B3"}>B3</option>
                                    <option value={"B4"}>B4</option>
                                    <option value={"NA"}>NA</option>

                                </select>
                            </div>
                            {errors.batch && (
                                <p className="text-red-600 font-semibold grid md:col-span-2 text-sm col-span-1 ">
                                    {errors.batch.message}
                                </p>
                            )}
                        </div>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            <label htmlFor="">Programme :</label>{" "}
                            <select
                                // defaultValue={prevData.programme}
                                disabled

                                name="programme"
                                {...register("programme", {
                                    required: "This field is required.",
                                })}
                                className="p-3 ring-slate-200 ring-2 rounded-xl outline-none"
                            >
                                <option value={"UG"}>UG</option>
                                <option value={"PG"}>PG</option>
                                <option value={"Phd"}>Phd</option>
                            </select>
                            {errors.programme && (
                                <p className="text-red-600 font-semibold grid md:col-span-2 text-sm col-span-1 ">
                                    {errors.programme.message}
                                </p>
                            )}
                        </div>

                        {watchProgramme == "UG" && (
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                <label htmlFor="">Discipline :</label>{" "}
                                <input
                                    type="text"
                                    name="discipline_specialisation_area"
                                    {...register("discipline_specialisation_area", {
                                        required: "This field is required.",
                                    })}
                                    className="w-12/12 py-2 px-3 rounded-xl ring-2 ring-slate-300 focus:outline-none"
                                />
                                {errors.discipline_specialisation_area && (
                                    <p className="text-red-600 font-semibold grid md:col-span-2 text-sm col-span-1 ">
                                        {errors.discipline_specialisation_area.message}
                                    </p>
                                )}
                            </div>
                        )}
                        {watchProgramme == "PG" && (
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                <label htmlFor="">Specialisation :</label>{" "}
                                <input
                                    type="text"
                                    name="discipline_specialisation_area"
                                    {...register("discipline_specialisation_area", {
                                        required: "This field is required.",
                                    })}
                                    className="w-12/12 py-2 px-3 rounded-xl ring-2 ring-slate-300 focus:outline-none"
                                />
                                {errors.discipline_specialisation_area && (
                                    <p className="text-red-600 font-semibold grid md:col-span-2 text-sm col-span-1 ">
                                        {errors.discipline_specialisation_area.message}
                                    </p>
                                )}
                            </div>
                        )}
                        {watchProgramme == "Phd" && (
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                <label htmlFor="">Area :</label>{" "}
                                <input
                                    type="text"
                                    name="discipline_specialisation_area"
                                    {...register("discipline_specialisation_area", {
                                        required: "This field is required.",
                                    })}
                                    className="w-12/12 py-2 px-3 rounded-xl ring-2 ring-slate-300 focus:outline-none"
                                />
                                {errors.discipline_specialisation_area && (
                                    <p className="text-red-600 font-semibold grid md:col-span-2 text-sm col-span-1 ">
                                        {errors.discipline_specialisation_area.message}
                                    </p>
                                )}
                            </div>
                        )}
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">

                            <label htmlFor="">Admission Type :</label>{" "}
                            <select
                                // defaultValue={prevData.type_of_admission}
                                className="p-3 ring-slate-200 ring-2 rounded-xl outline-none"
                                name="type_of_admission"
                                {...register("type_of_admission", {
                                    required: "This field is required.",
                                })}
                            >
                                <option value={"Normal"}>Normal</option>
                                <option value={"LET"}>LET</option>
                                <option value={"College Transfer"}>College Transfer</option>
                            </select>
                            {errors.type_of_admission && (
                                <p className="text-red-600 font-semibold grid md:col-span-2 text-sm col-span-1 ">
                                    {errors.type_of_admission.message}
                                </p>
                            )}
                        </div>

                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">

                            <label htmlFor="">Year :</label>{" "}
                            <select
                                // defaultValue={prevData.current_year}
                                disabled
                                className="p-3 ring-slate-200 ring-2 rounded-xl outline-none"
                                name="current_year"
                                {...register("current_year", {
                                    required: "This field is required.",
                                })}
                            >
                                <option value={"1"}>First Year</option>
                                <option value={"2"}>Second Year</option>
                                <option value={"3"}>Third Year</option>
                                <option value={"4"}>Fourth Year</option>
                            </select>
                            {errors.current_year && (
                                <p className="text-red-600 font-semibold grid md:col-span-2 text-sm col-span-1 ">
                                    {errors.current_year.message}
                                </p>
                            )}
                        </div>
                    </div>
                </div>
            )
        },
        {
            title: 'Applicant',
            content: () => (
                <div>
                    <div className="space-y-4">
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            <label htmlFor="">Admission Categoryy :</label>{" "}
                            <select
                                className="p-3 ring-slate-200 ring-2 rounded-xl outline-none"
                                name="admission_category"
                                {...register("admission_category", {
                                    required: "This field is required.",
                                    onChange: e => {
                                        if (e.target.value == "SC")
                                            setValue('scholarship_category', 'SC')
                                        else if (e.target.value == "ST")
                                            setValue('scholarship_category', 'ST')
                                        else if (e.target.value == "GOIN")
                                            setValue('scholarship_category', 'Other')


                                    }
                                })}
                                autoWidth
                                label="Age"
                            >
                                <option value={"SC"}>SC</option>
                                <option value={"ST"}>ST</option>
                                <option value={"Ph"}>Ph</option>
                                <option value={"BPL"}>BPL</option>
                                <option value={"GOIN"}>GOIN</option>
                                <option value={"Other"}>Other</option>
                            </select>
                            {errors.admission_category && (
                                <p className="text-red-600 font-semibold grid md:col-span-2 text-sm col-span-1 ">
                                    {errors.admission_category.message}
                                </p>
                            )}
                        </div>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            <label htmlFor="">E-Grants Category :</label>{" "}
                            <select
                                className="p-3 ring-slate-200 ring-2 rounded-xl outline-none"
                                name="scholarship_category"
                                {...register("scholarship_category", {
                                    required: "This field is required.",
                                    onChange: e => {
                                        if (getValues("admission_category") == "SC")
                                            setValue('scholarship_category', 'SC')
                                        else if (getValues("admission_category") == "ST")
                                            setValue('scholarship_category', 'ST')
                                        else if (getValues("admission_category") == "GOIN")
                                            setValue('scholarship_category', 'Other')


                                    }
                                })}
                                autoWidth
                            >
                                <option value={"SC"}>SC</option>
                                <option value={"ST"}>ST</option>
                                <option value={"OBC(H)"}>OBC(H)</option>
                                <option value={"OEC"}>OEC</option>
                                <option value={"Fisherman"}>Fisherman</option>
                                <option value={"Ph"}>Ph</option>
                                <option value={"Other"}>Other</option>
                            </select>
                            {errors.scholarship_category && (
                                <p className="text-red-600 font-semibold grid md:col-span-2 text-sm col-span-1 ">
                                    {errors.scholarship_category.message}
                                </p>
                            )}
                        </div>

                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            <label htmlFor="">Annual Family Income In Rupees :</label>{" "}
                            <input
                                type="number"
                                name="afi"
                                {...register("afi", {
                                    required: true,
                                    min: 10000,
                                    max: 1000000000,
                                })}
                                className="w-12/12 py-2 px-3 rounded-xl ring-2 ring-slate-300 focus:outline-none"
                            />
                            {errors.afi && errors.afi.type === "required" && (
                                <p className="text-red-600 font-semibold grid md:col-span-2 text-sm col-span-1 ">
                                    <span role="alert">This is required</span>
                                </p>
                            )}
                            {errors.afi && errors.afi.type === "min" && (
                                <p className="text-red-600 font-semibold grid md:col-span-2 text-sm col-span-1 ">
                                    <span role="alert">Income Must be greater than 10,000</span>
                                </p>
                            )}
                            {errors.afi && errors.afi.type === "max" && (
                                <p className="text-red-600 font-semibold grid md:col-span-2 text-sm col-span-1 ">
                                    <span role="alert">Income Must be less than 100,000,0000</span>
                                </p>
                            )}
                        </div>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            <label htmlFor="">District :</label>{" "}
                            <select
                                className="p-3 ring-slate-200 ring-2 rounded-xl outline-none"
                                name="district"
                                {...register("district", {
                                    required: "This field is required.",
                                })}
                                autoWidth
                            >
                                {districts.map(d => <option value={d}>{d}</option>)}
                            </select>
                            {errors.district && (
                                <p className="text-red-600 font-semibold grid md:col-span-2 text-sm col-span-1 ">
                                    {errors.district.message}
                                </p>
                            )}
                        </div>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            <label htmlFor="" className="mt-2">Pincode </label>
                            <input type="text"
                                name="pincode"
                                {...register("pincode", {
                                    required: "This field is required.",
                                    onBlur: () => { handleCheckPincode() },

                                })}
                                className={pincodeError ? "w-12/12 py-2 px-3 rounded-xl ring-2 ring-red-400 border-2 focus:outline-none" : "w-12/12 py-2 px-3 rounded-xl ring-2 ring-slate-300 focus:outline-none"} />
                            {errors.pincode && (
                                <p className="text-red-600 font-semibold grid md:col-span-2 text-sm col-span-1 ">
                                    {errors.pincode.message}
                                </p>
                            )}
                            {pincodeError && (
                                <p className="text-red-600 font-semibold grid md:col-span-2 text-sm col-span-1 ">
                                    {"Enter a Valid Pincode"}
                                </p>
                            )}

                        </div>

                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            <label htmlFor="" className="mt-2">Post Office </label>
                            <select
                                disabled={pincodeError}
                                className="p-3 ring-slate-200 ring-2 rounded-xl outline-none"
                                name="post_office"
                                {...register("post_office", {
                                    required: "This field is required.",
                                })}
                                autoWidth
                                label="Age"
                            >
                                {
                                    postoffices?.map(d => {
                                        return <option value={d.Name}>{d.Name}</option>

                                    })
                                }
                            </select>
                            {errors.post_office && (
                                <p className="text-red-600 font-semibold grid md:col-span-2 text-sm col-span-1 ">
                                    {errors.post_office.message}
                                </p>
                            )}
                        </div>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            <label htmlFor="">Distance (In Km):</label>{" "}
                            <input
                                type="number"
                                name="distance"
                                {...register("distance", {
                                    required: true,
                                    max: 600,
                                })}
                                className="w-12/12 py-2 px-3 rounded-xl ring-2 ring-slate-300 focus:outline-none"
                            />
                            {errors.distance && errors.distance.type === "required" && (
                                <p className="text-red-600 font-semibold grid md:col-span-2 text-sm col-span-1 ">
                                    <span role="alert">This field is required</span>
                                </p>
                            )}
                            {errors.distance && errors.distance.type == "max" && (
                                <p className="text-red-600 font-semibold grid md:col-span-2 text-sm col-span-1 ">
                                    <span role="alert">Distance Should be less than or equal to 600</span>
                                </p>
                            )}
                        </div>
                        {/* <div className="w-full flex flex-col md:flex-row items-center justify-between mt-5 space-y-4 md:space-y-0">
                            <motion.button
                                whileHover={{ x: -10, backgroundColor: 'red' }}
                                className="w-full md:w-auto p-3 bg-stone-800 text-white rounded-xl"
                                onClick={() => setCurrPage(currpage - 1)}
                            >
                                Back
                            </motion.button>
                            <motion.button
                                whileHover={{ x: 10, backgroundColor: 'green' }}
                                className="w-full md:w-auto p-3 bg-stone-800 text-white rounded-xl"
                                type='submit'
                            >
                                Next
                            </motion.button>
                        </div> */}
                        {appliedStatus.open_in == true &&
                            <div className='mx-5'>

                                <Button className=" bg-stone-800 text-white m-3" type="secondary" htmlType="submit" >
                                    Done
                                </Button>
                                <Button onClick={() => prev()}>
                                    Previous
                                </Button>
                            </div>}

                    </div>
                </div>

            )
        },
        {
            title: "Download Application",
            content: () => (
                <div ref={reportTemplateRef} className='w-full'>
                    <div id="report-template">
                        <ReportTemplate studentData={studentData} />
                    </div>

                </div>)
        }
    ];
    const items = steps.map((item) => ({ key: item.title, title: item.title }));


    // ... (keep other state variables and functions)

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            className='w-11/12 max-w-4xl mx-auto mt-12 bg-white rounded-xl text-left p-4 md:p-5'
        >
            {appliedStatus.open_in == true &&
                <Steps className="mb-10" current={current} items={items} />}
            <div >
                <form onSubmit={handleSubmit(handleModal)} lassName="space-y-6">
                    {(steps[current].content())}
                </form>
            </div>
            {appliedStatus.open_in == true &&

                <div className='mt-5 md:mx-0'>
                    {current < steps.length - 2 && (
                        <Button className=" bg-stone-800 text-white mx-2 " type="secondary" onClick={() => next()}>
                            Next
                        </Button>
                    )}
                    {/* {current === steps.length - 1 && (
               
                )} */}
                    {(current < steps.length - 2 && current < steps.length) &&
                        (
                            <Button onClick={() => prev()}>
                                Previous
                            </Button>
                        )}
                </div>
            }

            <div className=''>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <div
                        onClick={backdropClickHandler}
                        className="bg-slate-500/[.8] z-20 fixed inset-0 flex justify-center items-center"
                    >
                        <div className="flex flex-col bg-white rounded-2xl w-full md:w-6/12 h-3/4 relative overflow-scroll">
                            <div
                                // className='absolute top-1 right-1 flex justify-center items-center bg-red-500 aspect-square w-7 h-7 cursor-pointer text-center text-xs font-bold text-white rounded-full hover:bg-red-700'
                                className="absolute top-1 right-1 cursor-pointer text-red-500 rounded-full hover:text-red-700 "
                                onClick={handleClose}
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-7 w-7"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    stroke-width="2"
                                >
                                    <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                                    />
                                </svg>
                            </div>
                            <div className="">
                                <div className="bg-slate-600 text-white p-6 print:bg-white print:text-black">
                                    <div className="flex justify-between items-center">
                                        <div>
                                            <h1 className="text-2xl font-bold">College of Engineering Trivandrum</h1>
                                            <h2 className="text-xl mt-2">{studentData?.name}</h2>
                                        </div>
                                        {/* <button onClick={handlePrint} className="bg-white text-blue-600 px-4 py-2 rounded-full flex items-center hover:bg-blue-100 transition-colors no-print">
                                                    <Printer className="mr-2" size={18} />
                                                    Print
                                                </button> */}
                                    </div>
                                </div>
                                <div className="p-6">
                                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                        {renderField("Student Name :", studentData?.name)}
                                        {renderField("Year of Admission :", studentData?.year_of_admission)}
                                        {renderField("Gender :", studentData?.gender)}
                                        {renderField("Mobile No", studentData?.mobile_no)}
                                        {renderField("Admission Number :", studentData?.admission_no)}
                                        {renderField("Email :", studentData?.email)}
                                        {renderField("Department :", studentData?.department)}
                                        {renderField("Batch :", studentData?.batch)}
                                        {renderField("Programme :", studentData?.programme)}
                                        {renderField("Admission Type :", studentData?.type_of_admission)}
                                        {renderField("Year :", studentData?.current_year)}
                                        {renderField("Admission Category :", studentData?.admission_category)}
                                        {renderField("Scholarship Category :", studentData?.scholarship_category)}
                                        {renderField("Annual Family Income :", studentData?.afi)}
                                        {renderField("District :", studentData?.district)}
                                        {renderField("Pincode :", studentData?.pincode)}
                                        {renderField("Post Office :", studentData?.post_office)}
                                        {renderField("Distance (In Km) :", studentData?.distance)}
                                    </div>
                                    <div className='flex flex-col justify-between items-center p-3 gap-3 hello'>
                                        {+studentData.distance < 20 && <FormControlLabel onChange={(event) => {
                                            setDistacePermissionState(!distacePermissionState)
                                            console.log(distacePermissionState)
                                        }
                                        }
                                            required control={<Checkbox />} label={renderDisclaimer("I shall submit a written permission from the Principal to take admission even if I am coming from within 20 kilometres from CET. I know that failing to submit this permission letter from the Principal which is issued before the last date of applying for hostel admission shall lead to cancellation of my application.")} />}
                                        <FormControlLabel required control={<Checkbox onChange={(event) => setDownloadCopyState(!downloadCopyState)} />} label={renderDisclaimer("I will be downloading the pdf showing my application and I will produce a copy of the same, failing which can lead to cancellation of application at any point of time")} />
                                        <FormControlLabel required control={<Checkbox onChange={(event) => setProduceAnnualIncomeState(!produceAnnualIncomeState)} />} label={renderDisclaimer("I will produce an annual family income certificate from a competent revenue authority showing the income shown above, failing which can lead to cancellation of application.")} />
                                        <FormControlLabel required control={<Checkbox onChange={(event) => setProduceAddressProof(!produceAddressProof)} />} label={renderDisclaimer("I will produce a valid certificate showing my address proof with the post office shown above, failing which or failing to prove that the distance claimed above is correct, can lead to cancellation of application.")} />
                                        {((studentData.admission_category == 'SC' || studentData.admission_category == 'ST') || ((studentData.admission_category == 'BPL' || studentData.admission_category == "Ph" || studentData.admission_category == "Other") && (studentData.scholarship_category == 'SC' || studentData.scholarship_category == "ST" || studentData.scholarship_category == "OEC"))) && <FormControlLabel required control={<Checkbox onChange={(event) => setCasteCertificateState(!casteCertificateState)} />} label={renderDisclaimer("I will produce a valid caste cerificate showing my caste as shown above, failing which can lead to cancellation of application.")} />}
                                        {studentData.scholarship_category == 'OBC(H)' && <FormControlLabel required control={<Checkbox onChange={(event) => setCasteCertificateState(!casteCertificateState)} />} label={renderDisclaimer("I will produce a valid caste cerificate showing my caste as shown above and an annual family income certificate showing that my annual family income is less than 6 lacks, failing which can lead to cancellation of application.")} />}
                                        {studentData.scholarship_category == 'Fisherman' && <FormControlLabel required control={<Checkbox onChange={(event) => setFishermanState(!fishermanState)} />} label={renderDisclaimer("I will produce a  cerificate showing that my family belongs to fisherman caetegory, failing which can lead to cancellation of application.")} />}
                                        {studentData.admission_category == 'Ph' && <FormControlLabel required control={<Checkbox onChange={(event) => setPhDisablityCertificateState(!phDisablityCertificateState)} />} label={renderDisclaimer("I will produce a valid Disablity cerificate, failing which can lead to cancellation of application.")} />}
                                        {studentData.admission_category == 'BPL' && <FormControlLabel required control={<Checkbox onChange={(event) => setBplGOINCertificateState(!bplGOINCertifcateState)} />} label={renderDisclaimer("I will produce a Ration Card showing that my family belongs to the BPL category, failing which can lead to cancellation of application.")} />}
                                        {studentData.admission_category == 'GOIN' && <FormControlLabel required control={<Checkbox onChange={(event) => setBplGOINCertificateState(!bplGOINCertifcateState)} />} label={renderDisclaimer("I will produce a certifcate showing that I am a candidate nominated by the Government of India, failing which can lead to cancellation of application.")} />}

                                        <motion.button disabled={!allConditionsMet} className={!allConditionsMet ? "w-6/12 bg-[#e0e0e0] p-3 text-[#a6a6a6] rounded-lg text-sm" : "w-6/12 bg-stone-800 text-white p-3 rounded-lg text-sm"} onClick={handleSubmit(handleSubmitData)}>
                                            Submit</motion.button>
                                        {/* <motion.button disabled={(studentData.distance < 20 && distacePermissionState == false) || (((studentData.admission_category == 'SC' || studentData.admission_category == 'ST') || ((studentData.admission_category == 'BPL' || studentData.admission_category == "Ph") && studentData.scholarship_category == 'SC' || studentData.scholarship_category == "ST")) && produceAddressProof == false) || (studentData.scholarship_category == 'OBC(H)' && casteCertificateState == false && downloadCopyState == false) || (studentData.admission_category === 'Ph' && phDisablityCertificateState === false) || (studentData.admission_category === 'BPL' && bplGOINCertifcateState == false) || produceAnnualIncomeState == false || produceAddressProof == false} className={(studentData.distance < 20 && distacePermissionState == false) || ((studentData.admission_category == 'SC' || studentData.admission_category == 'ST') || ((studentData.admission_category == 'BPL' || studentData.admission_category == "Ph") && (studentData.scholarship_category == 'SC' || studentData.scholarship_category == "ST")) && produceAddressProof == false) || (studentData.scholarship_category == 'OBC(H)' && casteCertificateState == false && downloadCopyState == false) || (studentData.admission_category === 'Ph' && phDisablityCertificateState === false) || (studentData.admission_category === 'BPL' && bplGOINCertifcateState == false) || produceAnnualIncomeState == false || produceAddressProof == false ? "w-6/12 bg-[#e0e0e0] p-3 text-[#a6a6a6] rounded-lg text-sm" : "w-6/12 bg-stone-800 text-white p-3 rounded-lg text-sm"} onClick={handleSubmit(handleSubmitData)}> Submit</motion.button> */}
                                        {/* <motion.button disabled={(studentData.distance < 20 && distacePermissionState == false) || (((studentData.admission_category == 'SC' || studentData.admission_category == 'ST') || ((studentData.admission_category == 'BPL' || studentData.admission_category == "Ph") && studentData.scholarship_category == 'SC' || studentData.scholarship_category == "ST")) && produceAddressProof == false) || (studentData.scholarship_category == 'OBC(H)' && casteCertificateState == false && downloadCopyState == false) || (studentData.admission_category === 'Ph' && phDisablityCertificateState === false) || (studentData.admission_category === 'BPL' && bplGOINCertifcateState == false) || produceAnnualIncomeState == false || produceAddressProof == false} className={(studentData.distance < 20 && distacePermissionState == false) || ((studentData.admission_category == 'SC' || studentData.admission_category == 'ST') || ((studentData.admission_category == 'BPL' || studentData.admission_category == "Ph") && (studentData.scholarship_category == 'SC' || studentData.scholarship_category == "ST")) && produceAddressProof == false) || (studentData.scholarship_category == 'OBC(H)' && casteCertificateState == false && downloadCopyState == false) || (studentData.admission_category === 'Ph' && phDisablityCertificateState === false) || (studentData.admission_category === 'BPL' && bplGOINCertifcateState == false) || produceAnnualIncomeState == false || produceAddressProof == false ? "w-6/12 bg-[#e0e0e0] p-3 text-[#a6a6a6] rounded-lg text-sm" : "w-6/12 bg-stone-800 text-white p-3 rounded-lg text-sm"} onClick={handleSubmit(handleSubmitData)}> Submit</motion.button> */}
                                        {/* <motion.button onClick={handleSubmit(handleSubmitData)}> Submit</motion.button> */}
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>


                </Modal>

            </div>

            {/* Add more pages here, following the same responsive pattern */}
            {/* 
            <Modal
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >


            </Modal> */}

            <AlertDialog
                open={open1}
                setOpen={setOpen1}
                modalHeading={modalHeading}
                modalText={modalText}
            />
        </motion.div>
    );
}

export default HostelApplicationForm;