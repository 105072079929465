
import axios from "axios";
import { useState, useContext, useEffect } from "react";
import ConfirmDialog from "../../../components/ConfirmDialog";
import AlertDialog from "../../../components/AlertDialog";
import { baseUrl } from "../../../baseUrl";
import { UserContext } from "../../../Contexts/UserContext";
import DateConverter from "../../../Utils/DateConverter";
function CloseMessFormComponent() {

  const { setLoading } = useContext(UserContext)
  const [hostel,setHostel]=useState("MH")
  const [fromdate,setFromDate]=useState()
  const [todate,setTodate]=useState()

  const [modalText, setModalText] = useState("");
  const [modalHeading, setModalHeading] = useState("");
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);

  const hostel_mp={
    "MH":"MENS HOSTEL",
    "LH":"LADIES HOSTEL"
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if(fromdate>todate){
      setModalHeading("Invalid Date")
      setModalText("Please select a valid Date Range and Try Again .")
      setOpen1(true)
      return;
    }
    setModalHeading("Are you sure ");
    setModalText("The "+ hostel_mp[hostel]+ " Mess will be closed from "+DateConverter.parseDateDDMMYYYY(fromdate)+ " And Will Begin to function from " + DateConverter.parseDateDDMMYYYY(todate) + " . Once Done this changes cannot be reverted . Do you want to continue ?")
    setOpen2(true);
  }

  const closeHostelMess=()=>{
    setLoading(true);
    const data={
        hostel,fromdate,todate
    }

    axios.post(`${baseUrl}/warden/close-hostel-mess`,data).then((res)=>{
      setModalHeading("Request completed successfully !")
      setModalText("The Hostel  Mess Will be close for the provided Days .")
      setOpen1(true)
    }).catch((err)=>{
      setModalHeading("Could not complete request !")
      setModalText(err.response.data.msg)
      setOpen1(true)
    }).finally(()=>{
      setLoading(false)
    })

  }



  return (
    <div className="mb-3">
      <h2 className="font-semibold text-lg mb-2">
        Close Hostel Mess 
      </h2>
     
      <form onSubmit={handleSubmit}>

      <div className=" mt-4  grid grid-cols-2 w-100 gap-4 mb-3">
      <label className="font-bold" htmlFor="">Select Hostel :</label>{" "}
          <select
          required
          onChange={(e)=>{
            setHostel(e.target.value);
          }}
            className="w-11/12 py-2 px-3 rounded-xl ring-2 ring-slate-300 focus:outline-none"
          >
            <option value={"MH"}>Mens Hostel</option>
            <option value={"LH"}>Ladies Hostel</option>
          </select>
        </div>
      <div className=" mt-4  grid grid-cols-2 w-100 gap-4 mb-3">
          <label className="font-bold" htmlFor="">Mess Close From Date :</label>{" "}
          <input
            required
            type="date"
            // min={DateConverter.getToday()}
            onChange={(e) => {
              setFromDate(e.target.value)
            }}
            className="w-11/12 py-2 px-3 rounded-xl ring-2 ring-slate-300 focus:outline-none"
          />
      
        </div>
        <div className=" mt-4  grid grid-cols-2 w-100 gap-4 mb-3">
          <label className="font-bold" htmlFor="">Mess Open Date:</label>{" "}
          <input
            required
            type="date"
            // min={DateConverter.getToday()}
            onChange={(e) => {
              setTodate(e.target.value)
            }}
            className="w-11/12 py-2 px-3 rounded-xl ring-2 ring-slate-300 focus:outline-none"
          />
      
        </div>
        <div className="w-full flex items-end justify-end mt-5">
          <button
            type="submit"
            className="ml-auto p-3 bg-stone-800 text-white rounded-xl"
          >
            Submit Close Mess
          </button>
        </div>
      </form>
      <div className="bg-yellow-200 text-yellow-800 p-4 mt-4 rounded-md">
        <p className="font-semibold mb-2">Before proceeding:</p>
        <ul className="list-disc ml-6">
          <li>Make sure the dates selected are correct </li>
          <li>No changes will be possible once done</li>
          <li>Mess Will Be closed from the given from date and Mess Will start to begin function from the given Mess Open Date.</li>
        </ul>
      </div>
      <AlertDialog
        open={open1}
        setOpen={setOpen1}
        modalHeading={modalHeading}
        modalText={modalText}
      />
      <ConfirmDialog
        open={open2}
        setOpen={setOpen2}
        modalHeading={modalHeading}
        modalText={modalText}
        confirmFunction={closeHostelMess}
      />



    </div>
  );

}
export default CloseMessFormComponent;
