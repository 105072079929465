import InfoIcon from "@mui/icons-material/Info";
import axios from "axios";
import { useState, useContext, useEffect } from "react";
import { UserContext } from "../../../Contexts/UserContext";
import AlertDialog from "../../../components/AlertDialog";
import ConfirmDialog from "../../../components/ConfirmDialog";
import { baseUrl } from "../../../baseUrl";
import { useParams } from "react-router-dom";


function WardenEditMessoutPage() {

  const { id } = useParams();
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState('');
  const [modalText, setModalText] = useState("");
  const [modalHeading, setModalHeading] = useState("");
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3,setOpen3]=useState(false)
  const { user, setLoading } = useContext(UserContext);

  const dateConverter = (inputdate) => {
    const date = new Date(inputdate);
    let month = (date.getMonth() + 1).toString();
    let day = date.getDate().toString();
    let year = date.getFullYear();
    if (month.length < 2) {
      month = "0" + month;
    }
    if (day.length < 2) {
      day = "0" + day;
    }
    return [year, month, day].join("-");
  };
  const parseDateDDMMYYYY = (inputdate) => {
    const date = new Date(inputdate);
    let month = (date.getMonth() + 1).toString();
    let day = date.getDate().toString();
    let year = date.getFullYear();
    if (month.length < 2) {
      month = "0" + month;
    }
    if (day.length < 2) {
      day = "0" + day;
    }
    return day + "-" + month + "-" + year;
  };
  useEffect(() => {
    if (!user.is_admin) {
      return window.location = '/'
    }
    setLoading(true);
    axios
      .get(`${baseUrl}/warden/get-inmate-messout-details?messoutid=${id}`)
      .then((res) => {
        if (res.data.status == "ok") {
          const data = res.data.data;
          setFromDate(dateConverter(data.fromdate));
          setToDate( dateConverter(data.todate));
        }
        else {
          throw new Error("Something went wrong")
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);





  const renderStateNewMessout = () => {

    const deleteButtonHandler=()=>{
      setModalText(
        "You are about to delete Mess Out  from " +
        parseDateDDMMYYYY(fromDate) +
        " And Mess In from " +
        parseDateDDMMYYYY(toDate) +
        "\n . Do you want to Confirm?"
      );
      setOpen3(true)
    }

    const submiteDeleteRequest=()=>{
      setLoading(true)
      axios
        .post(`${baseUrl}/warden/delete-inmate-messout`, {
          user_id: user.user_id,
          messoutid:id
        })
        .then((res) => {
          if (res.data.status == "ok") {
            setModalHeading("Messout deleted successfully")
            setModalText("The messout request have been deleted !");
            setOpen1(true)
            window.location='/'
          } else {
            setModalHeading("Failed to execute");
            setModalText("The Request Failed  . Please try again later !");
            setOpen1(true);
          }

        }).catch((err) => {
          setModalHeading("Failed to execute");
          setModalText("The Request Failed  . Please try again later !");
          setOpen1(true);
        }).finally(() => {
          setLoading(false);
        })
    }

    const submitMessoutHandler = (e) => {
      e.preventDefault()
      try {
  
        if (new Date(fromDate) > new Date(toDate)) {
          setModalHeading("Invalid Date");
          setModalText("Please Check Your date and Apply Again .");
          setOpen1(true);
          return;
        }
        setModalText(
          "You have filled Mess Out  from " +
          parseDateDDMMYYYY(fromDate) +
          " And Mess In from " +
          parseDateDDMMYYYY(toDate) +
          "\nNo Preconditions are checked while editing please ensure the correctness of the data . Do you want to Confirm?"
        );
        setOpen2(true)

      } catch (err) {
        setModalHeading("Something went wrong");
        setModalText("Couldn't complete your request . Please proceed after some time");
        setOpen1(true);

      }
    }

    const submitMessoutRequest = async () => {
      setLoading(true)
      axios
        .post(`${baseUrl}/warden/edit-inmate-messout`, {
          user_id: user.user_id,
          fromdate: dateConverter(fromDate),
          todate: dateConverter(toDate),
          messoutid:id
        })
        .then((res) => {
          if (res.data.status == "ok") {
            setModalHeading("Messout Edited successfully")
            setModalText("The messout request have been edited correctly");
            setOpen1(true)
          } else {
            console.log('res.data', res.data)
            setModalHeading("Failed to execute");
            setModalText(res.data.msg);
            setOpen1(true);
          }

        }).catch((err) => {
          console.log('err', err)
          setModalHeading("Failed to execute");
          setModalText(`${err.response.data.msg}`);
          setOpen1(true);
        }).finally(() => {
          setLoading(false);
        })
    }

    return (
      <>
        <form onSubmit={submitMessoutHandler}>
          <div className="grid grid-cols-2 w-100 gap-4 mb-3">
            <label htmlFor="">Mess out from date:</label>{" "}
            <input
              required
              type="date"
              value={fromDate}
              onChange={(e) => {
                setFromDate(e.target.value)
                setToDate('')
              }}
              className="w-12/12 py-2 px-3 rounded-xl ring-2 ring-slate-300 focus:outline-none"
            />
          </div>
          <div className="grid grid-cols-2 w-100 gap-4 mb-3">
            <label htmlFor="">Mess In date:</label>{" "}
            <input
              required
              type="date"
              value={toDate}
              onChange={(e) => {
                setToDate(e.target.value)
              }}
              className="w-12/12 py-2 px-3 rounded-xl ring-2 ring-slate-300 focus:outline-none"
            />
          </div>
          <p className="flex items-center mt-2">
            <InfoIcon className="text-md font-bold text-red-400 mr-1" /> No precondition is checked while editing the messout.Please ensure it manually
          </p>

          <div className="w-full flex items-end justify-end mt-5">
            <button
              type="submit"
              className="ml-auto p-3 bg-stone-800 text-white rounded-xl"
            >
              Edit Messout
            </button>
          </div>
          <div className="w-full flex items-end justify-end mt-5">
            <button
              type="button"
              onClick={deleteButtonHandler}
              className="ml-auto p-3 bg-stone-800 text-white rounded-xl"
            >
              Delete Messout
            </button>
          </div>
        </form>
        <AlertDialog
          open={open1}
          setOpen={setOpen1}
          modalHeading={modalHeading}
          modalText={modalText}
        />
        <ConfirmDialog
          open={open2}
          setOpen={setOpen2}
          modalHeading={modalHeading}
          modalText={modalText}
          confirmFunction={submitMessoutRequest}
        />
           <ConfirmDialog
          open={open3}
          setOpen={setOpen3}
          modalHeading={modalHeading}
          modalText={modalText}
          confirmFunction={submiteDeleteRequest}
        />




      </>)
  }

  return (
    <div className='flex flex-col w-full items-center min-h-screen h-full overflow-y-scroll'>
    <div className='flex flex-row justify-between w-11/12 pt-4 items-center'>
      <div className='text-xl font-bold'>Mess</div>
      <div className='flex flex-row space-x-4 items-center'>
          <div className='bg-white border rounded-full w-10 aspect-square'/>
          <div>{user.name}</div>
      </div>
    </div>

    <div initial={{opacity:0}} animate={{opacity:1}} transition={{duration:0.3}}  className='flex flex-col items-center py-8 space-y-4 w-11/12 mt-8 bg-white rounded-xl'>
    <div className="w-11/12">
      <div className="mb-3">
        <h2 className="font-semibold text-lg mb-2">
          Edit Inmate Messout
        </h2>
        {renderStateNewMessout()}
      </div>
    </div>
    
    </div>
  </div>
   
  );

}
export default WardenEditMessoutPage;
