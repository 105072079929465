import editSvg from'../../icons/edit.svg'
import userSvg from'../../icons/user.svg'
import bookSvg from'../../icons/book.svg'
import fitnessSvg from'../../icons/fitness.svg'

const ClerkA1Links=[
    {
        title:"Recieve Payments",
        to:"/CLRKA1/mess-bill-list",
        icon:fitnessSvg,
    },
    {
        title:"Mess Bill",
        to:"/CLRKA1/transactions",
        icon:fitnessSvg,
    },
    // {
    //     title:"Hostel Admission",
    //     to:"/CLRKA1/hostel-admission",
    //     icon:fitnessSvg,
    // },

    
]

export {ClerkA1Links}