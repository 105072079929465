import React, { useState, useEffect, useContext } from 'react'
import { motion } from 'framer-motion'
import { UserContext } from '../../Contexts/UserContext';
import GenerateMessBillPage from './GenerateMessBillPage';
import InmateBillPaymentSummary from '../../components/InmateBillPaymentSummary';
import InmateBillSummaryHostel from '../../components/InmateBillsSummaryHostel';
import AddAdditionalMessExpensePage from '../../components/AddAdditionalMessExpensePage';
import ModifyInmateMessAttendancePage from '../../components/ModifyInmateMessAttendancePage';
import AddMessExemptionPage from '../../components/AddMessExemptionPage';
import InmateDuesList from '../../components/InmateDuesList';
import ListAllMessBillAndEditMessBill from './ListAllMessBillsPage';
function ManageInmateMessBill() {
  const { user } = useContext(UserContext)
  const [tabSelected, setTabSelected] = useState(7)


  return (
    <div className='flex flex-col w-full items-center min-h-screen h-full overflow-y-scroll'>
      <div className='flex flex-row justify-between w-11/12 pt-4 items-center'>
        <div className='text-xl font-bold'>Manage Inmate Mess Bill</div>
        <div className='flex flex-row space-x-4 items-center'>
          <div className='bg-white border rounded-full w-10 aspect-square' />
          <div>{user.name}</div>
        </div>
      </div>

      <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.3 }} className='flex flex-col items-center py-8 space-y-4 w-11/12 mt-8 bg-white rounded-xl'>
        {/* white box nav bar */}
        <div className='flex flex-row justify-between w-11/12 items-center'>
          <div className='flex flex-row tex-black text-sm font-bold relative'>

            {<div
              className='ml-5 cursor-pointer'
              onClick={() => {
                setTabSelected(7)
              }}
            >
              <div>Inmate Dues</div>
              <div className={tabSelected === 7 ? 'mt-2 h-1 w-12/12 self-center bg-stone-800 rounded-full' : ''} />
            </div>
            }

            { <div
                className='ml-5 cursor-pointer'
                onClick={() => {
                  setTabSelected(8)
                }}
              >
                <div>Edit Mess Bill</div>
                <div className={tabSelected === 8 ? 'mt-2 h-1 w-12/12 self-center bg-stone-800 rounded-full' : ''} />
              </div>
            }


          </div>

          {/* {tabSelected===1&&<div className='text-sm mb-2'>Showing 1-8 out of 200 results</div>} */}
          <br />
        </div>
        {tabSelected == 1 && <InmateBillPaymentSummary />}
        {tabSelected === 2 && <GenerateMessBillPage />}
        {tabSelected === 3 && <InmateBillSummaryHostel />}
        {tabSelected === 7 && <InmateDuesList />}
        {tabSelected === 4 && <AddAdditionalMessExpensePage />}
        {tabSelected === 5 && <ModifyInmateMessAttendancePage />}
        {tabSelected === 6 && <AddMessExemptionPage />}
        {tabSelected === 8 && <ListAllMessBillAndEditMessBill />}


      </motion.div>
    </div>
  )
}

export default ManageInmateMessBill