import React, { useState, useEffect } from 'react';
import { Table, Input, DatePicker, Button, message } from 'antd';
import { SearchOutlined, EditOutlined, SaveOutlined, CloseOutlined } from '@ant-design/icons';

import axios from 'axios';
import { baseUrl } from '../../../baseUrl';
import dayjs from 'dayjs';

const RelievedInmatesTable = () => {
  const [inmates, setInmates] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [editingKey, setEditingKey] = useState('');

  const dateFormat = 'YYYY-MM-DD';

  useEffect(() => {
    const fetchInmates = async () => {
      try {
        const response = await axios.get(`${baseUrl}/warden/relieved-inmates`);
        setInmates(response.data);
        // setInmates(structuredInmateData); // Using the data from the previous artifact
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchInmates();
  }, []);

  const isEditing = (record) => record.hostel_admission_no === editingKey;

  const edit = (record) => {
    setEditingKey(record.hostel_admission_no);
  };

  const cancel = () => {
    setEditingKey('');
  };
  const save = async (key) => {
    try {
      const row = inmates.find((item) => key === item.hostel_admission_no);
      if (row) {
        console.log('row', row)
        // Here you would typically make an API call to update the data
        // For now, we'll just update the local state
        const res = await axios.put(`${baseUrl}/warden/update-relieved-inmates`,{UpdateData:row});
        const newData = [...inmates];
        const index = newData.findIndex((item) => key === item.hostel_admission_no);
        if (index > -1) {
          newData[index] = row;
          setInmates(newData);
          setEditingKey('');
          message.success('Dates updated successfully');
        }
      }
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <button
          type="button"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </button>
        <button onClick={() => handleReset(clearFilters)} style={{ width: 90 }}>
          Reset
        </button>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <span style={{ fontWeight: 'bold' }}>{text}</span>
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const columns = [
    {
      title: 'Index',
      key: 'index',
      render: (text, record, index) => index + 1,
    },
    {
      title: 'Hostel Admission No',
      dataIndex: 'hostel_admission_no',
      key: 'hostel_admission_no',
      ...getColumnSearchProps('hostel_admission_no'),
    },
    {
      title: 'Admission No',
      dataIndex: 'admission_no',
      key: 'admission_no',
      ...getColumnSearchProps('admission_no'),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      ...getColumnSearchProps('name'),
    },
    {
      title: 'Joining Date',
      dataIndex: 'joining_date',
      key: 'joining_date',
      width: 200,
      render: (text, record) => {
        const editable = isEditing(record);
        return editable ? (
          <DatePicker
            format={dateFormat}
            defaultValue={dayjs(text, dateFormat)}
            onChange={(date, dateString) => {
              console.log('date', date)
              record.joining_date = date.format("YYYY-MM-DD");;
            }}
          />
        ) : (
          text ? dayjs(text).format('YYYY-MM-DD') : 'N/A'
        );
      },
    },
    {
      title: 'Vacating Date',
      dataIndex: 'vacating_date',
      key: 'vacating_date',
      width: 200,
      render: (text, record) => {
        const editable = isEditing(record);
        return editable ? (
          <DatePicker
            defaultValue={dayjs(text, dateFormat)}
            format={dateFormat}
            cellWidth={10}
            onChange={(date, dateString) => {
              console.log('date', date)
              record.vacating_date = date.format("YYYY-MM-DD");
            }}
          />
        ) : (
          text ? dayjs(text).format('YYYY-MM-DD') : 'N/A'
        );
      },
    },
    {
      title: 'Programme',
      dataIndex: 'programme',
      key: 'programme',
      ...getColumnSearchProps('programme'),
    },
    {
      title: 'Department',
      dataIndex: 'department',
      key: 'department',
      ...getColumnSearchProps('department'),
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Button
              onClick={() => save(record.hostel_admission_no)}
              style={{ marginRight: 8 }}
              icon={<SaveOutlined />}
            >
              Save
            </Button>
            <Button onClick={cancel} icon={<CloseOutlined />}>
              Cancel
            </Button>
          </span>
        ) : (
          <Button disabled={editingKey !== ''} onClick={() => edit(record)} icon={<EditOutlined />}>
            Edit
          </Button>
        );
      },
    },

  ];

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Relieved Inmates</h1>
      <Table columns={columns} dataSource={inmates} rowKey={(record) => record.hostel_admission_no} />
    </div>
  );
};

export default RelievedInmatesTable;