import React, { useState, useEffect, useContext } from 'react'
import { motion } from 'framer-motion'
import { UserContext } from '../../Contexts/UserContext';

import InmateBillPaymentSummary from '../../components/InmateBillPaymentSummary';
import InmateBillSummaryHostel from '../../components/InmateBillsSummaryHostel';
import ClerkUpdateMessBillPayments from './Components/ClerkUpdateMessBillPayments';
import GenerateMessBillPage from '../Warden/GenerateMessBillPage';
import InmateDuesList from '../../components/InmateDuesList';
import ListAllMessBillAndEditMessBill from '../Warden/ListAllMessBillsPage';
import AddMessExemptionPage from '../../components/AddMessExemptionPage';
import ModifyInmateMessAttendancePage from '../../components/ModifyInmateMessAttendancePage';
import AddAdditionalMessExpensePage from '../../components/AddAdditionalMessExpensePage';

function HostelOfficeMessBillPage() {
  const { user } = useContext(UserContext)
  const [tabSelected, setTabSelected] = useState(7)


  return (
    <div className='flex flex-col w-full items-center min-h-screen h-full overflow-y-scroll'>
      <div className='flex flex-row justify-between w-11/12 pt-4 items-center'>
        <div className='text-xl font-bold'>Manage Inmate Mess Bills</div>
        <div className='flex flex-row space-x-4 items-center'>
          <div className='bg-white border rounded-full w-10 aspect-square' />
          <div>{user.name}</div>
        </div>
      </div>

      <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.3 }} className='flex flex-col items-center py-8 space-y-4 w-11/12 mt-8 bg-white rounded-xl'>
        {/* white box nav bar */}
        <div className='flex flex-row justify-between w-11/12 items-center'>
          <div className='flex flex-row tex-black text-sm font-bold relative'>
            {
              <div
                className='ml-5 cursor-pointer'
                onClick={() => {
                  setTabSelected(7)
                }}
              >
                <div>Inmate Dues</div>
                <div className={tabSelected === 7 ? 'mt-2 h-1 w-12/12 self-center bg-stone-800 rounded-full' : ''} />
              </div>
            }

          </div>


          <br />
        </div>
        {tabSelected === 7 && <InmateDuesList />}



      </motion.div >
    </div >
  )
}

export default HostelOfficeMessBillPage