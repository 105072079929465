import React, { useContext, useEffect, useState } from 'react'
import axios from "axios";
import { UserContext } from '../../../Contexts/UserContext';
import { baseUrl } from '../../../baseUrl';
import {
    InfoCircleOutlined,
} from '@ant-design/icons';
import InfoIcon from '@mui/icons-material/Info';
import { Table, Button, Upload, message } from 'antd';
import dayjs from 'dayjs';

function ViewAllotmentStudent() {
    const { user } = useContext(UserContext)
    const [allotmentP1, setAllotmentP1] = useState([])
    const [allotmentP2, setAllotmentP2] = useState([])
    useEffect(() => {
        axios.get(`${baseUrl}/student/get-allotment`, { params: { admission_no: user.user_id } }).then(res => {
            setAllotmentP1(res.data.allotment_p1)
            setAllotmentP2(res.data.allotment_p2)
            console.log('res.data ranbis', res.data)
        })
    }, [])
    const columns = [
        {
            title: 'Index',
            key: 'index',
            render: (text, record, index) => index + 1,
        },
        {
            title: 'Admission No',
            dataIndex: 'admission_no',
            key: 'admission_no',
        },
        {
            title: 'Student Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Rank',
            dataIndex: 'rank',
            key: 'rank',
        },
        {
            title: 'Deadline for taking Admission',
            dataIndex: 'deadline',
            key: 'deadline',
            render: (text => {
                return dayjs(text).format('DD-MM-YYYY')
            })
        },

    ]
    return (
        <div className="w-full overflow-x-scroll">
            {/* <table className='w-full relative table-auto'>
                <tr className='rounded-xl p-3 bg-primary text-center'>
                    <th className='p-3'>Sl.No</th>
                    <th className='p-3'>Admission No.</th>
                    <th className='p-3'>Student Name </th>
                    <th className='p-3'>Rank</th>
                    <th className='p-3'>Deadline for taking Admission </th>

                </tr>
                {allotment?.map((user, index) => (
                    <tr
                        className={'border-b text-center border-slate-200 border-solid hover:bg-gray-300  '}
                    >
                        <td className='p-3'>{index + 1}</td>
                        <td className='p-3'>{user.user_id}</td>
                        <td className='p-3'>{user.name}</td>
                        <td className='p-3'>{user.rank}</td>
                        <td className='p-3'>{user.deadline.split("T")[0]}</td>
                    </tr>
                ))}
            </table> */}
            <Table
                title={() =>
                    <div className='gap-2'>
                        <h2 className='font-bold text-2xl'>College of Engineering Trivandrum</h2>
                        <h2 className='font-bold text-xl'>Priority II</h2>
                    </div>

                }
                columns={columns}
                dataSource={allotmentP2}
                pagination={false}

            />
            <Table
                title={() => <h2 className='font-bold text-xl'>Priority I</h2>}
                columns={columns}
                dataSource={allotmentP1}
                pagination={false}

            />
            <div className='p-3 gap-2'>
                <h2 className="text-xl font-bold">Note</h2>

                <p className='text-black'><InfoIcon className='text-black' /> Allotment given and the application will get cancelled automatically if the allotted appicant fails to join within the deadline for taking Admisison</p>

            </div>
        </div>

    )
}

export default ViewAllotmentStudent