import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { Carousel } from 'antd';
import HomeNavbar from '../components/HomeNavbar';
import Hostel from '../icons/hostel-image.jpeg'

const LandingPage = () => {
  const [images, setImages] = useState([Hostel, Hostel, Hostel])
  return (
    <div className="flex flex-col self-center bg-slate-200 min-h-screen">
      <HomeNavbar />
      <div className="flex flex-col-reverse lg:flex-row items-center justify-between w-5/6 m-auto" style={{ minHeight: "80vh" }}>
        <div className="lg:w-1/2 lg:pr-10">
          <div>
            <h2 className="font-bold text-4xl">Your Partner for Hostel Needs and Activities.</h2>
          </div>
          <div className="flex items-center justify-center pt-6 lg:pt-10">
            <Link to="/login" className="bg-stone-800 text-white px-4 py-2 rounded-lg">Get Started</Link>
          </div>
        </div>
        <div className="md:w-11/12 md:mt-12 lg:mt-0 w-full mt-16">
          <div className="w-full max-w-md mx-auto">
            <Carousel autoplay effect="fade" className="rounded-lg overflow-hidden shadow-md">
              {images.map((image, index) => (
                <div key={index} className="h-64">
                  <img src={image} alt={`Hostel Scene ${index + 1}`} className="w-full h-full object-cover" />
                </div>
              ))}
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LandingPage;