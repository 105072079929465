import { useState, useEffect, useContext } from "react"
import { motion } from "framer-motion"
import axios from "axios";

import { Link } from "react-router-dom";
import { UserContext } from "../../../Contexts/UserContext";
import { baseUrl } from "../../../baseUrl";

function Get_Mess_Special_EventsCLRKA3() {
  const [status, set_status] = useState(2);
  const [mes_special_events, set_mess_special_events] = useState([]);
  const { setLoading, user } = useContext(UserContext)
  const [hostel, setHostel] = useState("LH")

  const mp = {
    1: "BreakFast",
    2: "Lunch",
    3: "Tea",
    4: "Dinner"
  };


  const parseDateDDMMYYYY = (inputdate) => {
    const date = new Date(inputdate);
    let month = (date.getMonth() + 1).toString();
    let day = date.getDate().toString();
    let year = date.getFullYear();
    if (month.length < 2) {
      month = "0" + month;
    }
    if (day.length < 2) {
      day = "0" + day;
    }
    return day + "-" + month + "-" + year;
  };
  useEffect(() => {
    setLoading(true);
    axios.get(`${baseUrl}/inmate/get-mess-special-events?status=${status}&&hostel=${hostel}`).then((res) => {
      set_mess_special_events(res.data.data);
      console.log(mes_special_events)
    }).catch((err) => {
      console.log("error occured ", err.message);
    }).finally(() => {
      setLoading(false)
    })
  }, [status,hostel])



  return (
    <>
      <div className='w-11/12 '>
        <div className="flex items-center justify-between w-full">
          <select
            onChange={(e) => {
              set_status(e.target.value)
            }}
            className="w-1/2 p-3 ring-slate-200 ring-2 rounded-xl outline-none"
          >
            <option value={2}>Upcoming</option>
            <option value={1}>Previous</option>
            <option value={0}>All</option>
          </select>

        </div>
        <div className="flex items-center justify-between w-4/12">
          <select
            onChange={(e) => {
              setHostel(e.target.value)
            }}
            className="m-2 p-4 ring-slate-200 ring-2 rounded-md outline-none"
          >
            <option value={"LH"}>Ladies Hostel</option>
            <option value={"MH"}>Mens Hostel</option>
          </select>
        </div>

        {
          mes_special_events.length < 1 && <div className=" m-4 w-full h-96 flex justify-center items-center"><h2 className="font-bold">No Events Found</h2></div>
        }
        {
          mes_special_events.length > 0 && mes_special_events.map((event, index) => {
            return (
              <motion.div key={index} whileHover={{ scale: 1.02 }} className="md:flex sm:p-2 items-center justify-between w-11/12 bg-gray-100 py-3 cursor-pointer mb-3 rounded-md p-2 mt-5" >
                <Link className="md:flex sm:p-2 items-center justify-between w-11/12 bg-gray-100 py-3 cursor-pointer mb-3 rounded-md p-2 mt-" to={"/mess-special-events-bookings/" + event.id}>
                  <p>{event.item}</p>
                  {/* <p>{index}</p> */}
                  <p>{parseDateDDMMYYYY(event.event_date)}</p>
                  <p>{parseDateDDMMYYYY(event.book_before_date)}</p>
                  <p>{mp[event.served_time]}</p>
                  <button className="p-2 text-black bg-white rounded-md" >View List</button>
                </Link>
              </motion.div>
            )
          })
        }
      </div>

    </>
  )
}


export default Get_Mess_Special_EventsCLRKA3