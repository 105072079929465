import React, { useRef } from 'react';
import { Printer, Download } from 'lucide-react';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

const ReportTemplate = ({ studentData }) => {
	const reportRef = useRef(null);
	const contentRef = useRef(null);

	const renderField = (label, value) => (
		<div className="mb-2">

			<span className="font-medium text-sm text-gray-600">{label}</span>
			<span className="text-gray-800 font-bold text-sm">{value}</span>
		</div>
	);

	const renderDisclaimer = (text) => (
		<p className="text-sm text-gray-600 mb-2">{text}</p>
	);

	const handlePrint = () => {
		window.print();
	};

	const handleDownload = async () => {
		if (reportRef.current) {

			const buttons = reportRef.current.querySelector('.no-print');
			const headrer = reportRef.current.querySelector('.report-header');

			if (buttons) buttons.style.display = 'none';
			if (headrer) headrer.style.display = 'flex';
			if (headrer) headrer.style.justifyContent = 'center';
			const canvas = await html2canvas(reportRef.current);
			const imgData = canvas.toDataURL('image/png');

			if (headrer) headrer.style.display = 'block';
			if (buttons) buttons.style.display = 'flex';

			const pdf = new jsPDF('p', 'mm', 'a4');
			const pdfWidth = pdf.internal.pageSize.getWidth();
			const pdfHeight = pdf.internal.pageSize.getHeight();

			// Add main content
			const imgWidth = canvas.width;
			const imgHeight = canvas.height;
			const ratio = Math.min(pdfWidth / imgWidth, (pdfHeight - 30) / imgHeight); // Leave space for signature
			const imgX = (pdfWidth - imgWidth * ratio) / 2;
			const imgY = 10;

			pdf.addImage(imgData, 'PNG', imgX, imgY, imgWidth * ratio, imgHeight * ratio);

			// Add signature at the bottom right corner
			pdf.setFontSize(10);
			// pdf.text('   S/d', pdfWidth - 20, pdfHeight - 20, { align: 'right' });
			// pdf.text(studentData?.name || '', pdfWidth - 20, pdfHeight - 15, { align: 'right' });

			pdf.save('student_report.pdf');
		}
	};

	return (
		<>
			<style jsx>{`
        @media print {
          body * {
            visibility: hidden;
          }
          #reportContainer, #reportContainer * {
            visibility: visible;
          }
          #reportContainer {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
          }
          .no-print, .btn {
            display: none !important;
          }
          .signature-print {
            position: fixed;
            bottom: 50px;
            right: 50px;
            visibility: visible !important;
          }
          @page {
            size: A4;
            margin: 10mm;
          }
        }
        .report-card {
          max-width: 64rem;
          background-color: white;
          box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
          border-radius: 0.5rem;
          overflow: hidden;
        }
        .report-header {
          background-color: #475569;
          color: white;
          padding: 1.5rem;
        }
        .report-content {
          padding: 1.5rem;
        }
        .btn {
          display: inline-flex;
          align-items: center;
          padding: 0.5rem 1rem;
          border-radius: 0.375rem;
          font-weight: 500;
          cursor: pointer;
          transition: background-color 0.2s;
        }
        .btn-print {
          background-color: white;
          color: #2563eb;
        }
        .btn-print:hover {
          background-color: #e0e7ff;
        }
        .btn-download {
          background-color: white;
          color: #16a34a;
        }
        .btn-download:hover {
          background-color: #dcfce7;
        }
        .declarations {
          background-color: #f3f4f6;
          padding: 1rem;
          border-radius: 0.375rem;
          margin-top: 2rem;
        }
      `}</style>

			<div id="reportContainer" className="report-card max-w-4xl" ref={reportRef}>
				<div className="report-header mb-6">
					<div className="flex flex-col md:flex-row justify-between items-center">
						<div className="text-center md:text-left mb-4 md:mb-0">
							<h1 className="text-2xl font-bold">College of Engineering Trivandrum</h1>
							<h2 className="text-xl mt-2">{studentData?.name}</h2>
						</div>
						<div className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-2 no-print">
							<button onClick={handlePrint} className="btn btn-print  md:text-md text-sm md:px-4 bg-blue-500 text-white px-2 py-2 rounded">
								<Printer className="mr-2 inline" size={18} />
								Print
							</button>
							{/* <button onClick={handleDownload} className="btn btn-download md:text-md text-sm bg-green-500 text-white px-4 py-2 rounded">
								<Download className="mr-2 inline" size={18} />
								Download PDF
							</button> */}
						</div>
					</div>
				</div>
				<div className="report-content" ref={contentRef}>
					<div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
						{renderField("Student Name:", studentData?.name)}
						{renderField("Year of Admission:", studentData?.year_of_admission)}
						{renderField("Gender:", studentData?.gender)}
						{renderField("Mobile No:", studentData?.mobile_no)}
						{renderField("Admission Number:", studentData?.admission_no)}
						{renderField("Email:", studentData?.email)}
						{renderField("Department:", studentData?.department)}
						{renderField("Batch:", studentData?.batch)}
						{renderField("Programme:", studentData?.programme)}
						{renderField("Admission Type:", studentData?.type_of_admission)}
						{renderField("Year:", studentData?.current_year)}
						{renderField("Admission Category:", studentData?.admission_category)}
						{renderField("Scholarship Category:", studentData?.scholarship_category)}
						{renderField("Annual Family Income:", studentData?.afi)}
						{renderField("District:", studentData?.district)}
						{renderField("Pincode:", studentData?.pincode)}
						{renderField("Post Office:", studentData?.post_office)}
						{renderField("Distance (In Km):", studentData?.distance)}
					</div>

					<div className="declarations mt-6">
						<h3 className="text-lg font-semibold mb-4">Declarations:</h3>
						{+studentData?.distance < 20 && renderDisclaimer("I shall submit a written permission from the Principal to take admission even if I am coming from within 20 kilometres from CET. I know that failing to submit this permission letter from the Principal which is issued before the last date of applying for hostel admission shall lead to cancellation of my application.")}
						{renderDisclaimer("I will be downloading the PDF showing my application and I will produce a copy of the same, failing which can lead to cancellation of application at any point of time.")}
						{renderDisclaimer("I will produce an annual family income certificate from a competent revenue authority showing the income shown above, failing which can lead to cancellation of application.")}
						{renderDisclaimer("I will produce a valid certificate showing my address proof with the post office shown above, failing which or failing to prove that the distance claimed above is correct, can lead to cancellation of application.")}
						{((studentData?.admission_category === 'SC' || studentData?.admission_category === 'ST') || ((studentData?.admission_category === 'BPL' || studentData?.admission_category === "Ph" || studentData?.admission_category === "Other") && (studentData?.scholarship_category === 'SC' || studentData?.scholarship_category === "ST"))) &&
							renderDisclaimer("I will produce a valid caste certificate showing my caste as shown above, failing which can lead to cancellation of application.")}
						{studentData?.scholarship_category === 'OBC(H)' &&
							renderDisclaimer("I will produce a valid caste certificate showing my caste as shown above and an annual family income certificate showing that my annual family income is less than 6 lakhs, failing which can lead to cancellation of application.")}
						{studentData?.scholarship_category === 'Fisherman' &&
							renderDisclaimer("I will produce a certificate showing that my family belongs to fisherman category, failing which can lead to cancellation of application.")}
						{studentData?.admission_category === 'Ph' &&
							renderDisclaimer("I will produce a valid Disability certificate, failing which can lead to cancellation of application.")}
						{studentData?.admission_category === 'BPL' &&
							renderDisclaimer("I will produce a Ration Card showing that my family belongs to the BPL category, failing which can lead to cancellation of application.")}
						{studentData?.admission_category === 'GOIN' &&
							renderDisclaimer("I will produce a certificate showing that I am a candidate nominated by the Government of India, failing which can lead to cancellation of application.")}
					</div>
				</div>

				<div className='signature-print flex flex-col items-end justify-end m-4 w-auto gap-3'>
					<p className='text-sm'>S/d <br />{studentData?.name} </p>
				</div>
			</div>
		</>
	);
};

export default ReportTemplate;