import { useState, useEffect, useContext } from "react";
import { motion } from "framer-motion";
import { UserContext } from "../Contexts/UserContext";
import axios from "axios";
import { baseUrl } from "../baseUrl";
import AlertDialog from "./AlertDialog";
import ConfirmDialog from "./ConfirmDialog";
function GetMessSpecialEventsToBuy() {
  const [status, set_status] = useState(0);
  const [mes_special_events, set_mess_special_events] = useState([]);
  const { setLoading, user } = useContext(UserContext);
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [modalHeading, setModalHeading] = useState("");
  const [modaltext, setModalText] = useState("");
  const [selected_event_id, set_selected_event_id] = useState("");
  const [update_state, set_update_state] = useState(false);
  const [quantities, setQuantities] = useState({});

  const mp = {
    1: "BreakFast",
    2: "Lunch",
    3: "Tea",
    4: "Dinner",
  };

  const parseDateDDMMYYYY = (inputdate) => {
    const date = new Date(inputdate);
    let month = (date.getMonth() + 1).toString();
    let day = date.getDate().toString();
    let year = date.getFullYear();
    if (month.length < 2) {
      month = "0" + month;
    }
    if (day.length < 2) {
      day = "0" + day;
    }
    return day + "-" + month + "-" + year;
  };
  const updateQuantity = (productId, newQuantity) => {
    setQuantities((prevQuantities) => ({
      ...prevQuantities,
      [productId]: parseInt(newQuantity),
    }));
  };
  useEffect(() => {
    setLoading(true);
    axios
      .get(
        `${baseUrl}/inmate/get-inmates-special-mess-events?user_id=${
          user.user_id
        }&&status=${1}&&purchased=${status}&&hostel=${user.hostel}`
      )
      .then((res) => {
        set_mess_special_events(res.data.data);
      })
      .catch((err) => {
        console.log("error occured ", err.message);
      })
      .finally(() => {
        set_selected_event_id("");
        setLoading(false);
        set_update_state(false);
      });
  }, [status, update_state]);

  const cancel_purchase_confirmation=(event_id, event_date, book_before_date)=>{
    setModalHeading("Are You Sure ?")
    setModalText("You are about to cancel your booking for the event on the date "+parseDateDDMMYYYY(event_date)+" . Are You Sure You want to continue ?")
    setOpen1(true)
    set_selected_event_id(event_id)
  }

  const cancel_purchase=async()=>{
    setLoading(true);
    const user_id=user.user_id
    const event_id=selected_event_id;
    axios.post(`${baseUrl}/inmate/cancel-booking`, { user_id, event_id }).then((res)=>{
        setModalHeading("Request complete ")
        setModalText("You have successfully cancelled the booking for the event")
        setOpen(true)

    }).catch((err)=>{
        setModalHeading("Request Failed")
        setModalText(err.response.data.msg)
        setOpen(true)
    }).finally(()=>{
        set_update_state(true)
        setLoading(false)
    })
  }
  const purchase_event_confirm = (event_id, event_date, book_before_date,time,item,price) => {
    const quantity=quantities[selected_event_id]  === undefined? 1:quantities[selected_event_id]
    setModalHeading("Are you Sure ?");
    setModalText(
    `You are about to book ${item} along with ${mp[time]} on ${parseDateDDMMYYYY(event_date)}. You will be able to change your purchase before  ${parseDateDDMMYYYY(book_before_date)} \n . Quantity : ${quantity} TOTAL COST : ${quantities[event_id]*price}  Do you want to confirm ?`
    );
    setOpen1(true);
    set_selected_event_id(event_id);
  };

  const purchase_special_event = async () => {
    const user_id = user.user_id;
    const event_id = selected_event_id;

    const quantity=quantities[selected_event_id]  === undefined? 1:quantities[selected_event_id]
    setLoading(true);
    axios
      .post(`${baseUrl}/inmate/book-special-events`, { user_id, event_id,quantity })
      .then((res) => {
        setModalHeading("Purchase Confirmation");
        setModalText(
          "Your purchase was successfull . Thank You ."
        );
        setOpen(true);
      })
      .catch((err) => {
        setModalHeading("Request Failed");
        setModalText(
          "Oops something went wrong counldnt complete your request . Please try again after sometime"
        );
        setOpen(true);
      })
      .finally(() => {
        set_update_state(true);
        setLoading(false);
      });
  };

  return (
    <>
      <div className="w-11/12 ">
        <div className="flex items-center justify-between w-full">
          <select
            onChange={(e) => {
              set_status(e.target.value);
            }}
            className="w-1/2 p-3 ring-slate-200 ring-2 rounded-xl outline-none"
          >
            <option value={0}>To Buy</option>
            <option value={1}>Purchased</option>
          </select>
        </div>
        {mes_special_events.length < 1 && (
          <div className=" m-4 w-full h-96 flex justify-center items-center">
            <h2 className="font-bold">No Events Found</h2>
          </div>
        )}
        {mes_special_events.length > 0 &&
          mes_special_events.map((event, index) => {
            return (
              <motion.div
                key={index}
                whileHover={{ scale: 1.02 }}
                className="md:flex sm:p-2 items-center justify-between w-11/12 bg-gray-100 py-3 cursor-pointer mb-3 rounded-md p-2 mt-5 m-2"
              >
                <p className="flex">
                  <p className="font-bold">Item</p> :{event.item}
                </p>
                {/* <p>{index}</p> */}
                <p className="flex">
                  <p className="font-bold">Event Date</p> :
                  {parseDateDDMMYYYY(event.event_date)}
                </p>
                <p className="flex">
                  <p className="font-bold">Time</p> :{mp[event.served_time]}
                </p>
                <p className="flex">
                  <p className="font-bold">Price</p> :{event.event_price}
                </p>
                {status==0 && <p className="flex">
                  <button onClick={()=>{
                    if(!quantities[event.id] || quantities[event.id]==1){
                      alert("cannot reduce to 0");
                    }else
                    updateQuantity(event.id,quantities[event.id]-1);
                  }} className="flex rounded-lg text-xl items-center justify-center m-2 w-8 bg-white font-bold"><p>-</p></button>
                  <p className="font-bold">{quantities[event.id] || 1}</p>
                  <button onClick={()=>{
                    updateQuantity(event.id,(quantities[event.id] || 0) + 1);
                  }} className="flex rounded-lg text-xl items-center justify-center m-2 w-8 bg-white font-bold"><p>+</p></button> 
                </p>}
                {
                    status==0?<button
                  onClick={() => {
                    purchase_event_confirm(
                      event.id,
                      event.event_date,
                      event.book_before_date,
                      event.served_time,
                      event.item,
                      event.event_price
                    );
                  }}
                  className="p-2 text-black bg-white rounded-md"
                >
                  purchase
                </button>:<button
                  onClick={() => {
                    cancel_purchase_confirmation(
                      event.id,
                      event.event_date,
                      event.book_before_date
                    );
                  }}
                  className="p-2 text-black bg-white rounded-md"
                >
                  cancel
                </button>
                }
           
              </motion.div>
            );
          })}
        <AlertDialog
          open={open}
          setOpen={setOpen}
          modalHeading={modalHeading}
          modalText={modaltext}
        />
        <ConfirmDialog
          open={open1}
          setOpen={setOpen1}
          modalHeading={modalHeading}
          modalText={modaltext}
          confirmFunction={status==0?purchase_special_event:cancel_purchase}
        />
      </div>
    </>
  );
}

export default GetMessSpecialEventsToBuy;
