
import axios from "axios";
import { useState, useContext, useEffect } from "react";
import ConfirmDialog from "../../../components/ConfirmDialog";
import AlertDialog from "../../../components/AlertDialog";
import { baseUrl } from "../../../baseUrl";
import { UserContext } from "../../../Contexts/UserContext";
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import DateConverter from "../../../Utils/DateConverter";
import dayjs from 'dayjs'
import { Table, Input, Select } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

function SuspendInmateDues({ setRes }) {

  const { setLoading } = useContext(UserContext)
  const [file, setFile] = useState(null)
  const [fromdate, setFromDate] = useState()
  const [todate, setTodate] = useState()

  const [modalText, setModalText] = useState("");
  const [modalHeading, setModalHeading] = useState("");
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);


  const [dueStatus, setDueStatus] = useState("due")

  // const downloadExcel = async () => {
  //   const wb = XLSX.utils.book_new();
  //   const columns = ["Sl.No", "Hostel Admission Number", "Name"]
  //   const ws = XLSX.utils.aoa_to_sheet([columns]);
  //   XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  //   const wbBlob = new Blob([XLSX.write(wb, { type: 'array', bookType: 'xlsx' })], { type: 'application/octet-stream' });

  //   FileSaver.saveAs(wbBlob, `Mess Suspension List Template.xlsx`)

  // }


  const downloadExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(dueList);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Revoked Inmates");
    XLSX.writeFile(workbook, "revoked_list.xlsx");
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    // if (fromdate > todate) {
    //   setModalHeading("Invalid Date")
    //   setModalText("Please select a valid Date Range and Try Again .")
    //   setOpen1(true)
    //   return;
    // }
    setModalHeading("Are you sure ");
    setModalText("The following Inmates Will be Suspended from Mess From " + DateConverter.parseDateDDMMYYYY(fromdate) + " To " + dayjs(fromdate).add(4, 'year').format('DD-MM-YYYY') + " . Once Done this changes cannot be reverted . Do you want to continue ?")
    setOpen2(true);
  }
  const handleSubmitRevoke = (e) => {
    e.preventDefault();
    setModalHeading("Are you sure ");
    setModalText("The following suspension Will be revoked and they will be Mess in from " + dayjs().add(1, 'day').format("DD-MM-YYYY") + " Once Done this changes cannot be reverted . Do you want to continue ?")
    setOpen3(true);
  }

  const uploadAdditionalExpense = async () => {
    setLoading(true);
    // if (!file) {
    //   setModalHeading("No file found")
    //   setModalText("Please select a file and try again .")
    //   setOpen1(true)
    //   return;
    // }

    // const formData = new FormData();
    // formData.append('fromdate', fromdate)
    // formData.append('todate', todate)
    // formData.append('excelFile', file);

    axios.post(`${baseUrl}/warden/suspend-inmate-due-mess`, { inmate_list: dueList, fromdate: fromdate, todate: todate, month: month })
      .then(response => {
        // setDueList(response.data.data)
        setDueList(dueList.map(d => {
          return {
            ...d, status: response.data.status[d['hostel_admission_no']]
          }
        }))
        setModalHeading("File Uploaded")
        setModalText("Mess Suspension List Added succesfully")
        setOpen1(true)
      })
      .catch(error => {
        console.log('error', error)
        setModalHeading("Something went wrong")
        setModalText("please try again later")
        setOpen1(true)
      }).finally(() => {
        setLoading(false)
      });
  }
  const [dueList, setDueList] = useState([])
  const [hostel, setHostel] = useState("MH");
  const [month, setMonth] = useState(dayjs().format('YYYY-MM'))
  useEffect(() => {
    setLoading(true);
    axios.get(`${baseUrl}/warden/get-inmate-dues?hostel=${hostel}&&month=${month}&&due_status=${dueStatus}`).then((res) => {
      setDueList(res.data.data)
      // setDueList(res.data.data.map(d => {
      //   return { ...d, status: 'Not processed' }
      // }))
    }).catch((err) => {
      setDueList([])
      alert("No Data Found")
    }).finally(() => {
      setLoading(false)
    })
  }, [hostel, month, dueStatus])



  const handleRevoke = () => {
    axios.post(`${baseUrl}/warden/revoke-inmate-due-mess`, { inmate_list: dueList, fromdate: fromdate, todate: todate, month: month })
      .then(response => {
        // setDueList(response.data.data)
        // // setDueList(dueList.map(d => {
        // //   return { ...d, status: response.data.status[d['College Admission Number']] || 'Not processed' }
        // // }))
        setDueList(dueList.map(d => {
          return {
            ...d, status: response.data.status[d['hostel_admission_no']]
          }
        }))
        setModalHeading("Request Completed")
        setModalText("Following suspensions are revoked succesfully")
        setOpen1(true)
      })
      .catch(error => {
        console.log('error', error)
        setModalHeading("Something went wrong")
        setModalText("please try again later")
        setOpen1(true)
      }).finally(() => {
        setLoading(false)
      });

  }



  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');


  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <button
          type="button"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </button>
        <button onClick={() => handleReset(clearFilters)} style={{ width: 90 }}>
          Reset
        </button>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <span style={{ fontWeight: 'bold' }}>{text}</span>
      ) : (
        text
      ),
  });


  const test = () => {

  }
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const handleMonthChange = (month) => {
    setModalHeading("Warning !!")
    setModalText(`No inmates are suspended based on ${dayjs(month).add(1, 'month').format('MM/YYYY')} bill or a later bill.`)
    setMonth(month)
    setOpen4(true)

  }
  const columns = [
    {
      title: 'Index',
      key: 'index',
      render: (text, record, index) => index + 1,
    },
    {
      title: 'Admission No',
      dataIndex: 'admission_no',
      key: 'admission_no',
      ...getColumnSearchProps('admission_no'),

    },
    {
      title: 'College Admission No',
      dataIndex: 'hostel_admission_no',
      key: 'hostel_admission_no',
      ...getColumnSearchProps('hostel_admission_no'),

    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      ...getColumnSearchProps('name'),

    },
    {
      title: 'Department',
      dataIndex: 'department',
      key: 'department',
      ...getColumnSearchProps('department'),


    },
    {
      title: 'Programme',
      dataIndex: 'programme',
      key: 'programme',
      ...getColumnSearchProps('programme'),

    },
    {
      title: 'Year of Study',
      dataIndex: 'current_year',
      key: 'current_year',
      ...getColumnSearchProps('current_year'),

    },
    {
      title: 'Dues',
      dataIndex: 'sum',
      key: 'sum',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      ...getColumnSearchProps('status'),

    },

  ];

  return (
    <div className="mb-3 w-11/12">
      <div className="w-full flex justify-end mt-5 gap-3">
        <button

          type="button"
          onClick={() => {
            downloadExcel()
          }}
          className="p-3 bg-green-700 text-white rounded-xl"
        >
          Download Excel
        </button>

      </div>
      <h2 className="font-semibold text-lg mb-2">
        View Inmates with Dues
      </h2>




      {dueStatus == 'due' ?
        <form onSubmit={handleSubmit}>
          <div className="mt-4  grid grid-cols-2 w-100 gap-4 mb-3">
            <label className="m-2 font-bold">Select Hostel :</label>
            <select
              onChange={(e) => {
                setHostel(e.target.value)
              }}
              className="w-11/12 py-2 px-3 rounded-xl ring-2 ring-slate-300 focus:outline-none"
            >
              <option value={"MH"}>Mens Hostel</option>
              <option value={"LH"}>Ladies Hostel</option>

            </select>
          </div>
          <div className="mt-4  grid grid-cols-2 w-100 gap-4 mb-3">
            <label className="m-2 font-bold">Select Month :</label>
            <input
              type="month"
              value={month}
              onChange={(e) => {
                setMonth(e.target.value)
              }}
              className="w-11/12 py-2 px-3 rounded-xl ring-2 ring-slate-300 focus:outline-none"
            />

          </div>
          <div className=" mt-4  grid grid-cols-2 w-100 gap-4 mb-3">
            <label className="font-bold" htmlFor="">Due</label>{" "}
            <select
              onChange={(e) => {
                setDueStatus(e.target.value)
              }}
              className="w-11/12 py-2 px-3 rounded-xl ring-2 ring-slate-300 focus:outline-none"
            >
              <option value={"due"}>Suspend Inmates Having Dues greater than 0</option>
              <option value={"nodue"}>Revoke Suspension of Inmates with due less than or equal to 0</option>

            </select>

          </div>
          <div className=" mt-4  grid grid-cols-2 w-100 gap-4 mb-3">
            <label className="font-bold" htmlFor="">From Date:</label>{" "}
            <input
              required
              type="date"
              // min={DateConverter.getToday()}
              onChange={(e) => {
                setFromDate(e.target.value)
              }}
              className="w-11/12 py-2 px-3 rounded-xl ring-2 ring-slate-300 focus:outline-none"
            />

          </div>
          {/* <div className=" mt-4  grid grid-cols-2 w-100 gap-4 mb-3">
            <label className="font-bold" htmlFor="">To Date:</label>{" "}
            <input
              required
              type="date"
              min={DateConverter.getToday()}
              onChange={(e) => {
                setTodate(e.target.value)
              }}
              className="w-11/12 py-2 px-3 rounded-xl ring-2 ring-slate-300 focus:outline-none"
            />
          </div> */}

          <div className="w-full flex items-end justify-end mt-5">
            <button
              type="submit"
              className="ml-auto p-3 bg-stone-800 text-white rounded-xl"
            >
              Suspend Inmates
            </button>

          </div>
        </form>
        :
        <form onSubmit={handleSubmitRevoke}>

          <div className="mt-4  grid grid-cols-2 w-100 gap-4 mb-3">
            <label className="m-2 font-bold">Select Hostel :</label>
            <select
              onChange={(e) => {
                setHostel(e.target.value)
              }}
              className="w-11/12 py-2 px-3 rounded-xl ring-2 ring-slate-300 focus:outline-none"
            >
              <option value={"MH"}>Mens Hostel</option>
              <option value={"LH"}>Ladies Hostel</option>

            </select>
          </div>
          <div className="mt-4  grid grid-cols-2 w-100 gap-4 mb-3">
            <label className="m-2 font-bold">Select Month :</label>
            <input
              type="month"
              value={month}

              onChange={(e) => handleMonthChange(e.target.value)}
              className="w-11/12 py-2 px-3 rounded-xl ring-2 ring-slate-300 focus:outline-none"
            />

          </div>
          <div className=" mt-4  grid grid-cols-2 w-100 gap-4 mb-3">
            <label className="font-bold" htmlFor="">Due</label>{" "}
            <select
              onChange={(e) => {
                setDueStatus(e.target.value)
              }}
              className="w-11/12 py-2 px-3 rounded-xl ring-2 ring-slate-300 focus:outline-none"
            >
              <option value={"due"}>Suspend Inmates Having Dues greater than 0</option>
              <option value={"nodue"}>Revoke Suspension of Inmates with due less than or equal to 0</option>

            </select>

          </div>
          <div className="w-full flex items-end justify-end mt-5">

            <button
              type="submit"
              className="p-3 bg-red-500 text-white rounded-xl"
            >
              Revoke Suspension
            </button>
          </div>

        </form>
      }


      <div className="container p-4">
        <Table columns={columns} dataSource={dueList} pagination={{ defaultPageSize: 50 }} rowKey={(record) => record.hostel_admission_no} />
      </div>
      <div className="bg-yellow-200 text-yellow-800 p-4 mt-4 rounded-md">
        <p className="font-semibold mb-2">Before proceeding:</p>
        <ul className="list-disc ml-6">
          <li>Make sure the file selected is correct </li>
          <li>No changes will be possible once done</li>
          <li>Double-check the accuracy of file uploaded.</li>
          <li>Check the status of the entries once the process is completed.</li>
        </ul>
      </div>
      <AlertDialog
        open={open1}
        setOpen={setOpen1}
        modalHeading={modalHeading}
        modalText={modalText}
      />
      <ConfirmDialog
        open={open2}
        setOpen={setOpen2}
        modalHeading={modalHeading}
        modalText={modalText}
        confirmFunction={uploadAdditionalExpense}
      />
      <ConfirmDialog
        open={open3}
        setOpen={setOpen3}
        modalHeading={modalHeading}
        modalText={modalText}
        confirmFunction={handleRevoke}
      />
      <ConfirmDialog
        open={open4}
        setOpen={setOpen4}
        modalHeading={modalHeading}
        modalText={modalText}
        confirmFunction={test}

      />



    </div >
  );

}
export default SuspendInmateDues;
