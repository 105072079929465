import { useState, useEffect } from 'react'
import AddMessExemptionForm from './AddMessExemptionForm';
import AddMessExemptionList from './AddMessExemptionList';
function AddMessExemptionPage({ date }) {

  const [res, setRes] = useState([])

  return (
    <div className='w-11/12'>
      <AddMessExemptionForm setRes={setRes} date = {date} />
      <hr />
      {res.length > 0 && < AddMessExemptionList res={res} />}
    </div>

  )
}

export default AddMessExemptionPage