import { useState, useEffect, useContext } from "react";
import axios from "axios";
import { baseUrl } from "../baseUrl";
import { UserContext } from "../Contexts/UserContext";
import * as FileSaver from "file-saver";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { Link } from "react-router-dom";
function GetMessOut_Month_History_Of_inmate({

}) {
  const { user } = useContext(UserContext);
  var date = new Date();
  var dateFormat =
    date.getFullYear() +
    "-" +
    (date.getMonth() + 1 < 10
      ? "0" + (date.getMonth() + 1)
      : date.getMonth() + 1) +
    "-";
  const [selected_month, set_selected_month] = useState(dateFormat);
  const [messreqs, setMessreqs] = useState([]);
  const [hostel_admission_no, set_hostel_admission_no] = useState("");
  const [inmate_name, set_inmate_name] = useState("");
  const [inmate_admission_no, set_inmate_admission_no] = useState("");
  const { setLoading } = useContext(UserContext);

  const parseDateDDMMYYYY = (inputdate) => {
    const date = new Date(inputdate);
    let month = (date.getMonth() + 1).toString();
    let day = date.getDate().toString();
    let year = date.getFullYear();
    if (month.length < 2) {
      month = "0" + month;
    }
    if (day.length < 2) {
      day = "0" + day;
    }
    return day + "-" + month + "-" + year;
  };

  useEffect(() => {
    if (hostel_admission_no.length > 6) {
      setLoading(true);
      axios
        .get(
          `${baseUrl}/inmate/get-inmate-details?hostel_admission_no=${hostel_admission_no.toUpperCase()}`
        )
        .then((res) => {
          set_inmate_name(res.data.data.name);
          set_inmate_admission_no(res.data.data.user_id);
          if (selected_month.length > 2) {
            axios
              .get(
                `${baseUrl}/inmate/get-messout-history-of-inmate?month=${selected_month}&&hostel_admission_no=${hostel_admission_no.toUpperCase()}`
              )
              .then((res) => {
                setMessreqs(res.data.data);
              })
              .catch((err) => {
                console.log("error occured ", err);
              });
          }
        })
        .catch((err) => {
          console.log("error occured ", err);
        })
        .finally(() => {
          setLoading(false);
        });
    }

  }, [hostel_admission_no, selected_month]);

  const downloadPDF = () => {
    const pdf = new jsPDF();
    if (messreqs.length < 1) return alert("nothing to download");

    const mp = {
      MH: "MENS HOSTEL",
      LH: "LADIES HOSTEL",
    };

    const title = `CET  Mess Out List ${parseDateDDMMYYYY(
      selected_month
    )}`;
    pdf.text(title, 14, 15);

    const columns = ["hostel_admission_no", "name", "block_name", "room_no"];

    const header = ["Admission No", "Name", "Block", "Hostel Room No"];

    // columns.unshift("index");
    header.unshift("Index");
    const rows = messreqs.map((row, index) => [
      index + 1,
      ...columns.map((column) => row[column]),
    ]);
    pdf.autoTable({
      head: [header],
      body: rows,
      startY: 25,
    });

    pdf
      .save(
        `CET  Mess Out List .pdf`,
        { returnPromise: true, type: "blob" }
      )
      .then((blob) => {
        FileSaver.saveAs(
          blob,
          `CET Mess Out List .pdf`
        );
      });
  };
  return (
    <>
      <div className=" w-5/6">
        <h2 className="text-xl font-bold text-black font-semibold text-lg mt-5 mb-3 m-2">
          Inmate Mess Out History
        </h2>
        <div className="m-2 p-2  ">
          <form>
            <div className="w-full grid grid-cols-2  gap-4 mb-3 text-lg ">
              <label htmlFor="">Select A Month:</label>{" "}
              <input
                defaultValue={dateFormat}
                onChange={(e) => {
                  set_selected_month(e.target.value);
                }}
                required
                type="month"
                value={selected_month}
                className="w-3/4 py-2 px-3 rounded-xl ring-2 ring-slate-300 focus:outline-none"
              />
            </div>
            <div className="w-full grid grid-cols-2  gap-4 mb-3 text-lg ">
              <label htmlFor="">Hostel Admission No:</label>{" "}
              <input
                required
                type="text"
                onChange={(e) => {
                  set_hostel_admission_no(e.target.value);
                  set_inmate_name("");
                  set_inmate_admission_no("");
                  setMessreqs([]);
                }}
                value={hostel_admission_no}
                className="w-3/4 py-2 px-3 rounded-xl ring-2 ring-slate-300 focus:outline-none"
              />
            </div>
            <div className="w-full grid grid-cols-2  gap-4 mb-3 text-lg ">
              <label htmlFor="">Inmate Admission No:</label>{" "}
              <input
                required
                readOnly
                type="text"
                value={inmate_admission_no}
                className="w-3/4 py-2 px-3 rounded-xl ring-2 ring-slate-300 focus:outline-none"
              />
            </div>
            <div className="w-full grid grid-cols-2  gap-4 mb-3 text-lg ">
              <label htmlFor="">Inmate Name:</label>{" "}
              <input
                required
                readOnly
                type="text"
                value={inmate_name}
                className="w-3/4 py-2 px-3 rounded-xl ring-2 ring-slate-300 focus:outline-none"
              />
            </div>
            <div className="w-full flex items-end justify-end mt-10">
              <button
                type="submit"
                className=" w-2/6 ml-auto p-3 bg-stone-800 text-white rounded-xl"
              >
                Search
              </button>
            </div>
          </form>
        </div>
      </div>
      <div className="w-11/12">
        <div className="flex items-center justify-between w-11/12 py-4">
          <p className="font-semibold">No Of Mess Outs : {messreqs.length} </p>
        </div>
        <div className="w-11/12 overflow-x-scroll">
          <table className="w-full relative table-auto">
            <tr className="rounded-xl p-3 bg-primary text-center">
              <th className="p-3">Sl.No</th>
              <th className="p-3">From Date</th>
              <th className="p-3">Mess In Date</th>
              <th className="p-3">Number of Days</th>
              <th className="p-3">Edit</th>
            </tr>
            {messreqs.map((user, index) => {
              var fdate = new Date(user.fromdate);
              var tdate = new Date(user.todate);
              return (
                <tr
                  key={index}
                  className={
                    "border-b text-center border-slate-200 border-solid hover:bg-gray-300"
                  }
                >
                  <td className="p-3">{index + 1}</td>
                  <td className="p-3">
                    {fdate.getDate() +
                      "/" +
                      parseInt(fdate.getMonth() + 1) +
                      "/" +
                      fdate.getFullYear()}
                  </td>
                  <td className="p-3">
                    {tdate.getDate() +
                      "/" +
                      parseInt(tdate.getMonth() + 1) +
                      "/" +
                      tdate.getFullYear()}
                  </td>
                  <td className="p-3">
                    {(tdate.getTime() - fdate.getTime()) / (1000 * 3600 * 24)}
                  </td>
                  <td>
                    <Link
                    to={`/WD/edit-messout-page/${user.messout_id}`}
                      className={`bg-green-400 text-white font-bold py-2 px-2 rounded`}

                    >
                      Edit MessOut
                    </Link>
                  </td>

                </tr>
              );
            })}
          </table>
        </div>
      </div>
    </>
  );
}

export default GetMessOut_Month_History_Of_inmate;
