import React, { useEffect, useState } from 'react'
import axios from "axios";
import dayjs from 'dayjs'
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { baseUrl } from '../../../baseUrl';
import AlertDialog from '../../../components/AlertDialog';
import UpgradeIcon from '@mui/icons-material/Upgrade';
import { Table, Input, Button, message, Form, Modal, Popconfirm } from 'antd';
import { SearchOutlined, EditOutlined, SaveOutlined, CloseOutlined, PlusOutlined, DeleteOutlined } from '@ant-design/icons';
function ViewCurrentInvitationsClerk({ setInviteStatus }) {
    const [details, setDetails] = useState([])
    const [editMode, setEditMode] = useState({});



    const [open1, setOpen1] = useState("");
    const [open2, setOpen2] = useState("");
    const [modalText, setModalText] = useState("");
    const [modalHeading, setModalHeading] = useState("");

    useEffect(() => {
        axios.get(`${baseUrl}/warden/get-current-invites`).then(res => {
            console.log('res.data', res.data)
            if (res.data.length === 0) setInviteStatus(false)
            setDetails(res.data)
        })
    }, [open1])

    const toggleEditMode = (id) => {
        details.filter((item) => {
            if (item.invite_id === id) {
                if (item.allotment_published == true) {
                    setModalHeading("Invalid Request ")
                    setModalText(`Clear Allotment  before proceeding`);
                    setOpen1(true)
                } else if (item.final_rank_list == true) {
                    setModalHeading("Invalid Request ")
                    setModalText(`Clear Final Ranklist before proceeding`);
                    setOpen1(true)
                } else if (item.provisional_rank_list == true) {
                    setModalHeading("Invalid Request ")
                    setModalText(`Clear Provisional Ranklist before proceeding`);
                    setOpen1(true)
                }
                else {
                    setEditMode((prevEditMode) => ({
                        ...prevEditMode,
                        [id]: !prevEditMode[id],
                    }));
                }
            }

        })
    };

    const [admiConfigs, setAdminConfigs] = useState()
    useEffect(() => {
        axios.get(`${baseUrl}/warden/get-hostel-admin-configs`).then(res => {
            console.log('res.data', res.data)
            setAdminConfigs(res.data)
        })
    }, [])

    const handleDateChange = (id, field, value) => {

        setDetails((prevData) =>
            prevData.map((item) => {
                if (item.invite_id === id) {
                    const data = { ...item, [field]: value }
                    if (dayjs(data.close_in).diff(dayjs(data.open_in), 'day') < admiConfigs?.gap_invite_close_ + admiConfigs?.gap_close_provisional) {
                        setModalHeading("Invalid Date")
                        setModalText(`Minimun ${admiConfigs?.gap_invite_close + admiConfigs?.gap_close_provisional} days gap is requred between open_in and close in`);
                        setOpen1(true)


                    } else if (dayjs(data.concern_till).diff(dayjs(data.close_in), 'day') < admiConfigs?.gap_close_provisional + admiConfigs?.gap_provisional_concern) {
                        setModalHeading("Error ")
                        setModalText(`Minimun gap of ${admiConfigs?.gap_close_provisional + admiConfigs?.gap_provisional_concern} days is requred between close date and concern date  `);
                        setOpen1(true)


                    } else {
                        axios.post(`${baseUrl}/warden/update-invite-application`, { ...item, [field]: value }).then(res => console.log(res))
                        return { ...item, [field]: dayjs(value).format("YYYY-MM-DD") }


                    }

                }
                return item
            }
            )
        );

        console.log("item", details)

    };
    const handleDelete = (invite_id) => {
        axios.delete(`${baseUrl}/warden/delete-invite`, { params: { invite_id: invite_id } }).then(res => {
            setModalHeading("Request Completed ")
            setModalText("Invite Deleted Successfully.");
            setOpen1(true)
        })
    }
    const handleUpdateEnableAdmission = (invite_id) => {
        axios.put(`${baseUrl}/warden/update-enable-admission`, { invite_id: invite_id }).then(res => {
            setModalHeading("Request Completed ")
            setModalText("Enable Hostel Admission is updated Successfully.");
            setOpen1(true)
        })
    }

    return (
        <div className='w-11/12 overflow-y-scroll no-scrollbar'>

            <table className='w-full relative table-auto'>
                <tr className='rounded-xl p-3 bg-primary text-center'>
                    <th className='p-3'>Sl.No</th>
                    <th className='p-3'>Hostel </th>
                    <th className='p-3'>Year</th>
                    <th className='p-3'>Programmewe</th>
                    <th className='p-3'>Open In</th>
                    <th className='p-3'>Close In</th>
                    <th className='p-3'>Provisonal Ranklist</th>
                    <th className='p-3'>Submitting Concern Till</th>
                    <th className='p-3'>Final Rank List & Allotment</th>
                    <th className='p-3'>Update</th>
                    <th className='p-3'>Enable newly created users </th>
                    <th className='p-3'>Delete</th>

                    {/* <th className='p-3'>Status</th> */}
                    {/* <th className='p-3'>Download</th> */}
                </tr>
                {details && details.map((data, index) => {
                    return (
                        <tr
                            className='border-b text-center border-slate-200 border-solid hover:bg-gray-300'
                            key={index}>
                            <td className='p-3'>{index + 1}</td>
                            <td className='p-3'>{data.hostel}</td>
                            <td className='p-3 capitalize'>{data.current_year}</td>
                            <td className='p-3 capitalize'>{data.programme}</td>
                            <td className='p-3'>
                                {editMode[data.invite_id] ? (
                                    <input
                                        type="date"
                                        defaultValue={dayjs(data.open_in).format('YYYY-MM-DD')}
                                        min={dayjs().format('YYYY-MM-DD')}
                                        // min={da}
                                        onChange={(e) =>
                                            handleDateChange(data.invite_id, 'open_in', e.target.value)
                                        }
                                        className='w-12/12 rounded-xl ring-2 ring-slate-300 focus:outline-none'

                                    />
                                ) : (
                                    dayjs(data.open_in).format('DD/MM/YYYY')
                                )}
                            </td>
                            <td className='p-3'>
                                {editMode[data.invite_id] ? (
                                    <input
                                        type="date"
                                        defaultValue={dayjs(data.close_in).format('YYYY-MM-DD')}
                                        min={dayjs(data.open_in).add(admiConfigs?.gap_invite_close, 'day').format('YYYY-MM-DD')}
                                        onChange={(e) =>
                                            handleDateChange(data.invite_id, 'close_in', e.target.value)
                                        }
                                        className='w-12/12  rounded-xl ring-2 ring-slate-300 focus:outline-none'

                                    />
                                ) : (
                                    dayjs(data.close_in).format('DD/MM/YYYY')
                                )}
                            </td>
                            <td className='p-3'>
                                {
                                    dayjs(data.close_in).add(admiConfigs?.gap_close_provisional, 'day').format('DD/MM/YYYY')
                                }
                            </td>
                            <td className='p-3'>
                                {editMode[data.invite_id] ? (
                                    <input
                                        type="date"
                                        defaultValue={dayjs(data.concern_till).format('YYYY-MM-DD')}
                                        min={dayjs(data.close_in).add(admiConfigs?.gap_close_provisional + admiConfigs?.gap_provisional_concern, 'day').format('YYYY-MM-DD')}
                                        onChange={(e) => {
                                            handleDateChange(data.invite_id, 'concern_till', e.target.value)
                                        }
                                        }
                                        className='w-12/12 rounded-xl ring-2 ring-slate-300 focus:outline-none'

                                    />
                                ) : (
                                    dayjs(data.concern_till).format('DD/MM/YYYY')
                                )}
                            </td>
                            <td className='p-3'>
                                {
                                    dayjs(data.concern_till).add(admiConfigs?.gap_concern_final, 'day').format('DD/MM/YYYY')
                                }
                            </td>
                            <td className='p-3'>
                                <button onClick={() => toggleEditMode(data.invite_id)}>
                                    {editMode[data.invite_id] ? <CloseIcon /> : <EditIcon />}
                                </button>
                            </td>
                            <td className='p-3'>
                                <button onClick={() => handleUpdateEnableAdmission(data.invite_id)}>
                                    <UpgradeIcon />

                                </button>
                            </td>
                            <td className='p-3'>
                                {/* <button onClick={() => handleDelete(data.invite_id)}>
                                    <DeleteOutlineIcon />
                                </button> */}
                                <Popconfirm
                                    title="Are you sure you want to continue ? All the applications along with ranklist,allotment will be cleared."
                                    onConfirm={() => handleDelete(data.invite_id)}
                                    okText="Yes"
                                    cancelText="No"
                                    okButtonProps={{danger:true}}
                                >
                                    <Button icon={<DeleteOutlined />} type="danger">
                                        Delete
                                    </Button>
                                </Popconfirm>
                            </td>
                        </tr>
                    )
                })}
            </table>
            <AlertDialog
                open={open1}
                setOpen={setOpen1}
                modalHeading={modalHeading}
                modalText={modalText}
            />
        </div>
    )
}

export default ViewCurrentInvitationsClerk