import { motion } from "framer-motion";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import axios from "axios";
import Box from "@mui/material/Box";
import { useState, useContext, useEffect } from "react";
import { UserContext } from "../Contexts/UserContext";
import AlertDialog from "./AlertDialog";
import ConfirmDialog from "./ConfirmDialog";
import { baseUrl } from "../baseUrl";
import { DevTool } from "@hookform/devtools";

import { useForm } from "react-hook-form";
function CreateUserWarder1() {
  const {
    register,
    handleSubmit,
    control,
    getValues,
    watch,
    formState: { errors },
  } = useForm({
    // defaultValues: {
    //   hosteltype: "MH",
    // },
  });
  const watchHosteltype = watch("hosteltype", "");
  const watchProgramme = watch("programme", "");
  const [age, setAge] = useState("");
  const { setLoading } = useContext(UserContext);

  const [studentData, setStudentData] = useState(null)

  const [open1, setOpen1] = useState("");
  const [open2, setOpen2] = useState("");
  const [modalText, setModalText] = useState("");
  const [modalHeading, setModalHeading] = useState("");

  const saveData = (data) => {
    console.log(data);
    console.log('studentData', studentData)

    setStudentData({ ...studentData, ...data });
    setCurrPage(currpage + 1)
  };

  const submitData = (data) => {
    setStudentData({ ...studentData, ...data });
    console.log('Done', studentData)

    setLoading(true);
    axios
      .post(`${baseUrl}/warden/create-user`, {
        email: studentData.email,
        admission_no: studentData.admino,
        name: studentData.studentName,
        college_admission_year: studentData.admiyear,
        hostel_admission_no: studentData.hosteladmino,
        phone_no: studentData.mobileno,
        block: studentData.block,
        room_no: studentData.roomno,
        admission_category: studentData.admicategory,
        egrants_category: studentData.egrantscategory,
        department: studentData.department,
        programme: studentData.programme,
        parent_name: studentData.nameparent,
        parent_mobile_number: studentData.mobileparent,
        designation: "student",
        hostel: studentData.hosteltype,
        block_name: studentData.block,
      })
      .then((res) => {
        setModalHeading("Request Completed ");
        setModalText(" User Added Successfully .");
        setOpen1(true);
      })
      .catch((err) => {
        console.log("reg", err.response.data.msg);
        setModalHeading("Error");
        setModalText(err.response.data.msg);
        setOpen1(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }
  const [currpage, setCurrPage] = useState(1)


  return (
    <div className="flex flex-col w-11/12 items-center">
      <h2 className="font-semibold text-lg m-2">Add User</h2>
      {currpage === 1 && <div>
        <form onSubmit={handleSubmit(saveData)}>
          <div className="grid grid-cols-2 w-100 gap-4 mb-3">
            <label htmlFor="">Email:</label>{" "}
            <input
              type="text"
              name="email"
              {...register("email", {
                required: "Email is required.",
                pattern: {
                  value: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
                  message: "Email is not valid.",
                },
              })}
              className="w-12/12 py-2 px-3 rounded-xl ring-2 red-slate-300 focus:outline-none"
            />
            {errors.email && (
              <p className="text-red-600 font-semibold grid col-span-2 ">
                {errors.email.message}
              </p>
            )}
          </div>
          <div className="grid grid-cols-2 w-100 gap-4 mb-3">
            <label htmlFor="">College Admission No :</label>{" "}
            <input
              type="text"
              name="admino"
              {...register("admino", {
                required: "This field is required.",
              })}
              className="w-12/12 py-2 px-3 rounded-xl ring-2 ring-slate-300 focus:outline-none"
            />
            {errors.admino && (
              <p className="text-red-600 font-semibold grid col-span-2 ">
                {errors.admino.message}
              </p>
            )}
          </div>
          <div className="grid grid-cols-2 w-100 gap-4 mb-3">
            <label htmlFor="">College Admission Year :</label>{" "}
            <input
              type="text"
              name="admiyear"
              {...register("admiyear", {
                required: "This field is required.",
              })}
              className="w-12/12 py-2 px-3 rounded-xl ring-2 ring-slate-300 focus:outline-none"
            />
            {errors.admiyear && (
              <p className="text-red-600 font-semibold grid col-span-2 ">
                {errors.admiyear.message}
              </p>
            )}
          </div>
          <div className="grid grid-cols-2 w-100 gap-4 mb-3">
            <label htmlFor="">Hostel Admission Number :</label>{" "}
            <input
              type="text"
              name="hosteladmino"
              {...register("hosteladmino", {
                required: "This field is required.",
              })}
              className="w-12/12 py-2 px-3 rounded-xl ring-2 ring-slate-300 focus:outline-none"
            />
            {errors.hosteladmino && (
              <p className="text-red-600 font-semibold grid col-span-2 ">
                {errors.hosteladmino.message}
              </p>
            )}
          </div>
          <div className="grid grid-cols-2 w-100 gap-4 mb-3">
            <label htmlFor="">Name of the Inmate :</label>{" "}
            <input
              type="text"
              name="studentName"
              {...register("studentName", {
                required: "This field is required.",
              })}
              className="w-12/12 py-2 px-3 rounded-xl ring-2 ring-slate-300 focus:outline-none"
            />
            {errors.studentName && (
              <p className="text-red-600 font-semibold grid col-span-2 ">
                {errors.studentName.message}
              </p>
            )}
          </div>
          <div className="grid grid-cols-2 w-100 gap-4 mb-3">
            <label htmlFor="">Mobile number of the Inmate :</label>{" "}
            <input
              type="text"
              name="mobileno"
              {...register("mobileno", {
                required: "This field is required.",
              })}
              className="w-12/12 py-2 px-3 rounded-xl ring-2 ring-slate-300 focus:outline-none"
            />
            {errors.mobileno && (
              <p className="text-red-600 font-semibold grid col-span-2 ">
                {errors.mobileno.message}
              </p>
            )}
          </div>

          <div className="w-full flex items-end justify-end mt-5">
            <motion.button whileHover={{ x: 10, backgroundColor: 'green' }} className="ml-auto p-3 bg-stone-800 text-white rounded-xl" type="submit" >Proceed to Apply</motion.button>
          </div>
        </form>
      </div>}
      {currpage == 2 && <div>
        <form onSubmit={handleSubmit(saveData)}>
          <div className="grid grid-cols-2 w-100 gap-4 mb-3">
            <label htmlFor="">Hostel :</label>{" "}
            <select
              className="p-3 ring-slate-200 ring-2 rounded-xl outline-none"
              name="hosteltype"
              {...register("hosteltype", {
                required: "This field is required.",
              })}
            >
              <option value={"MH"}>Mens Hostel</option>
              <option value={"LH"}>Ladies Hostel</option>
            </select>
            {errors.hosteltype && (
              <p className="text-red-600 font-semibold grid col-span-2 ">
                {errors.hosteltype.message}
              </p>
            )}
          </div>
          {/* <div className="grid grid-cols-2 w-100 gap-4 mb-3">
          <label htmlFor="">Hostel :</label>{" "}
          <Box
            sx={{
              minWidth: 120,
              bgcolor: grey[100],
              borderRadius: 10,
              border: 0,
              outlineWidth: 5,
            }}
          >
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Age</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={age}
                label="Age"
                onChange={handleChange}
              >
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </div> */}

          {watchHosteltype == "LH" && (
            <div className="grid grid-cols-2 w-100 gap-4 mb-3">
              <label htmlFor="">Block :</label>{" "}
              <select
                className="p-3 ring-slate-200 ring-2 rounded-xl outline-none"
                name="block"
                {...register("block", {
                  required: "This field is required.",
                })}
              >
                <option value={"new block"}>New Block</option>
                <option value={"old block"}>Old Block</option>
              </select>
              {errors.block && (
                <p className="text-red-600 font-semibold grid col-span-2 ">
                  {errors.block.message}
                </p>
              )}
            </div>
          )}
          {watchHosteltype === "MH" && (
            <div className="grid grid-cols-2 w-100 gap-4 mb-3">
              <label htmlFor="">Block :</label>{" "}
              <select
                className="p-3 ring-slate-200 ring-2 rounded-xl outline-none"
                name="block"
                {...register("block", {
                  required: "This field is required.",
                })}
              >
                <option value={"A"}>A</option>
                <option value={"B"}>B</option>
                <option value={"C"}>C</option>
                <option value={"D"}>D</option>
              </select>
              {errors.block && (
                <p className="text-red-600 font-semibold grid col-span-2 ">
                  {errors.block.message}
                </p>
              )}
            </div>
          )}

          <div className="grid grid-cols-2 w-100 gap-4 mb-3">
            <label htmlFor="">Room Number :</label>{" "}
            <input
              type="text"
              name="roomno"
              {...register("roomno", {
                required: "This field is required.",
                pattern: {
                  value: /^[0-9]*$/,
                  message: "Input Must be a Number",
                },
              })}
              className="w-12/12 py-2 px-3 rounded-xl ring-2 ring-slate-300 focus:outline-none"
            />
            {errors.roomno && (
              <p className="text-red-600 font-semibold grid col-span-2 ">
                {errors.roomno.message}
              </p>
            )}
          </div>

          <div className="grid grid-cols-2 w-100 gap-4 mb-3">
            <label htmlFor="">Admission Category :</label>{" "}
            <div>
              <select
                className="p-3 ring-slate-200 ring-2 rounded-xl outline-none"
                name="admicategory"
                {...register("admicategory", {
                  required: "This field is required.",
                })}
                autoWidth
                label="Age"
              >
                <option value={"OBC"}>OBC</option>
                <option value={"OEC"}>OEC</option>
                <option value={"Other"}>Other</option>
              </select>
            </div>
            {errors.admicategory && (
              <p className="text-red-600 font-semibold grid col-span-2 ">
                {errors.admicategory.message}
              </p>
            )}
          </div>
          <div className="grid grid-cols-2 w-100 gap-4 mb-3">
            <label htmlFor="">E-grants Category :</label>{" "}
            <div>
              <select
                className="p-3 ring-slate-200 ring-2 rounded-xl outline-none"
                name="egrantscategory"
                {...register("egrantscategory", {
                  required: "This field is required.",
                })}
                autoWidth
                label="Age"
              >
                <option value={"OBC"}>OBC</option>
                <option value={"OEC"}>OEC</option>
                <option value={"Other"}>Other</option>
              </select>
            </div>
            {errors.egrantscategory && (
              <p className="text-red-600 font-semibold grid col-span-2 ">
                {errors.egrantscategory.message}
              </p>
            )}
          </div>
          <div className="grid grid-cols-2 w-100 gap-4 mb-3">
            <label htmlFor="">Department :</label>{" "}
            <div>
              <select
                className="p-3 ring-slate-200 ring-2 rounded-xl outline-none"
                name="department"
                {...register("department", {
                  required: "This field is required.",
                })}
                autoWidth
                label="Age"
              >
                <option value={"CE"}>Civil Engineering</option>
                <option value={"ME"}>Mechanical Engineering </option>
                <option value={"EC"}>
                  Electrical and Electronics Engineering{" "}
                </option>
                <option value={"EE"}>
                  Electronics and Communication Engineering{" "}
                </option>
                <option value={"CS"}> Computer Science and Engineering </option>
                <option value={"AR"}> Architecture </option>
                <option value={"CA"}> Computer Application </option>
                <option value={"SOM"}> CET SOM </option>
              </select>
            </div>
            {errors.department && (
              <p className="text-red-600 font-semibold grid col-span-2 ">
                {errors.department.message}
              </p>
            )}
          </div>

          <div className="w-full flex items-center justify-between mt-5">
            <motion.button whileHover={{ x: -10, backgroundColor: 'red' }} className="p-3 bg-stone-800 text-white rounded-xl" onClick={() => setCurrPage(currpage - 1)}>Back</motion.button>
            <motion.button whileHover={{ x: 10, backgroundColor: 'green' }} className="p-3 bg-stone-800 text-white rounded-xl" type="submit">Next</motion.button>
          </div>
        </form>
      </div>}

      {currpage == 3 && <div>
        <form onSubmit={handleSubmit(submitData)} >

          <div className="grid grid-cols-2 w-100 gap-4 mb-3">
            <label htmlFor="">Programme :</label>{" "}
            <select
              name="programme"
              {...register("programme", {
                required: "This field is required.",
              })}
              className="p-3 ring-slate-200 ring-2 rounded-xl outline-none"
            >
              <option value={"UG"}>UG</option>
              <option value={"PG"}>PG</option>
              <option value={"Phd"}>Phd</option>
            </select>
            {errors.programme && (
              <p className="text-red-600 font-semibold grid col-span-2 ">
                {errors.programme.message}
              </p>
            )}
          </div>

          {watchProgramme == "PG" && (
            <div className="grid grid-cols-2 w-100 gap-4 mb-3">
              <label htmlFor="">Specialisation :</label>{" "}
              <input
                type="text"
                name="specialisation"
                {...register("specialisation", {
                  required: "This field is required.",
                })}
                className="w-12/12 py-2 px-3 rounded-xl ring-2 ring-slate-300 focus:outline-none"
              />
              {errors.specialisation && (
                <p className="text-red-600 font-semibold grid col-span-2 ">
                  {errors.specialisation.message}
                </p>
              )}
            </div>
          )}
          {watchProgramme == "UG" && (
            <div className="grid grid-cols-2 w-100 gap-4 mb-3">
              <label htmlFor="">Admission Type :</label>{" "}
              <select
                className="p-3 ring-slate-200 ring-2 rounded-xl outline-none"
                name="hosteltype"
                {...register("admissiontype", {
                  required: "This field is required.",
                })}
              >
                <option value={"Normal"}>Normal</option>
                <option value={"LET"}>LET</option>
                <option value={"CT"}>College Transfer</option>
              </select>
              {errors.hosteltype && (
                <p className="text-red-600 font-semibold grid col-span-2 ">
                  {errors.hosteltype.message}
                </p>
              )}
            </div>
          )}
          <div className="grid grid-cols-2 w-100 gap-4 mb-3">
            <label htmlFor="">Name of the parent :</label>{" "}
            <input
              type="text"
              name="nameparent"
              {...register("nameparent", {
                required: "This field is required.",
              })}
              className="w-12/12 py-2 px-3 rounded-xl ring-2 ring-slate-300 focus:outline-none"
            />
            {errors.nameparent && (
              <p className="text-red-600 font-semibold grid col-span-2 ">
                {errors.nameparent.message}
              </p>
            )}
          </div>
          <div className="grid grid-cols-2 w-100 gap-4 mb-3">
            <label htmlFor="">Mobile number of the parent :</label>{" "}
            <input
              type="text"
              name="mobileparent"
              {...register("mobileparent", {
                required: "This field is required.",
              })}
              className="w-12/12 py-2 px-3 rounded-xl ring-2 ring-slate-300 focus:outline-none"
            />
            {errors.mobileparent && (
              <p className="text-red-600 font-semibold grid col-span-2 ">
                {errors.mobileparent.message}
              </p>
            )}
          </div>
          {/* <div className="w-full flex items-end justify-end mt-5">
            <button
              type="submit"
              className="ml-auto p-3 bg-stone-800 text-white rounded-xl"
            >
              Submit
            </button>
          </div> */}
          <div className="w-full flex items-center justify-between mt-5">
            <motion.button whileHover={{ x: -10, backgroundColor: 'red' }} className="p-3 bg-stone-800 text-white rounded-xl" onClick={() => setCurrPage(currpage - 1)}>Back</motion.button>
            <motion.button whileHover={{ x: 10, backgroundColor: 'green' }} className="p-3 bg-stone-800 text-white rounded-xl" type="submit">Submit Application</motion.button>
          </div>
        </form >
      </div>}
      {/* {<ApplicationListWarden certificates={certificates} setCertificates={setCertificates} setAppsno={setAppsno}/>} */}
      <AlertDialog
        open={open1}
        setOpen={setOpen1}
        modalHeading={modalHeading}
        modalText={modalText}
      />
      <DevTool control={control} /> {/* set up the dev tool */}
    </div >
  );
}

export default CreateUserWarder1;
