import { useEffect, useState } from "react"
import axios from "axios";
import * as XLSX from "xlsx";
import notFound from "../../../images/not-found.jpg"
import dayjs from 'dayjs'
import AlertDialog from "../../../components/AlertDialog";
import ConfirmDialog from "../../../components/ConfirmDialog";
import { baseUrl } from "../../../baseUrl";


function ViewRankList() {
  const [hostel, setHostel] = useState("MH")
  const [current_year, setCurrent_year] = useState("1")
  const [programme, setProgramme] = useState("UG")
  const [type, setType] = useState("provisional")
  const [invites, setInvites] = useState()

  const [seat, setSeat] = useState()
  const [DeadLine, setDeadLine] = useState()

  const [open1, setOpen1] = useState("");
  const [open2, setOpen2] = useState("");
  const [open3, setOpen3] = useState("");
  const [open4, setOpen4] = useState("");
  const [open5, setOpen5] = useState("");
  const [modalText, setModalText] = useState("");
  const [modalHeading, setModalHeading] = useState("");
  const [status, setStatus] = useState()

  const [vacancy, setVacancy] = useState()




  const [ranks, setRanks] = useState([])


  const [admiConfigs, setAdminConfigs] = useState()
  useEffect(() => {
    axios.get(`${baseUrl}/warden/get-hostel-admin-configs`).then(res => {
      console.log('res.data', res.data)
      setAdminConfigs(res.data)
    })
  }, [])


  useEffect(() => {
    axios.get(`${baseUrl}/warden/get-current-invites`).then((res) => {
      console.log('warden')
      setInvites(res.data)
      console.log('res.data intea', res.data)
    });
  }, [])
  useEffect(() => {
    axios.get(`${baseUrl}/warden/get-ranklist`, { params: { programme: programme, type: type, hostel: hostel, currentYear: current_year } }).then(res => {
      setRanks(res.data.ranklist)
      setStatus(res.data.status)
      setVacancy(res.data.vacancy)

      console.log('type', type)
      console.log('res.data warden ranlist', res.data)
    })
  }, [programme, type, hostel, current_year])

  // useEffect(() => {
  //   axios.get(`${baseUrl}/warden/get-current-vacancy`, { params: { table_name: invites?.rank_list_table_name } }).then((res) => {
  //     setVacancy(res.data)
  //     console.log('res.data', res.data)
  //   });
  // }, [type])



  const GenerateAllotment = () => {
    axios.post(`${baseUrl}/warden/generate-allotment`, {
      rank_list_table_name: status.rank_list_table_name,
      seat: seat,
      deadline: DeadLine,
    }).then(res => {
      setModalHeading("Request Completed ");
      setModalText("Allotment Generated Successfully.");
      setOpen1(true);
    })
  }


  const handleReAllotment = async () => {
    axios.get(`${baseUrl}/warden/generate-reallotment`, { params: { table_name: status?.rank_list_table_name, added_vacancy: parseInt(seat), deadline: DeadLine, special_vacancy: parseInt(vacancy.p_i_vacancy), normal_vacancy: parseInt(vacancy.p_ii_vacancy) } })
      .then((res) => {
        console.log(res.data)
        setModalHeading("Request Completed ");
        setModalText("Reallotment Generated Successfully .");
        setOpen1(true);
      }).catch(err => {
        console.log('err', err)
        setModalHeading("Request Completed ");
        setModalText(err.response.data.msg);
        setOpen1(true);
      });

  }

  const downloadExcel = () => {
    const ws = XLSX.utils.json_to_sheet(ranks);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");
    const excelFileName = `Rank_List.xlsx`;
    XLSX.writeFile(wb, excelFileName);
  };
  const handleSubmitReallotment = (e) => {
    e.preventDefault()
    setModalHeading("Confirm")
    setModalText("Do you wish to continue.Once Allotment is published you can not perform any modification in the allotment")
    setOpen3(true)

  }
  const handleSubmitAllotment = (e) => {
    e.preventDefault()
    setModalHeading("Confirm")
    setModalText("Do you wish to continue.Once Allotment is published you can not perform any modification in the allotment")
    setOpen2(true)


  }

  const clearFinal = () => {
    axios.get(`${baseUrl}/warden/clear-final-ranklist`, { params: { programme: programme, type: type, hostel: hostel, currentYear: current_year } })
      .then((res) => {
        console.log(res.data)
        setModalHeading("Request Completed ");
        setModalText("Final Ranklist Cleared .");
        setOpen1(true);
      }).catch(err => {
        console.log('err', err)
        setModalHeading("Invalid Request");
        setModalText(err.response.data.msg);
        setOpen1(true);
      });
  }
  const clearProvisional = (invite_id) => {
    axios.get(`${baseUrl}/warden/clear-provisional-ranklist`, { params: { programme: programme, type: type, hostel: hostel, currentYear: current_year } })
      .then((res) => {
        console.log(res.data)
        setModalHeading("Request Completed ");
        setModalText("Final Ranklist Cleared .");
        setOpen1(true);
      }).catch(err => {
        console.log('err', err)
        setModalHeading("Request Completed ");
        setModalText(err.response.data.msg);
        setOpen1(true);
      });
  }
  const [p1Vacancy, setP1Vacancy] = useState()
  const [p2Vacancy, setP2Vacancy] = useState()
  const handleClearProvisional = () => {
    setModalHeading("Confirm");
    setModalText("Provisional Ranklist will be cleared.");
    setOpen4(true);
  }
  const handleClearFinal = () => {
    setModalHeading("Confirm");
    setModalText("Final Ranklist will be cleared.");
    setOpen5(true);
  }
  return (
    <div className='w-11/12' >
      <h2 className="font-bold col-span-2">Select Rank List</h2>
      <div className='grid grid-cols-2 gap-3 w-6/12'>
        <label htmlFor="" className="mt-2">Hostels :</label>
        <select onChange={e => setHostel(e.target.value)} className='p-3 ring-slate-200 ring-2 rounded-xl outline-none'>
          <option value="MH">Mens Hostel</option>
          <option value="LH">Ladies Hostel</option>
        </select>
        <label htmlFor="" className="mt-2">Year:</label>
        <select onChange={e => setCurrent_year(e.target.value)} className='p-3 ring-slate-200 ring-2 rounded-xl outline-none'>
          <option value="1">First Year</option>
          <option value="2">Second Year</option>
          <option value="3">Third Year</option>
          <option value="4">Fourth Year</option>
        </select>
        <label htmlFor="" className="mt-2">Programme :</label>
        <select onChange={e => setProgramme(e.target.value)} className='p-3 ring-slate-200 ring-2 rounded-xl outline-none'>
          <option value="UG">UG</option>
          <option value="PG">PG</option>
          <option value="Phd">Phd</option>
        </select>
        <label htmlFor="" className="mt-2">Type of Ranklist :</label>
        <select onChange={e => setType(e.target.value)} className='p-3 ring-slate-200 ring-2 rounded-xl outline-none'>
          <option value="provisional">Provisional Rank List</option>
          <option value="final">Final Rank List</option>
        </select>
      </div>

      {/* {<RanklistWarden programme={programme} hostel={hostel} year={current_year} type={type} setRanklist={setRanklist} />}
       */}
      {typeof ranks != "undefined" && ranks != null && ranks.length != null && ranks.length > 0 ? <div className="">


        {type === 'final' &&
          <button className="bg-stone-800 text-white p-3 rounded-lg text-sm mr-5" onClick={downloadExcel}>Download as Excel</button>
        }
        {type === 'provisional' && <div className="grid grid-cols-2 gap-3 w-6/12">

          {/* <label>Final Rank List: </label>
          <h2 className="p-3">{dayjs(status?.concern_till).add(1, 'day').format('DD/MM/YYYY')}</h2> */}
          <button className="bg-stone-800 text-white p-3 rounded-lg text-sm my-3" onClick={downloadExcel}>Download as Excel</button>

        </div>}
        <div className="w-full overflow-x-scroll m-3">
          <table className='w-full relative table-auto'>
            <tr className='rounded-xl p-3 bg-primary text-center'>
              <th className='p-3'>Sl.No</th>
              <th className='p-3'>Admission No.</th>
              <th className='p-3'>Student Name </th>
              <th className='p-3'>District</th>
              <th className='p-3'>PostOffice</th>
              <th className='p-3'>Annual Income</th>
              <th className='p-3'>Income  W</th>
              <th className='p-3'>Distance</th>
              <th className='p-3'>Distance W</th>
              <th className='p-3'>Total W</th>
              <th className='p-3'>Rank</th>
            </tr>
            {ranks?.map((user, index) => (
              <tr
                className={'border-b text-center border-slate-200 border-solid hover:bg-gray-300 '}
              >
                <td className='p-3'>{index + 1}</td>
                <td className='p-3'>{user.user_id}</td>
                <td className='p-3'>{user.name}</td>
                <td className='p-3'>{user.district}</td>
                <td className='p-3'>{user.post_office}</td>
                <td className='p-3'>{user.afi}</td>
                <td className='p-3'>{user.i_w}</td>
                <td className='p-3'>{user.distance}</td>
                <td className='p-3'>{user.d_w}</td>
                <td className='p-3'>{user.w}</td>
                <td className='p-3'>{user.rank}</td>
              </tr>
            ))}
          </table>
        </div>

      </div> : <div className="m-3 flex flex-col justify-center items-center " >
        <h2 className="font-bold col-span-2 text-2xl text-red-600">No Ranklist Found !!!</h2>
        <img src={notFound} className="w-11/12" />
      </div>
      }

      <AlertDialog
        open={open1}
        setOpen={setOpen1}
        modalHeading={modalHeading}
        modalText={modalText}
      />
      <ConfirmDialog
        open={open2}
        setOpen={setOpen2}
        modalHeading={modalHeading}
        modalText={modalText}
        confirmFunction={GenerateAllotment}
      />
      <ConfirmDialog
        open={open3}
        setOpen={setOpen3}
        modalHeading={modalHeading}
        modalText={modalText}
        confirmFunction={handleReAllotment}
      />
      <ConfirmDialog
        open={open4}
        setOpen={setOpen4}
        modalHeading={modalHeading}
        modalText={modalText}
        confirmFunction={clearProvisional}
      />
      <ConfirmDialog
        open={open5}
        setOpen={setOpen5}
        modalHeading={modalHeading}
        modalText={modalText}
        confirmFunction={clearFinal}
      />
    </div>
  )
}

export default ViewRankList