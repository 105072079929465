import { useState, useEffect, useContext } from "react";
import axios from "axios";
import { baseUrl } from "../baseUrl";
import { UserContext } from "../Contexts/UserContext";
import DateConverter from '../Utils/DateConverter'
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';




function InmateDuesList() {
  const { user, setLoading } = useContext(UserContext)
   
  const [hostel, setHostel] = useState("LH");
  
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const mon = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based
   var formattedDate = `${year}-${mon}`;
  const [month,setMonth]=useState(formattedDate)
  const [messBillList,setMessBillList]=useState([])
  const [hostelAdmissionNo,setHostelAdmissionNo]=useState("")


  useEffect(() => {
    setLoading(true);
    axios.get(`${baseUrl}/warden/get-inmate-total-dues?hostel_admission_no=${hostelAdmissionNo}&&hostel=${hostel}&&month=${month}`).then((res) => {
      setMessBillList(res.data.data)
    }).catch((err) => {
      setMessBillList([])
      alert("No Data Found")
    }).finally(() => {
      setLoading(false)
    })
  }, [hostelAdmissionNo,hostel,month])


    const fileType='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset-UTF-8';
    
    const downloadExcel=async()=>{
        const date=new Date()
        const ws=XLSX.utils.json_to_sheet(messBillList);
        const wb={Sheets:{'data':ws},SheetNames:['data']};
        const excelBuffer=XLSX.write(wb,{bookType:'xlsx',type:'array'});
        const data=new Blob([excelBuffer],{type:fileType});
        FileSaver.saveAs(data,`Mess Dues ${DateConverter.parseDateDDMMYYYY(date)}.xlsx`)

    }
  



  return (
    <>
      <div className="w-full m-5 p-4">
        <div className="m-2 p-2 grid grid-cols-2 w-100 gap-4 mb-3">
          <label className="font-bold" htmlFor="">Hostel Admission No</label>{" "}
          <input
            required
            type="text"
            onChange={(e) => {
              setHostelAdmissionNo(e.target.value)
            }}
            className="w-11/12 py-2 px-3 rounded-xl ring-2 ring-slate-300 focus:outline-none"
          />
        </div>
        <div className="flex items-center justify-between w-4/12">
        <label className="m-2 font-bold">Select Hostel :</label>
          <select
            onChange={(e) => {
              setHostel(e.target.value)
            }}
            className="m-2 p-4 ring-slate-200 ring-2 rounded-md outline-none"
          >
            <option value={"LH"}>LADIES HOSTEL</option>
            <option value={"MH"}>MENS HOSTEL</option>
            
          </select>
        </div>
        <div className="flex items-center justify-between w-4/12">
          <label className="m-2 font-bold">Select Month :</label>
          <input
          type="month"
          value={month}
          onChange={(e)=>{
            setMonth(e.target.value)
          }}
            className="m-2 p-4 ring-slate-200 ring-2 rounded-md outline-none"
          />
           
        </div>

        <div className="flex items-center justify-end mb-5">
          <button className="bg-stone-800 text-white p-2 rounded-lg text-sm mr-5" onClick={() => {
             downloadExcel()
          }}>
            Download as Excel
          </button>
        </div>


        <h2 className="text-black font-semibold text-lg mt-5 mb-3">
         Dues List
        </h2>
        <div className="flex items-center justify-between w-4/12 py-4">
          <p className="font-semibold">No Of Mess Bill :</p>
          <p className="font-semibold">{messBillList.length} </p>
        </div>
        <div className='w-12/12 overflow-x-scroll'>
          <table className="w-full relative table-auto">
            <tr className="rounded-xl p-3 bg-primary text-center">
              <th className="p-2">Sl.No</th>
              <th className="p-2">Admission No</th>
              <th className="p-2">Hostel Admission No.</th>
              <th className="p-2">Name</th>
              <th className="p-2">Department</th>
              <th className="p-2">Programme</th>
              <th className="p-2">Year of Study</th>
              <th className="p-2">Due Amount</th>


            </tr>
            {messBillList && messBillList.map((messbill, index) => {
              return (
                <tr
                  key={index}
                  className={
                    "border-b text-center border-slate-200 border-solid hover:bg-gray-300"
                  }
                >
                  <td className="p-3 text-sm">{index + 1}</td>
                  <td className="p-3 text-sm">{messbill.admission_no}</td>
                  <td className="p-3 text-sm">{messbill.hostel_admission_no}</td>
                  <td className="p-3 text-sm ">{messbill.name}</td>
                  <td className="p-3 text-sm ">{messbill.department}</td>
                  <td className="p-3 text-sm ">{messbill.programme}</td>
                  <td className="p-3 text-sm ">{messbill.current_year}</td>
                  <td className="p-3 text-sm">{messbill.sum}</td>
                </tr>
              );
            })}
          </table>
        </div>
      </div>
    </>
  );
}

export default InmateDuesList;
