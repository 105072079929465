import {useState,useContext, useEffect} from 'react'
import axios from 'axios'
import { UserContext } from '../Contexts/UserContext'
import AlertDialog from './AlertDialog';
import ConfirmDialog from './ConfirmDialog';
export default function CalculateMessBill(){
    const date=new Date()
    const [hostel,setHostel]=useState("MH");
    const [messDailyRate,setMessDailyRate]=useState(0);
    const [wages,setWages]=useState(0);
    const [roomRent,setRoomRent]=useState(0);
    const [open1,setOpen1]=useState(false);
    const [modalHeading,setModalHeading]=useState("");
    const [open2,setOpen2]=useState(false);
    const [modalText,setModalText]=useState("");

    const {user,setLoading} = useContext(UserContext)

    const parseDateDDMMYYYY = (inputdate) => {
        const date = new Date(inputdate);
        let month = (date.getMonth() + 1).toString();
        let day = date.getDate().toString();
        let year = date.getFullYear();
        if (month.length < 2) {
          month = "0" + month;
        }
        if (day.length < 2) {
          day = "0" + day;
        }
        return day + "-" + month + "-" + year;
      };
    const submitHandler = (e)=>{
        e.preventDefault();
        setLoading(true)
    }

    return(
        <div className="w-11/12">
            <h2 className="font-bold text-black mb-3">Generate Mess Bill For Month </h2>
            <form action="" onSubmit={submitHandler}>
                <div className="grid grid-cols-2 gap-y-4 w-6/12">
                <label htmlFor="">Hostel:</label>
                <select
            className="p-3 ring-slate-200 ring-2 rounded-xl outline-none"
          >
            <option value="MH">Mens Hostel</option>
            <option value="LH">Ladies Hostel</option>
          </select>
                <label htmlFor="">Daily Rate:</label>
                <input type="number" name="" id="" placeholder="Enter Mess Daily Charge" className="w-full py-2 px-3 rounded-xl ring-2 ring-slate-300 focus:outline-none" value={messDailyRate} onChange={(e)=>{setMessDailyRate(e.target.value)}} required/>
                <label htmlFor="">Wages & Coolies:</label>
                <input type="text" name="" id="" placeholder="Enter preferred room" className="w-full py-2 px-3 rounded-xl ring-2 ring-slate-300 focus:outline-none" value={wages} onChange={(e)=>{setWages(e.target.value)}} required/>
                <label htmlFor="">Wages & Coolies:</label>
                <input type="text" name="" id="" placeholder="Enter preferred room" className="w-full py-2 px-3 rounded-xl ring-2 ring-slate-300 focus:outline-none" value={roomRent} onChange={(e)=>{roomRent(e.target.value)}} required/>
                </div>
                <div className="w-full flex items-end justify-end mt-5">
                    <button className="ml-auto p-3 bg-stone-800 text-white rounded-xl" type="submit">Generate Mess Bill</button>
                </div>
            </form>
            <AlertDialog
        open={open1}
        setOpen={setOpen1}
        modalHeading={modalHeading}
        modalText={modalText}
      />
      <ConfirmDialog
        open={open2}
        setOpen={setOpen2}
        modalHeading={modalHeading}
        modalText={modalText}
        confirmFunction={submitHandler}
      />
        </div>
    )
}