import React, { useContext, useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import SideBar from '../../components/SideBar'
import editSvg from '../../icons/edit.svg'
import userSvg from '../../icons/user.svg'
import bookSvg from '../../icons/book.svg'
import fitnessSvg from '../../icons/fitness.svg'
import infoSvg from '../../icons/info.svg'
import { UserContext } from '../../Contexts/UserContext'
import { Layout, Menu, theme } from 'antd';

function StudentHome() {

  const { user } = useContext(UserContext)
  console.log('user', user)

  const links = [
    {
      title: "View Hostel Details",
      to: "/student",
      icon: fitnessSvg,
    },
    {
      title: "Certificate",
      to: "/student/noninmatecertificate",
      icon: bookSvg,
    },
    {
      title: "Hostel Admission",
      to: "/student/hostelapply",
      icon: bookSvg,
    }

  ]
  return (
    <Layout hasSider className='h-screen'>

      <SideBar myLinks={links} myActiveIndex={0} myOpenedIndex={0} />

      <Outlet />

    </Layout>
  )
}

export default StudentHome