
import axios from "axios";
import { useState, useContext, useEffect } from "react";
import { UserContext } from "../Contexts/UserContext";
import AlertDialog from "./AlertDialog";
import ConfirmDialog from "./ConfirmDialog";
import { baseUrl } from "../baseUrl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import AddAdditionalMessExpensePage from "./AddAdditionalMessExpensePage";
import ModifyInmateMessAttendancePage from "./ModifyInmateMessAttendancePage";
import AddMessExemptionPage from "./AddMessExemptionPage";
import dayjs from "dayjs";
import ViewAdditionalMessExpense from "../pages/Warden/Components/ViewAdditionalMessExpense";
import ViewMessExemptInmates from "../pages/Warden/Components/ViewMessExemptInmates";
import VIewModiefiedAttendanceList from "../pages/Warden/Components/VIewModiefiedAttendanceList";
function GenerateMessBillForm({ setCalculatedMessBillList }) {

  const { setLoading } = useContext(UserContext)
  const [date, setDate] = useState("")
  const [hostel, setHostel] = useState("")
  const [isFinal, setIsFinal] = useState(false)
  const [additionalInputValues, setAdditionalInputValues] = useState([{ 'name': "Daily Rate", "amount": 0, "establishment_charge": false }, { 'name': "Room Rent", "amount": 0, "establishment_charge": false }, { 'name': "Wages And Coolies", "amount": 0, "establishment_charge": true }]);
  const [modalText, setModalText] = useState("");
  const [modalHeading, setModalHeading] = useState("");
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [additionMessExpenseStatus, setAdditionMessExpenseStatus] = useState({ Add: false, viewEdit: false })
  const [modifyAttendanceStatus, setModifyAttendanceStatus] = useState({ Add: false, viewEdit: false })
  const [exemtInmateStatus, setExemtInmateStatus] = useState({ Add: false, viewEdit: false })

  const dateConverter = (inputdate) => {
    const date = new Date(inputdate);
    let month = (date.getMonth() + 1).toString();
    let day = date.getDate().toString();
    let year = date.getFullYear();
    if (month.length < 2) {
      month = "0" + month;
    }
    if (day.length < 2) {
      day = "0" + day;
    }
    return [year, month, day].join("-");
  };

  const handleAddInput = () => {
    setAdditionalInputValues([...additionalInputValues, { name: '', value: 0, establishment_charge: false }]);
  };

  const handleInputChange = (index, fieldName, fieldValue) => {
    const newInputFields = [...additionalInputValues];
    newInputFields[index][fieldName] = fieldValue;
    setAdditionalInputValues(newInputFields);
  };

  const handleDeleteInput = (index) => {
    if (index < 3) {
      setModalHeading("Invalid Delete")
      setModalText("You cannot remove this input field , It is a required field");
      setOpen1(true)
      return
    }
    const newInputFields = [...additionalInputValues];
    newInputFields.splice(index, 1);
    setAdditionalInputValues(newInputFields);
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    setModalHeading("Are you sure ");
    setModalText("You are about to generate mess bill for the month " + date + " for the hostel " + hostel + " Are you sure you want to continue .")
    setOpen2(true);
  }

  const generateMessBill = async () => {
    setLoading(true);
    axios.post(`${baseUrl}/warden/generate-mess-bill`, { hostel, date, isFinal, additional_inputs: additionalInputValues }).then((res) => {
      setCalculatedMessBillList(res.data.data)
    }).catch((err) => {
      console.log(err)
      setModalHeading("Something went wrong")
      setModalText("Something went wrong while generating the mess bill. Please try again after sometime . If the issue persisit try contacting the developer ")
      setOpen1(true)

    }).finally(() => {
      setLoading(false)
    })
  }
  return (
    <div className="mb-3">
      <h2 className="font-semibold text-lg mb-2">
        Generate Mess Bill
      </h2>
      <form onSubmit={handleSubmit}>
        <div className="grid grid-cols-2 w-100 gap-4 mb-3">
          <label className="font-bold" htmlFor="">Month for which Bill is calculated:</label>{" "}
          <input
            required
            type="month"
            onChange={(e) => {
              setDate(e.target.value)
            }}
            className="w-11/12 py-2 px-3 rounded-xl ring-2 ring-slate-300 focus:outline-none"
          />

        </div>
        <div className="grid grid-cols-2 w-100 gap-4 mb-3">
          <label className="font-bold" htmlFor="">
            Select hostel to calculate mess bill
          </label>{" "}
          <select
            required
            onChange={(e) => {
              setHostel(e.target.value);
            }}
            className="w-11/12 py-2 px-3 rounded-xl ring-2 ring-slate-300 focus:outline-none"
          >
            <option value="" disabled selected>
              Select Hostel
            </option>
            <option value="MH">MH</option>
            <option value="LH">LH</option>
          </select>
        </div>

        {date.length > 0 && hostel.length > 0 &&
          <>
            <div className="grid grid-cols-2 w-100 gap-4 mb-3">
              <label className="font-bold" htmlFor="">
                Additional Mess Expense :
              </label>{" "}
              <div className="flex justify-end gap-2">

                <button
                  onClick={() => {
                    console.log('additionMessExpenseStatus', additionMessExpenseStatus)
                    setAdditionMessExpenseStatus(({ Add: !additionMessExpenseStatus.Add, viewEdit: false }))
                    setExemtInmateStatus(({ Add: false, viewEdit: false }))
                    setModifyAttendanceStatus(({ Add: false, viewEdit: false }))

                  }}
                  type="button"
                  className="ml-auto p-3 bg-stone-800 text-white rounded-xl"
                >
                  Add
                </button>
                <button
                  onClick={() => {

                    console.log('additionMessExpenseStatus', additionMessExpenseStatus)
                    setAdditionMessExpenseStatus(({ Add: false, viewEdit: !additionMessExpenseStatus.viewEdit }))
                    setExemtInmateStatus(({ Add: false, viewEdit: false }))
                    setModifyAttendanceStatus(({ Add: false, viewEdit: false }))

                  }}
                  type="button"
                  className="p-3 bg-stone-800 text-white rounded-xl"
                >
                  View / Edit
                </button>
              </div>
            </div>
            {additionMessExpenseStatus.Add && <AddAdditionalMessExpensePage date={date} />}
            {additionMessExpenseStatus.viewEdit && <ViewAdditionalMessExpense date={date} hostel={hostel} />}
            <div className="grid grid-cols-2 w-100 gap-4 mb-3">
              <label className="font-bold" htmlFor="">
                Modify Attendance :
              </label>{" "}
              <div className="flex justify-end gap-2">
                <button
                  onClick={() => {
                    setModifyAttendanceStatus(({ Add: !modifyAttendanceStatus.Add, viewEdit: false }))
                    setExemtInmateStatus(({ Add: false, viewEdit: false }))
                    setAdditionMessExpenseStatus(({ Add: false, viewEdit: false }))

                  }}
                  type="button"
                  className="p-3 bg-stone-800 text-white rounded-xl"
                >
                  Add
                </button>
                <button
                  onClick={() => {
                    setModifyAttendanceStatus(({ Add: false, viewEdit: !modifyAttendanceStatus.viewEdit }))
                    setExemtInmateStatus(({ Add: false, viewEdit: false }))
                    setAdditionMessExpenseStatus(({ Add: false, viewEdit: false }))

                  }}
                  type="button"
                  className="p-3 bg-stone-800 text-white rounded-xl"
                >
                  View / Edit
                </button>
              </div>
            </div>
            {modifyAttendanceStatus.Add && <ModifyInmateMessAttendancePage date={date} hostel={hostel} />}
            {modifyAttendanceStatus.viewEdit && <VIewModiefiedAttendanceList date={date} hostel={hostel} />}
            <div className="grid grid-cols-2 w-100 gap-4 mb-3">
              <label className="font-bold" htmlFor="">
                Exempt Inmates from E.C :
              </label>{" "}
              <div className="flex justify-end gap-2">

                <button
                  onClick={() => {
                    setExemtInmateStatus(({ Add: !exemtInmateStatus.Add, viewEdit: false }))
                    setModifyAttendanceStatus(({ Add: false, viewEdit: false }))
                    setAdditionMessExpenseStatus(({ Add: false, viewEdit: false }))


                  }}
                  type="button"
                  className="p-3 bg-stone-800 text-white rounded-xl"
                >
                  Add
                </button>
                <button
                  onClick={() => {
                    setExemtInmateStatus(({ Add: false, viewEdit: !exemtInmateStatus.viewEdit }))
                    setModifyAttendanceStatus(({ Add: false, viewEdit: false }))
                    setAdditionMessExpenseStatus(({ Add: false, viewEdit: false }))

                  }}
                  type="button"

                  className="p-3 bg-stone-800 text-white rounded-xl"
                >
                  View / Edit
                </button>

              </div>
            </div>
            {exemtInmateStatus.Add && <AddMessExemptionPage date={date} hostel={hostel} />}
            {exemtInmateStatus.viewEdit && <ViewMessExemptInmates date={date} hostel={hostel} />}

          </>
        }


        {date.length > 0 && hostel.length > 0 && <div className="mt-5">
          {additionalInputValues.map((field, index) => (
            <div className="flex w-full justify-space-between items-center" key={index}>
              <input
                readOnly={index < 3}
                required
                className="w-4/12 m-2 py-2 px-3 rounded-xl ring-2 ring-slate-300 focus:outline-none"
                type="text"
                placeholder="title"
                value={field.name}
                onChange={(e) => {
                  if (index >= 3)
                    handleInputChange(index, 'name', e.target.value)
                }}
              />
              <input
                required
                className="w-4/12 m-2 py-2 px-3 rounded-xl ring-2 ring-slate-300 focus:outline-none"
                type="number"
                placeholder="Amount in R.S"
                value={field.value}
                onChange={(e) => handleInputChange(index, 'value', e.target.value)}
              />
              <p className="p-3 text-sm v"> Establishment Charge</p>
              <input
                className="m-4 p-2  py-2 px-3 rounded-xl ring-2 ring-slate-300 focus:outline-none"
                type="checkbox"
                checked={additionalInputValues[index]["establishment_charge"]}
                placeholder="Amount in R.S"
                value={field.value}
                onChange={(e) => {
                  handleInputChange(index, 'establishment_charge', e.target.checked)
                }}
              />
              {index >= 3 && <div onClick={() => {
                handleDeleteInput(index)
              }} className="flex w-14  justify-center items-center">
                <FontAwesomeIcon className="text-red-400" size='xl' icon={faTrash}></FontAwesomeIcon>
              </div>

              }
            </div>
          ))}
          <div className="w-full flex  mt-5">
            <button
              onClick={() => {
                handleAddInput()
              }}
              type="button"
              className=" p-3 bg-stone-800 text-white rounded-xl"
            >
              Add Additional Inputs
            </button>
          </div>
        </div>}

        {date.length > 0 && hostel.length > 0 &&< div className="grid grid-cols-2 w-100 gap-4 my-6">
        <label className="font-bold" htmlFor="">
          Do You want to Commit This as Final ?
        </label>{" "}
        <input
          type="checkbox"
          onChange={(e) => {
            // setIsFinal(e.target.checked);
            if (isFinal == false) {
              setModalHeading("Are you sure ");
              setModalText("Checking this option will finalise the mess bill which cannot be changed later. Make sure that you have downloaded Excel Sheet showing the draft mess bill and found as correct. Do you want to confirm?")
              setOpen3(true)

            } else {
              setIsFinal(!isFinal)
            }
          }}
          checked={isFinal}
          className="flex flex-begin"
        >
        </input>
    </div>}
<div className="w-full flex items-end justify-end mt-5">
  <button
    type="submit"
    className="ml-auto p-3 bg-stone-800 text-white rounded-xl"
  >
    Generate Mess Bill
  </button>
</div>
      </form >
      <AlertDialog
        open={open1}
        setOpen={setOpen1}
        modalHeading={modalHeading}
        modalText={modalText}
      />
      <ConfirmDialog
        open={open2}
        setOpen={setOpen2}
        modalHeading={modalHeading}
        modalText={modalText}
        confirmFunction={generateMessBill}
      />
      <ConfirmDialog
        open={open3}
        setOpen={setOpen3}
        modalHeading={modalHeading}
        modalText={modalText}
        confirmFunction={() => (setIsFinal(!isFinal))}
      />



    </div >
  );

}
export default GenerateMessBillForm;
