import React, { useContext, useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'
import SideBar from '../components/SideBar'
import editSvg from '../icons/edit.svg'
import userSvg from '../icons/user.svg'
import bookSvg from '../icons/book.svg'
import fitnessSvg from '../icons/fitness.svg'
import { adminLinks } from './Admin/AdminLinks'
import { saLinks } from './StaffAdvisor/StaffAdvisorLinks'
import { createBrowserHistory } from 'history'
import { wardenLinks } from './Warden/WardenLinks'
import { hostelOfficeLinks } from './HostelOffice/HostelOfficeLinks'
import { hodLinks } from './HOD/HODLinks'
import { sergeantLinks } from './Sergeant/SergeantLinks'
import { UserContext } from '../Contexts/UserContext'
import { matronLinks } from './Matron/MatronLinks'
import { ClerkLinks } from './Clerk/ClerkLinks'
import { ClerkA1Links } from './Clerk_A1/ClerkA1Links'
import { ClerkA3Links } from './Clerk_A3/ClerkA3Links'
// import { studentLinks } from './Student/StudentLinks'
import {
    AppstoreOutlined,
    BarChartOutlined,
    CloudOutlined,
    ShopOutlined,
    TeamOutlined,
    UploadOutlined,
    UserOutlined,
    VideoCameraOutlined,
} from '@ant-design/icons';
import { Layout, Menu, theme } from 'antd';
const { Header, Content, Footer, Sider } = Layout;

function CommonHome() {

    const { user } = useContext(UserContext)
    const [links, setLinks] = useState([])
    const [role, setRole] = useState(user.roles[0]) //index of the role selected in the user.roles array
    const [activeLinkTo, setActiveLinkTo] = useState("")

    useEffect(() => {
        // if(user.roles[role]==="admin")
        // {
        //     // setRoleTo("admin")
        //     console.log("new links : ",adminLinks)
        //     setLinks(adminLinks.map(item=>({...item})))
        // }
        // else if(user.roles[role]==="staff advisor")
        // {
        //     // setRoleTo("staffadvisor")
        //     console.log("new links : ",saLinks)
        //     setLinks(saLinks.map(item=>({...item})))
        // }

        // else if(user.roles[role]==="hod")
        // {
        //     // setRoleTo("staffadvisor")
        //     console.log("new links : ",hodLinks)
        //     setLinks(hodLinks.map(item=>({...item})))
        // }
        // else if(user.roles[role]==="warden"){
        //     // setRoleTo("warden")
        //     console.log("new links : ",wardenLinks)
        //     setLinks(wardenLinks.map(item=>({...item})))
        // }
        // else if(user.roles[role]==="hosteloffice"){
        //     // setRoleTo("warden")
        //     console.log("new links : ",hostelOfficeLinks)
        //     setLinks(hostelOfficeLinks.map(item=>({...item})))
        // }

        if (role === "admin") {
            // setRoleTo("admin")
            console.log("new links : ", adminLinks)
            setLinks(adminLinks.map(item => ({ ...item })))
        }
        else if (role === "SA") {
            // setRoleTo("staffadvisor")
            console.log("new links : ", saLinks)
            setLinks(saLinks.map(item => ({ ...item })))
        }

        else if (role === "HOD") {
            // setRoleTo("staffadvisor")
            console.log("new links : ", hodLinks)
            setLinks(hodLinks.map(item => ({ ...item })))
        }
        else if (role === "WD") {
            // setRoleTo("warden")
            console.log("new links : ", wardenLinks)
            setLinks(wardenLinks.map(item => ({ ...item })))
        }
        else if (role === "CLRKA3") {
            setLinks(ClerkA3Links.map(item => ({ ...item })))
        }
        else if (role === "AA" || role === "CLRKA3" || role === "CLRKA2" || role === "SG" || role === "MTRN" || role === "CLRKB1" || role === "MTRN" || role === "AWD") {
            console.log("new links : ", wardenLinks)
            setLinks(hostelOfficeLinks.map(item => ({ ...item })))


        }
        else if (role === "CLRKA1") {
            setLinks(ClerkA1Links.map(item => ({ ...item })))
        }
        else if (role === "CLRK") {
            // setRoleTo("warden")
            console.log("new links : ", ClerkLinks)
            setLinks(ClerkLinks.map(item => ({ ...item })))
        }
        else if (role === "MTRN") {
            // setRoleTo("warden")
            console.log("new links : ", hostelOfficeLinks)
            setLinks(matronLinks.map(item => ({ ...item })))
        }
        else if (user.roles[role] === "SG") {
            // setRoleTo("warden")
            console.log("new links : ", sergeantLinks)
            setLinks(sergeantLinks.map(item => ({ ...item })))
        }

    }, [role])

    return (
        <Layout hasSider className='h-screen'>

            <SideBar myLinks={links} roles={user.roles} setRole={setRole} currentRole={role} />

            <Outlet context={[role, setRole]} />
            
        </Layout>
    )
}

export default CommonHome