import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useState } from 'react';

export default function DialogBoxWithInputField({open,setOpen,value,setValue,modalText,modalHeading,confirmFunction,label}) {
  

  const handleClose = () => {
    setOpen(false);
  };

  return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        
      >
        <DialogTitle>{modalHeading}</DialogTitle>
        <DialogContent>
          <DialogContentText>
           {modalText}
          </DialogContentText>
          <TextField
            autoFocus
            required
            margin="dense"
            id="name"
            label={label}
            type="text"
            value={value}
            onChange={(e)=>{
                setValue(e.target.value)
            }}
            fullWidth
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={()=>{
            confirmFunction()
            handleClose()
          }}>Submit</Button>
        </DialogActions>
      </Dialog>
  );
}