import React, { useContext, useEffect, useState } from 'react'
import { baseUrl } from '../../../baseUrl'
import axios from 'axios'
import { UserContext } from '../../../Contexts/UserContext';
import { Calendar, Clock, AlertCircle } from 'lucide-react';
import { format } from 'date-fns';

const formatDate = (dateString) => {
    return format(new Date(dateString), 'MMMM d, yyyy');
};

const DateItem = ({ icon: Icon, label, date }) => (
    <div className="flex items-center space-x-4 py-2">
        <div className="bg-blue-100 p-2 rounded-full">
            <Icon className="h-6 w-6  text-slate-800 " />
        </div>
        <div>
            <p className="text-sm text-gray-500">{label}</p>
            <p className="font-semibold">{formatDate(date)}</p>
        </div>
    </div>
);
function ApplicationStatus({ appliedStatus }) {

    const [status, setStatus] = useState({})
    const { user } = useContext(UserContext)


    useEffect(() => {
        axios.get(`${baseUrl}/student/application-status`, { params: { userId: user.user_id } }).then(res => {
            setStatus(res.data)
            console.log('res.data', res.data)
        })
    }, [])
    return (
        <>

            <div className="bg-white rounded-lg shadow-lg overflow-hidden w-full max-w-md mx-auto mt-3">
                <div className="p-6">
                    <div className="bg-slate-500 text-white py-4 px-6">
                        <h2 className="text-xl font-bold text-center">Important Dates</h2>
                    </div>
                    <div className="space-y-4">
                        <DateItem icon={Calendar} label="Application Opens" date={appliedStatus.open_in_date} />
                        <DateItem icon={Calendar} label="Application Closes" date={appliedStatus.close_in_date} />
                        <DateItem icon={Calendar} label="Provisionl Ranklist (Expected Date)" date={appliedStatus.provisional_date} />
                        <DateItem icon={Clock} label="Concerns Addressed Until (Expected Date)" date={appliedStatus.concern_till_date} />
                        <DateItem icon={Clock} label="Final Ranklist (Expected Date)" date={appliedStatus.final_ranklist_date} />
                    </div>
                </div>
                <div className="flex flex-col items-center justify-center p-5 bg-teal-700 rounded-lg m-3">
                    <h2 className="text-lg font-semibold text-white">Status</h2>
                    <h1 className="text-2xl text-center font-bold text-white">{status.status}</h1>
                </div>
            </div>
        </>
    )
}

export default ApplicationStatus