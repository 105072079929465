import React, { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import { Table, Input, Select } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import XLSX from 'sheetjs-style';
import { baseUrl } from '../../../baseUrl';
import axios from 'axios';


function IntersectingMessout() {
    const [messout, setMessout] = useState()
    const [month, setMonth] = useState(dayjs().format('MM-YYYY'))
    useEffect(() => {
        axios.get(`${baseUrl}/warden/get-intersecting-messout`, { params: { month: month } }).then(res => {
            setMessout(res.data)
        })

    }, [month])



    const [modalText, setModalText] = useState("");
    const [modalHeading, setModalHeading] = useState("");
    const [open1, setOpen1] = useState(false);
    const [open2, setOpen2] = useState(false);

    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');


    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <button
                    type="button"
                    onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 90, marginRight: 8 }}
                >
                    Search
                </button>
                <button onClick={() => handleReset(clearFilters)} style={{ width: 90 }}>
                    Reset
                </button>
            </div>
        ),
        filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
                setTimeout(() => this.searchInput.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <span style={{ fontWeight: 'bold' }}>{text}</span>
            ) : (
                text
            ),
    });


    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };


    const columns = [
        {
            title: 'Index',
            key: 'index',
            render: (text, record, index) => index + 1,
        },
        {
            title: 'College Admission No',
            dataIndex: 'hostel_admission_no',
            key: 'hostel_admission_no',
            ...getColumnSearchProps('hostel_admission_no'),

        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            ...getColumnSearchProps('name'),

        },
        {
            title: 'From Date-1',
            dataIndex: 'fromdate1',
            key: 'fromdat1',
            render: (text) => text ? dayjs(text).format('YYYY-MM-DD') : 'N/A'


        },
        {
            title: 'To Date-1',
            dataIndex: 'todate1',
            key: 'todate1',
            render: (text) => text ? dayjs(text).format('YYYY-MM-DD') : 'N/A'

        },
        {
            title: 'From Date-2',
            dataIndex: 'fromdate2',
            key: 'fromdate2',
            render: (text) => text ? dayjs(text).format('YYYY-MM-DD') : 'N/A'


        },
        {
            title: 'To Date-2',
            dataIndex: 'todate2',
            key: 'todate2',
            render: (text) => text ? dayjs(text).format('YYYY-MM-DD') : 'N/A'

        },
        {
            title: 'Month',
            dataIndex: 'month',
            key: 'month',
            render: (text) => text ? dayjs(text).format('YYYY-MM') : 'N/A'
        },

    ];

    const downloadExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(messout);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Revoked Inmates");
        XLSX.writeFile(workbook, "revoked_list.xlsx");
    };
    return (
        <div className="mb-3 w-11/12">
            <div className="w-full flex justify-end mt-5 gap-3">
                <button

                    type="button"
                    onClick={() => {
                        downloadExcel()
                    }}
                    className="p-3 bg-green-700 text-white rounded-xl"
                >
                    Download Excel
                </button>

            </div>
            <div className="mt-4  grid grid-cols-2 w-100 gap-4 mb-3">
                <label className="m-2 font-bold">Select Month :</label>
                <input
                    type="month"
                    value={month}
                    onChange={(e) => {
                        setMonth(e.target.value)
                    }}
                    className="w-11/12 py-2 px-3 rounded-xl ring-2 ring-slate-300 focus:outline-none"
                />

            </div>
            <h2 className="font-semibold text-lg mb-2">
                View Inmates with Dues
            </h2>

            <Table columns={columns} dataSource={messout} rowKey={(record) => record.hostel_admission_no} pagination={{ pageSize: 50 }} />




        </div>
    )
}

export default IntersectingMessout