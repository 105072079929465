import React, { useContext } from 'react'
import { useEffect, useState } from "react"
import axios from "axios";
import dayjs from 'dayjs'
import notFound from '../../../images/not-found.jpg'
import * as XLSX from "xlsx";
import { baseUrl } from '../../../baseUrl';
import { Table, Input, DatePicker, Button, message, Popconfirm, Form, theme, Modal } from 'antd';
import { SearchOutlined, EditOutlined, SaveOutlined, CloseOutlined, DeleteOutlined } from '@ant-design/icons';
import ConfirmDialog from '../../../components/ConfirmDialog';
import AlertDialog from '../../../components/AlertDialog';
import { UserContext } from '../../../Contexts/UserContext';


function ViewAdditionalMessExpense({ date, hostel }) {
    const [current_year, setCurrent_year] = useState("All")
    const [titles, setTitles] = useState([])
    const [title, setTitle] = useState("")
    const [invites, setInvites] = useState([])
    const [additionalExpense, setAdditionalExpense] = useState([])
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [form] = Form.useForm();



    const [open1, setOpen1] = useState("");
    const [open2, setOpen2] = useState("");
    const [actualDeadline, setActualDeadline] = useState("");
    const [modalText, setModalText] = useState("");
    const [modalHeading, setModalHeading] = useState("");
    const { user, setLoading } = useContext(UserContext)


    const fetchAddtionalExpense = () => {
        setLoading(true)
        axios.get(`${baseUrl}/warden/get-mess-additional-expense`, { params: { date: date, hostel: hostel, title: title } }).then((res) => {
            setAdditionalExpense(res.data)
            setLoading(false)

        }).catch((err) => {
            setLoading(false)
            console.log(err)
            setModalHeading("Something went wrong")
            setModalText("Something went wrong while generating the mess bill. Please try again after sometime . If the issue persisit try contacting the developer ")
            // setOpen1(true)

        })
    }
    useEffect(() => {
        fetchAddtionalExpense()
    }, [hostel, date, title])


    useEffect(() => {
        setLoading(true)

        axios.get(`${baseUrl}/warden/get-mess-additional-expense-title`, { params: { date: date, hostel: hostel } }).then((res) => {
            setTitles(res.data)
            setTitle(res.data[0].title)
            setLoading(false)

        }).catch((err) => {
            setLoading(false)

            console.log(err)
            setModalHeading("Something went wrong")
            setModalText("Something went wrong while generating the mess bill. Please try again after sometime . If the issue persisit try contacting the developer ")
            // setOpen1(true)

        })
    }, [hostel, date])



    const downloadExcel = () => {
        const ws = XLSX.utils.json_to_sheet(additionalExpense);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");
        const excelFileName = `Applicant_List.xlsx`;
        XLSX.writeFile(wb, excelFileName);
    };

    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [editingKey, setEditingKey] = useState('');

    const dateFormat = 'YYYY-MM-DD';



    const isEditing = (record) => record.id === editingKey;

    const edit = (record) => {
        setEditingKey(record.id);
    };

    const cancel = () => {
        setEditingKey('');
    };
    const save = async (key) => {
        try {
            const row = additionalExpense.find((item) => key === item.id);
            if (row) {
                console.log('row', row)
                // Here you would typically make an API call to update the data
                // For now, we'll just update the local state
                const res = await axios.put(`${baseUrl}/warden/update-additional-mess-expense`, { id: row.id, amount: row.amount, title: title });
                const newData = [...additionalExpense];
                const index = newData.findIndex((item) => key === item.id);
                if (index > -1) {
                    newData[index] = row;
                    setAdditionalExpense(newData);
                    setEditingKey('');
                    message.success('Amount updated successfully');
                }
            }
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    };
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <button
                    type="button"
                    onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 90, marginRight: 8 }}
                >
                    Search
                </button>
                <button onClick={() => handleReset(clearFilters)} style={{ width: 90 }}>
                    Reset
                </button>
            </div>
        ),
        filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
                setTimeout(() => this.searchInput.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <span style={{ fontWeight: 'bold' }}>{text}</span>
            ) : (
                text
            ),
    });

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const columns = [
        {
            title: 'Index',
            key: 'index',
            render: (text, record, index) => index + 1,
        },
        {
            title: 'Hostel Admission No',
            dataIndex: 'hostel_admission_no',
            key: 'hostel_admission_no',
            ...getColumnSearchProps('hostel_admission_no'),
        },
        {
            title: 'Student Name',
            dataIndex: 'name',
            key: 'name',
            ...getColumnSearchProps('name'),
        },
        {
            title: 'Issued Date',
            dataIndex: 'issued_date',
            key: 'issued_date',
            render: (text) => text ? dayjs(text).format('DD-MM-YYYY') : 'N/A',

        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            width: 200,
            render: (text, record) => {
                const editable = isEditing(record);
                return editable ? (
                    <Input
                        defaultValue={text}
                        onChange={(e) => {
                            console.log('e.target.value', e.target.value)
                            record.amount = e.target.value
                        }}

                    />
                ) : (
                    text ? text : 'N/A'
                );
            },
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => {
                const editable = isEditing(record);
                return editable ? (
                    <span>
                        <Button
                            onClick={() => save(record.id)}
                            style={{ marginRight: 8 }}
                            icon={<SaveOutlined />}
                        >
                            Save
                        </Button>
                        <Button onClick={cancel} icon={<CloseOutlined />}>
                            Cancel
                        </Button>
                    </span>
                ) : (
                    <span>

                        <Button disabled={editingKey !== ''} onClick={() => edit(record)} icon={<EditOutlined />} style={{ marginRight: 8 }}>
                            Edit
                        </Button>
                        <Popconfirm
                            title="Are you sure you want to delete this Expense?"
                            onConfirm={() => handleDelete(record.id)}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button icon={<DeleteOutlined />} type="danger">
                                Delete
                            </Button>
                        </Popconfirm>
                    </span>

                );
            },
        },

    ];



    const handleDelete = (id) => {
        axios.delete(`${baseUrl}/warden/delete-additional-mess-expense`, { params: { id: id }, }).then(() => {
            fetchAddtionalExpense()
            message.success('Expense deleted successfully');
        }).catch(error => {
            message.error('Failed to delete Expense');
        });
    };
    const handleAdd = (values) => {
        axios.post(`${baseUrl}/warden/add-additional-mess-expense`, { ...values, date: date, expense: title }).then(() => {
            setIsModalVisible(false);
            form.resetFields();
            message.success('Expense added successfully');
            fetchAddtionalExpense()
        }).catch(error => {
            message.error(error.response.data.msg);
        });
    };


    const clearMessExpense = () => {
        axios.delete(`${baseUrl}/warden/delete-additional-mess-expense-month`, { params: { date: date, title: title, hostel: hostel }, })
            .then((res) => {
                console.log(res.data)
                setModalHeading("Request Completed ");
                setModalText(`Addtional Expense ${title} has been Cleared .`);
                fetchAddtionalExpense()
                setOpen1(true);
            }).catch(err => {
                console.log('err', err)
                setModalHeading("Request Completed ");
                setModalText(err.response.data.msg);
                setOpen1(true);
            });
    }
    const handleClear = () => {
        setModalHeading("Confirm");
        setModalText(`Addtional Mess Expense "${title}" for ${dayjs(date).format('MM-YYYY')}  will be cleared.`);
        setOpen2(true);
    }

    return (
        <div className='w-11/12'>
            <div className='grid grid-cols-2 gap-4 items-center w-6/12'>
                <label>Select Expense : </label>

                <select onChange={e => setTitle(e.target.value)} className='p-3 ring-slate-200 ring-2 rounded-xl outline-none'>
                    {titles?.map(data => {
                        return <option value={data.title}>{`${data.title}`}</option>
                    })}
                </select>

            </div>

            {titles &&

                (
                    <div className="w-full overflow-x-scroll">

                        <div className="flex items-center justify-end mb-5">
                            <button className="bg-red-600 text-white p-3 rounded-lg text-sm mr-5" type='button' onClick={handleClear}>Clear List</button>

                            <button onClick={() => {
                                console.log("GU")
                                downloadExcel()
                            }
                            } className="bg-stone-800 text-white p-3 rounded-lg text-sm mr-5" type='button'>Download as Excel</button>
                            <button onClick={() => setIsModalVisible(true)} type='button' className="bg-stone-800 text-white p-3 rounded-lg text-sm mr-5">
                                Add Expense
                            </button>
                        </div>
                        <Form form={form} component={false}>
                            {additionalExpense && additionalExpense.length ?
                                <Table columns={columns} dataSource={additionalExpense} pagination={{ pageSize: 100 }} rowKey={(record) => record.id} /> :
                                <div className="m-3 flex flex-col justify-center items-center " >
                                    <h2 className="font-bold col-span-2 text-2xl text-black">No Additional Mess Expense List Found !!!</h2>
                                </div>
                            }
                        </Form>
                        <Modal
                            title="Add New Expense"
                            visible={isModalVisible}
                            onOk={() => {
                                form
                                    .validateFields()
                                    .then((values) => {
                                        handleAdd(values);
                                    })
                                    .catch((info) => {
                                        console.log('Validate Failed:', info);
                                    });
                            }}
                            onCancel={() => {
                                setIsModalVisible(false);
                                form.resetFields();
                            }}
                        >
                            <Form
                                form={form}
                                layout="vertical"
                                name="form_in_modal"
                            >
                                {/* <Form.Item
                                    name="expense"
                                    label="Expense Name"
                                    rules={[{ required: true, message: 'Please input the Expense!' }]}
                                >
                                    <Input />
                                </Form.Item> */}
                                <Form.Item
                                    name="hostelAdmissionNo"
                                    label="Hostel Admission No"
                                    rules={[{ required: true, message: 'Please input the Hostel Admission No!' }]}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    name="amount"
                                    label="Amount"
                                    rules={[{ required: true, message: 'Please input the Amount!' }]}
                                >
                                    <Input type="number" />
                                </Form.Item>
                                {/* <Form.Item
                                    name="issuedDate"
                                    label="Issued Date"
                                    rules={[{ required: true, message: 'Please input the Issued Date!' }]}
                                >
                                    <Input type="date" />
                                </Form.Item> */}
                            </Form>
                        </Modal>

                    </div>
                )

            }
            <AlertDialog
                open={open1}
                setOpen={setOpen1}
                modalHeading={modalHeading}
                modalText={modalText}
            />
            <ConfirmDialog
                open={open2}
                setOpen={setOpen2}
                modalHeading={modalHeading}
                modalText={modalText}
                confirmFunction={clearMessExpense}
            />

        </div >
    )
}

export default ViewAdditionalMessExpense