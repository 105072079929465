import React from 'react'
import { useEffect, useState } from "react"
import axios from "axios";
import dayjs from 'dayjs'
import notFound from '../../../images/not-found.jpg'
import * as XLSX from "xlsx";
import { baseUrl } from '../../../baseUrl';
import { Table, Input, DatePicker, Button, message, Popconfirm, Form, Alert, Modal, InputNumber } from 'antd';
import { SearchOutlined, EditOutlined, SaveOutlined, CloseOutlined, DeleteOutlined } from '@ant-design/icons';
import ConfirmDialog from '../../../components/ConfirmDialog';
import AlertDialog from '../../../components/AlertDialog';


function VIewModiefiedAttendanceList({ date, hostel }) {
    const [current_year, setCurrent_year] = useState("All")
    const [titles, setTitles] = useState([])
    const [title, setTitle] = useState("")
    const [invites, setInvites] = useState([])
    const [modifiedAttendaceList, setModifiedAttendaceList] = useState([])
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [form] = Form.useForm();
    const [error, setError] = useState('');


    const [open1, setOpen1] = useState("");
    const [open2, setOpen2] = useState("");
    const [modalText, setModalText] = useState("");
    const [modalHeading, setModalHeading] = useState("");



    const fetchAttendance = () => {
        axios.get(`${baseUrl}/warden/get-alter-attendance`, { params: { date: date, hostel: hostel, title: title } }).then((res) => {
            setModifiedAttendaceList(res.data)
        }).catch((err) => {
            console.log(err)
            // setOpen1(true)

        })
    }
    useEffect(() => {
        fetchAttendance()
    }, [hostel, date, title])


    const downloadExcel = () => {
        const ws = XLSX.utils.json_to_sheet(modifiedAttendaceList);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");
        const excelFileName = `Applicant_List.xlsx`;
        XLSX.writeFile(wb, excelFileName);
    };

    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [editingKey, setEditingKey] = useState('');

    const dateFormat = 'YYYY-MM-DD';


    const handleDelete = (id) => {
        axios.delete(`${baseUrl}/warden/delete-alter-attendance`, { params: { hostel_admission_no: id, date: date } }).then(() => {
            fetchAttendance()
            message.success('Row deleted successfully');
        }).catch(error => {
            message.error('Failed to delete Row');
        });
    };

    const handleAdd = (values) => {
        axios.post(`${baseUrl}/warden/add-alter-attendance`, { ...values, date: date, expense: title }).then(() => {
            setIsModalVisible(false);
            form.resetFields();
            message.success('Attendace added successfully');
            fetchAttendance()
        }).catch(error => {
            message.error(error.response.data.msg);
        });
    };
    const isEditing = (record) => record.hostel_admission_no === editingKey;

    const edit = (record) => {
        setEditingKey(record.hostel_admission_no);
    };

    const cancel = () => {
        setEditingKey('');
    };
    const save = async (key) => {
        try {
            const row = modifiedAttendaceList.find((item) => key === item.hostel_admission_no);
            if (row) {
                console.log('row', row)
                // Here you would typically make an API call to update the data
                // For now, we'll just update the local state
                const res = await axios.put(`${baseUrl}/warden/update-alter-attendance`, { hostel_admission_no: row.hostel_admission_no, attendance: row.attendance, date: date });
                const newData = [...modifiedAttendaceList];
                const index = newData.findIndex((item) => key === item.hostel_admission_no);
                if (index > -1) {
                    newData[index] = row;
                    setModifiedAttendaceList(newData);
                    setEditingKey('');
                    message.success('Attendance updated successfully');
                }
            }
        } catch (errInfo) {
            message.error('Failed to update attendance');
            console.log('Validate Failed:', errInfo);
        }
    };
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <button
                    type="button"
                    onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 90, marginRight: 8 }}
                >
                    Search
                </button>
                <button onClick={() => handleReset(clearFilters)} style={{ width: 90 }}>
                    Reset
                </button>
            </div>
        ),
        filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
                setTimeout(() => this.searchInput.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <span style={{ fontWeight: 'bold' }}>{text}</span>
            ) : (
                text
            ),
    });

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const columns = [
        {
            title: 'Index',
            key: 'index',
            render: (text, record, index) => index + 1,
        },
        {
            title: 'Hostel Admission No',
            dataIndex: 'hostel_admission_no',
            key: 'hostel_admission_no',
            ...getColumnSearchProps('hostel_admission_no'),
        },
        {
            title: 'Inmate Name',
            dataIndex: 'name',
            key: 'name',
            ...getColumnSearchProps('name'),
        },
        {
            title: 'Attendace',
            dataIndex: 'attendance',
            key: 'attendance',
            width: 200,
            render: (text, record) => {
                const editable = isEditing(record);
                return editable ? (
                    <span>

                        <Input
                            type='number'
                            defaultValue={text}
                            onChange={(e) => {
                                if (e.target.value === null) {
                                    setError('Please input a number!');
                                } else if (e.target.value < 0 || e.target.value > dayjs(date).daysInMonth()) {
                                    setError('Enter a valid attendance');
                                } else {
                                    setError('');
                                    console.log('Submitted value:', e.target.value);
                                    // Here you would typically send the value to your backend or perform some action
                                }
                                console.log('e.target.value', e.target.value)
                                record.attendance = e.target.value
                            }} min={1} max={dayjs(date).daysInMonth()} />
                        {error && (
                            <Alert
                                message={error}
                                type="error"
                                showIcon
                                style={{ marginTop: '10px' }}
                            />
                        )}
                    </span>

                ) : (
                    text ? text : 'N/A'
                );
            },

        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => {
                const editable = isEditing(record);
                return editable ? (
                    <span>
                        <Button
                            onClick={() => save(record.hostel_admission_no)}
                            style={{ marginRight: 8 }}
                            icon={<SaveOutlined />}
                        >
                            Save
                        </Button>
                        <Button onClick={cancel} icon={<CloseOutlined />}>
                            Cancel
                        </Button>
                    </span>
                ) : (
                    <span>

                        <Button disabled={editingKey !== ''} onClick={() => edit(record)} icon={<EditOutlined />} style={{ margin: 4 }}>
                            Edit
                        </Button>
                        <Popconfirm
                            title="Are you sure you want to delete this row?"
                            onConfirm={() => handleDelete(record.hostel_admission_no)}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button icon={<DeleteOutlined />} type="danger">
                                Delete
                            </Button>
                        </Popconfirm>
                    </span>

                );
            },
        },

    ];




    const clearAttendanceMonth = () => {
        axios.delete(`${baseUrl}/warden/delete-alter-attendance-month`, { params: { date: date, hostel: hostel }, })
            .then((res) => {
                console.log(res.data)
                setModalHeading("Request Completed ");
                setModalText(`Attendance of ${date} has been Cleared .`);
                fetchAttendance()
                setOpen1(true);
            }).catch(err => {
                console.log('err', err)
                setModalHeading("Request Completed ");
                setModalText(err.response.data.msg);
                setOpen1(true);
            });
    }
    const handleClear = () => {
        setModalHeading("Confirm");
        setModalText(`Attentence for ${date} will be cleared.`);
        setOpen2(true);
    }

    return (
        <div className='w-11/12'>
            {/* <div className='grid grid-cols-2 gap-4 items-center w-6/12'>
                <label>Select Expense : </label>

                <select onChange={e => setTitle(e.target.value)} className='p-3 ring-slate-200 ring-2 rounded-xl outline-none'>
                    {titles?.map(data => {
                        return <option value={data.title}>{`${data.title}`}</option>
                    })}
                </select>

            </div> */}

            <div className="w-full overflow-x-scroll">
                <div className="flex items-center justify-end m-3">
                    <button className="bg-red-600 text-white p-3 rounded-lg text-sm mr-5" type='button' onClick={handleClear}>Clear List</button>
                    <button onClick={() => {
                        console.log("GU")
                        downloadExcel()
                    }
                    } className="bg-stone-800 text-white p-3 rounded-lg text-sm mr-5">Download as Excel</button>
                    <button onClick={() => setIsModalVisible(true)} type='button' className="bg-stone-800 text-white p-3 rounded-lg text-sm mr-5">
                        Add Attendace
                    </button>
                </div>
                <Form form={form} component={false}>
                    {modifiedAttendaceList && modifiedAttendaceList.length ?
                        <Table columns={columns} dataSource={modifiedAttendaceList} pagination={{ pageSize: 100 }} rowKey={(record) => record.hostel_admission_no} /> :
                        <div className="m-3 flex flex-col justify-center items-center " >
                            <h2 className="font-bold col-span-2 text-2xl text-black">No Additional Mess Expense List Found !!!</h2>
                        </div>
                    }
                </Form>
                <Modal
                    title="Add New Expense"
                    visible={isModalVisible}
                    onOk={() => {
                        form
                            .validateFields()
                            .then((values) => {
                                handleAdd(values);
                            })
                            .catch((info) => {
                                console.log('Validate Failed:', info);
                            });
                    }}
                    onCancel={() => {
                        setIsModalVisible(false);
                        form.resetFields();
                    }}
                >
                    <Form
                        form={form}
                        layout="vertical"
                        name="form_in_modal"
                    >
                        {/* <Form.Item
                                    name="expense"
                                    label="Expense Name"
                                    rules={[{ required: true, message: 'Please input the Expense!' }]}
                                >
                                    <Input />
                                </Form.Item> */}
                        <Form.Item
                            name="hostelAdmissionNo"
                            label="Hostel Admission No"
                            rules={[{ required: true, message: 'Please input the Hostel Admission No!' }]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name="attendance"
                            label="Attendance"
                            rules={[{ required: true, message: 'Please input a Attendance!' },
                            { type: 'number', min: 1, max: dayjs(date).daysInMonth(), message: 'Enter a valid attendance' }]}
                        >

                            <InputNumber min={1} max={dayjs(date).daysInMonth()} />
                        </Form.Item>
                        {/* <Form.Item
                                    name="issuedDate"
                                    label="Issued Date"
                                    rules={[{ required: true, message: 'Please input the Issued Date!' }]}
                                >
                                    <Input type="date" />
                                </Form.Item> */}
                    </Form>
                </Modal>
                {/* <Table columns={columns} dataSource={modifiedAttendaceList} pagination={{ pageSize: 100 }} rowKey={(record) => record.hostel_admission_no} /> */}
            </div>

            <AlertDialog
                open={open1}
                setOpen={setOpen1}
                modalHeading={modalHeading}
                modalText={modalText}
            />
            <ConfirmDialog
                open={open2}
                setOpen={setOpen2}
                modalHeading={modalHeading}
                modalText={modalText}
                confirmFunction={clearAttendanceMonth}
            />

        </div >
    )
}

export default VIewModiefiedAttendanceList