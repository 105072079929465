import React, { useContext, useState } from 'react';
import { baseUrl } from '../../../baseUrl';
import axios from 'axios';
import { UserContext } from '../../../Contexts/UserContext';
import { Table, Button, Upload, message } from 'antd';
import { UploadOutlined, EyeOutlined, DownloadOutlined,InfoCircleOutlined,SendOutlined, InboxOutlined } from '@ant-design/icons';
const { Dragger } = Upload;


const BulkUpdateProfiles = () => {
  const [file, setFile] = useState(null);
  const [csvData, setCsvData] = useState([]);
  const [summary, setSummary] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { setLoading } = useContext(UserContext)



  // const handleFileUpload = (info) => {
  //   if (info.file.status === 'done') {
  //     setFile(info.file.originFileObj);
  //     // Here you would typically parse the CSV file and set the data
  //     // For this example, we'll just set some dummy data
  //     setCsvData([
  //       { key: 1, collegeAdmNo: '190192', hostelAdmNo: '19MH050', name: 'John Doe', department: 'CSE', programme: 'UG', yearOfAdmission: '2019', sem: 'S6' },
  //       { key: 2, collegeAdmNo: '190159', hostelAdmNo: '19MH052', name: 'Jane Smith', department: 'ECE', programme: 'UG', yearOfAdmission: '2019', sem: 'S6' },
  //     ]);
  //   }
  // };
  const handleDownloadTemplate = async () => {
    try {
      setSummary(null)
      const response = await axios.get(`${baseUrl}/warden/download-template-bulk-update`, {
        responseType: 'blob',
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'user_deletion_template.csv');
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      setError('Error downloading template: ' + error.message);
    }
  };



  const processEntries = async () => {
    // Logic to process the entries
    setLoading(true)
    setIsLoading(true);
    try {
      const response = await axios.post(`${baseUrl}/warden/process-bulk-update-profile`, { entries: csvData });
      // setProcessedStatus(response.data.status);
      setCsvData(csvData.map(d => {
        return { ...d, STATUS: response.data.status[d['College Admission Number']] || 'Not processed' }
      }))
      setSummary(response.data.summary);

    } catch (err) {


      setError(`Error processing entries: ${err.response?.data?.message || err.message}`);
    } finally {
      setIsLoading(false);
      setLoading(false)

    }
  };

  const props = {
    name: 'file',
    action: `${baseUrl}/warden/upload-update-profile`,
    // method: 'post',
    onChange(info) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
        setFile(info.file.originFileObj)
        setCsvData(info.file.response.map(row => {
          return { ...row, STATUS: 'Not processed' }
        }))
      }
      if (info.file.status === 'done') {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };
  const columns = csvData.length > 0 ? Object.keys(csvData[0]).map(key => ({
    title: key,
    dataIndex: key,
    key: key,
  })) : [];

  return (
    <div className="flex flex-col justify-start w-11/12 overflow-hidden">
      <div className="flex flex-col w-full p-4">
        <div className="mb-8">
          <h4 className="text-lg font-semibold mb-2">Instructions</h4>
          <ul className="list-disc list-inside text-sm text-gray-600 space-y-2">
            <li>Download the template file and fill in the required information</li>
            <li>Ensure all data is accurate and up-to-date</li>
            <li>Do not modify the College Admission Number and Hostel Admission Number</li>
            <li>The file size should not exceed 10MB</li>
            <li>Once uploaded, review the data before final submission</li>
          </ul>
        </div>
        <div className="flex items-center justify-between mb-6">
          <div className='m-3'>

            <Dragger {...props} maxCount={1} accept='.csv,.xlx,.xlsx'>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">Click or drag file to this area to upload</p>
              <p className="ant-upload-hint">
                Support for a single or bulk upload. Strictly prohibited from uploading company data or other
                banned files.
              </p>
            </Dragger>

          </div>
          <Button onClick={handleDownloadTemplate} icon={<DownloadOutlined />} className="bg-gray-800 text-white hover:bg-gray-700">
            Download Template
          </Button>
        </div>

        {file && (
          <div className="bg-blue-50 border border-blue-200 rounded-md p-4 mb-6">
            <div className="flex items-center m-3">
              <InfoCircleOutlined className="text-blue-500 mr-2" />
              <span className="text-sm text-blue-700">File "{file.name}" ready for processing</span>
            </div>
            <Button onClick={processEntries} className="bg-gray-800 text-white hover:bg-gray-700">
              Process Bulk Update
            </Button>
          </div>
        )}

        {summary && (
          <div className="my-4 p-4 bg-green-100 text-green-700 rounded-lg">
            <h3 className="font-bold">Operation Summary</h3>
            <p>Total records processed: {summary.totalRecords}</p>
            <p>Profiles updated: {summary.profilesUpdated}</p>
            <p>Errors: {summary.errors}</p>
          </div>
        )}

        {csvData.length > 0 && (
          <div className="mt-6">
            <h2 className="text-xl font-bold mb-4">File Contents</h2>
            <Table
              columns={columns}
              dataSource={csvData}
              scroll={{ x: true }}
              pagination={{ pageSize: 20 }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default BulkUpdateProfiles;