import React, { useState, useEffect, useContext } from "react";
import { motion } from "framer-motion";
import axios from "axios";

import { baseUrl } from "../../baseUrl";
import ViewCurrentInvitations from "./Components/ViewCurrentInvitations";
import ViewHostelApplications from "./Components/ViewHostelApplications";
import ViewAllotment from "./Components/ViewAllotment";
import PayHDF from "./Components/PayHDF";
import DefineFeeStructure from "./Components/DefineFeeStructure";
import ExtendHdf from "./Components/ExtendHdf";
import ViewHdfExtended from "./Components/ViewHdfExtended";
import DownloadApplicationWD from "./Components/DownloadApplicationWD";
import HostelAdmissionConfig from "./Components/HostelAdmissionConfig";
import { UserContext } from "../../Contexts/UserContext";
import AlertDialog from "../../components/AlertDialog";
import { Layout, Menu, Avatar, Typography, Select } from 'antd';
import ViewRankList from "./Components/ViewRankList";

function HostelAdmission() {
  const [modal, setModal] = useState(null); //modal showing columns
  const backdropClickHandler = (event) => {
    if (event.target === event.currentTarget) {
      setModal(null);
    }
  };
  const [suppliers, setSuppliers] = useState([]);
  const { setLoading } = useContext(UserContext);
  const [inviteStatus, setInviteStatus] = useState(false);

  useEffect(() => {
    axios.get(`${baseUrl}/warden/get-invite-status`).then(res => {
      setInviteStatus(res.data.status)
      console.log('es.data.statu', res.data.status)
    })
  }, []);

  const [openHostel, setOpenHostel] = useState("");
  const [modalTextHostel, setModalTextHostel] = useState("");
  const [modalHeadingHostel, setModalHeadingHostel] = useState("");
  const [tabSelected, setTabSelected] = useState(1);
  useEffect(() => {
    setLoading(true);
    axios
      .get(`${baseUrl}/warden/get-supplier-list`)
      .then((res) => {
        console.log(res.data);
        setSuppliers(res.data.data);
        setLoading(false);
      })
      .catch((er) => {
        console.log(er);
        setLoading(false);
      });
  }, []);
  const menuItems = [
    { key: '1', label: 'View Current Invites' },
    { key: '2', label: 'View Hostel Applications' },
    { key: '3', label: 'View Ranklist' },
    { key: '4', label: 'View Allotment' },
    { key: '5', label: 'Define Fee' },
    { key: '7', label: 'Extend  HDF Deadline' },
    { key: '8', label: 'View Extended HDF' },
    { key: '9', label: 'Download Application' },
    { key: '10', label: 'Admission Configs' },
  ];
  return (
    <div className="flex flex-col w-full items-center min-h-screen h-full overflow-y-scroll">
      <div className="flex flex-row justify-between w-11/12 pt-4 items-center">
        <div className="text-xl font-bold">Hostel Admission</div>
        <div className="flex flex-row space-x-4 items-center">
          <div className="bg-white border rounded-full w-10 aspect-square" />
          <div>user Name</div>
        </div>
      </div>

      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.3 }}
        className="flex flex-col items-center py-8 space-y-4 w-11/12 mt-8 bg-white rounded-xl"
      >
        <div className="w-full">
          <Menu
            className='font-bold w-full justify-start'
            mode="horizontal"
            selectedKeys={[tabSelected.toString()]}
            onSelect={({ key }) => setTabSelected(parseInt(key))}
            items={menuItems}
          />
        </div>

        {/* {tabSelected === 1 && <InviteApplicationWD />} */}
        {tabSelected === 1 && <ViewCurrentInvitations />}
        {tabSelected === 2 && <ViewHostelApplications setTabSelected={setTabSelected} setModalHeadingHostel={setModalHeadingHostel} setModalTextHostel={setModalTextHostel} setOpenHostel={setOpenHostel} />}
        {tabSelected === 3 && <ViewRankList />}
        {tabSelected === 4 && <ViewAllotment />}
        {tabSelected === 6 && <PayHDF />}
        {tabSelected === 5 && <DefineFeeStructure />}
        {tabSelected === 7 && <ExtendHdf />}
        {tabSelected === 8 && <ViewHdfExtended />}
        {tabSelected === 9 && <DownloadApplicationWD />}
        {tabSelected === 10 && <HostelAdmissionConfig />}
        {/* {tabSelected === 15 && <ViewCancelledAllotment />} */}

        {/* {tabSelected === 4 && (
          <MessExpenseList
            paymentinitiated={false}
            suppliers={suppliers}
            setSuppliers={setSuppliers}
          />
        )}
        {tabSelected === 5 && <SupplierList suppliers={suppliers} />} */}
      </motion.div>

      <AlertDialog
        open={openHostel}
        setOpen={setOpenHostel}
        modalHeading={modalHeadingHostel}
        modalText={modalTextHostel}
      />
    </div>
  );
}

export default HostelAdmission;
