import React, { useEffect, useState } from 'react';
import axios from "axios";
import { Table, Input, Button, message, Form, Modal, Popconfirm } from 'antd';
import { SearchOutlined, EditOutlined, SaveOutlined, CloseOutlined, PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import { baseUrl } from '../../../baseUrl';
import { Female } from '@mui/icons-material';

function DefineFeeStructure() {
    const [fees, setFees] = useState([]);
    const [editingKey, setEditingKey] = useState('');
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [form] = Form.useForm();

    useEffect(() => {
        fetchFees();
    }, []);

    const fetchFees = () => {
        axios.get(`${baseUrl}/warden/get-all-fee`).then(res => {
            setFees(res.data);
        });
    };

    const handleAdd = (values) => {
        axios.post(`${baseUrl}/warden/add-fee`, values).then(() => {
            message.success('Fee added successfully');
            setIsModalVisible(false);
            form.resetFields();
            fetchFees();
        }).catch(error => {
            message.error('Failed to add fee');
        });
    };

    const handleDelete = (feeId) => {
        axios.delete(`${baseUrl}/warden/delete-fee`, { params: { feeId: feeId }, }).then(() => {
            message.success('Fee deleted successfully');
            fetchFees();
        }).catch(error => {
            message.error('Failed to delete fee');
        });
    };

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Button
                    type="primary"
                    onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    icon={<SearchOutlined />}
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Search
                </Button>
                <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                    Reset
                </Button>
            </div>
        ),
        filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
    });

    const isEditing = (record) => record.fee_id === editingKey;

    const edit = (record) => {
        form.setFieldsValue({ ...record });
        setEditingKey(record.fee_id);
    };

    const cancel = () => {
        setEditingKey('');
    };

    const save = async (key) => {
        try {
            const row = await form.validateFields();
            const newData = [...fees];
            const index = newData.findIndex((item) => key === item.fee_id);
            if (index > -1) {
                const item = newData[index];
                newData.splice(index, 1, { ...item, ...row });
                await axios.post(`${baseUrl}/warden/update-fee`, { feeId: key, value: row.value ,feeName:row.fee_name});
                setFees(newData);
                setEditingKey('');
                message.success('Fee updated successfully');
            }
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    };

    const columns = [
        {
            title: 'Index',
            key: 'index',
            render: (text, record, index) => index + 1,
        },
        {
            title: 'Fee Name',
            dataIndex: 'fee_name',
            key: 'fee_name',
            ...getColumnSearchProps('fee_name'),            
            editable: true,


        },
        {
            title: 'Value',
            dataIndex: 'value',
            key: 'value',
            editable: true,
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => {
                const editable = isEditing(record);
                const isDeletable = ![13, 11, 12,1].includes(record.fee_id);
                return editable ? (
                    <span>
                        <Button
                            onClick={() => save(record.fee_id)}
                            style={{ marginRight: 8 }}
                            icon={<SaveOutlined />}
                        >
                            Save
                        </Button>
                        <Button onClick={cancel} icon={<CloseOutlined />}>
                            Cancel
                        </Button>
                    </span>
                ) : (
                    <span>
                        <Button disabled={editingKey !== ''} onClick={() => edit(record)} icon={<EditOutlined />} style={{ marginRight: 8 }}>
                            Edit
                        </Button>
                        {isDeletable && (
                            <Popconfirm
                                title="Are you sure you want to delete this fee?"
                                onConfirm={() => handleDelete(record.fee_id)}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button icon={<DeleteOutlined />} type="danger">
                                    Delete
                                </Button>
                            </Popconfirm>
                        )}
                    </span>
                );
            },
        },
    ];

    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record) => ({
                record,
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
            }),
        };
    });

    return (
        <div className="w-full overflow-x-scroll p-3">
            <div className="container mx-auto p-4">
                <button onClick={() => setIsModalVisible(true)} className="flex items-center px-4 py-2 m-2 bg-gray-800 text-white rounded-md hover:bg-gray-700 transition-colors">
                    Add Fee
                </button>
                <Form form={form} component={false}>
                    <Table
                        components={{
                            body: {
                                cell: EditableCell,
                            },
                        }}
                        bordered
                        dataSource={fees}
                        columns={mergedColumns}
                        rowClassName="editable-row"
                        pagination={{
                            pageSize: 20,
                            onChange: cancel,
                        }}
                    />
                </Form>
                <Modal
                    title="Add New Fee"
                    visible={isModalVisible}
                    onOk={() => {
                        form
                            .validateFields()
                            .then((values) => {
                                handleAdd(values);
                            })
                            .catch((info) => {
                                console.log('Validate Failed:', info);
                            });
                    }}
                    onCancel={() => {
                        setIsModalVisible(false);
                        form.resetFields();
                    }}
                >
                    <Form
                        form={form}
                        layout="vertical"
                        name="form_in_modal"
                    >
                        <Form.Item
                            name="feeName"
                            label="Fee Name"
                            rules={[{ required: true, message: 'Please input the fee name!' }]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name="value"
                            label="Value"
                            rules={[{ required: true, message: 'Please input the fee value!' }]}
                        >
                            <Input type="number" />
                        </Form.Item>
                    </Form>
                </Modal>
            </div>
        </div>
    );
}

const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
}) => {
    const inputNode =  <Input />;
    return (
        <td {...restProps}>
            {editing ? (
                <Form.Item
                    name={dataIndex}
                    style={{
                        margin: 0,
                    }}
                    rules={[
                        {
                            required: true,
                            message: `Please Input ${title}!`,
                        },
                    ]}
                >
                    {inputNode}
                </Form.Item>
            ) : (
                children
            )}
        </td>
    );
};

export default DefineFeeStructure;