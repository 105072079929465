import axios from 'axios'
import { useEffect, useContext, useState } from 'react'
import { UserContext } from '../../../Contexts/UserContext'
import { baseUrl } from '../../../baseUrl'
import DateConverter from '../../../Utils/DateConverter'
import AlertDialog from '../../../components/AlertDialog'
import ConfirmDialog from '../../../components/ConfirmDialog'
import * as FileSaver from 'file-saver';
import 'jspdf-autotable';
import XLSX from 'sheetjs-style';


const MessSuspendedListPageClerk = (props) => {
  const { user, setLoading } = useContext(UserContext)
  const [hostel, setHostel] = useState("MH")

  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const mon = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  var formattedDate = `${year}-${mon}`;
  const [month, setMonth] = useState(formattedDate)

  const [hostelAdmissionNo, setHostelAdmissionNo] = useState("")
  const [details, setDetails] = useState({})
  const [selectedData, setSelectedData] = useState({})
  const [open1, setOpen1] = useState(false)
  const [open2, setOpen2] = useState(false)
  const [modalHeading, setModalHeading] = useState("")
  const [modalText, setModalText] = useState("")

  const [changed, setChanged] = useState(false)

  const confirmAlert = (data) => {
    setSelectedData(data)

    setModalHeading("Are you sure ?")
    setModalText("Do you want to revoke the mess suspension for the inmate with Hostel Admission NO : " + data.hostel_admission_no + " and let Mess in From " + DateConverter.parseDateDDMMYYYY(DateConverter.getTommorow()) + " Do you want to continue ?")
    setOpen2(true)

  }

  const handleRevoke = () => {
    setLoading(true)
    const data = {
      hostel_admission_no: selectedData.hostel_admission_no,
      suspension_id: selectedData.id,
      messout_id: selectedData.messout_id
    }

    axios.post(`${baseUrl}/warden/revoke-mess-suspension`, data).then((res) => {
      setChanged(true)
      setModalHeading("Request Success !")
      setModalText("You have succesfully revoked the Mess Suspension for the inmate " + selectedData.hostel_admission_no)
      setOpen1(true)

    }).catch((err) => {
      setModalHeading("Request Failed")
      setModalText("Something went wrong . Could not complete your request .")
      setOpen1(true)
    }).finally(() => {
      setLoading(false)
    })


  }

  useEffect(() => {
    setLoading(true)
    axios.get(`${baseUrl}/warden/get-suspended-list?hostel=${hostel}&&hostel_admission_no=${hostelAdmissionNo}&&month=${month}`).then((res) => {
      setDetails(res.data.data)
    }).catch((err) => {
      alert("Something went wrong")
    }).finally(() => {
      setChanged(false)
      setLoading(false)
    })
  }, [hostel, hostelAdmissionNo, month, changed])

  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset-UTF-8';
  const downloadExcel = () => {

    const columns = ["sl.no", "hostel_admission_no", "name", "fromdate", "todate"];

    const header = ["SL NO", "Hostel Admission NO", "Name", "Suspended from", "Suspended To"];

    if(details.length<1){
      return alert("Nothing to download")
    }
    const rows = details.map((row, index) => {
      return columns.map((column, i) => {
        if (column == "sl.no") {
          return index + 1
        }
        else if (column == "fromdate" || column == "todate") {
          return DateConverter.parseDateDDMMYYYY(row[column])
        }
        return row[column]
      })
    });
    const datas = [header, ...rows]
    const ws = XLSX.utils.json_to_sheet(datas);
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    const date = new Date()
    FileSaver.saveAs(data, `Mess Suspended List ${DateConverter.parseDateDDMMYYYY(date)} .xlsx`)
  }

  return (
    <>

      <div className="m-2 p-2 grid grid-cols-2 w-1/2 gap-4 mb-3">
        <label className="font-bold" htmlFor="">Select Hostel</label>{" "}
        <select
          defaultValue={hostel}
          onChange={(e) => {
            setHostel(e.target.value);
          }}
          className="p-3 ring-slate-200 ring-2 rounded-xl outline-none"
        >
          <option value="MH">Mens Hostel</option>
          <option value="LH">Ladies Hostel</option>
        </select>
      </div>

      {/* <div className="m-2 p-2 grid grid-cols-2 w-1/2 gap-4 mb-3">
        <label className="font-bold" htmlFor="">Select Month</label>{" "}
        <input
          value={month}
          onChange={(e) => {
            setMonth(e.target.value);
          }}
          className="p-3 ring-slate-200 ring-2 rounded-xl outline-none"
        />
      </div> */}
       <div className="m-2 p-2 grid grid-cols-2 w-1/2 gap-4 mb-3">
          <label className="m-2 font-bold">Select Month :</label>
          <input
          type="month"
          value={month}
          onChange={(e)=>{
            setMonth(e.target.value)
          }}
            className="m-2 p-4 ring-slate-200 ring-2 rounded-md outline-none"
          />
           
        </div>

      <div className="m-2 p-2 grid grid-cols-2 w-1/2 gap-4 mb-3">
        <label className="font-bold" htmlFor="">Hostel Admission No</label>{" "}
        <input
          required
          type="text"
          onChange={(e) => {
            setHostelAdmissionNo(e.target.value)
          }}
          className="w-12/12 py-2 px-3 rounded-xl ring-2 ring-slate-300 focus:outline-none"
        />
      </div>
      <div className='w-full'>
        <div className="flex items-center justify-end mb-5">
          <button className="bg-stone-800 text-white p-2 rounded-lg text-sm mr-5" onClick={() => {
            downloadExcel()
          }}>
            Download as Excel
          </button>
        </div>
      </div>

      <div className="flex items-center justify-between w-11/12 py-4">
        <p className="font-semibold">No Of Inmates : {details.length}</p>
      </div>
      <div className='w-11/12 overflow-x-scroll'>
        <table className='w-11/12 relative table-auto'>
          <tr className='rounded-xl p-3 bg-primary text-center'>
            <th className='p-3'>Sl.No</th>
            <th className='p-3'>Name</th>
            <th className='p-3'>College Admission No.</th>
            <th className='p-3'>Hostel Admission No.</th>
            <th className='p-3'>Department</th>
            <th className='p-3'>Programme</th>
            <th className='p-3'>Year of Study</th>
            <th className='p-3'>Suspended From</th>
            <th className='p-3'>Suspended To</th>
            <th className='p-3'>Dues</th>
            <th className='p-3'>Action</th>
          </tr>
          {details.length > 0 && details.map((data, index) => (
            <tr
              key={index}
              className={'border-b text-center border-slate-200 border-solid hover:bg-gray-300'}
            >
              <td className='p-3'>{index + 1}</td>
              <td className='p-3'>{data.name}</td>
              <td className='p-3'>{data.admission_no}</td>
              <td className='p-3'>{data.hostel_admission_no}</td>
              <td className='p-3'>{data.department}</td>
              <td className='p-3'>{data.programme}</td>
              <td className='p-3'>{data.current_year}</td>
              <td className='p-3'>{DateConverter.parseDateDDMMYYYY(data.fromdate)}</td>
              <td className='p-3'>{DateConverter.parseDateDDMMYYYY(data.todate)}</td>
              <td className='p-3'>{data.sum}</td>
              <td className="p-3  text-left whitespace-nowrap">
                <button
                  onClick={() => {
                    confirmAlert(data)
                  }

                  }
                  className={` bg-green-400 hover:bg-green-700 text-white font-bold py-2 px-2 rounded`}

                >
                  Revoke
                </button>

              </td>
            </tr>
          ))}
        </table>
        <AlertDialog
          open={open1}
          setOpen={setOpen1}
          modalHeading={modalHeading}
          modalText={modalText}
        />
        <ConfirmDialog
          open={open2}
          setOpen={setOpen2}
          modalHeading={modalHeading}
          modalText={modalText}
          confirmFunction={handleRevoke}
        />
      </div>
    </>

  )
}
export default MessSuspendedListPageClerk