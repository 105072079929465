import { useDropzone } from "react-dropzone";
import React, { useCallback, useState } from "react";
import axios from "axios";
import { baseUrl } from "../baseUrl";
import * as XLSX from "xlsx";
import AlertDialog from "./AlertDialog";
import ConfirmDialog from "./ConfirmDialog";
import jsPDF from "jspdf";
import "jspdf-autotable";
import * as FileSaver from "file-saver";
import { Link } from "react-router-dom";
import writeXlsxFile from "write-excel-file";

function AddUserExcel(props) {
  const [file, setFile] = useState(null);
  const [selectedHostel, setSelectedHostel] = useState("");
  const [open1, setOpen1] = useState("");
  const [open2, setOpen2] = useState("");
  const [modalText, setModalText] = useState("");
  const [modalHeading, setModalHeading] = useState("");

  async function importDataMh() {
    const extractNumber = (str) => {
      if (typeof str == "number") return str;
      const match = str.match(/\d+/); // \d+ matches one or more digits
      return match ? parseInt(match[0], 10) : 11111;
    };
    try {
      for (const value of Object.values(excelData)) {
        const email = value["Email address"];
        const admission_no = value["College Admission Number"];
        const name = value["Name of the Inmate"];
        console.log("name", name);
        const college_admission_year = value["College Admission Year"];
        const hostel_admission_no = value["Hostel Admission Number"];
        const phone_no = value["Mobile number of the Inmate"];
        const block = value["Block Name"].toLowerCase();
        let room_no = extractNumber(value["Room Number"]);

        console.log("room_no", room_no);
        const admission_category = value["Admission- Category"];
        const egrants_category = value["eGrants - Category"];
        const department = value["Department"];
        const programme = value["Programme"];
        const parent_name = value["Name of the parent"];
        const parent_mobile_number = value["Mobile number of the parent"];
        const designation = "student";
        const hostel = "MH";

        await axios
          .post(`${baseUrl}/warden/create-user`, {
            email: email,
            admission_no: admission_no,
            name: name,
            college_admission_year: college_admission_year,
            hostel_admission_no: hostel_admission_no,
            phone_no: phone_no,
            block: block,
            room_no: room_no,
            admission_category: admission_category,
            egrants_category: egrants_category,
            department: department,
            programme: programme,
            parent_name: parent_name,
            parent_mobile_number: parent_mobile_number,
            designation: "student",
            hostel: hostel,
            block_name: block,
          })
          .then((res) => {
            const updatedRecords = excelData.map((student) => ({
              ...student,
              remark: "Nil",
              status: "Sucess",
            }));

            setExcelData(updatedRecords);

            console.log("res", res);
            setModalHeading("Request Completed ");
            setModalText("Messout Added Successfully .");
            setOpen1(true);
          })
          .catch((err) => {
            // value["Remarks"] = "";
            // value["Status"] = "Failed";

            setExcelData((prevStudentRecords) => {
              return prevStudentRecords.map((record) => {
                if (record["Email address"] === email) {
                  // Update the object with remarks null and status success
                  return { ...record, remarks: "null", status: "success" };
                }
                return record;
              });
            });

            if (err.response.data != null) {
              setExcelData((prevStudentRecords) => {
                return prevStudentRecords.map((record) => {
                  if (record["Email address"] === email) {
                    // Update the object with remarks null and status success
                    return {
                      ...record,
                      remarks: err.response.data.msg,
                      status: "Failed",
                    };
                  }
                  return record;
                });
              });
            } else {
              setExcelData((prevStudentRecords) => {
                return prevStudentRecords.map((record) => {
                  if (record["Email address"] === email) {
                    // Update the object with remarks null and status success
                    return { ...record, remarks: "nill", status: "Failed" };
                  }
                  return record;
                });
              });
            }

            setModalHeading("Error");
            setModalText(err.response.data.msg);
            setOpen1(true);
          });
      }
    } catch (err) {
      console.log(err);
    }
  }
  async function importDataLH() {
    const extractNumber = (str) => {
      if (typeof str == "number") return str;
      const match = str.match(/\d+/); // \d+ matches one or more digits
      if (match == null) return 22222;
      return match ? parseInt(match[0], 10) : 22222;
    };

    try {
      for (const value of Object.values(excelData)) {
        const email = value["Email address"];
        const admission_no = value["College Admission Number"];
        const name = value["Name of the Inmate"];
        console.log("name", name);

        const college_admission_year = value["College Admission Year"];
        const hostel_admission_no = value["Hostel Admission Number"];
        const phone_no = value["Mobile number of the Inmate"];
        const block = value["Block Name"].toLowerCase();
        let room_no = extractNumber(value["Room Number"]);
        const admission_category = value["Admission- Category"];
        const egrants_category = value["eGrants - Category"];
        const department = value["Department"];
        const programme = value["Programme"];
        const parent_name = value["Name of the parent"];
        const parent_mobile_number = value["Mobile number of the parent"];
        const designation = "student";
        const hostel = "LH";

        await axios
          .post(`${baseUrl}/warden/create-user`, {
            email: email,
            admission_no: admission_no,
            name: name,
            college_admission_year: college_admission_year,
            hostel_admission_no: hostel_admission_no,
            phone_no: phone_no,
            block: block,
            room_no: room_no,
            admission_category: admission_category,
            egrants_category: egrants_category,
            department: department,
            programme: programme,
            parent_name: parent_name,
            parent_mobile_number: parent_mobile_number,
            designation: "student",
            hostel: hostel,
            block_name: block,
          })
          .then((res) => {
            setExcelData((prevStudentRecords) => {
              return prevStudentRecords.map((record) => {
                if (record["Email address"] === email) {
                  // Update the object with remarks null and status success
                  return { ...record, remarks: "null", status: "success" };
                }
                return record;
              });
            });

            console.log("res", res);
            setModalHeading("Request Completed ");
            setModalText(" Messout Added Successfully .");
            setOpen1(true);
          })
          .catch((err) => {
            console.log("excelData6", excelData);
            // value["Status"] = "Failed";
            // value["Remarks"] = "";
            console.log("reg", err);
            if (err.response.data != null) {
              setExcelData((prevStudentRecords) => {
                return prevStudentRecords.map((record) => {
                  if (record["Email address"] === email) {
                    // Update the object with remarks null and status success
                    return {
                      ...record,
                      remarks: err.response.data.msg,
                      status: "Failed",
                    };
                  }
                  return record;
                });
              });
            } else {
              setExcelData((prevStudentRecords) => {
                return prevStudentRecords.map((record) => {
                  if (record["Email address"] === email) {
                    // Update the object with remarks null and status success
                    return { ...record, remarks: "nill", ƒstatus: "Failed" };
                  }
                  return record;
                });
              });
            }

            setModalHeading("Error");
            setModalText(err.response.data.msg);
            setOpen1(true);
          });
      }
    } catch (err) {
      console.log("Error", err);
    }
  }

  // onchange states
  const [excelFile, setExcelFile] = useState(null);
  const [typeError, setTypeError] = useState(null);

  // submit state
  const [excelData, setExcelData] = useState(null);

  // onchange event
  const handleFile = (e) => {
    let fileTypes = [
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "text/csv",
    ];
    let selectedFile = e.target.files[0];
    setFile(selectedFile);
    if (selectedFile) {
      if (selectedFile && fileTypes.includes(selectedFile.type)) {
        setTypeError(null);
        let reader = new FileReader();
        reader.readAsArrayBuffer(selectedFile);
        reader.onload = (e) => {
          setExcelFile(e.target.result);
        };
      } else {
        setTypeError("Please select only excel file types");
        setExcelFile(null);
      }
    } else {
      console.log("Please select your file");
    }
  };

  const downloadPDF = () => {
    const pdf = new jsPDF();
    if (excelData.length < 1) return alert("nothing to download");

    const mp = {
      MH: "MENS HOSTEL",
      LH: "LADIES HOSTEL",
    };

    const title = `CET ${mp[selectedHostel]} Student Out List `;
    pdf.text(title, 14, 15);

    const columns = [Object.keys(excelData[0]).map((key) => key)];
    console.log("columns", columns);

    const rows = [
      ...excelData.map((individualExcelData) =>
        Object.keys(individualExcelData).map((key) => individualExcelData[key])
      ),
    ];
    console.log("rows", rows);
    pdf.autoTable({
      styles: { cellPadding: 0.2, fontSize: 5 },
      startY: 5,
      startX: 5,
      head: columns,
      body: rows,
    });

    pdf
      .save(`CET ${mp[selectedHostel]} Student List.pdf`, {
        returnPromise: true,
        type: "blob",
      })
      .then((blob) => {
        FileSaver.saveAs(blob, `CET ${mp[selectedHostel]} Student List.pdf`);
      });
  };

  const downloadExcel = () => {
    const ws = XLSX.utils.json_to_sheet(excelData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");
    const excelFileName = `${selectedHostel}_User_List.xlsx`;
    XLSX.writeFile(wb, excelFileName);
  };
  // submit event
  const handleFileSubmit = (e) => {
    e.preventDefault();
    console.log(excelFile);
    if (excelFile !== null) {
      const workbook = XLSX.read(excelFile, { type: "buffer" });
      const worksheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[worksheetName];
      const data = XLSX.utils.sheet_to_json(worksheet);
      setExcelData(data.slice(0, 10));
    }
    console.log("excelData", excelData);
  };

  const handleClick = async () => {
    setModalHeading("Submit Request");
    setModalText("You wish to continue?");
    setOpen2(true);
  };

  const handleSubmit = async () => {
    if (selectedHostel === "LH") {
      const data = await importDataLH();
      // excelData.map(d =>d.Remarks = "hiii" )
    } else {
      const data = await importDataMh();
    }
  };

  const handleDownloadTemplete = async () => {
    const HEADER_ROW = [
      {
        value: "Email address",
        fontSize: 12,
        width: 40,
      },
      {
        value: "College Admission Number",
        fontSize: 12,
        width: 40,
      },
      {
        value: "College Admission Year",
        fontSize: 12,
        width: 40,
      },
      {
        value: "Hostel Admission Number",
        fontSize: 12,
      },
      {
        value: "Name of the Inmate",
        fontSize: 12,
      },
      {
        value: "Mobile number of the Inmate",
        fontSize: 12,
      },
      {
        value: "Block Name",
        fontSize: 12,
      },
      {
        value: "Room Number",
        fontSize: 12,
      },
      {
        value: "Admission- Category",
        fontSize: 12,
      },
      {
        value: "eGrants - Category",
        fontSize: 12,
      },
      {
        value: "Department",
        fontSize: 12,
      },
      {
        value: "Programme",
        fontSize: 12,
      },
      {
        value: "Specialisation",
        fontSize: 12,
      },
      {
        value: "Name of the parent",
        fontSize: 12,
      },
      {
        value: "Mobile number of the parent",
        fontSize: 12,
      },
    ];

    const data = [
      HEADER_ROW,
      // DATA_ROW_1
    ];

    const file = await writeXlsxFile(data, { buffer: true });
    const workbook = XLSX.read(file, { type: "buffer" });
    // XLSX.writeFile(workbook, "excelFileName.xlsx");

    FileSaver.saveAs(file, `Tempelte.xlsx`);
  };
  const handleRemarks = () => {
    props.setTabSelected(1);
  };

  return (
    <div className="flex flex-col justify-start w-11/12 overflow-hidden">
      <div className="flex items-center justify-between w-full p-4">
        <select
          defaultValue={selectedHostel}
          onChange={(e) => {
            setSelectedHostel(e.target.value);
          }}
          className="p-3 ring-slate-200 ring-2 rounded-xl outline-none"
        >
          <option value="MH">Mens Hostel</option>
          <option value="LH">Ladies Hostel</option>
        </select>
        <div className="w-auto flex">
          <button
            className="m-auto p-3 bg-stone-800 text-white rounded-xl"
            onClick={handleDownloadTemplete}
          >
            Download Template
          </button>
        </div>
      </div>
      <div className="w-full p-5">
        {/* form */}
        <h2 className="font-semibold text-lg m-2">Upload File</h2>
        <div className="m-auto flex justify-center items-center w-full p-4">
          <form
            className="form-group flex justify-between items-center"
            onSubmit={handleFileSubmit}
          >
            {/* <input
              type="file"
              required
              className="w-full"
              onChange={handleFile}
            /> */}
            <div className="flex justify-center items-center">
              <label for="file-input" class="sr-only">Choose file</label>
              <input onChange={handleFile} type="file" name="file-input" id="file-input" className="block border border-gray-200 shadow-sm rounded-lg text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none file:bg-ring-slate-600 file:border-0 file:me-4 file:py-3 file:px-4" />
            </div>
            <div className="flex justify-center items-center w-12/6">
              <div className="flex justify-end items-center gap-2">
                <div className="w-auto flex items-center justify-center">
                  <button
                    type="submit"
                    className="m-auto p-3 bg-stone-800 text-white rounded-xl"
                  >
                    Upload
                  </button>
                </div>
                <div className="w-full auto items-center justify-center ">
                  <button
                    onClick={downloadExcel}
                    className="m-auto p-3 bg-stone-800 text-white rounded-xl"
                  >
                    Download PDF
                  </button>
                </div>
              </div>
            </div>
            {typeError && (
              <div className="alert alert-danger" role="alert">
                {typeError}
              </div>
            )}

          </form>
        </div>


        {/* view data */}
        {excelData ? (
          <div className="w-full overflow-x-scroll">
            <table className="w-full relative table-auto" id="my-table">
              <thead>
                <tr className="rounded-xl p-3 bg-primary text-sm text-center">
                  {Object.keys(excelData[0]).map((key) => (
                    <th key={key} className="p-3">
                      {key}
                    </th>
                  ))}
                </tr>
              </thead>

              <tbody>
                {excelData.map((individualExcelData, index) => (
                  <tr
                    key={index}
                    className={
                      "border-b text-center border-slate-200 text-sm border-solid hover:bg-gray-300"
                    }
                  >
                    {Object.keys(individualExcelData).map((key) => {
                      if (key === "Remarks") {
                        return (
                          <button onClick={handleRemarks}>
                            <td className="p-3 hover:text-md" key={key}>
                              {individualExcelData[key]}
                            </td>
                          </button>
                        );
                      } else {
                        return (
                          <td className="p-3" key={key}>
                            {individualExcelData[key]}
                          </td>
                        );
                      }
                    })}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <div>No File is uploaded yet!</div>
        )}
      </div>

      <div className="w-full flex ">
        <button
          onClick={handleClick}
          type="submit"
          className="ml-auto p-3 bg-stone-800 text-white rounded-xl"
        >
          Submit
        </button>
      </div>
      <ConfirmDialog
        open={open2}
        setOpen={setOpen2}
        modalHeading={modalHeading}
        modalText={modalText}
        confirmFunction={handleSubmit}
      />
      {/* <AlertDialog
        open={open1}
        setOpen={setOpen1}
        modalHeading={modalHeading}
        modalText={modalText}
      /> */}
    </div>
  );
}

export default AddUserExcel;
