import { useState, useEffect, useContext } from "react";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import axios from "axios";

import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import AlertDialog from "../../../components/AlertDialog";
import ConfirmDialog from "../../../components/ConfirmDialog";
import { baseUrl } from "../../../baseUrl";
import DateConverter from "../../../Utils/DateConverter";
import { UserContext } from "../../../Contexts/UserContext";
import { Link } from "react-router-dom";
function ClerkUpdateMessBillPayments() {
  const { user, setLoading } = useContext(UserContext)

  const [hostel,setHostel]=useState("LH")
  const [status, setStatus] = useState(0);
  const [messBillList, setMessBillList] = useState([])
  const [hostelAdmissionNo, setHostelAdmissionNo] = useState(null)


  useEffect(() => {
    setLoading(true);
    axios.get(`${baseUrl}/warden/get-inmate-bill-details?hostel_admission_no=${hostelAdmissionNo}&&status=${status}&&hostel=${hostel}`).then((res) => {
      setMessBillList(res.data.data)
    }).catch((err) => {
      setMessBillList([])
      alert("something went wrong")
    }).finally(() => {
      setLoading(false)
    })
  }, [hostelAdmissionNo, status,hostel])


  return (
    <>
      <div className="w-full m-5 p-4">
        <div className="m-2 p-2 grid grid-cols-2 w-100 gap-4 mb-3">
          <label className="font-bold" htmlFor="">Hostel Admission No</label>{" "}
          <input
            required
            type="text"
            onChange={(e) => {
              setHostelAdmissionNo(e.target.value)
            }}
            className="w-11/12 py-2 px-3 rounded-xl ring-2 ring-slate-300 focus:outline-none"
          />
        </div>
        <div className="flex items-center justify-between w-4/12">
          <select
            onChange={(e) => {
              setStatus(e.target.value)
            }}
            className="m-2 p-4 ring-slate-200 ring-2 rounded-md outline-none"
          >
            <option value={0}>Not Paid</option>
            <option value={1}>Paid</option>
          </select>
        </div>
        <div className="flex items-center justify-between w-4/12">
          <select
            onChange={(e) => {
              setHostel(e.target.value)
            }}
            className="m-2 p-4 ring-slate-200 ring-2 rounded-md outline-none"
          >
            <option value={"LH"}>Ladies Hostel</option>
            <option value={"MH"}>Mens Hostel</option>
          </select>
        </div>
        <div className="flex items-center justify-end mb-5">
          <button className="bg-stone-800 text-white p-2 rounded-lg text-sm mr-5" onClick={() => {

          }}>
            Download as Excel
          </button>
        </div>

        <div className="flex items-center justify-end mb-5">
          <button className="bg-stone-800 text-white p-2 rounded-lg text-sm mr-5" onClick={() => {
          }}>
            Download as PDF
          </button>
        </div>
        <h2 className="text-black font-semibold text-lg mt-5 mb-3">
          Mess Bills
        </h2>
        <div className="flex items-center justify-between w-4/12 py-4">
          <p className="font-semibold">No Of Mess Bill :</p>
          <p className="font-semibold">{messBillList.length} </p>
        </div>
        <div className='w-12/12 overflow-x-scroll'>
          <table className="w-full relative table-auto">
            <tr className="rounded-xl p-3 bg-primary text-center">
              <th className="p-2">Sl.No</th>
              <th className="p-2">Hstl Admsn No.</th>
              <th className="p-2">Name</th>
              <th className="p-2">Bill Issue Date</th>
              <th className="p-2">Mess Bill Month</th>
              <th className="p-2">Attendance</th>
              <th className="p-2">Total Amount</th>
              <th className="p-2">Remaining Amount</th>
              <th className="p-2">Update Payment </th>


            </tr>
            {messBillList && messBillList.map((messbill, index) => {
              return (
                <tr
                  key={index}
                  className={
                    "border-b text-center border-slate-200 border-solid hover:bg-gray-300"
                  }
                >
                  <td className="p-3 text-sm">{index + 1}</td>
                  <td className="p-3 text-sm">{messbill.hostel_admission_no}</td>
                  <td className="p-3 text-sm">{messbill.name}</td>
                  <td className="p-3 text-sm">{DateConverter.parseDateDDMMYYYY(messbill.bill_issued_date)}</td>
                  <td className="p-3 text-sm">{messbill.month + " - " + messbill.year}</td>
                  <td className="p-3 text-sm ">{messbill.attendance}</td>
                  <td className="p-3 text-sm">{messbill.total_amount}</td>
                  <td className="p-3 text-sm">{messbill.remaining_amount}</td>
                  <td className="p-3  text-left whitespace-nowrap">
                    <Link
                    to={`/HO/update-transaction-details/${messbill.id}`}
                      className={` bg-green-400 hover:bg-green-700 text-white font-bold py-2 px-2 rounded`}
                     
                    >
                  Update Payment
                    </Link>
  
                  </td>

                </tr>
              );
            })}
          </table>
        </div>
      </div>
    </>
  );
}

export default ClerkUpdateMessBillPayments;
