import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import dayjs from 'dayjs';
import { Autocomplete, TextField, Button, MenuItem, FormControl, InputLabel } from '@mui/material';
import { Table, Input, Select } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { baseUrl } from '../../../baseUrl';
import ConfirmDialog from '../../../components/ConfirmDialog';
import AlertDialog from '../../../components/AlertDialog';
import { UserContext } from '../../../Contexts/UserContext';
import * as XLSX from 'xlsx';


function ViewParentDetails() {
    const { setLoading, user } = useContext(UserContext);
    const [viewOption, setViewOption] = useState('individual');
    const [hostelType, setHostelType] = useState('MH');
    const [hostelAdmissionNo, setHostelAdmissionNo] = useState('');
    const [open1, setOpen1] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [modalText, setModalText] = useState('');
    const [modalHeading, setModalHeading] = useState('');
    const [inmates, setInmates] = useState([]);
    const [inmate, setInmate] = useState({});
    const [allParentDetails, setAllParentDetails] = useState([]);


    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');


    useEffect(() => {
        axios.get(`${baseUrl}/warden/get-all-inmates`).then((res) => {
            setInmates(res.data);
        });
    }, []);


    useEffect(() => {
        axios.get(`${baseUrl}/warden/all-parent-details`, { params: { hostelType: hostelType } })
            .then((res) => {
                setAllParentDetails(res.data);
            })
    }, [hostelType]);

    const handleOptionChange = (event, newValue) => {
        if (newValue) {
            axios.get(`${baseUrl}/warden/parent-details`, { params: { HostelAdmissionNo: newValue.hostel_admission_no } })
                .then((res) => {
                    setInmate(res.data);
                })
                .catch(err => {
                    setLoading(false);
                    setModalHeading("Request Completed");
                    setModalText(err.response.data.msg);
                    setOpen1(true);
                });
            setHostelAdmissionNo(newValue.hostel_admission_no);
        }
    };

    const handleViewOptionChange = (event) => {
        setViewOption(event.target.value);
        if (event.target.value === 'all') {
            setInmate({});
            setHostelAdmissionNo('');
        }
    };

    const handleHostelTypeChange = (value) => {
        setHostelType(value);
    };


    const downloadExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(allParentDetails);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Parent Details");
        XLSX.writeFile(workbook, "parent_details.xlsx");
    };
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <button
                    type="button"
                    onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 90, marginRight: 8 }}
                >
                    Search
                </button>
                <button onClick={() => handleReset(clearFilters)} style={{ width: 90 }}>
                    Reset
                </button>
            </div>
        ),
        filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
                setTimeout(() => this.searchInput.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <span style={{ fontWeight: 'bold' }}>{text}</span>
            ) : (
                text
            ),
    });

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const columns = [
        {
            title: 'Index',
            key: 'index',
            render: (text, record, index) => index + 1,
        },
        {
            title: 'Admission No',
            dataIndex: 'admission_no',
            key: 'admission_no',
            ...getColumnSearchProps('admission_no'),
        },
        {
            title: 'College Admission No',
            dataIndex: 'hostel_admission_no',
            key: 'hostel_admission_no',
            ...getColumnSearchProps('hostel_admission_no'),
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            ...getColumnSearchProps('name'),
        },
        {
            title: 'Parent Name',
            dataIndex: 'parent_name',
            key: 'parent_name',
            ...getColumnSearchProps('parent_name'),

        },
        {
            title: 'Parent Number',
            dataIndex: 'parent_number',
            key: 'parent_number',
            ...getColumnSearchProps('parent_number'),
        },

    ];
    return (
        <div className="w-11/12">
            <h1 className="text-2xl font-bold mb-4">View Parent Details</h1>

            {/* <FormControl margin="normal">
                <InputLabel>View Option</InputLabel>
                <Select value={viewOption} onChange={handleViewOptionChange} label="View Option">
                <MenuItem value="individual">Individual</MenuItem>
                <MenuItem value="all">All</MenuItem>
                </Select>
                </FormControl> */}


            <div>
                {/* <FormControl margin="normal">
                    <InputLabel>Select Hostel</InputLabel>
                    <Select value={hostelType} onChange={handleHostelTypeChange} defaultValue="MH" fullWidth label="Select Hostel">
                    <MenuItem value="MH">Men's Hostel</MenuItem>
                    <MenuItem value="LH">Ladies' Hostel</MenuItem>
                    </Select>
                    </FormControl> */}


                <div className="flex justify-between">

                    <Select onChange={handleHostelTypeChange} defaultValue="MH">
                        <Select.Option value="MH">Men's Hostel</Select.Option>
                        <Select.Option value="LH">Ladies' Hostel</Select.Option>
                    </Select>
                    <Button variant="contained" onClick={downloadExcel} style={{ marginLeft: '10px' }}>
                        Download as Excel
                    </Button>
                </div>

                {/* <Button variant="contained" onClick={fetchAllParentDetails} disabled={!hostelType}>
                        Fetch All Parent Details
                    </Button> */}
                {/* {allParentDetails.length > 0 && (
                    <div className="mt-6">
                        <h2 className="text-xl font-bold mb-4">File Contents</h2>
                        <Table
                            columns={Object.keys(allParentDetails[0]).map(key => ({
                                title: key,
                                dataIndex: key,
                                key: key,
                            }))}
                            dataSource={allParentDetails}
                            scroll={{ x: true }}
                            pagination={{ pageSize: 20 }}
                        />
                    </div>
                )} */}
                <div className="container mx-auto p-4">
                    <Table columns={columns} dataSource={allParentDetails} rowKey={(record) => record.hostel_admission_no} />
                </div>
            </div>

            <AlertDialog open={open1} setOpen={setOpen1} modalHeading={modalHeading} modalText={modalText} />
            <ConfirmDialog open={open2} setOpen={setOpen2} modalHeading={modalHeading} modalText={modalText} />
        </div>
    );
}

export default ViewParentDetails;