
import axios from "axios";
import { useState, useContext, useEffect } from "react";
import { UserContext } from "../Contexts/UserContext";
import AlertDialog from "./AlertDialog";
import ConfirmDialog from "./ConfirmDialog";
import { baseUrl } from "../baseUrl";
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';


function AddAdditionalMessExpenseForm({setRes, date}) {

  const {setLoading}=useContext(UserContext)
  // const [date,setDate]=useState()
  const [file,setFile]=useState(null)
  const [title,setTitle]=useState("")
  const [modalText, setModalText] = useState("");
  const [modalHeading, setModalHeading] = useState("");
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const downloadExcel=async()=>{
    const wb = XLSX.utils.book_new();
    const columns= ["Sl. No", "Hostel Admission Number", "Name", "Charges"]
    const ws = XLSX.utils.aoa_to_sheet([columns]);
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    const wbBlob = new Blob([XLSX.write(wb, { type: 'array', bookType: 'xlsx' })], { type: 'application/octet-stream' });
    
    FileSaver.saveAs(wbBlob,`Mess-Additional-Expense-Template.xlsx`)

}

  const handleSubmit=(e)=>{
    e.preventDefault();
    setModalHeading("Are you sure ");
    setModalText("Please Ensure that you have selected the correct file for uploading . Once Done this changes cannot be reverted .")
    setOpen2(true);
  }

  const uploadAdditionalExpense=async()=>{
    setLoading(true);
    if (!file) {
      setModalHeading("No file found")
      setModalText("Please select a file and try again .")
      setOpen1(true)
      return;
    }

    const formData = new FormData();
    formData.append('date',date)
    formData.append('title', title);
    formData.append('excelFile', file);

    axios.post(`${baseUrl}/warden/upload-mess-additional-expense`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    .then(response => {
      setRes(response.data.data)
      setModalHeading("File Uploaded")
      setModalText("Mess Additional Expense Added succesfully")
      setOpen1(true)
    })
    .catch(error => {
      setModalHeading("Something went wrong")
      setModalText("please try again later")
      setOpen1(true)
    }).finally(()=>{
      setLoading(false)
    });
  }

  return (
    <div className="mb-3">
       <div className="w-full flex items-end justify-end mt-5">
          <button
            type="button"
            onClick={()=>{
              downloadExcel()
            }}
            className="ml-auto p-3 bg-green-700 text-white rounded-xl"
          >
           Download Excel Template
          </button>
        </div>
      <h2 className="font-semibold text-lg mb-2">
        Upload Mess Additional Expense
      </h2>
      <form >
      {/* <div className=" mt-4  grid grid-cols-2 w-100 gap-4 mb-3">
          <label className="font-bold" htmlFor="">Month for which Bill is calculated:</label>{" "}
          <input
            required
            type="month"
            onChange={(e) => {
              setDate(e.target.value)
            }}
            className="w-11/12 py-2 px-3 rounded-xl ring-2 ring-slate-300 focus:outline-none"
          />
      
        </div> */}
      <div className="mt-4  flex w-full justify-space-between items-center " >
              <input
                required
                className="w-6/12 m-2 py-2 px-3 rounded-xl ring-2 ring-slate-300 focus:outline-none"
                type="text"
                placeholder="title"
                onChange={(e) => {
                    setTitle(e.target.value)
                }}
              />
              <input
                required
                className="w-6/12 m-2 py-2 px-3 rounded-xl ring-2 ring-slate-300 focus:outline-none"
                type="file"
                onChange={(e)=>{
                    setFile(e.target.files[0])
                }}
                placeholder="Select File"
              
              />
            </div>
        <div className="w-full flex items-end justify-end mt-5">
          <button
            onClick={handleSubmit}
            className="ml-auto p-3 bg-stone-800 text-white rounded-xl"
          >
            Upload Mess Additional Expense
          </button>
        </div>
      </form>
      <AlertDialog
        open={open1}
        setOpen={setOpen1}
        modalHeading={modalHeading}
        modalText={modalText}
      />
      <ConfirmDialog
        open={open2}
        setOpen={setOpen2}
        modalHeading={modalHeading}
        modalText={modalText}
        confirmFunction={uploadAdditionalExpense}
      />



    </div>
  );

}
export default  AddAdditionalMessExpenseForm;
