import { useState, useEffect } from 'react'

import AddAdditionalMessExpenseForm from './AddAdditionalExpenseForm';
import MessAdditionalExpenseStatusList from './MessAdditionalExpenseStatusList';
function AddAdditionalMessExpensePage({date}) {


  const [calculatedMessBillList, setCalculatedMessBillList] = useState([]);
  const [res, setRes] = useState([])

  return (
    <div className='w-11/12'>
      <AddAdditionalMessExpenseForm setRes={setRes} date={date} />
      <hr />
      {res.length > 0 && <MessAdditionalExpenseStatusList res={res} date={date} />}
    </div>

  )
}

export default AddAdditionalMessExpensePage