
const dateConverter = (inputdate) => {
    const date = new Date(inputdate);
    let month = (date.getMonth() + 1).toString();
    let day = date.getDate().toString();
    let year = date.getFullYear();
    if (month.length < 2) {
      month = "0" + month;
    }
    if (day.length < 2) {
      day = "0" + day;
    }
    console.log( [year, month, day].join("-"))
    return [year, month, day].join("-");
  };
  const parseDateDDMMYYYY = (inputdate) => {
    const date = new Date(inputdate);
    let month = (date.getMonth() + 1).toString();
    let day = date.getDate().toString();
    let year = date.getFullYear();
    if (month.length < 2) {
      month = "0" + month;
    }
    if (day.length < 2) {
      day = "0" + day;
    }
    return day + "-" + month + "-" + year;
  };

  const getToday = () => {
    const date = new Date();
    date.setDate(date.getDate());
    let month = (date.getMonth() + 1).toString();
    let day = (date.getDate()).toString();
    let year = date.getFullYear();
    if (month.length < 2) {
      month = "0" + month;
    }
    if (day.length < 2) {
      day = "0" + day;
    }
    return year + "-" + month + "-" + day;
  };
  const getTommorow=()=>{
    const date = new Date();
    date.setDate(date.getDate()+1);
    let month = (date.getMonth() + 1).toString();
    let day = (date.getDate()).toString();
    let year = date.getFullYear();
    if (month.length < 2) {
      month = "0" + month;
    }
    if (day.length < 2) {
      day = "0" + day;
    }
    return year + "-" + month + "-" + day;
  }
  const getMonthName = (monthNumber) => {
    const months = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];
  
    // Check if the provided monthNumber is within a valid range
    if (monthNumber >= 1 && monthNumber <= 12) {
      // Arrays are zero-indexed, so we subtract 1 to get the correct index
      return months[monthNumber - 1];
    } else {
      // Handle invalid monthNumber
      return "Invalid month number. Please provide a number between 1 and 12.";
    }
  };

  function trimTransactionID(str) {
    const parts = str.split('--');
    return parts[0];
}
  
  export default {dateConverter,parseDateDDMMYYYY,getToday,getTommorow,getMonthName,trimTransactionID};