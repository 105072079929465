import { useState, useEffect, useContext } from "react";
import axios from "axios";
import * as XLSX from "xlsx";
import { Button } from "@mui/material";
import { createTheme } from '@mui/material/styles';
import dayjs from 'dayjs'
import notFound from "../../../images/not-found.jpg"
import { baseUrl } from "../../../baseUrl";
import { UserContext } from "../../../Contexts/UserContext";
import ConfirmDialog from "../../../components/ConfirmDialog";
import AlertDialog from "../../../components/AlertDialog";


const theme = createTheme({
  palette: {
    primary: {
      light: '#757ce8',
      main: '#3f50b5',
      dark: '#002884',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ff7961',
      main: '#f44336',
      dark: '#ba000d',
      contrastText: '#000',
    },
  },
});


function ViewHostelApplications({ setTabSelected, setModalHeadingHostel, setModalTextHostel, setOpenHostel }) {
  const [applications, setApplications] = useState([]);
  const [status, setStatus] = useState({})
  const { setLoading } = useContext(UserContext);

  const [hostel, setHostel] = useState("MH")
  const [current_year, setCurrent_year] = useState("1")
  const [programme, setProgramme] = useState("UG")
  const [excelData, setExcelData] = useState(null);



  const [open1, setOpen1] = useState("");
  const [open2, setOpen2] = useState("");
  const [open3, setOpen3] = useState("");
  const [modalText, setModalText] = useState("");
  const [modalHeading, setModalHeading] = useState("");

  const [modal, setModal] = useState(true); //modal showing columns
  const backdropClickHandler = (event) => {
    if (event.target === event.currentTarget) {
      setModal(null);
    }
  };



  const generateProvisionalHandler = () => {

    const today = dayjs()
    const publish_provisional = today > dayjs(status?.close_in) ? today.format('YYYY-MM-DD') : dayjs(status?.close_in).format('YYYY-MM-DD')
    console.log('publish_provisional', publish_provisional)

    axios.get(`${baseUrl}/warden/provisional-ranklist`, { params: { hostel: hostel, current_year: current_year, programme: programme } })
      .then((res) => {
        console.log(res.data)
        setModalHeading("Request Completed ");
        setModalText("Provisionl Ranklist Generated Successfully .");
        setOpen1(true);
      }).catch(err => {
        console.log('err', err)
        setModalHeading("Request Completed ");
        setModalText(err.respose.data.message);
        setOpen1(true);
      });


  };
  const generateFinalHandler = () => {
    axios.get(`${baseUrl}/warden/final-ranklist`, { params: { hostel: hostel, current_year: current_year, programme: programme } })
      .then((res) => {
        console.log(res.data)
        setModalHeading("Request Completed ");
        setModalText("Final Ranklist Generated Successfully .");
        setOpen1(true);
      }).catch(err => {
        console.log('err', err)
        setModalHeading("Request Completed ");
        setModalText(err.respose.data.message);
        setOpen1(true);
      });


  };

  useEffect(() => {
    axios.get(`${baseUrl}/warden/get-hostel-applicants`, { params: { hostel: hostel, current_year: current_year, programme: programme } }).then((res) => {
      setApplications(res.data);
      console.log('res.datvhjva', res.data == [])
    });
  }, [hostel, current_year, programme])

  useEffect(() => {
    axios.get(`${baseUrl}/warden/get-ranklist-status`, { params: { hostel: hostel, current_year: current_year, programme: programme } }).then((res) => {
      setStatus(res.data);
      console.log('res.data', res.data)
    });
  }, [hostel, current_year, programme])


  const downloadExcel = () => {
    const ws = XLSX.utils.json_to_sheet(applications);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");
    const excelFileName = `Applicant_List.xlsx`;
    XLSX.writeFile(wb, excelFileName);
  };
  // submit event
  const [isShown, setIsShown] = useState(false);
  const [isButtonDisabled, setButtonDisabled] = useState(false);

  const [admiConfigs, setAdminConfigs] = useState()
  useEffect(() => {
    axios.get(`${baseUrl}/warden/get-hostel-admin-configs`).then(res => {
      console.log('res.data', res.data)
      setAdminConfigs(res.data)
    })
  }, [])

  return (
    <div className='w-11/12'>
      {modal && modal}
      <div className='flex gap-3 items-center w-6/12'>
        <select onChange={e => setHostel(e.target.value)} className='p-3 ring-slate-200 ring-2 rounded-xl outline-none'>
          <option value="MH">Mens Hostel</option>
          <option value="LH">Ladies Hostel</option>
        </select>
        <select onChange={e => setCurrent_year(e.target.value)} className='p-3 ring-slate-200 ring-2 rounded-xl outline-none'>
          <option value="1">First Year</option>
          <option value="2">Second Year</option>
          <option value="3">Third Year</option>
          <option value="4">Fourth Year</option>
        </select>

        <select onChange={e => setProgramme(e.target.value)} className='p-3 ring-slate-200 ring-2 rounded-xl outline-none'>
          <option value="UG">UG</option>
          <option value="PG">PG</option>
          <option value="Phd">Phd</option>
        </select>
      </div>


      {applications && applications.length ?

        <>
          <div className="flex items-center justify-end m-5 ">

            <button onClick={() => downloadExcel()} className="bg-stone-800 text-white p-3 font-medium rounded-lg text-sm mr-5">Download as Excel</button>

            {/* {status?.provisional_rank_list == false && <button onClick={() => {
              if (status?.change_precondtions_provisional == true) {
                setModalHeadingHostel("Alert")
                setModalTextHostel(`There should be a minimum gap of ${admiConfigs.gap_provisional_concern} days between the date of publishing provisional rank list and the deadline for submitting concerns. If you want to change this date, please edit concern date accordingly`)
                setOpenHostel(true)
                setTabSelected(4)

              }
              else {

                setModalHeading("Confirm")
                setModalText("Do you wish to continue.Once Provsional Ranklist is published you can not perform any modification in the Provsional Ranklist ")
                setOpen2(true)
              }

            }
            }
              disabled={status?.enable_provisional === false}
              className={status?.enable_provisional === true ? "bg-stone-800 text-white p-3 rounded-lg text-sm" : "bg-[#e0e0e0] p-3 text-[#a6a6a6] rounded-lg text-sm"}>Publish Provisional Rank List</button>}

            {status?.provisional_rank_list == true && status?.final_rank_list == false && <button onClick={() => {
              // if (status?.change_precondtions_final == true) {
              //   setModalHeadingHostel("Alert")
              //   setModalTextHostel(`Change Date to ${dayjs().format("DD-MM-YYYY")} `)
              //   setOpenHostel(true)
              //   setTabSelected(4)

              // }


              setModalHeading("Confirm")
              setModalText("Do you wish to continue.Once Final Ranklist is published you can not perform any modification in the Final Ranklist ")
              setOpen3(true)

            }}
              onMouseEnter={() => {
                setIsShown(true)
                console.log(isShown)
              }}
              onMouseLeave={() => {
                setIsShown(false)
                console.log(isShown)
              }}
              disabled={status?.enable_final === false}
              className={status?.enable_final === true ? "bg-stone-800 text-white p-3 rounded-lg text-sm" : " bg-[#e0e0e0] p-3 text-[#a6a6a6] rounded-lg text-sm"}>Publish Final Rank List</button>} */}

          </div>
          <>
            <div className="w-full overflow-x-scroll">
              <table className="w-full relative table-auto">
                <tr className="rounded-xl p-3 bg-primary text-center">
                  <th className="p-3">Sl.No</th>
                  <th className="p-3">Admission No.</th>
                  <th className="p-3">Department</th>
                  <th className="p-3">Batch</th>
                  <th className="p-3">Admission Category </th>
                  <th className="p-3">District </th>
                  <th className="p-3">Type of Admission</th>
                  <th className="p-3">Income</th>
                  <th className="p-3">Distance</th>
                </tr>

                {applications?.map((user, index) => (
                  <tr
                    key={index}
                    className={
                      "border-b text-center border-slate-200 border-solid hover:bg-gray-300"
                    }
                  >
                    <td className="p-3">{index + 1}</td>
                    <td className="p-3">{user.admission_no}</td>
                    <td className="p-3">{user.department}</td>
                    <td className="p-3">{user.batch}</td>
                    <td className="p-3">{user.admission_category}</td>
                    <td className="p-3">{user.district}</td>
                    <td className="p-3">{user.type_of_admission}</td>
                    <td className="p-3 capitalize">{user.afi}</td>
                    <td className="p-3">{user.distance}</td>
                  </tr>

                ))}

              </table>
            </div>
          </>
        </> : <div className="m-3 flex flex-col justify-center items-center " >
          <h2 className="font-bold col-span-2 text-2xl text-red-600">No Applications Found !!!</h2>
          <img src={notFound} className="w-11/12" />
        </div>

      }

      <AlertDialog
        open={open1}
        setOpen={setOpen1}
        modalHeading={modalHeading}
        modalText={modalText}
      />

      <ConfirmDialog
        open={open2}
        setOpen={setOpen2}
        modalHeading={modalHeading}
        modalText={modalText}
        confirmFunction={generateProvisionalHandler}
      />
      <ConfirmDialog
        open={open3}
        setOpen={setOpen3}
        modalHeading={modalHeading}
        modalText={modalText}
        confirmFunction={generateFinalHandler}
      />

    </div>
  );
}

export default ViewHostelApplications;
