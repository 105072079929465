import { useState, useEffect, useContext } from "react";
import { motion } from "framer-motion";
import { UserContext } from "../Contexts/UserContext";
import axios from "axios";
import { baseUrl } from "../baseUrl";
import AlertDialog from "./AlertDialog";
import ConfirmDialog from "./ConfirmDialog";
import dateUtils from '../Utils/DateConverter'
import nothing_to_show from "../images/nothing_to_show.jpg"
import { Link } from "react-router-dom";


function InmateBillListComponent() {

  const { setLoading, user } = useContext(UserContext);
  const [userBillList, setUserBillList] = useState([])
  const [status, setStatus] = useState(0)
  const [open, setOpen] = useState(false);
  const [modalHeading, setModalHeading] = useState("");
  const [modaltext, setModalText] = useState("");
  const[totalSum,setTotalSum]=useState(0)
 
  useEffect(() => {
    setLoading(true);
    axios.get(`${baseUrl}/inmate/get-mess-bills?user_id=${user.user_id}&&status=${status}`).then((res) => {
      setUserBillList(res.data.data)
      setTotalSum(res.data.sum)
    }).catch((err) => {
      console.log(err)

    }).finally(() => {
      setLoading(false)
    })
  }, [status])


  return (
    <>
      <div className="w-11/12  mb-20 ">

        <div className="flex items-center justify-between w-full">
          <select
            onChange={(e) => {
              setStatus(e.target.value)
            }}
            className="w-1/2 p-3 ring-slate-200 ring-2 rounded-xl outline-none"
          >
            <option value={0}>Pending Mess Bills</option>
            <option value={3}>Processing Mess Bills</option>
            <option value={1}>Cleared Mess Bills</option>
          </select>
        </div>
        <div className="m-3  items-centerfixed top-0 right-0 m-4 bg-blue-500 text-white p-4 rounded-md justify-between w-full">
          <div className="bg-blue-500 font-bold text-lg text-white p-4 rounded-md">
            Total Amount : {totalSum}
          </div>
          <div className="bg-blue-500 font-bold text-lg text-white p-4 rounded-md">
            No of Bills  : {userBillList.length}
          </div>
        </div>


        {userBillList.length == 0 ? <div className=" h-full flex flex-col justify-center items-center ">
          <div className="">
            <img className="h-64" src={nothing_to_show}></img>
          </div>
          <div className="">
            <p className="font-bold "> Nothing to show here</p>
          </div>



        </div> :
          userBillList.map((bill, index) => {
      
            return (
              <motion.div

                key={index}
                whileHover={{ scale: 1.02 }}
                className="md:flex sm:p-2 items-center justify-between w-12/12 md:h-24 bg-gray-100 py-3 cursor-pointer mb-3 rounded-md p-2 mt-5 m-2"
              >
                {/* <div className="block">
                <p className="flex">
                  <p className="">Published Date</p> :{index}
                </p>
                <p className="flex">
                  <p className="">Bill Month</p> :{index}
                </p>
                </div> */}
                <p className="flex ">
                  <p className="font-semibold">Bill Date </p> : {dateUtils.parseDateDDMMYYYY(bill.bill_issued_date)}
                </p>

                <p className="flex">
                  <p className="font-semibold">Bill Month </p> : {bill.month + " - " + bill.year}
                </p>
                <p className="flex">
                  <p className="font-bold">Total </p> :{bill.total_amount}
                </p>
                <p className="flex">
                  <p className="font-bold">Remaining </p> :{bill.remaining_amount}
                </p>
                <p className="flex">
                  <p className="font-bold">Fine </p> :{bill.fine}
                </p>
                <p className="flex">
                  <p className="font-bold">Sum </p> :{parseFloat(bill.remaining_amount) + parseFloat(bill.fine)}
                </p>
                <p className="flex shadow-md rounded-md  bg-white" >
                  {
                    status == 3 ? <Link onClick={(e) => {
                      e.preventDefault()
                      setModalHeading("Cannot Make Payment ")
                      setModalText("The Transaction For This Mess Bill Paid On " + dateUtils.parseDateDDMMYYYY(bill.paid_date) + " Is Being Processed . You Cannot Make Further Updates Until It Is Processed . Please Try Again Later !")
                      setOpen(true)
                    }} className=" w-full" to={`/`}>
                      <div className="relative">
                        <button data-dropdown-toggle="dropdown">
                          <div className="bg-white p-1 m-1 rounded-md"><button className="font-bold text-lg">Processing</button></div>
                        </button>
                      </div>
                    </Link> : <Link className=" w-full" to={`/inmate/mess-bill-payment-manual/${bill.id}`}>
                      <div className="relative">
                        <button data-dropdown-toggle="dropdown">
                          <div className="bg-white p-1 m-1 rounded-md"><button className="font-bold text-lg">Update Payment Details</button></div>
                        </button>
                      </div>
                    </Link>
                  }



                </p>
                <AlertDialog

                  open={open}
                  setOpen={setOpen}
                  modalText={modaltext}
                  modalHeading={modalHeading}

                />
              </motion.div>
            );
          })}


      </div>

    </>
  );
}

export default InmateBillListComponent;
