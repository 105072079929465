import { useState, useEffect, useContext } from "react";
import axios from "axios";
import { baseUrl } from "../baseUrl";
import { UserContext } from "../Contexts/UserContext";
import DateConverter from '../Utils/DateConverter'
import * as FileSaver from 'file-saver';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import AlertDialog from "./AlertDialog";
import ConfirmDialog from "./ConfirmDialog";
import { Link } from "react-router-dom";
import DialogBoxWithInputField from "./DialogBoxWithInputField";
function InmateBillPaymentSummary() {
  const { user, setLoading } = useContext(UserContext)
 
  const [open1,setOpen1]=useState(false);
  const [open2,setOpen2]=useState(false);
  const [open4,setOpen4]=useState(false)
  const [modalHeading,setModalHeading]=useState("");
  const [modalText,setModalText]=useState("");
  const [update,setUpdate]=useState(false)
  
  const [hostel,setHostel]=useState("LH")
  const [status, setStatus] = useState(0);
  const [paymentDetails, setPaymentDetails] = useState([]);
  const [hostelAdmissionNo,setHostelAdmissionNo]=useState(null)
  const [selectedPaymentDetails,setSelectedPaymentDetails]=useState(null)
  const [changeCFRstatuspayment,setChangeCFRstatusPayment]=useState()
  const [updateStatus,setUpdateStatus]=useState(0)
  const [toggleMenu,setToggleMenu]=useState([])

  /////////////variables for other option in rejection status //
  const [open3,setOpen3]=useState(false)
  const [value,setValue]=useState("")
  const [label,setLabel]=useState("")

  const rejectionStatus={
    "-1":'Amount mismatch',
    "-2":"No Such Transaction",
    "-3":"Wrong Payment Date",
    "-4":"Other"
  }

  const paymentStatusMap={
    0:"Bank",
    1:"Hostel Office"
  }

  useEffect(() => {
    setLoading(true);
    axios.get(`${baseUrl}/warden/get-inmate-all-payment-details?hostel_admission_no=${hostelAdmissionNo}&&status=${status}&&hostel=${hostel}`).then((res) => {
      setToggleMenu(Array.from({ length: res.data.data.length }, () => false))
      setPaymentDetails(res.data.data)
    }).catch((err) => {

      alert("something went wrong")
    }).finally(() => {
      setUpdate(false)
      setLoading(false)
    })
  }, [hostelAdmissionNo,status,update,hostel])

    const downloadPDF = () => {
      const pdf = new jsPDF();
      if(paymentDetails.length<1)
      return alert("nothing to download");
      const date=new Date();

      const today=DateConverter.parseDateDDMMYYYY(date)

      const title = `Mess Bill Transactions ${today}`;
      pdf.text(title, 14, 15); 

      const columns = ["hostel_admission_no","transaction_id","amount","paid_date","mess_bill_month"];

      const header = ["Admission No","Transaction ID","Amount","Paid Date","Mess Bill Month"];

      const rows = paymentDetails.map((row)=>{
        return (
          columns.map((col)=>{
            console.log(col)
            if(col=="paid_date")
            return DateConverter.parseDateDDMMYYYY(row[col])
           if(col=="mess_bill_month")
           return row.month +" - "+row.year
          return row[col]
          })
        )
      });

      pdf.autoTable({
          head: [header],
          body: rows,
          startY: 25,
      });

      pdf.save(`CET Mess Bill Transactions ${today}.pdf`, { returnPromise: true, type: 'blob' })
      .then((blob) => {
          FileSaver.saveAs(blob, `CET  Mess Bill Transactions ${today}.pdf`);
      });
  };


  const handleSubmit=(details,new_status)=>{
    setSelectedPaymentDetails(details)

    switch (new_status){
      case 1:{
        setUpdateStatus(1);
        setModalHeading("Are you sure  ?")
        setModalText("Before Proceeding please ensure the transaction ID is " +details.transaction_id +" , Payment Date is "+DateConverter.parseDateDDMMYYYY(details.paid_date)+" , Amount  Paid is "+details.amount +" . Do You Want To Continue ?")
        setOpen2(true);
        break
      }
      case -1:
      case -2:
      case -3:{
        setValue(rejectionStatus[new_status])
        setUpdateStatus(-1);
        setModalHeading("Are you sure  ?")
        setModalText("You Are About To Reject The Transaction With Transaction ID " +details.transaction_id + " With Reason " + rejectionStatus[new_status]+" . Do You Want To Continue ?")
        setOpen2(true);
        break
      }
      case -4:{
        setValue("")
        setUpdateStatus(-1);
        setModalHeading("Reject ")
        setModalText("Enter The Reason For The Rejection Of The Payment .")
        setLabel("Reason")
        setOpen3(true)
        break;
      }
      case -5:{
        setUpdateStatus(-1)
        setModalHeading("Are you sure  ?")
        setModalText("You Are About To Reject The Transaction With Transaction ID " +details.transaction_id + " With Reason " + value+" . Do You Want To Continue ?")
        setOpen2(true);
        break;
      }
      default:{
        setModalHeading("Invalid Request !")
        setModalText("invalid request recieved . Please change it and try again ")
        setOpen1(true)
      }
    }
   
  }

  const changeStatus=()=>{
    setLoading(true)
    const data={
      id:selectedPaymentDetails.id,
      status:updateStatus,
      reason:value
    }
    axios.post(`${baseUrl}/warden/update-mess-bill-status`,data).then((res)=>{
      setModalHeading("Updated")
      setModalText("The Payment Status was updated successfully . ")
      setOpen1(true)
    }).catch((err)=>{
      console.log(err)
      console.log(err.message)
      setModalHeading("Request Failed")
      setModalText(err.response.data.msg)
      setOpen1(true)
    }).finally(()=>{
      setUpdate(true)
      setLoading(false)
    })

  }

  const changeCFRStatus=()=>{
    setLoading(true)
    const data={
      id:user.user_id,
      transaction_id:changeCFRstatuspayment.id
    }
    axios.post(`${baseUrl}/clerk-a3/change-cfr-status`,data).then((res)=>{
      setUpdate(true)
      setModalHeading("Reqeust Sucess !")
      setModalText("succesfully changed the CFR status for the payment .")
      setOpen1(true)
    }).catch((err)=>{
      setModalHeading("Request Failed !")

      setModalText("Failed to change the status . Please try again later !")
      setOpen1(true)
    }).finally(()=>{
      setLoading(false)
    })
  }

  const handleCFRChange=(details)=>{
    setChangeCFRstatusPayment(details)
    setModalHeading("Are you sure ?")
    setModalText("Have you made the CFR entry for the transaction with transaction ID : "+details.transaction_id +" Do you want to continue ?")
    setOpen4(true)
  }


  return (
    <>
      <div className="w-full m-5 p-4">
        {
          user.stage == 'inmate' ? <div className="flex items-center justify-between w-4/12">
            <select
              className="p-3 ring-slate-200 ring-2 rounded-xl outline-none"
            >
              <option >{user.hostel == 'MH' ? 'Mens Hostel' : 'Ladies Hostel'}</option>
            </select>
  
          </div> : ""}
        <div className="m-2 p-2 grid grid-cols-2 w-100 gap-4 mb-3">
          <label className="font-bold" htmlFor="">Hostel Admission No</label>{" "}
          <input
            required
            type="text"
            onChange={(e) => {
              setHostelAdmissionNo(e.target.value)
            }}
            className="w-11/12 py-2 px-3 rounded-xl ring-2 ring-slate-300 focus:outline-none"
          />
        </div>
        <div className="flex items-center justify-between w-4/12">
          <select
            onChange={(e) => {
              setStatus(e.target.value)
            }}
            className="m-2 p-4 ring-slate-200 ring-2 rounded-md outline-none"
          >
            <option value={0}>Processing</option>
            <option value={1}>Approved</option>
            <option value={2}>Generated Receipt</option>
            <option value={-1}>Rejected</option>
          </select>
        </div>
        <div className="flex items-center justify-between w-4/12">
          <select
            onChange={(e) => {
              setHostel(e.target.value)
            }}
            className="m-2 p-4 ring-slate-200 ring-2 rounded-md outline-none"
          >
            <option value={"LH"}>Ladies Hostel</option>
            <option value={"MH"}>Mens Hostel</option>
          </select>
        </div>

        <div className="flex items-center justify-end mb-5">
          <button className="bg-stone-800 text-white p-2 rounded-lg text-sm mr-5" onClick={() => {

          }}>
            Download as Excel
          </button>
        </div>

        <div className="flex items-center justify-end mb-5">
          <button className="bg-stone-800 text-white p-2 rounded-lg text-sm mr-5" onClick={() => {
            downloadPDF()
          }}>
            Download as PDF
          </button>
        </div>
        <h2 className="text-black font-semibold text-lg mt-5 mb-3">
          Payment Transactions
        </h2>
        <div className="flex items-center justify-between w-4/12 py-4">
          <p className="font-semibold">No Of Payments :</p>
          <p className="font-semibold">{paymentDetails.length} </p>
        </div>
        <div className='w-12/12 overflow-x-scroll'>
          <table className="w-full relative table-auto">
            <tr className="rounded-xl p-3 bg-primary text-center">
              <th className="p-2">Sl.No</th>
              <th className="p-2">Hostel Admn No.</th>
              <th className="p-2">Name</th>
              <th className="p-2">Transaction ID</th>
              <th className="p-2">Amount Paid</th>
              <th className="p-2">Paid Date</th>
              <th className="p-2">Month</th>
              <th className="p-2">Remaining</th>
              <th className="p-2">Payment Mode</th>
              <th className="p-2">{status==2?"Receipt":"Actions "}</th>
              {status==2 && user.user_id=='954437' && <th className="p-2">CFR Done</th>}
            </tr>
            {paymentDetails.length>0 && paymentDetails.map((payment, index) => {
              return (
                <tr
                  key={index}
                  className={
                    "border-b text-center border-slate-200 border-solid hover:bg-gray-300"
                  }
                >
                  <td className="p-3 text-sm">{index+1}</td>
                  <td className="p-3 text-sm">{payment.hostel_admission_no}</td>
                  <td className="p-3 text-sm">{payment.name}</td>
                  <td className="p-3 text-sm">{payment.transaction_id}</td>
                  <td className="p-3 text-sm">{payment.amount}</td>
                  <td className="p-3 text-sm ">{DateConverter.parseDateDDMMYYYY(payment.paid_date)}</td>
                  <td className="p-3 text-sm">{payment.month + " - " + payment.year}</td>
                  <td className="p-3 text-sm">{payment.remaining_amount}</td>     
                  <td className="p-3 text-sm">{paymentStatusMap[payment.payment_mode]}</td>
                  <td className="p-3  text-left whitespace-nowrap">
                  {
                    (payment.payment_status==-1) && <button
                    disabled={true}
                    className={` bg-green-400 hover:bg-green-700 text-white font-bold py-2 px-2 rounded m-1`}
                  >
                  {payment.reason}
                  </button>
                  }
                  {
                    (payment.payment_status==2) && <button
                    disabled={true}
                    className={` bg-green-400 hover:bg-green-700 text-white font-bold py-2 px-2 rounded m-1`}
                  >
                  {payment.receipt_number}
                  </button>
                  }
                  {
                    payment.payment_status==1 && payment.payment_mode==1 && <button
                    disabled={true}
                    className={`bg-green-400 hover:bg-green-700 text-white font-bold py-2 px-2 rounded m-1`}
                  >
                  {payment.transaction_id}
                  </button>
                  }
                  {payment.payment_status==0 &&  user.user_id!='954437' && <button
                      disabled={true}
                      className={` bg-green-400 hover:bg-green-700 text-white font-bold py-2 px-2 rounded`}
                    >
                    Processing
                    </button> }
                  {payment.payment_status==1 && payment.payment_mode==0 && <button
                      disabled={true}
                      className={` bg-green-400 hover:bg-green-700 text-white font-bold py-2 px-2 rounded`}
                    >
                    Approved
                    </button> }
                  {
                   payment.payment_status==0 && user.user_id=='954437' && <>
                    <button
                      disabled={payment.payment_status!=0}
                      className={` bg-green-400 hover:bg-green-700 text-white font-bold py-2 px-2 rounded`}
                      onClick={()=>{
                        handleSubmit(payment,1)
                      }}
                    >
                      {payment.payment_status==1? "Approved":"Approve"}
                    </button>
                    <button
                      disabled={payment.payment_status!=0}
                      className={`bg-red-400 hover:bg-red-700 m-2 text-white font-bold py-2 px-2 rounded`}
                    
                      onMouseOver={()=>{
                        if(payment.payment_status!=0)
                        return
                        let arr=Array.from({ length: paymentDetails.length }, () => false)
                        arr[index]=true
                        setToggleMenu(arr)
                      }}
                    >
                      {payment.payment_status==-1? payment.reason:"Reject"}
                    </button>
                    </>

                  }
                  </td>
                  {status==2 && user.user_id=='954437' && <td className="p-3 text-sm">
                  <button
                      disabled={payment.cfr_done}
                      className={`${payment.cfr_done?"bg-green-400 ":"bg-red-400 hover:bg-red-700"}  text-white font-bold py-2 px-2 rounded`}
                    onClick={()=>{
                      handleCFRChange(payment)
                    }}
                    >
                    
                    {payment.cfr_done?"Completed":"CFR Done"}
                    </button>
                  </td> }
                 
                  <ul
                    onMouseLeave={()=>{
                      setToggleMenu(Array.from({ length: paymentDetails.length }, () => false))
                    }}
                  className={` z-10 absolute bg-white right-0 w-40 py-2 mt-2 rounded-lg shadow-xl ${toggleMenu[index]? "block" : "hidden"
                    }`}
                >
                  <li className="flex w-full items-center px-3 py-2 text-sm hover:bg-gray-100">
                    <button    onClick={()=>{
                        handleSubmit(payment,-1)
                      }} className=" w-full" to="/mess-bill-payment">Amount mismatch</button>
                  </li>

                  <li className="flex w-full items-center px-3 py-2 text-sm hover:bg-gray-100">
                  <button  onClick={()=>{
                        handleSubmit(payment,-2)
                      }}  className=" w-full" to="/mess-bill-payment">No Such Transaction</button>
                  </li>
                  <li className="flex w-full items-center px-3 py-2 text-sm hover:bg-gray-100">
                  <button onClick={()=>{
                        handleSubmit(payment,-3)
                      }} className=" w-full" to="/mess-bill-payment">Wrong Payment Date</button>
                  </li>
                  <li className="flex w-full items-center px-3 py-2 text-sm hover:bg-gray-100">
                  <button    onClick={()=>{
                        handleSubmit(payment,-4)
                      }} className=" w-full" to="/mess-bill-payment">Other</button>
                  </li>
                </ul>
                </tr>
              );
            })}
          </table>
      
        </div>
        <AlertDialog
        open={open1}
        setOpen={setOpen1}
        modalHeading={modalHeading}
        modalText={modalText}
      />
      <ConfirmDialog
        open={open2}
        setOpen={setOpen2}
        modalHeading={modalHeading}
        modalText={modalText}
        confirmFunction={changeStatus}
      />
       <ConfirmDialog
        open={open4}
        setOpen={setOpen4}
        modalHeading={modalHeading}
        modalText={modalText}
        confirmFunction={changeCFRStatus}
      />
       <DialogBoxWithInputField
       open={open3}
       setOpen={setOpen3}
       value={value}
       setValue={setValue}
       modalText={modalText}
       modalHeading={modalHeading}
       confirmFunction={()=>{
        setOpen3(false)
        handleSubmit(selectedPaymentDetails,-5)
       }}
       label={label}
       />
      </div>
    </>
  );
}

export default InmateBillPaymentSummary;
