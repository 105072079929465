import InfoIcon from "@mui/icons-material/Info";
import axios from "axios";
import { useState, useContext, useEffect } from "react";
import { UserContext } from "../Contexts/UserContext";
import AlertDialog from "./AlertDialog";
import ConfirmDialog from "./ConfirmDialog";
import { baseUrl } from "../baseUrl";
import { Box, Button, Modal } from "@mui/material";
function ExtendMessOutAdminForm() {
  const dateConverter = (inputdate) => {
    const date = new Date(inputdate);
    let month = (date.getMonth() + 1).toString();
    let day = date.getDate().toString();
    let year = date.getFullYear();
    if (month.length < 2) {
      month = "0" + month;
    }
    if (day.length < 2) {
      day = "0" + day;
    }
    return [year, month, day].join("-");
  };


  const parseDateDDMMYYYY = (inputdate) => {
    const date = new Date(inputdate);
    let month = (date.getMonth() + 1).toString();
    let day = date.getDate().toString();
    let year = date.getFullYear();
    if (month.length < 2) {
      month = "0" + month;
    }
    if (day.length < 2) {
      day = "0" + day;
    }
    return day + "-" + month + "-" + year;
  };
  const today = new Date()
  const { setLoading } = useContext(UserContext);
  const [select_messout,set_selected_messout]=useState(null)
  const [extended_messin_date,set_Extended_MessinDate]=useState('')
  const [hostelAdmissionNo, setHostelAdmissionNo] = useState(null);
  const [studentName, setStudentName] = useState(null);
  const [messout_history, set_Messout_history] = useState([])
  const [open,setOpen]=useState(false); 
  const [open1, setOpen1] = useState("");
  const [open2, setOpen2] = useState("");
  const [modalText, setModalText] = useState("");
  const [modalHeading, setModalHeading] = useState("");
  const [requestRecievedDate, setRequestRecievedDate] = useState(" ");

  const getMaxDateforrequestedDate = () => {
    const d = new Date();
    d.setDate(d.getDate());
    return dateConverter(d);
  }

  const mindateforextension=(date)=>{
    const d=new Date(date);
    d.setDate(d.getDate()+1);
    return dateConverter(d)
  }

const handleClose=()=>{
  setOpen(false)
}

const handleExtendButton=(messout)=>{
  if(requestRecievedDate==null || requestRecievedDate ==" ")
  {
    setModalHeading("Invalid Data")
    setModalText("Please provide a valid date for the request recieved date and then try again .");
    setOpen1(true)
    return;

  }
  set_selected_messout(messout)
  setOpen(true)
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};



  const extendMessOut = () => {
    return (
      <div className="m-2 p-2">
        <form>
          <div className="grid grid-cols-2 w-100 gap-4 mb-3">
            <label htmlFor="">Hostel Admission No :</label>{" "}
            <input
              required
              type="text"
              onChange={(e) => {
                setHostelAdmissionNo(e.target.value);
                setStudentName("");

                if (e.target.value.length > 6) {
                  setLoading(true);
                  axios
                    .get(
                      `${baseUrl}/inmate/get-inmate-details?hostel_admission_no=${e.target.value}`
                    )
                    .then((res) => {
                      if (res.data.status == "ok") {
                        setStudentName(res.data.data.name)
                        axios.get(`${baseUrl}/warden/get-messouts-to-extend?hostel_admission_no=${e.target.value}`).then((res1) => {
                          set_Messout_history(res1.data.data);

                        }).catch(() => {
                          setModalHeading("failed to udpate")
                          setModalText("no messout found to update please check your data and continue")
                          setOpen1(true)
                        })

                      }

                    })
                    .catch((err) => {
                    })
                    .finally(() => {
                      setLoading(false);
                    });
                }
              }}
              className="w-12/12 py-2 px-3 rounded-xl ring-2 ring-slate-300 focus:outline-none"
            />
          </div>
          <div className="grid grid-cols-2 w-100 gap-4 mb-3">
            <label htmlFor="">Student Name :</label>{" "}
            <input
              required
              type="text"
              value={studentName}
              className="w-12/12 py-2 px-3 rounded-xl ring-2 ring-slate-300 focus:outline-none"
            />
          </div>
          <div className="grid grid-cols-2 w-100 gap-4 mb-3 mt-5">
            <label htmlFor="">Hard Copy Requested On :</label>{" "}
            <input
              type="date"
              value={requestRecievedDate}
              max={getMaxDateforrequestedDate()}
              onChange={(e) => {
                setRequestRecievedDate(e.target.value);
              }}
              className="w-12/12 py-2 px-3 rounded-xl ring-2 ring-slate-300 focus:outline-none"
              required
            />
          </div>

          {/* <div className="grid grid-cols-2 w-100 gap-4 mb-3 mt-5">
            <label className="font-bold text-xl" htmlFor="">Has Permission From Principle:</label>{" "}
            <input
              type="checkbox"
              onChange={(e) => {
                if (e.target.checked)
                  setHasPermission(true);
                else
                  setHasPermission(false)
              }}
            />
          </div> */}
        </form>
        <AlertDialog
          open={open1}
          setOpen={setOpen1}
          modalHeading={modalHeading}
          modalText={modalText}
        />
  
      </div>
    );
  };

  const ExtendConfirmation=(e)=>{
    e.preventDefault()
    if(requestRecievedDate==null || requestRecievedDate ==" ")
    {
      setModalHeading("Invalid Data")
      setModalText("Please provide a valid date for the request recieved date and then try again .");
      setOpen1(true)
      return;

    }
    setModalHeading("Are you sure you want to confirm")
    setModalText("You are about to update the messout as messout from date : "+ parseDateDDMMYYYY(select_messout.fromdate)+" mess in date : " +parseDateDDMMYYYY(extended_messin_date) +". Do you want to submit ?");
    setOpen2(true)
  }
  
  const extendMessoutRequest = async () => {
    setOpen(false)
    setLoading(true)
    axios
      .post(`${baseUrl}/warden/extend-mess-out-special`, {
        messout_id:select_messout.messout_id,
        todate:extended_messin_date,
        hostel_admission_no:hostelAdmissionNo,
      })
      .then((res) => {
        const array=messout_history;
        const updatedArray = array.filter(obj => obj.messout_id !== select_messout.messout_id);
        set_Messout_history([...updatedArray,res.data.data])
        setModalHeading("Update succesfull")
        setModalText("Messout was succesfully extended .")
        setOpen1(true)
      })
      .catch((err) => {
        setModalHeading("Invalid Data");
        setModalText(err.response.data.msg);
        // setModalText("The given extension for the date is not possible as it will exceed the monthly limit .");
        setOpen1(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updateMessoutArray=(array,messout,newdata)=>{

  }
  return (
    <div className="mb-3 w-full m-auto">
      <div className="m-auto mb-3">
        <h2 className="font-semibold text-lg m-2">Extend Messout</h2>
        {extendMessOut()}
      </div>
      <div className='w-12/12 overflow-x-scroll m-2'>
        <h1 className="font-semibold text-black text-lg mb-3 mt-3">Mess Out History</h1>
        {false ? <p>No MessOut History</p> : <table className='w-full relative table-auto'>
          <tr className='rounded-xl p-3 bg-primary text-center'>
            <th className='p-3'>Sl.No</th>
            <th className='p-3'>From Date</th>
            <th className='p-3'>Mess in Date</th>
            <th className='p-3'>Action</th>
          </tr>
          {messout_history.map((data, index) => {
            return (
              <tr
                key={index}
                className={'border-b text-center border-slate-200 border-solid hover:bg-gray-300'}
              >
                <td className='p-3'>{index + 1}</td>
                <td className='p-3'>{parseDateDDMMYYYY(data.fromdate)}</td>
                <td className='p-3'>{parseDateDDMMYYYY(data.todate)}</td>
                <td className='p-3'><button className="bg-slate-400 text-white rounded-md m-2 p-2" onClick={()=>{
                  handleExtendButton(data)
                }}>Extend</button></td>
              </tr>

            )
          })}
        </table>}
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="parent-modal-title"
          aria-describedby="parent-modal-description"
        >
          <Box sx={{ ...style,width: 400 }}>
            <div className="w-full flex item-center justify-center">
            <p id="parent-modal-title" className=" m-3 font-bold">Extend Messout</p>
            </div>
           {open && <form onSubmit={ExtendConfirmation}>
            <div className="grid grid-cols-2 w-100 gap-4 mb-3 mt-5 font-bold">
            <label htmlFor="">Mess in Date :</label>{" "}
            <input
              type="date"
              min={mindateforextension(select_messout.todate)}
              value={extended_messin_date}
              onChange={(e) => {
                set_Extended_MessinDate(e.target.value);
              }}
              className="w-12/12 py-2 px-3 rounded-xl ring-2 ring-slate-300 focus:outline-none"
              required
            />
          </div>
          <div className="w-full flex item-center justify-center">
            <button type="submit" className="rounded-lg m-3 p-2 bg-slate-400 w-20 font-bold text-white">Submit</button>
            </div>
            </form>} 
          </Box>
        </Modal>
      </div>
      <ConfirmDialog
          open={open2}
          setOpen={setOpen2}
          modalHeading={modalHeading}
          modalText={modalText}
          confirmFunction={extendMessoutRequest}
        />
    </div>
  );
}
export default ExtendMessOutAdminForm;
