import React, { useEffect, useState } from 'react';
import { Input, Button, Form, message, Modal, AutoComplete } from 'antd';
import { EditOutlined, SaveOutlined, UndoOutlined, LockOutlined } from '@ant-design/icons';
import axios from 'axios';
import { baseUrl } from '../../../baseUrl';
import AlertDialog from '../../../components/AlertDialog';
import ReportTemplate from '../../Student/components/ReportTemplate';

const DownloadApplicationWD = () => {
    const [form] = Form.useForm();
    const [admissionNo, setAdmissionNo] = useState('');
    const [profile, setProfile] = useState(null);
    const [editableFields, setEditableFields] = useState({});
    const [loading, setLoading] = useState(false);
    const [open1, setOpen1] = useState(false);
    const [modalText, setModalText] = useState("");
    const [modalHeading, setModalHeading] = useState("");
    const [options, setOptions] = useState([]);


    useEffect(() => {
        const searchAdmissionNumbers = async () => {
            if (admissionNo.length > 2) {
                try {
                    const response = await axios.get(`${baseUrl}/warden/search-userid`, {
                        params: { query: admissionNo }
                    });
                    setOptions(response.data.map(no => ({ value: no })));
                } catch (error) {
                    console.error('Error searching admission numbers:', error);
                }
            } else {
                setOptions([]);
            }
        };

        searchAdmissionNumbers();
    }, [admissionNo])

    const fetchProfile = async () => {
        try {
            setLoading(true);
            const res = await axios.get(`${baseUrl}/warden/get-hostel-applicant-info`, { params: { admissionNo: admissionNo } });
            setProfile(res.data);
            form.setFieldsValue(res.data);
        } catch (error) {
            message.error('Failed to fetch profile');
        } finally {
            setLoading(false);
        }
    };

    const resetPassword = async () => {
        try {
            const res = await axios.get(`${baseUrl}/utils/reset-password-users`, { params: { user_id: admissionNo } });
            message.success(`Password has been reset`);
        } catch (error) {
            message.error('Failed to reset password');
        }
    };



    return (
        <div className="w-11/12 mx-auto p-6 bg-white rounded-lg shadow-md">
            <h1 className="text-xl font-bold mb-6 text-gray-800">View Details</h1>
            {!profile ? (
                <div className="mb-8 w-1/8 flex items-center justify-center gap-4">
                    <AutoComplete
                        value={admissionNo}
                        options={options}
                        onSelect={setAdmissionNo}
                        onSearch={setAdmissionNo}
                        placeholder="Enter User Id"
                        className="mb-2 w-4/12"
                    />
                    <button
                        onClick={fetchProfile}
                        disabled={loading}
                        className="flex items-center w-1/6 px-4 py-2 bg-gray-800 text-white rounded-md hover:bg-gray-700 transition-colors"
                    >
                        {loading ? 'Loading...' : 'View Profile'}
                    </button>
                </div>
            ) : (
                <div className='flex flex-col justify-between items-end'>
                    <ReportTemplate studentData={profile} />
                </div>
            )}
            <AlertDialog open={open1} setOpen={setOpen1} modalHeading={modalHeading} modalText={modalText} />
        </div>
    );
};

export default DownloadApplicationWD;