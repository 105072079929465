import { React, useState, useContext, useEffect } from 'react'
import { useForm } from "react-hook-form";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import ErrorIcon from '@mui/icons-material/Error';
import { Checkbox, FormControlLabel, TextField } from "@mui/material"
import { grey } from "@mui/material/colors"
import { motion } from "framer-motion"
import axios from 'axios'
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import { useRef } from 'react';
import jsPDF from 'jspdf';
import { UserContext } from '../../../Contexts/UserContext';
import { baseUrl } from '../../../baseUrl';
import AlertDialog from '../../../components/AlertDialog';
import html2pdf from 'html2pdf.js';
import { Printer } from 'lucide-react';
import { Button, message, Steps, theme } from 'antd';


function DirectAllotmentForm({ appliedStatus }) {
  const [studentData, setStudentData] = useState({});
  const [currpage, setCurrPage] = useState(1);
  const { user } = useContext(UserContext);
  const { setLoading } = useContext(UserContext);
  const navigate = useNavigate();
  const [prevData, setPrevData] = useState({});
  const {
    register,
    handleSubmit,
    reset,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useForm();

  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [modalText, setModalText] = useState("");
  const [modalHeading, setModalHeading] = useState("");
  const reportTemplateRef = useRef(null);


  const handleGeneratePdf = () => {
    const doc = new jsPDF({
      format: 'a4',
      unit: 'px',
    });

    // Adding the fonts.
    doc.setFont('Inter-Regular', 'Normal');
    doc.setFontSize(3);

    doc.html(reportTemplateRef.current, {
      async callback(doc) {
        await doc.save('document');
      },
    });
  };

  const generatePDF = () => {
    const element = reportTemplateRef.current;
    const opt = {
      margin: 10,
      filename: `hostel_application.pdf`,
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2, useCORS: true },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
    };

    html2pdf().set(opt).from(element).save();
  };

  // useEffect(() => {
  //   axios.get(`${baseUrl}/student/getinfo`, { params: { user_id: user.user_id } })
  //     .then(res => {
  //       setPrevData(res.data)
  //       reset(res.data);
  //       console.log('res.data', res.data)
  //     })
  // }, [])

  const districts = ["Thiruvananthapuram", "Kollam", "Pathanamthitta", "Alappuzha", "Kottayam", "Idukki", "Ernakulam", "Thrissur", "Palakkad", "Malappuram", "Kozhikode", "Wayanad", "Kannur", "Kasaragod", "Other"]

  const watchProgramme = watch("programme", "");
  const watchPincode = watch("pincode", "");

  const [postoffices, setpostoffices] = useState([])
  const [pincodeError, setPostOfficeError] = useState(false)



  const handleCheckPincode = () => {
    console.log('pincodeError')
    axios.get(`https://api.postalpincode.in/pincode/${watchPincode}`).then(res => {
      if (res.data[0].Status == "Error") {
        setPostOfficeError(true)
      }
      else {
        setpostoffices(res.data[0].PostOffice)
        setPostOfficeError(false)
        setValue('post_office', res.data[0].PostOffice[0].Name)

      }
    }).catch(err => {
      console.log('err', err)
      setPostOfficeError(true)

    })

  }

  // const { onChange, onBlur, name, ref } = register('pincode'); 



  const backdropClickHandler = (event) => {
    if (event.target === event.currentTarget) {
      setDistacePermissionState(false)
      setDownloadCopyState(false)
      setProduceAnnualIncomeState(false)
      setProduceAddressProof(false)
      setCasteCertificateState(false)
      setFishermanState(false)
      setPhDisablityCertificateState(false)
      setBplGOINCertificateState(false)
      setOpen(false);
    }

  }

  const [distacePermissionState, setDistacePermissionState] = useState(false)
  const [downloadCopyState, setDownloadCopyState] = useState(false)
  const [produceAnnualIncomeState, setProduceAnnualIncomeState] = useState(false)
  const [produceAddressProof, setProduceAddressProof] = useState(false)
  const [casteCertificateState, setCasteCertificateState] = useState(false)
  const [fishermanState, setFishermanState] = useState(false)
  const [bplGOINCertifcateState, setBplGOINCertificateState] = useState(false)
  const [phDisablityCertificateState, setPhDisablityCertificateState] = useState(false)


  const handleOpen = () => setOpen(true);
  const handleClose = () => {

    setDistacePermissionState(false)
    setDownloadCopyState(false)
    setProduceAnnualIncomeState(false)
    setProduceAddressProof(false)
    setCasteCertificateState(false)
    setFishermanState(false)
    setPhDisablityCertificateState(false)
    setBplGOINCertificateState(false)
    setOpen(false);
  }


  const saveData = (data) => {
    console.log(data)
    next()
  };

  const handleModal = (data) => {
    console.log('datjhhjas', data)
    setStudentData(data);
    // RenderModal(data)
    setOpen(true)

  }

  const handleSubmitData = (data) => {
    console.log('studentData', data)
    axios
      .post(`${baseUrl}/warden/direct-admission`, {
        admission_category: data.admission_category,
        admission_no: data.admission_no,
        afi: data.afi,
        type_of_admission: data.type_of_admission,
        department: data.department,
        distance: data.distance,
        email: data.email,
        mobile_no: data.mobile_no,
        post_office: data.post_office,
        programme: data.programme,
        scholarship_category: data.scholarship_category,
        district: data.district,
        pincode: data.pincode,
        batch: data.batch,
        year: data.year_of_admission,
        name: data.name,
        discipline_specialisation_area: data.discipline_specialisation_area,
        gender: data.gender,
        current_year: data.current_year,
        batch: data.batch,
        parent_name: data.parent_name,
        parent_number: data.parent_number,
        designation: "student",
      })
      .then((res) => {
        setModalHeading("Request Completed ");
        setModalText(`Student is Successfully admitted with hostel Admission No : ${res.data.HostelAdmissionNo} and is added in to the inmate list.`);
        setLoading(false)
        setOpen(false)
        setOpen1(true);
        // message.success("Application submitted successfully.")
        // setCurrPage(currpage + 1)

        // setSubmited(!submited)
      })
      .catch((err) => {
        console.log("reg", err.response.data.msg);
        setModalHeading("Error");
        setModalText(err.response.data.msg);
        setOpen(false)
        setOpen1(true);


      })
      .finally(() => {
        setLoading(false);
      });
  }
  const [allConditionsMet, setAllConditionsMet] = useState(false);

  useEffect(() => {
    // Check if all conditions are true and update allConditionsMet
    setValue("admission_category", "GOIN")
    setValue("scholarship_category", "Other")
    setValue("programme", "UG")
    setValue("type_of_admission", "Normal")

  }, []);

  const renderField = (label, value) => (
    <div className="grid grid-cols-2 gap-2 py-2 border-b border-gray-200 last:border-b-0">
      <span className="font-medium text-sm text-gray-600">{label}</span>
      <span className="text-gray-800 text-sm">{value}</span>
    </div>
  );

  const renderDisclaimer = (text) => (
    <p className="text-sm text-gray-600 mb-2">{text}</p>
  );

  const handlePrint = () => {
    window.print();
  };


  const { token } = theme.useToken();
  const [current, setCurrent] = useState(0);

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const [formData, setFormData] = useState({
    admission_no: '',
    hostel_admission_no: '',
    name: '',
    department: '',
    programme: '',
    year_of_admission: '',
    batch: '',
    gender: '',
    current_year: 1,
    type_of_admission: '',
    discipline_specialisation_area: '',
    mobile_no: '',
    email: '',
    admission_category: '',
    scholarship_category: '',
    aft: '',
    district: '',
    post_office: '',
    distance: '',
    afi: '',
    pincode: '',
    parent_name: '',
    parent_number: ''
  });

  // const [errors, setErrors] = useState({}); 




  const steps = [
    {
      title: 'Enter Personal Details',
      content: () => (
        <form onSubmit={handleSubmit(saveData)}>
          <div className="space-y-4">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <label htmlFor="admission_no" className="font-medium">College Admission No:</label>
              <input
                type="text"
                id="admission_no"
                {...register("admission_no", { required: "This field is required." })}
                className="w-full py-2 px-3 rounded-xl ring-2 ring-slate-300 focus:outline-none"
              />
              {errors.admission_no && (
                <p className="text-red-600 font-semibold col-span-full text-sm">
                  {errors.admission_no.message}
                </p>
              )}
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <label htmlFor="">Name :</label>{" "}
              <input
                type="text"
                name="name"
                // defaultValue={user.name}
                {...register("name", {
                  required: "This field is required.",
                })}
                className="w-12/12 py-2 px-3 rounded-xl ring-2 ring-slate-300 border-0 focus:outline-none"
              />
              {errors.name && (
                <p className="text-red-600 font-semibold grid col-span-2 text-sm ">
                  {errors.name.message}
                </p>
              )}
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <label htmlFor="">Gender :</label>{" "}
              <select
                name="gender"
                {...register("gender", {
                  required: "This field is required.",
                })}
                className="p-3 ring-slate-200 ring-2 rounded-xl outline-none"
              >
                <option value={"Male"}>Male</option>
                <option value={"Female"}>Female</option>
                <option value={"Other"}>Other</option>
              </select>
              {errors.gender && (
                <p className="text-red-600 font-semibold grid col-span-2 text-sm ">
                  {errors.gender.message}
                </p>
              )}
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <label htmlFor="">Mobile number  :</label>{" "}
              <input
                type="text"
                name="mobile_no"
                {...register("mobile_no", {
                  required: "This field is required.",
                })}
                className="w-12/12 py-2 px-3 rounded-xl ring-2 ring-slate-300 focus:outline-none"
              />
              {errors.mobile_no && (
                <p className="text-red-600 font-semibold grid col-span-2 text-sm ">
                  {errors.mobile_no.message}
                </p>
              )}
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <label htmlFor="">Email:</label>{" "}
              <input
                type="text"
                name="email"
                {...register("email", {
                  required: "Email is required.",
                  pattern: {
                    value: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
                    message: "Email is not valid.",
                  },
                })}
                className="w-12/12 py-2 px-3 rounded-xl ring-2 ring-slate-300 focus:outline-none"
              />
              {errors.email && (
                <p className="text-red-600 font-semibold grid col-span-2 text-sm ">
                  {errors.email.message}
                </p>
              )}
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <label htmlFor="">Year of Admission:</label>{" "}
              <input
                type="number"
                name="year_of_admission"
                // defaultValue={prevData.year_of_admission}
                {...register("year_of_admission", {
                  required: "Year of admissioin is required.",
                })}
                className="w-12/12 py-2 px-3 rounded-xl ring-2 ring-slate-300 focus:outline-none"
              />
              {errors.year_of_admission && (
                <p className="text-red-600 font-semibold grid col-span-2 text-sm ">
                  {errors.year_of_admission.message}
                </p>
              )}
            </div>


            <div className='mt-5 md:mx-0'>

              <Button className=" bg-stone-800 text-white mx-2" htmlType="submit" type="secondary">
                Next
              </Button>
            </div>

          </div>
        </form>)
    },
    {
      title: 'Verify Details',
      content: () => (
        <form onSubmit={handleSubmit(saveData)}>
          <div className="space-y-4">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <label htmlFor="">Department :</label>{" "}
              <div>
                <select
                  className="p-3 ring-slate-200 ring-2 rounded-xl outline-none"
                  name="department"
                  {...register("department", {
                    required: "This field is required.",
                  })}
                  autoWidth
                  label="Age"
                >
                  <option value={"CE"}>CE</option>
                  <option value={"ME"}>ME </option>
                  <option value={"ECE"}>
                    ECE{" "}
                  </option>
                  <option value={"EEE"}>
                    EEE{" "}
                  </option>
                  <option value={"CSE"}>CSE</option>
                  <option value={"Architecture"}> Architecture </option>
                  <option value={"Computer Application"}> Computer Application </option>
                  <option value={"CETSOM"}> CET SOM </option>
                </select>
              </div>
              {errors.department && (
                <p className="text-red-600 font-semibold grid col-span-2 text-sm ">
                  {errors.department.message}
                </p>
              )}
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <label htmlFor="">Batch :</label>{" "}
              <div>
                <select
                  className="p-3 ring-slate-200 ring-2 rounded-xl outline-none"
                  name="batch"
                  {...register("batch", {
                    required: "This field is required.",
                  })}
                  autoWidth
                  label="Age"
                >
                  <option value={"B1"}>B1</option>
                  <option value={"B2"}>B2</option>
                  <option value={"B3"}>B3</option>
                  <option value={"B4"}>B4</option>

                </select>
              </div>
              {errors.batch && (
                <p className="text-red-600 font-semibold grid col-span-2 text-sm ">
                  {errors.batch.message}
                </p>
              )}
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <label htmlFor="">Programme :</label>{" "}
              <select
                value={"UG"}
                disabled
                name="programme"
                {...register("programme", {
                  required: "This field is required.",
                })}
                className="p-3 ring-slate-200 ring-2 rounded-xl outline-none"
              >
                <option value={"UG"}>UG</option>
                <option value={"PG"}>PG</option>
                <option value={"Phd"}>Phd</option>
              </select>
              {errors.programme && (
                <p className="text-red-600 font-semibold grid col-span-2 text-sm ">
                  {errors.programme.message}
                </p>
              )}
            </div>

            {watchProgramme == "UG" && (
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <label htmlFor="">Discipline :</label>{" "}
                <input
                  type="text"
                  name="discipline_specialisation_area"
                  {...register("discipline_specialisation_area", {
                    required: "This field is required.",
                  })}
                  className="w-12/12 py-2 px-3 rounded-xl ring-2 ring-slate-300 focus:outline-none"
                />
                {errors.discipline_specialisation_area && (
                  <p className="text-red-600 font-semibold grid col-span-2 text-sm ">
                    {errors.discipline_specialisation_area.message}
                  </p>
                )}
              </div>
            )}
            {watchProgramme == "PG" && (
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <label htmlFor="">Specialisation :</label>{" "}
                <input
                  type="text"
                  name="discipline_specialisation_area"
                  {...register("discipline_specialisation_area", {
                    required: "This field is required.",
                  })}
                  className="w-12/12 py-2 px-3 rounded-xl ring-2 ring-slate-300 focus:outline-none"
                />
                {errors.discipline_specialisation_area && (
                  <p className="text-red-600 font-semibold grid col-span-2 text-sm ">
                    {errors.discipline_specialisation_area.message}
                  </p>
                )}
              </div>
            )}
            {watchProgramme == "Phd" && (
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <label htmlFor="">Area :</label>{" "}
                <input
                  type="text"
                  name="discipline_specialisation_area"
                  {...register("discipline_specialisation_area", {
                    required: "This field is required.",
                  })}
                  className="w-12/12 py-2 px-3 rounded-xl ring-2 ring-slate-300 focus:outline-none"
                />
                {errors.discipline_specialisation_area && (
                  <p className="text-red-600 font-semibold grid col-span-2 text-sm ">
                    {errors.discipline_specialisation_area.message}
                  </p>
                )}
              </div>
            )}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">

              <label htmlFor="">Admission Type :</label>{" "}
              <select
                // defaultValue={prevData.type_of_admission}
                disabled
                className="p-3 ring-slate-200 ring-2 rounded-xl outline-none"
                name="type_of_admission"
                {...register("type_of_admission", {
                  required: "This field is required.",
                })}
              >
                <option value={"Normal"}>Normal</option>
                <option value={"LET"}>LET</option>
                <option value={"College Transfer"}>College Transfer</option>
              </select>
              {errors.type_of_admission && (
                <p className="text-red-600 font-semibold grid col-span-2 text-sm ">
                  {errors.type_of_admission.message}
                </p>
              )}
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">

              <label htmlFor="">Year :</label>{" "}
              <select
                // defaultValue={prevData.current_year}
                className="p-3 ring-slate-200 ring-2 rounded-xl outline-none"
                name="current_year"
                {...register("current_year", {
                  required: "This field is required.",
                })}
              >
                <option value={"1"}>First Year</option>
                <option value={"2"}>Second Year</option>
                <option value={"3"}>Third Year</option>
                <option value={"4"}>Fourth Year</option>
              </select>
              {errors.current_year && (
                <p className="text-red-600 font-semibold grid col-span-2 text-sm ">
                  {errors.current_year.message}
                </p>
              )}
            </div>


            <div className='mt-5 md:mx-0'>

              <Button className=" bg-stone-800 text-white mx-2" htmlType="submit" type="secondary" >
                Next
              </Button>

              <Button onClick={() => prev()}>
                Previous
              </Button>
            </div>


          </div>
        </form >
      )
    },
    {
      title: 'Applicant',
      content: () => (
        <form onSubmit={handleSubmit(handleSubmitData)} lassName="space-y-6">
          <div className="space-y-4">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <label htmlFor="">Admission Categoryy :</label>{" "}
              <select
                className="p-3 ring-slate-200 ring-2 rounded-xl outline-none"
                disabled
                value={"GOIN"}
                name="admission_category"
                {...register("admission_category", {
                  required: "This field is required.",
                })}
                autoWidth
                label="Age"
              >
                <option value={"SC"}>SC</option>
                <option value={"ST"}>ST</option>
                <option value={"Ph"}>Ph</option>
                <option value={"BPL"}>BPL</option>
                <option value={"GOIN"}>GOIN</option>
                <option value={"Other"}>Other</option>
              </select>
              {errors.admission_category && (
                <p className="text-red-600 font-semibold grid col-span-2 text-sm ">
                  {errors.admission_category.message}
                </p>
              )}
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <label htmlFor="">E-Grants Category :</label>{" "}
              <select
                className="p-3 ring-slate-200 ring-2 rounded-xl outline-none"
                value={"Other"}
                disabled
                name="scholarship_category"
                {...register("scholarship_category", {
                  required: "This field is required.",
                })}
                autoWidth
              >
                <option value={"SC"}>SC</option>
                <option value={"ST"}>ST</option>
                <option value={"OBC(H)"}>OBC(H)</option>
                <option value={"OEC"}>OEC</option>
                <option value={"Fisherman"}>Fisherman</option>
                <option value={"Ph"}>Ph</option>
                <option value={"Other"}>Other</option>
              </select>
              {errors.scholarship_category && (
                <p className="text-red-600 font-semibold grid col-span-2 text-sm ">
                  {errors.scholarship_category.message}
                </p>
              )}
            </div>

            {/* <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <label htmlFor="">Annual Family Income In Rupees :</label>{" "}
              <input
                type="number"
                name="afi"
                {...register("afi", {
                  required: true,
                  min: 10000,
                  max: 1000000000,
                })}
                className="w-12/12 py-2 px-3 rounded-xl ring-2 ring-slate-300 focus:outline-none"
              />
              {errors.afi && errors.afi.type === "required" && (
                <p className="text-red-600 font-semibold grid col-span-2 text-sm ">
                  <span role="alert">This is required</span>
                </p>
              )}
              {errors.afi && errors.afi.type === "min" && (
                <p className="text-red-600 font-semibold grid col-span-2 text-sm ">
                  <span role="alert">Income Must be greater than 10,000</span>
                </p>
              )}
              {errors.afi && errors.afi.type === "max" && (
                <p className="text-red-600 font-semibold grid col-span-2 text-sm ">
                  <span role="alert">Income Must be less than 100,000,0000</span>
                </p>
              )}
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <label htmlFor="">District :</label>{" "}
              <select
                className="p-3 ring-slate-200 ring-2 rounded-xl outline-none"
                name="district"
                {...register("district", {
                  required: "This field is required.",
                })}
                autoWidth
              >
                {districts.map(d => <option value={d}>{d}</option>)}
              </select>
              {errors.district && (
                <p className="text-red-600 font-semibold grid col-span-2 text-sm ">
                  {errors.district.message}
                </p>
              )}
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <label htmlFor="" className="mt-2">Pincode </label>
              <input type="text"
                name="pincode"
                {...register("pincode", {
                  required: "This field is required.",
                  onBlur: () => { handleCheckPincode() },

                })}
                className={pincodeError ? "w-12/12 py-2 px-3 rounded-xl ring-2 ring-red-400 border-2 focus:outline-none" : "w-12/12 py-2 px-3 rounded-xl ring-2 ring-slate-300 focus:outline-none"} />
              {errors.pincode && (
                <p className="text-red-600 font-semibold grid col-span-2 text-sm ">
                  {errors.pincode.message}
                </p>
              )}
              {pincodeError && (
                <p className="text-red-600 font-semibold grid col-span-2 text-sm ">
                  {"Enter a Valid Pincode"}
                </p>
              )}

            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <label htmlFor="" className="mt-2">Post Office </label>
              <select
                disabled={pincodeError}
                className="p-3 ring-slate-200 ring-2 rounded-xl outline-none"
                name="post_office"
                {...register("post_office", {
                  required: "This field is required.",
                })}
                autoWidth
                label="Age"
              >
                {
                  postoffices?.map(d => {
                    return <option value={d.Name}>{d.Name}</option>

                  })
                }
              </select>
              {errors.post_office && (
                <p className="text-red-600 font-semibold grid col-span-2 text-sm ">
                  {errors.post_office.message}
                </p>
              )}
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <label htmlFor="">Distance (In Km):</label>{" "}
              <input
                type="number"
                name="distance"
                {...register("distance", {
                  required: true,
                  max: 600,
                })}
                className="w-12/12 py-2 px-3 rounded-xl ring-2 ring-slate-300 focus:outline-none"
              />
              {errors.distance && errors.distance.type === "required" && (
                <p className="text-red-600 font-semibold grid col-span-2 text-sm ">
                  <span role="alert">This field is required</span>
                </p>
              )}
              {errors.distance && errors.distance.type == "max" && (
                <p className="text-red-600 font-semibold grid col-span-2 text-sm ">
                  <span role="alert">Distance Should be less than or equal to 600</span>
                </p>
              )}
            </div> */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <label htmlFor="">Parent Name:</label>{" "}
              <input
                type="text"
                name="parent_name"
                {...register("parent_name", {
                  required: true,
                })}
                className="w-12/12 py-2 px-3 rounded-xl ring-2 ring-slate-300 focus:outline-none"
              />
              {errors.parent_name && errors.parent_name.type === "required" && (
                <p className="text-red-600 font-semibold grid col-span-2 text-sm ">
                  <span role="alert">This field is required</span>
                </p>
              )}
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <label htmlFor="">Parent Number :</label>{" "}
              <input
                type="number"
                name="parent_number"
                {...register("parent_number", {
                  required: true,
                })}
                className="w-12/12 py-2 px-3 rounded-xl ring-2 ring-slate-300 focus:outline-none"
              />
              {errors.parent_number && errors.parent_number.type === "required" && (
                <p className="text-red-600 font-semibold grid col-span-2 text-sm ">
                  <span role="alert">This field is required</span>
                </p>
              )}
            </div>
            {/* <div className="w-full flex flex-col md:flex-row items-center justify-between mt-5 space-y-4 md:space-y-0">
                            <motion.button
                                whileHover={{ x: -10, backgroundColor: 'red' }}
                                className="w-full md:w-auto p-3 bg-stone-800 text-white rounded-xl"
                                onClick={() => setCurrPage(currpage - 1)}
                            >
                                Back
                            </motion.button>
                            <motion.button
                                whileHover={{ x: 10, backgroundColor: 'green' }}
                                className="w-full md:w-auto p-3 bg-stone-800 text-white rounded-xl"
                                type='submit'
                            >
                                Next
                            </motion.button>
                        </div> */}

            <div className='mx-5'>
              <Button className=" bg-stone-800 text-white m-3" type="secondary" htmlType="submit" >
                Done
              </Button>
              <Button onClick={() => prev()}>
                Previous
              </Button>
            </div>

          </div>
        </form>

      )
    },
  ];
  const items = steps.map((item) => ({ key: item.title, title: item.title }));


  // ... (keep other state variables and functions)

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      className='w-11/12 max-w-4xl mx-auto mt-12 bg-white rounded-xl text-left p-4 md:p-5'
    >

      <Steps className="mb-10" current={current} items={items} />
      <div >
        {(steps[current].content())}

      </div>



      {/* Add more pages here, following the same responsive pattern */}
      {/* 
            <Modal
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >


            </Modal> */}

      <AlertDialog
        open={open1}
        setOpen={setOpen1}
        modalHeading={modalHeading}
        modalText={modalText}
      />
    </motion.div>
  );
}

export default DirectAllotmentForm;