import "./App.css";
import LandingPage from "./pages/LandingPage";
import LoginPage from "./pages/LoginPage";
import SignUpPage from "./pages/SignUpPage";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import StudentHome from "./pages/Student/StudentHome";
import FacultyHome from "./pages/StaffAdvisor/AdvisorHome";
import StudentsDetails from "./pages/StaffAdvisor/StudentsDetails";
import SaViewApplication from "./pages/StaffAdvisor/SaViewApplication";
import AdminHome from "./pages/Admin/AdminHome";
import AdminInmates from "./pages/Admin/AdminInmates";
import AdminNonInmates from "./pages/Admin/AdminNonInmates";
import ViewDetails from "./pages/Student/ViewDetails";
import NonInmateCertificate from "./pages/Student/NonInmateCertificate";
import AdminPaths from "./pages/Admin/AdminPaths";
import AllotmentRule from "./pages/Admin/AllotmentRule";
import HostelRegistry from "./pages/Admin/HostelRegistry";
import CreateApplications from "./pages/Admin/CreateApplication";
import HostelBlocks from "./pages/Admin/HostelBlocks";
import AdminFaculty from "./pages/Admin/AdminFaculty";
import InmateHome from "./pages/Inmate/InmateHome";
import CertificatePage from "./pages/Inmate/CertificatePage";
import MessPage from "./pages/Inmate/MessPage";
import MessSecretary from "./pages/Inmate/MessSecretary";
import MessDirector from "./pages/Inmate/MessDirector";
import HostelPage from "./pages/Inmate/HostelPage";
import { useEffect, useState } from "react";
import SignupInvite from "./pages/StaffAdvisor/SignupInvite";
import CommonHome from "./pages/CommonHome";
import Page404 from "./pages/Page404";
import WardenHome from "./pages/Warden/WardenHome";
import HostelAdmission from "./pages/Warden/HostelAdmission";
import HostelOfficeHome from "./pages/HostelOffice/HostelOfficeHome";
import AdmissionHostelOffice from "./pages/HostelOffice/AdmissionHostelOffice";
import HostelOfficeMess from "./pages/HostelOffice/HostelOfficeMess";
import ManageInmateMessBill from './pages/Warden/ManageInmateMessBIll'
import InmateMessBillPage from './pages/Inmate/InmateMessBillPage'
import InmateMessBillPaymentPageManual from './pages/Inmate/ImateMessBillPaymentPageManual'
import Matron from "./pages/Matron/Matron";
import MatronHome from "./pages/Matron/MatronHome";
import MessBill from "./components/MessBill";
import MessOutList from "./components/MessOutList";
import UploadMessBill from "./components/UploadMessBill";
import MessBillPage from "./pages/Matron/MessBillPage";
import MessOutPage from "./components/MessOutPage";
import UploadMessBillPage from "./pages/Matron/UploadMessBillPage";
import MessOutListPage from "./pages/Matron/MessOutListPage";
import StudentsDetailsHod from "./pages/HOD/StudentsDetailsHod";
import HodViewApplication from "./pages/HOD/HodViewApplication";
import SignupInviteHod from "./pages/HOD/SignupInviteHod";
import AddStaffAdvisor from "./pages/HOD/AddStaffAdvisor";
import axios from "axios";
import SergeantHome from "./pages/Sergeant/SergeantHome";
import ViewComplaints from "./pages/Sergeant/ViewComplaints";
import SeatMatrix from "./pages/Admin/SeatMatrix";
import { UserContext, LoadingContext } from "./Contexts/UserContext";
import AdvisorHome from "./pages/StaffAdvisor/AdvisorHome";
import HodHome from "./pages/HOD/HodHome";
import BackdropLoading from "./components/BackdropLoading";
import ViewApplications from "./pages/Warden/ViewApplications";
import FacultySignUp from "./pages/FacultySignUp";
import ApplyMessout from "./pages/Student/ApplyMessout";
import MessManagementWarden from "./pages/Warden/MessManagement";
import { baseUrl } from "./baseUrl";
import ChangePassword from "./pages/ChangePassword";
import ForgotPassword from "./pages/ForgotPassword";
import SetNewPasswordPage from "./pages/SetNewPassword";
import MessExpensePage from "./pages/Sergeant/MessExpensePage";
import ClerkHome from "./pages/Clerk/ClerkHome";
import MessExpenseClerk from "./pages/Clerk/MessExpenseClerk";
import ChangePasswordAlertDialog from "./components/ChangePasswordAlertDialog";
import Mess_Special_Events_Page from "./pages/Inmate/MessSpecialEvents";
import Mess_Special_Event_BookingList from "./pages/Inmate/Mess_Special_Event_BookingList";
import CalculateMessBillPage from "./pages/Inmate/CalculateMessBIllPage";
import ManageUser from "./pages/Warden/ManageUser";
import HostelOfficeMessBillPage from "./pages/HostelOffice/HostelOfficeMessBillPage";
import HostelOfficeUpdateTransactionPage from "./pages/HostelOffice/HostelOfficeUpdateTransactionPage";
import SuspendMessInmates from "./pages/Warden/SuspendInmateMessPage";
import ClerkA1Home from "./pages/Clerk_A1/ClerkA1Home";
import ManageInmateTransactionsPage from "./pages/Clerk_A1/ManageInmateTransactionsPage";
import InmateBillListPage from "./pages/Clerk_A1/InmateBillListPage";
import ReceivePaymentPage from "./pages/Clerk_A1/ReceivePaymentsPage";
import EditMessBillPage from "./pages/Warden/Components/EditMessBillPage";
import WardenEditMessoutPage from "./pages/Warden/Components/EditMessOutPage";
import HostelAdmissionHO from "./pages/Clerk_A3/HostelAdmissionHO";
import ManageUserSA from "./pages/StaffAdvisor/ManageUserSA";
import HostelApplication from "./pages/Student/HostelApplication";
import MessManagementClerk from "./pages/HostelOffice/MessManagementClerk";
import ManageEgrants from "./pages/HostelOffice/ManageEgrants";
import SuspendInmateMessPageClerk from "./pages/HostelOffice/SuspendInmateMessPageClerk";
import ManageUserClerk from "./pages/Clerk_A3/ManageUserClerk";
import MessManagementCLRKA3 from "./pages/Clerk_A3/MessManagementCLRKA3";
import MessBillManagementCLRKA3 from "./pages/Clerk_A3/MessBillManagementCLRKA3";
import MessSuspensionCLRKA3 from "./pages/Clerk_A3/MessSuspensionCLRKA3";
import MessManagementWD from "./pages/Warden/MessManagementWD";

function App() {
  const [user, setUser] = useState();
  const [authenticating, setAuthenticating] = useState(true);
  const [loading, setLoading] = useState(true);
  const [open1, setOpen1] = useState(false);
  const [modalHeading, setModalHeading] = useState("");
  const [modalText, setModalText] = useState("");

  useEffect(() => {
    console.log("useefr")
    try {
      setAuthenticating(true)
      setLoading(true)
      axios.get(`${baseUrl}/auth/isAuthenticated`, {
        withCredentials: true
      }).then(function (response) {

        console.log('response.data', response.data)
        if (response.data != '') {
          setUser(response.data)
          console.log('response.data', response.data)
          if (!response.data.changedpassword && !window.location.href.includes("change-password")) {
            console.log('response.data.changedpassword', response.data.changedpassword)

            setModalHeading("Change your password");
            setModalText("Please change your password to continue using our service .");
            setOpen1(true)
          }

        } else {
          setUser(null)
          console.log("not auth")
        }

      }).catch(function (error) {
        console.log("not authenticated");
      }).finally(() => {
        setLoading(false)
        setAuthenticating(false)

      });
    }catch(e){
      console.log('e', e)


    }
  }, [])

  return (
    <div className="App">
      <UserContext.Provider value={{ user, setUser, loading, setLoading }}>
        <BrowserRouter>
          <Routes>
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/set-new-password" element={<SetNewPasswordPage />} />
            {user != null && (
              <Route path="/change-password" element={<ChangePassword />} />
            )}
            {user === null && <Route path="/" element={<LandingPage />} />}
            {user == null && <Route path="/login" element={<LoginPage />} />}
            {/* {user==null&&(<Route path="/signup" element={<SignUpPage/>}/>)}
          {user==null&&(<Route path="/facultysignup" element={<FacultySignUp/>}/>)} */}

            {user != null && user.designation == "faculty" && (
              <Route path="/" element={<CommonHome user={user} />}>
                <Route path='mess-special-events-bookings/:id' element={<Mess_Special_Event_BookingList />} />
                {/* Admin Routes */}
                {user.is_admin == true && (
                  <Route path="admin" element={<AdminHome />}>
                    <Route index element={<HostelRegistry />} />
                    <Route path="inmates" element={<AdminInmates />} />
                    <Route path="noninmates" element={<AdminNonInmates />} />
                    <Route path="faculty" element={<AdminFaculty />} />
                    <Route path="allotmentrule" element={<AllotmentRule />} />
                    <Route path="applicationpaths" element={<AdminPaths />} />
                    <Route path="hostelregistry" element={<HostelRegistry />} />
                    <Route
                      path="createapplication"
                      element={<CreateApplications />}
                    />
                    <Route path="hostelblocks" element={<HostelBlocks />} />
                    <Route path="seatmatrix" element={<SeatMatrix />} />
                  </Route>
                )}

                {/* staffadvisor Routes */}
                <Route path="SA" element={<AdvisorHome />}>
                  <Route index element={<StudentsDetails />} />
                  <Route path="studentsdetails" element={<StudentsDetails />} />
                  <Route path="signupinvite" element={<SignupInvite />} />
                  <Route path="saviewapplication" element={<SaViewApplication />} />
                  <Route path="managae-users-sa" element={<ManageUserSA />} />
                </Route>

                {/* Warden Routes */}
                <Route path="/WD" element={<WardenHome />}>
                  <Route index element={<HostelAdmission />} />
                  <Route path="mess-expense" element={<MessExpensePage />} />
                  <Route path="mess-bill-management" element={<ManageInmateMessBill />} />
                  <Route path="edit-mess-bill/:id" element={<EditMessBillPage />} />
                  <Route path="edit-messout-page/:id" element={<WardenEditMessoutPage />} />
                  <Route path="manage-egrants" element={<ManageEgrants />} />
                  <Route path="admission" element={<HostelAdmission />} />
                  <Route path="hostelregistry" element={<HostelRegistry />} />
                  <Route path="applications" element={<ViewApplications />} />
                  <Route path="messmanagement" element={<MessManagementWD />} />
                  <Route path="suspend-mess-inmates" element={<SuspendMessInmates />} />
                  <Route path='mess-special-events-bookings/:id' element={<Mess_Special_Event_BookingList />} />
                  <Route path="manageuser" element={<ManageUser />} />
                </Route>
                {/* hod Routes */}
                <Route path="/HOD" element={<HodHome />}>
                  <Route index element={<StudentsDetailsHod />} />
                  <Route
                    path="studentsdetails"
                    element={<StudentsDetailsHod />}
                  />
                  <Route path="addstaffadvisor" element={<AddStaffAdvisor />} />
                  <Route
                    path="hodviewapplication"
                    element={<HodViewApplication />}
                  />
                </Route>

                {/* Warden Routes */}


                <Route path="/SG" element={<SergeantHome />}>
                  <Route index element={<HostelRegistry />} />
                  <Route path="complaints" element={<ViewComplaints />} />
                </Route>

                <Route path="/MTRN" element={<SergeantHome />}>
                  <Route index element={<HostelRegistry />} />
                  <Route path="complaints" element={<ViewComplaints />} />
                </Route>

                {/* Hostel Office Routes */}
                <Route path="/HO" element={<HostelOfficeHome />}>
                  <Route index element={<AdmissionHostelOffice />} />
                  <Route path="manage-mess-bills" element={<HostelOfficeMessBillPage />} />
                  <Route path="update-transaction-details/:id" element={<HostelOfficeUpdateTransactionPage />} />
                  <Route path="admission" element={<AdmissionHostelOffice />} />
                  <Route path="hostelregistry" element={<HostelRegistry />} />
                  <Route path="mess" element={<HostelOfficeMess />} />
                  <Route path="hostel-admission" element={<HostelAdmissionHO />} />
                  <Route path="messmanagement" element={<MessManagementClerk />} />
                  <Route path="egrants" element={<ManageEgrants />} />
                  <Route path="mess-suspension" element={<SuspendInmateMessPageClerk />} />
                  {/* <Route path="manage-user" element={<ManageUserClerk />} /> */}
                </Route>

                <Route path="/CLRK" element={<ClerkHome />}>
                  <Route index element={<MessExpenseClerk />} />
                  <Route path="admission" element={<MessExpenseClerk />} />
                </Route>
                <Route path="/CLRKA1" element={<ClerkA1Home />}>
                  <Route index element={<ManageInmateTransactionsPage />} />
                  <Route path="mess-bill-list" element={<InmateBillListPage />} />
                  <Route path="transactions" element={<ManageInmateTransactionsPage />} />
                  <Route path="receive-payment/:id" element={<ReceivePaymentPage />} />

                </Route>
                <Route path="/CLRKA3" element={<HostelOfficeHome />}>
                  <Route index element={<HostelRegistry />} />
                  <Route path="manage-mess-bills" element={<MessBillManagementCLRKA3 />} />
                  <Route path="update-transaction-details/:id" element={<HostelOfficeUpdateTransactionPage />} />
                  <Route path="admission" element={<AdmissionHostelOffice />} />
                  <Route path="hostelregistry" element={<HostelRegistry />} />
                  {/* <Route path="mess" element={<HostelOfficeMess />} /> */}
                  <Route path="hostel-admission" element={<HostelAdmissionHO />} />
                  <Route path="messmanagement" element={<MessManagementCLRKA3 />} />
                  <Route path="egrants" element={<ManageEgrants />} />
                  <Route path="mess-suspension" element={<MessSuspensionCLRKA3 />} />
                  <Route path="manage-user" element={<ManageUserClerk />} />
                </Route>
              </Route>
            )}

            {/* Student Routes */}
            {user != null &&
              user.designation == "student" &&
              user.stage == "noninmate" && (
                <Route path="/" element={<StudentHome />}>
                  <Route index element={<ViewDetails />} />
                  {user.enable == true && (
                    <Route path="hostelapply" element={<HostelApplication />} />)}
                  <Route
                    path="noninmatecertificate"
                    element={<CertificatePage />}
                  />
                </Route>
              )}

            {user != null &&
              user.designation == "student" &&
              user.stage == "noninmate" && (
                <Route path="/student" element={<StudentHome />}>
                  <Route index element={<ViewDetails />} />
                  <Route path="hostelapply" element={<HostelApplication />} />
                  <Route
                    path="noninmatecertificate"
                    element={<CertificatePage />}
                  />
                </Route>
              )}

            {/* Inmate Routes */}
            {user != null && user.designation == 'student' && user.stage == 'inmate' && (<Route path="/" element={<InmateHome />}>
              <Route index element={<MessPage />} />
              <Route path="mess" element={<MessPage />} />
              <Route path="mess-bill" element={<InmateMessBillPage />} />
              <Route path="mess-bill-payment-manual" element={<InmateMessBillPaymentPageManual />} />

              <Route path="certificates" element={<CertificatePage />} />
              <Route path="mess-special-events" element={<Mess_Special_Events_Page />} />
              <Route path='mess-special-events-bookings/:id' element={<Mess_Special_Event_BookingList />} />
              <Route path="noninmatecertificate" element={<Mess_Special_Events_Page />} />
              <Route path="messsec" element={<MessSecretary />} />
              <Route path="messdirector" element={<MessDirector />} />
              <Route path="hostel" element={<HostelPage />} />
              <Route path="calculate-mess-bill" element={<CalculateMessBillPage />} />
            </Route>)}

            {user != null && user.designation == 'student' && user.stage == 'inmate' && (<Route path="/" element={<InmateHome />}>
              <Route index element={<MessPage />} />
              <Route path="mess" element={<MessPage />} />
              <Route path="mess-bill" element={<InmateMessBillPage />} />
              <Route path="mess-bill-payment-manual/:id" element={<InmateMessBillPaymentPageManual />} />

              <Route path="certificates" element={<CertificatePage />} />
              <Route path="mess-special-events" element={<Mess_Special_Events_Page />} />
              <Route path='mess-special-events-bookings/:id' element={<Mess_Special_Event_BookingList />} />
              <Route path="noninmatecertificate" element={<Mess_Special_Events_Page />} />
              <Route path="messsec" element={<MessSecretary />} />
              <Route path="messdirector" element={<MessDirector />} />
              <Route path="hostel" element={<HostelPage />} />
              <Route path="calculate-mess-bill" element={<CalculateMessBillPage />} />
            </Route>)}

            {user != null && user.designation == 'student' && user.stage == 'inmate' && (<Route path="/inmate" element={<InmateHome />}>
              <Route index element={<MessPage />} />
              <Route path="mess" element={<MessPage />} />
              <Route path="mess-bill" element={<InmateMessBillPage />} />
              <Route path="mess-bill-payment-manual/:id" element={<InmateMessBillPaymentPageManual />} />

              <Route path="certificates" element={<CertificatePage />} />
              <Route path="mess-special-events" element={<Mess_Special_Events_Page />} />
              <Route path='mess-special-events-bookings/:id' element={<Mess_Special_Event_BookingList />} />
              <Route path="noninmatecertificate" element={<Mess_Special_Events_Page />} />
              <Route path="messsec" element={<MessSecretary />} />
              <Route path="messdirector" element={<MessDirector />} />
              <Route path="hostel" element={<HostelPage />} />
              <Route path="calculate-mess-bill" element={<CalculateMessBillPage />} />
            </Route>)}

            {authenticating == false && (
              <Route path="*" element={<Page404 />} />
            )}

            {/* Matron Routes */}
            {/* <Route path="MTRN" element={<MatronHome/>}>
            <Route index element={<MessBillPage/>}/>
            <Route path="messbill" element={<MessBillPage/>}/>
            <Route path="mess-expense-list" element={<MessExpenseList/>}/>
            <Route path="messoutlist" element={<MessOutListPage/>}/>
            <Route path="uploadmessbill" element={<UploadMessBillPage/>}/>
          </Route> */}
          </Routes>
        </BrowserRouter>
        <ChangePasswordAlertDialog
          open={open1}
          setOpen={setOpen1}
          modalHeading={modalHeading}
          modalText={modalText}
        />
        <BackdropLoading />
      </UserContext.Provider>
    </div>
  );
}

export default App;
