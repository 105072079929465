import axios from 'axios'
import React, { useState, useContext, useEffect } from 'react'
import { Autocomplete, Checkbox, FormControlLabel, Switch, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import dayjs from 'dayjs'
import { baseUrl } from '../../../baseUrl';
import AlertDialog from '../../../components/AlertDialog';
import ConfirmDialog from '../../../components/ConfirmDialog';
import { UserContext } from '../../../Contexts/UserContext';

function ExtendHdf() {

    const { setLoading, user } = useContext(UserContext);
    console.log('user', user)


    const [admissionNo, setAdmissionNo] = useState("")


    const [open1, setOpen1] = useState("");
    const [open2, setOpen2] = useState("");
    const [modalText, setModalText] = useState("");
    const [modalHeading, setModalHeading] = useState("");

    const [applications, setApplications] = useState([]);
    const [applicant, setApplicant] = useState([])
    const [DeadLine, setDeadLine] = useState()
    const [minDeadLine, setMinDeadLine] = useState()
    const [hdfAmount, setHdfAmount] = useState()

    const   handleOptionChange = (event, newValue) => {

        axios.get(`${baseUrl}/warden/get-hdf-deadline`, { params: { admissionNo: newValue.admission_no } }).then((res) => {
            console.log('res.data.deadLine', res.data)
            setDeadLine(res.data.deadLine);
            setApplicant(res.data)
            // setMinDeadLine(res.data.deadLine);
            console.log('res.datvhjva', res.data)
        }).catch(err => {
            console.log('err', err)
            setLoading(false)
            setModalHeading("Request Completed ");
            setModalText(err.response.data.msg);
            setOpen1(true);

        })
        setAdmissionNo(newValue.admission_no)
    }


    const handleSubmit = () => {
        setLoading(true)
        axios.post(`${baseUrl}/warden/extend-hdf`, { admissionNo: admissionNo, DeadLine: DeadLine, hdfAmount: hdfAmount }).then((res) => {
            console.log('res.datass', res.data)
        }).then(res => {
            setLoading(false)

            setModalHeading("Request Completed ");
            setModalText(`Deadline extended to ${DeadLine}`);
            setOpen1(true);

        }).catch(err => {
            console.log('err', err)
            setLoading(false)
            setModalHeading("Error");
            setModalText(err.response.data.msg);
            setOpen1(true);

        })


    }


    const [fees, setFees] = useState([])

    useEffect(() => {
        axios.get(`${baseUrl}/warden/get-all-fee`).then(res => {
            setFees(res.data)
            console.log('res.datass', res.data)
            // console.log('rhi',res.data.find((fee) => fee.fee_id == 3))
        }).catch(err => {
            console.log('err', err)
            setModalHeading("Error");
            setModalText(err.response.data.msg);
            setOpen1(true);

        })
    }, [])
    useEffect(() => {
        axios.get(`${baseUrl}/warden/get-all-applicants`).then((res) => {
            setApplications(res.data);

            console.log('res.datvhjva', res.data)
        });
    }, [])

    return (
        <div className='"w-11/12 m-2">'>
            <h2 className="font-bold ">Enter Details
            </h2>
            <form className="grid grid-cols-2 w-100 gap-2 mb-3 w-11/12 m-2 " onSubmit={(e) => {
                e.preventDefault()
                setModalHeading("Confirm")
                setModalText(`HDF amount to be paid at the time of admission is ${hdfAmount}, extended deadline is ${DeadLine} and remaining amount for which deadline is extended is ${fees.find(fee => fee.fee_id == 13).value - hdfAmount}`)
                setOpen2(true)
            }}>
                <label htmlFor="">College Admission No : </label>{" "}

                <Autocomplete
                    id="auto-complete"
                    options={applications}
                    getOptionLabel={(option) => option.admission_no}
                    className=' min-w-6'
                    // sx={{ width: auto, outline: "null" }}
                    onChange={handleOptionChange}
                    renderInput={(params) => <TextField {...params} label="Admission no" variant="standard" />}

                />
                {applicant.length !== 0 && <>
                    <label htmlFor="">Name :</label>{" "}
                    <p>{applicant?.name}</p>
                    <label>Deahdline :</label>
                    <input onChange={(e) => { setDeadLine(e.target.value) }} defaultValue ={DeadLine} type='date'  min={applicant.deadLine} className=' py-2 px-3 rounded-xl ring-2 ring-slate-300 focus:outline-none' required />
                    <label>HDF Amount to be paid at the time of admission :</label>
                    <input onChange={(e) => { setHdfAmount(e.target.value) }} value={hdfAmount} type='number' min={1000} max={fees.find(fee => fee.fee_id == 13).value} className='py-2 px-3 rounded-xl ring-2 ring-slate-300 focus:outline-none' required />
                    <button disabled={applicant?.allotment_published} className={applicant?.allotment_published ? "bg-[#e0e0e0] text-[#a6a6a6] p-3 rounded-lg text-sm w-1/2" : " bg-stone-800 text-white p-3 rounded-lg text-sm w-1/2"} type='submit'>Submit</button>

                </>
                }

            </form>
            <AlertDialog open={open1} setOpen={setOpen1} modalHeading={modalHeading} modalText={modalText} />
            <ConfirmDialog open={open2} setOpen={setOpen2} modalHeading={modalHeading} modalText={modalText} confirmFunction={handleSubmit} />

        </div>
    )
}

export default ExtendHdf