import { useEffect } from 'react'
import { Outlet, useOutletContext } from 'react-router-dom'


function ClerkA1Home() {

  const [role, setRole]=useOutletContext()
  useEffect(() => {
    setRole("Clerk A1")
  }, [])
  
  return (
    <div className='flex w-full flex-row bg-primary'>
        <Outlet/>
    </div>
  )
}

export default ClerkA1Home