import {useState,useEffect} from 'react'
import ModifyInmateMessAttendanceForm from './ModifyInmateMessAttendanceForm';
import ModifyInmateMessAttendanceList from './ModifyInmateMessAttendanceList';
function ModifyInmateMessAttendancePage({date}) {

const [res,setRes]=useState([])

  return (
   <div className='w-11/12'>
        <ModifyInmateMessAttendanceForm setRes={setRes} date = {date}/>
        <hr/>
        {res.length >0 && <ModifyInmateMessAttendanceList res={res} />}
    </div>
 
  )
}

export default ModifyInmateMessAttendancePage