import { useState, useEffect, useContext } from 'react'

import axios from 'axios'
import MessOutForm from './MessOutForm';
import MessOutHistory from './MessOutHistory';
import suspendImg from '../../../images/11104.jpg'
import { UserContext } from '../../../Contexts/UserContext';
import { baseUrl } from '../../../baseUrl';
function MessOutPage({ noofkdaybefore, setnoofkdaybefore, noofDays, setNoofDays, noofMaxmessoutDays, setnoofMaxmessoutDays, noOfMaxMessOutsinMonth, setnoOfMaxMessOutsinMonth }) {
  const [messOutHistory, setMessOutHistory] = useState([])
  const [isEmpty, setIsEmpty] = useState(true)
  const [editPrevData, seteditPrevData] = useState(false);
  const [isSuspendedMess, SetIsSuspendedMess] = useState(false);

  const { user, setLoading } = useContext(UserContext)


  useEffect(() => {
    setLoading(true)

    axios.get(`${baseUrl}/inmate/is-suspended-mess`, { params: { user_id: user.user_id } })
      .then(res => {

        setLoading(false)
        SetIsSuspendedMess(res.data.isSuspendedMess)

      }).catch(err => {
        setLoading(false)


      })

  }, [])


  return (
    <div className='w-11/12'>
      {isSuspendedMess ? <div className='w-11/12 flex flex-col items-center'>
        <h1 className='text-3xl bold text-red-500'> Yor are suspended from Mess !!</h1>
        <img src={suspendImg} />
      </div> : <MessOutForm isEmpty={isEmpty} setIsEmpty={setIsEmpty} noofkdaybefore={noofkdaybefore} noofDays={noofDays} noofMaxmessoutDays={noofMaxmessoutDays} noOfMaxMessOutsinMonth={noOfMaxMessOutsinMonth} messOutHistory={messOutHistory} setMessOutHistory={setMessOutHistory} />}
      <hr />
      <MessOutHistory editPrevData={editPrevData} seteditPrevData={seteditPrevData} isEmpty={isEmpty} setIsEmpty={setIsEmpty} messOutHistory={messOutHistory} setMessOutHistory={setMessOutHistory} setnoofkdaybefore={setnoofkdaybefore} setNoofDays={setNoofDays} setnoofMaxmessoutDays={setnoofMaxmessoutDays} setnoOfMaxMessOutsinMonth={setnoOfMaxMessOutsinMonth} />
    </div>

  )
}

export default MessOutPage