import editSvg from'../../icons/edit.svg'
import userSvg from'../../icons/user.svg'
import bookSvg from'../../icons/book.svg'
import fitnessSvg from'../../icons/fitness.svg'

const ClerkLinks=[
    {
        title:"Hostel Admission",
        to:"/CLRK/admission",
        icon:fitnessSvg,
    },

    
]

export {ClerkLinks}